import React, { useEffect, useRef, useState } from "react";
import { BackTo, Icon } from "../../components/Component";
import { useNavigate } from "react-router";

const StreamLink = () => {
  const Navigate = useNavigate("");
  const inputRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleClick = () => {
    setShowTooltip(true);
  };
  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      handleClick();
    }
  };
  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5 className="pointer mb-3 d-inline-block" onClick={() => Navigate(-1)}>
              <Icon name="arrow-left" className="pe-1"></Icon>Get .m3u8 link
            </h5>
            <div className="row my-3 justify-content-center">
              <div className="col-xl-10">
                <div className="mt-3">
                  <h6>M3U8 stream link</h6>
                  <p className="opacity-75">
                    Stream your live linear channel in a third-party player, native apps or OTT platforms.
                  </p>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <input
                        type="text"
                        ref={inputRef}
                        readOnly
                        className="form-control"
                        defaultValue="https://app.streamdeck.tv/hls/channel/4354865db1ee52073199408acdb4a2b3.m3u8"
                        required=""
                      />
                      <div className="input-group-append pointer tooltip-container" onClick={copyToClipboard}>
                        <span className="input-group-text" id="basic-addon2">
                          Copy Link
                        </span>
                        {showTooltip && <div className="tooltip-theme">Copied!</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StreamLink;
