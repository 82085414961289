import React, { useEffect, useState, useRef } from "react";
import "video.js/dist/video-js.css";
import MediaStreamRecorder from "msr";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { Icon } from "../../components/Component";
import {
  Spinner,
  Tooltip,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Alert,
} from "reactstrap";
import Hls from "hls.js";
import Swal from "sweetalert2";
import { formatSeconds, getCookie } from "../../utils/Utils";
import { useNavigate, useParams } from "react-router";
import { MediaPlayer, MediaProvider, Poster } from "@vidstack/react";
import { DefaultAudioLayout, defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import RecordRTC from "recordrtc";
import TwitterNewLogo from "../../images/TwitterNewLogo";
import useStorageUsage from "../hooks/useStorageUsage";
const LiveStream1 = ({
  stream,
  setStream,
  mediaRecorder,
  setMediaRecorder,
  setIsPlaying,
  isPlaying,
  countdown,
  setCountdown,
  isAuthorized,
  setIsAuthorized,
  userId,
  isAuthorizedOBS,
  setIsAuthorizedOBS,
  isRecording,
  setIsRecording,
}) => {
  const randomString = () => {
    return (~~(Math.random() * Math.pow(32, 6))).toString(32);
  };
  // const [mediaRecorder, setMediaRecorder] = useState(null);
  const [name, setName] = useState(randomString());
  const videoRefsecond = useRef(null);
  // const [isAuthorized, setIsAuthorized] = useState(true);
  const [responseUrl, setResponseUrl] = useState("");
  // const [isRecording, setIsRecording] = useState(false);
  // const [stream, setStream] = useState(null);
  const DT = 10000;
  const serverLink = process.env.REACT_APP_EXPRESS_URL;
  var count = 0;
  const [isAuthorizedbtn, setIsAuthorizedBtn] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [sessionIdWebm, setSessionIdWebm] = useState(null);
  const [videos, setVideos] = useState([]);
  const [webmvideos, setwebmVideos] = useState([]);
  const [startStreamingTooltipOpen, setStartStreamingTooltipOpen] = useState(false);
  const [stopStreamingTooltipOpen, setStopStreamingTooltipOpen] = useState(false);
  // const [countdown, setCountdown] = useState(0);
  const [modalLg, setModalLg] = useState(false);
  const toggleLg = () => setModalLg(!modalLg);
  const [modalVideo, setModalVideo] = useState(null); // State to track the video for the modal
  const [recorder, setRecorder] = useState(null);
  const [isRecordingwebm, setIsRecordingwebm] = useState(true);
  const [stopRecordingTooltipOpen, setStopRecordingTooltipOpen] = useState(false);
  const [startRecordingTooltipOpen, setStartRecordingTooltipOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const toggleStopRecordingTooltip = () => setStopRecordingTooltipOpen(!stopRecordingTooltipOpen);
  const toggleStartRecordingTooltip = () => setStartRecordingTooltipOpen(!startRecordingTooltipOpen);
  const [videoThumbnail, setVideThumbnail] = useState(null);
  const [duration, setDuration] = useState(null);
  const { storageDetails, loading, error } = useStorageUsage();
  const [isstorageFilled, setIsStorageFilled] = useState(false);

  const handleModalOpen = (videoURL, thumbnail, duration) => {
    console.log(videoURL);
    setModalVideo(videoURL);
    setVideThumbnail(thumbnail);
    setDuration(duration);
  };

  const handleModalClose = () => {
    setModalVideo(null);
  };
  const toggleModal = (video, thumbnail, duration) => {
    // e.preventDefault();
    setModalVideo(video.relativePath); // Set the selected video for the modal
    setVideThumbnail(thumbnail);
    setDuration(duration);
  };

  const deleteWebVideo = async (videoId) => {
    // console.log(userId, folder, fileName);
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      const formData = new FormData();
      formData.append("videoId", videoId);
      try {
        const response = await fetch(`${process.env.REACT_APP_LARAVEL_URL}videos/deletewebvideos`, {
          method: "POST",
          headers: {
            authToken: getCookie("authToken", "null"),
          },
          body: formData,
        });
        const result = await response.json();
        if (response.ok) {
          toast.success(result.success);
          fetchWebmVideos();
        } else {
          toast.success(result.error);
        }
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", "An error occurred while deleting the file.", "error");
      }
    }
  };

  const deleteVideo = async (userId, folder, event) => {
    console.log(userId, folder);
    event.preventDefault();
    // Show confirmation dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      const formData = new FormData();
      formData.append("userId", userId);
      try {
        const response = await fetch(`${process.env.REACT_APP_LARAVEL_URL}videos/${userId}/${folder}`, {
          method: "POST",
          headers: {
            authToken: getCookie("authToken", "null"),
          },
        });
        // fetchVideos();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const updatesetUserid = (userId) => {
  //   setUserId(userId);
  // };

  useEffect(() => {
    console.log("responseUrl userId", userId);
    const newSessionId = generateSessionId();
    setSessionId(newSessionId);
    const newSessionIdWebm = generateSessionIdWebm();
    setSessionIdWebm(newSessionIdWebm);
    const player = document.getElementById("player");
    if (player) {
      player.muted = true; // Unmute the player
      player.volume = 0.5; // Set initial volume to 50% (0.0 to 1.0)
    }

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(responseUrl);
      hls.attachMedia(player);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
          const segmentUrl = data.frag.url;
          // fetchAndSaveSegment(segmentUrl);
        });
      });
    }

    return () => {
      if (player) {
        player.removeAttribute("src");
      }
    };
  }, [responseUrl]);

  const generateSessionId = () => {
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    return `${currentDate}${randomString}`;
  };
  const generateSessionIdWebm = () => {
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    return `${currentDate}${randomString}`;
  };

  const fetchAndSaveSegment = (segmentUrl) => {
    fetch(segmentUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer]);
        const formData = new FormData();
        formData.append("file", blob, "segment.ts");
        formData.append("sessionId", sessionId);
        formData.append("userId", userId);
        const authToken = getCookie("authToken", null); // Make sure getCookie is defined
        // const authToken=localStorage.getItem("authToken");
        const headers = new Headers();
        headers.append("authToken", authToken);
        // fetch(`${process.env.REACT_APP_EXPRESS_URL}upload-m3u8-stream`, {
        fetch(`${process.env.REACT_APP_LARAVEL_URL}upload-m3u8-stream`, {
          method: "POST",
          body: formData,
          headers: headers,
        })
          .then((response) => {
            if (response.ok) {
              console.log("Segment uploaded successfully");
            } else {
              console.error("Error uploading segment");
              toast.error("Error uploading segment");
            }
          })
          .catch((error) => {
            console.error("Error uploading segment:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching segment:", error);
      });
  };
  useEffect(() => {}, [userId]);
  useEffect(() => {
    if (userId && storageDetails.storageFilledAlert !== undefined) {
      setIsStorageFilled(storageDetails.storageFilledAlert);
      fetchWebmVideos();
    }
    console.log("userId:", userId);
  }, [userId, storageDetails]);

  console.log("Is Storage Filled:", isstorageFilled);

  // const fetchVideos = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_EXPRESS_URL}videos/${userId}`);
  //     setVideos(response.data);
  //     toast.success("Videos fetched successfully");
  //   } catch (error) {
  //     console.error("Error fetching videos:", error);
  //   }
  // };
  const [videoloading, setVideoLoading] = useState(false);
  // const fetchVideos = async () => {
  //   try {
  //     setVideoLoading(true);
  //     const authToken = getCookie("authToken", null);
  //     const headers = new Headers();
  //     headers.append("authToken", authToken);

  //     const response = await fetch(`${process.env.REACT_APP_LARAVEL_URL}videos/${userId}`, {
  //       method: "GET",
  //       headers: headers,
  //     });

  //     if (!response.ok) {
  //       setVideos([]);
  //       throw new Error("Failed to fetch videos");
  //     }

  //     const data = await response.json();

  //     const videoArray = Object.values(data);
  //     setVideos(videoArray);
  //     toast.success("Videos fetched successfully");
  //   } catch (error) {
  //     console.error("Error fetching videos:", error);
  //     setVideos([]);
  //   } finally {
  //     setVideoLoading(false);
  //   }
  // };
  const fetchWebmVideos = async () => {
    try {
      const authToken = getCookie("authToken", null);
      const headers = new Headers();
      headers.append("authToken", authToken);
      const response = await fetch(`${process.env.REACT_APP_LARAVEL_URL}get-webm-videos/${userId}`, {
        method: "POST",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }
      const data = await response.json();
      const videoArray = Array.isArray(data) ? data : [];
      setwebmVideos(videoArray);
      if (data.length > 0) {
        toast.success("Videos fetched successfully");
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
      setwebmVideos([]);
      // toast.error("Failed to fetch videos");
    }
  };

  const toggleStartStreamingTooltip = () => {
    setStartStreamingTooltipOpen(!startStreamingTooltipOpen);
  };

  const toggleStopStreamingTooltip = () => {
    setStopStreamingTooltipOpen(!stopStreamingTooltipOpen);
  };

  useEffect(() => {
    const startCamera = async () => {
      try {
        const mediaConstraints = {
          audio: true,
          video: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        setStream(stream);
        videoRefsecond.current.srcObject = stream;
        onMediaSuccess(stream, DT);
        if (videoRefsecond.current) {
          videoRefsecond.current.pause();
        }
      } catch (error) {
        onMediaError(error);
      }
    };

    startCamera();
  }, []);

  const zeroPad = (n, chars) => {
    const s = "" + n;
    const l = s.length;
    return new Array(chars - l + 1).join("0") + s;
  };

  const log = (msg) => {
    console.log(msg);
  };
  const [streamstatus, setStreamStatus] = useState("1");
  const [alertSet, setAlertSet] = useState(false);
  const send = (url, blob) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.responseType = "text/plain";
    xhr.setRequestHeader("Content-Type", "video/webm");

    xhr.onload = function (e) {
      if (this.status === 200) {
        console.log(this.response);
        const responseData = JSON.parse(this.response);
        console.log(responseData.filePath);
        setResponseUrl(responseData.filePath);
        if (!alertSet) {
          setAlert(true);
          setAlertSet(true);
        } else {
          setAlert(false);
        }
        if (!url.includes("/finish")) {
          updateLiveStreamUrl(responseData.filePath, streamstatus);
        }
      }
    };

    xhr.send(blob);
  };
  const { stream_name } = useParams();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setAlert(false);
  //   }, 2000);
  // }, [alert]);
  // console.log("Stream Name", stream_name);
  const updateLiveStreamUrl = async (live_url, stream_status) => {
    try {
      const formData = new FormData();
      formData.append("live_url", live_url);
      formData.append("stream_name", stream_name);
      formData.append("stream_status", stream_status);
      const response = await fetch(`${process.env.REACT_APP_API_URL}update-live-stream-url`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      console.log(responseData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {}, [isAuthorized]);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      const counter = document.getElementById("countdownid");
      if (counter) {
        counter.classList.add("countdown");
      }
      return () => clearInterval(timer);
    } else {
      setCountdown(null);
      if (countdown === null || countdown === 0) {
        const counter = document.getElementById("countdownid");
        if (counter) {
          counter.classList.remove("countdown");
        }
      }
    }
  }, [countdown]);

  const onMediaSuccess = (stream, DT) => {
    log("will start capturing and sending " + DT / 1000 + "s videos when you press start");
    const mediaRecorderInstance = new MediaStreamRecorder(stream);
    mediaRecorderInstance.mimeType = "video/webm";
    mediaRecorderInstance.ondataavailable = (blob) => {
      count++;
      const count2 = zeroPad(count, 5);
      log("sending chunk " + name + " #" + count2 + "...");
      send(`${serverLink}chunk/${name}/${count2}`, blob);
    };
    setMediaRecorder(mediaRecorderInstance);
    setName(randomString);
  };

  const onMediaError = (e) => {
    log("media error occurred: " + e.message);
  };
  const [alertloader, setAlertLoader] = useState(false);
  const startRecording = () => {
    captureUserMedia(captureUserMediaAndRecord);
    if (videoRefsecond.current) {
      videoRefsecond.current.currentTime = 0;
      videoRefsecond.current.play();
    }
    setIsAuthorized(true);
    setIsAuthorizedOBS(true);
    setCountdown(15);
    setIsPlaying(true);
    count = 0;
    console.log("mediaRecorder", mediaRecorder);

    const startCameraAndRecording = async () => {
      try {
        const mediaConstraints = {
          audio: true,
          video: true,
        };
        const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
        setStream(stream);
        videoRefsecond.current.srcObject = stream;
        onMediaSuccess(stream, DT);

        // Reset count variable
        count = 0;

        // Initialize mediaRecorder
        const mediaRecorderInstance = new MediaStreamRecorder(stream);
        mediaRecorderInstance.mimeType = "video/webm";
        mediaRecorderInstance.ondataavailable = (blob) => {
          count++;
          const count2 = zeroPad(count, 5);
          console.log("sending chunk " + name + " #" + count2 + "...");
          send(`${serverLink}chunk/${name}/${count2}`, blob);
        };
        setMediaRecorder(mediaRecorderInstance);
        setName(randomString);

        // Start recording
        setIsRecording(true);
        mediaRecorderInstance.start(DT);
      } catch (error) {
        onMediaError(error);
      }
    };

    if (!mediaRecorder) {
      startCameraAndRecording();
    } else {
      setIsRecording(true);
      mediaRecorder.start(DT);
    }
  };

  const captureUserMedia = (successCallback) => {
    console.log("successCallback", successCallback);
    const session = { audio: true, video: true };
    navigator.mediaDevices
      .getUserMedia(session)
      .then(successCallback)
      .catch((error) => {
        toast.error("Unable to capture your camera.");
        console.error(error);
      });
  };
  const captureUserMediaAndRecord = (stream) => {
    const recorderInstance = new RecordRTC(stream, {
      type: "video",
    });
    console.log("recorderInstance", recorderInstance);
    recorderInstance.startRecording();
    setRecorder(recorderInstance);
  };
  useEffect(() => {}, [isRecordingwebm]);
  const [isRecordingButton, setIsRecordingButton] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const stopRecordingLocal = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        postFiles(blob);
      });
      setIsRecordingwebm(false);
      setIsRecordingButton(false);
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };
  const startRecordingLocal = () => {
    if (recorder) {
      if (isstorageFilled) {
        Swal.fire({
          title: "Storage Alert",
          html: "<span class='fw-bold'>Your storage is <strong>90% full</strong>. Please free up some space</span>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Buy storage subscription",
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(`${process.env.REACT_APP_ACCOUNT_URL}subscriptions?tab=serviceSubscriptions`, "_blank");
          }
        });
        return;
      }
      generateSessionIdWebm();
      captureUserMedia(captureUserMediaAndRecord);
      setIsRecordingwebm(true);
      setIsRecordingButton(true);
      setRecordingTime(0);
      const id = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
      setIntervalId(id);
    }
  };
  const toggleRecording = () => {
    if (isRecordingButton) {
      stopRecordingLocal();
    } else {
      startRecordingLocal();
    }
  };
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  // Clean up interval on component unmount
  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);
  const postFiles = (blob) => {
    const fileName = generateRandomString() + ".webm";
    const file = new File([blob], fileName, {
      type: "video/webm",
    });
    generateSessionIdWebm();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("sessionId", sessionIdWebm);
    formData.append("userId", userId);
    const authToken = getCookie("authToken", null);

    const headers = new Headers();
    headers.append("authToken", authToken);
    const uploadPromise = fetch(`${process.env.REACT_APP_LARAVEL_URL}upload-video`, {
      method: "POST",
      body: formData,
      headers: headers,
    });

    toast
      .promise(
        uploadPromise.then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              console.log("Video uploaded successfully", data);
              fetchWebmVideos();
              return data.success;
            });
          } else {
            throw new Error("Error uploading video");
          }
        }),
        {
          pending: "Uploading your video, please wait...",
          success: "Video uploaded successfully! 🎉",
          error: "Error uploading video 😞",
        }
      )
      .catch((error) => {
        console.error("Error uploading video:", error);
      });
  };

  const generateRandomString = () => {
    if (window.crypto) {
      const a = window.crypto.getRandomValues(new Uint32Array(3));
      return Array.from(a, (num) => num.toString(36)).join("");
    } else {
      return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, "");
    }
  };

  const stopCamera = () => {
    stream.getTracks().forEach((track) => {
      track.stop();
      track.enabled = false;
      setStreamStatus("0");
      updateLiveStreamUrl(null, streamstatus);
    });
  };

  const stopRecording = () => {
    setIsPlaying(false);
    setIsRecording(false);
    setIsAuthorized(false);
    setIsAuthorizedBtn(true);
    setAlertLoader(true);
    setIsRecordingwebm(false);
    if (videoRefsecond.current) {
      videoRefsecond.current.pause();
    }
    if (isRecordingButton) {
      stopRecordingLocal();
    }
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      send(`${serverLink}chunk/${name}/finish`);
      // setResponseUrl(null);
    }
    stopCamera();
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadResponse, setUploadResponse] = useState("");
  const [volume, setVolume] = useState(1);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const addToMediaLibrary = async (event, folder, filepath, thumbnail_path, duration) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", folder);
      formData.append("filepath", filepath);
      formData.append("thumbnail", thumbnail_path);
      formData.append("duration", duration);
      const response = await fetch(`${process.env.REACT_APP_API_URL}add-to-media-library`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status) {
        toast.success("Video Added To Media Library Succesfully");
        navigate("/videos");
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [modalZoom, setModalZoom] = useState(false);
  const [videoLink, setVideoLink] = useState();
  const toggleZoom = () => setModalZoom(!modalZoom);
  const shareStream = (ev, videoLinks) => {
    ev.preventDefault();
    toggleZoom();
    setVideoLink(videoLinks);
  };
  const copyStreamLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(videoLink)
        .then(() => {
          toast.success("Video link copied to clipboard");
        })
        .catch((error) => {
          console.error("Error copying video link to clipboard:", error);
          toast.error("Failed to copy video link to clipboard");
        });
    } else {
      console.error("Clipboard API not supported");
      toast.error("Failed to copy video link to clipboard");
    }
  };
  const socialMediaSharing = (value) => {
    if (value === "Twitter") {
      const url = new URL("https://x.com/intent/post", window.location.origin);
      url.searchParams.set("text", "Hey, watch my SiloLoveStream!"); // Prepend text
      if (videoLink) {
        url.searchParams.set("url", videoLink);
      }
      window.open(url.toString(), "_blank");
    } else if (value === "Whatsapp") {
      const url = new URL(
        "https://wa.me/?text=I'm%20inquiring%20about%20the%20apartment%20listing",
        window.location.origin
      );
      url.searchParams.set("text", `Hey, watch my SiloLoveStream! ${videoLink}`);
      window.open(url.toString(), "_blank");
    }
  };
  const [backgroundimage, setBackgroundImage] = useState();
  useEffect(() => {
    const profileurl = getCookie("profile_pic");
    const username = getCookie("username") || "Pbelvalkar";
    const videoProfile =
      profileurl && profileurl !== "null"
        ? profileurl
        : `https://eu.ui-avatars.com/api/?name=${username}&size=70&rounded=true&background=e14954&color=ffffff`;

    setBackgroundImage(videoProfile);
  }, []);
  return (
    <>
      <div className="live-streaming-container">
        {!isAuthorizedbtn && <div className="notice">please accept webcam permissions above</div>}
        {isAuthorizedbtn && (
          <div className="gui mb-3">
            {!isRecording && (
              <React.Fragment>
                {/* <button
                  id="b1"
                  className="me-1 btn btn-light"
                  onClick={() => {
                    startRecording();
                  }}
                  onMouseEnter={toggleStartStreamingTooltip}
                  onMouseLeave={toggleStartStreamingTooltip}
                >
                  <Icon name="video-fill"></Icon> <span>Start Streaming </span>
                </button> */}
                {/* <Tooltip
                  placement="top"
                  isOpen={startStreamingTooltipOpen}
                  target="b1"
                  toggle={toggleStartStreamingTooltip}
                >
                  Start streaming
                </Tooltip> */}
              </React.Fragment>
            )}

            {isRecording && (
              <>
                {/* <button
                  id="stopRecordingBtn"
                  className="me-1 mt-10 btn btn-light mx-2"
                  onClick={stopRecordingLocal}
                  onMouseEnter={toggleStopRecordingTooltip}
                  onMouseLeave={toggleStopRecordingTooltip}
                  disabled={!isRecordingwebm}
                >
                  <span>Stop Recording</span>
                </button> */}
                {/* <Tooltip
                  placement="top"
                  isOpen={stopRecordingTooltipOpen}
                  target="stopRecordingBtn"
                  toggle={toggleStopRecordingTooltip}
                >
                  Stop recording the current session
                </Tooltip> */}

                {/* <button
                  id="startRecordingBtn"
                  className="me-1 mt-10 btn btn-light"
                  onClick={startRecordingLocal}
                  onMouseEnter={toggleStartRecordingTooltip}
                  onMouseLeave={toggleStartRecordingTooltip}
                  disabled={isRecordingwebm}
                >
                  <span>Start Recording</span>
                </button> */}
                {/* <Tooltip
                  placement="top"
                  isOpen={startRecordingTooltipOpen}
                  target="startRecordingBtn"
                  toggle={toggleStartRecordingTooltip}
                >
                  Start a new recording session
                </Tooltip> */}
              </>
            )}
            {isRecording && (
              // <React.Fragment>
              //   <button
              //     id="b2"
              //     className="btn btn-danger ml-2"
              //     onClick={stopRecording}
              //     onMouseEnter={toggleStopStreamingTooltip}
              //     onMouseLeave={toggleStopStreamingTooltip}
              //   >
              //     <Icon name="video-off"></Icon> <span>Stop streaming </span>
              //   </button>
              //   <Tooltip
              //     placement="top"
              //     isOpen={stopStreamingTooltipOpen}
              //     target="b2"
              //     toggle={toggleStopStreamingTooltip}
              //   >
              //     Stop streaming
              //   </Tooltip>
              // </React.Fragment>
              <></>
            )}
          </div>
        )}
        {/* {alert && (
        <Alert className={`alert-pro livealert`} color="danger">
          <div className="alert-text d-flex flex-row align-items-center">
            <div>
              <LiveComponent />
            </div>
            <div className="ms-3">
              <span className="fw-bold text-dark fs-3">You are live Now</span>
            </div>
          </div>
        </Alert>
      )} */}
        {alertloader && (
          <div className="loader-spinner-custom d-flex flex-column justify-content-center align-items-center">
            <Spinner color="dark" style={{ width: "3rem", height: "3rem" }} />
            <span className="text-center mt-1 fs-6">
              Please wait while your stream is being saved to the server. <br /> This process may take a few moments.
              Thank you for your patience.
            </span>
          </div>
        )}
        <div className="row">
          <div className="">
            <div className="video-container rtmp" style={{ marginTop: "36px" }}>
              {isAuthorized && (
                <>
                  <div class="video-container-cam">
                    {isRecording && (
                      <div className="live-videoeditor">
                        <button
                          class="btn-round btn btn-lg fw-lighter"
                          style={{ backgroundColor: "rgb(15, 14, 14, 30%)", color: "#ffffff" }}
                        >
                          <Icon name="live"></Icon>
                          <span>Live</span>
                        </button>
                      </div>
                    )}
                    <video
                      ref={videoRefsecond}
                      className="w-100 top-video border border-1 border-primary livecameradiv"
                      playsInline
                      autoPlay
                      muted={true}
                    />
                    {!isRecording && (
                      <div className="middle-button" style={{ backgroundImage: `url(${backgroundimage})` }} />
                    )}
                    <div className="start-stop-stream-button">
                      {!isRecording ? (
                        <>
                          <button
                            class="btn-round btn btn-lg bg-secondary text-white"
                            id="b1"
                            // style={{ backgroundColor: "#5BC236", color: "#ffffff" }}
                            onClick={() => {
                              startRecording();
                            }}
                          >
                            <Icon name="video-fill"></Icon>
                            <span>Start Streaming</span>
                          </button>
                        </>
                      ) : (
                        <button
                          class="btn-round btn btn-lg btn-danger"
                          id="b2"
                          style={{ color: "#ffffff" }}
                          onClick={stopRecording}
                        >
                          <Icon name="video-off"></Icon>
                          <span>Stop Streaming</span>
                        </button>
                      )}
                    </div>
                    {isRecording && (
                      <div className="recordStream">
                        <Button
                          className="btn-round btn-icon border border-0"
                          color="primary"
                          style={{
                            backgroundColor: isRecordingButton ? "#ffffff" : "#f0f0f0",
                            height: "30.28px",
                            width: "30.28px",
                          }}
                          onClick={toggleRecording}
                        >
                          <Icon
                            name="bullet-fill"
                            className="fs-3"
                            style={{ color: isRecordingButton ? "#EB5757" : "#000000" }}
                          />
                        </Button>
                      </div>
                    )}
                    {isRecording && (
                      <div className="recordTiming">
                        <button
                          className="btn-round btn btn-lg"
                          style={{ backgroundColor: "rgba(15, 14, 14, 0.3)", color: "#ffffff" }}
                        >
                          <Button
                            className="btn-round btn-icon border border-0"
                            color="primary"
                            style={{ backgroundColor: "#ffffff", height: "30.28px", width: "30.28px" }}
                            // onClick={toggleRecording}
                          >
                            <Icon name="bullet-fill" className="fs-3" style={{ color: "#EB5757" }} />
                          </Button>
                          <span className="ms-2 ff-mono">{formatTime(recordingTime)}</span>
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              {!isAuthorized && (
                // <img
                //   src={require("../../images/Livestreaming/Placeholder.jpg")}
                //   alt="Live stream"
                //   className="stream-image1"
                // />
                <></>
              )}
              {isRecording && (
                // <img
                //   src={require("../../images/Livestreaming/record.png")}
                //   alt="Live stream"
                //   className="stream-image"
                // />
                <></>
              )}
            </div>
            <div className="">
              <div className="my-2">
                <p className="text-primary">{uploadResponse}</p>
                {/* {responseUrl && <p>Response URL: {responseUrl}</p>} */}
              </div>
              {/* <div className="row cameralivefetchvideos">
                <h6 className="mt-2">Live Stream Videos</h6>
                {videoloading ? (
                  <>
                    <ShimmerSimpleGallery card imageHeight={160} caption />;
                  </>
                ) : videos.length === 0 && videos ? (
                  <div className="col-12 text-center"></div>
                ) : (
                  videos.map((video) => (
                    <div key={video.folder} className="col-sm-6 col-lg-4 col-xxl-3 pointer mt-1 ">
                      <Card
                        className="card-bordered d-flex align-items-center border-0 position-relative"
                        style={{
                          backgroundImage: `url(${video.thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "211px 160px",
                        }}
                      >
                        <div className="cameralivefetchvideosbadge">
                          <Badge color="secondary">{video.video_time}</Badge>
                        </div>
                        <div className="cameralivefetchvideosbadgeduration fw-bold text-dark">
                          <Badge color="black border-0">{formatSeconds(video.duration)}</Badge>
                        </div>
                        <div className="cameralivefetchvideosdelete">
                          <UncontrolledDropdown>
                            <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                              <Icon name="more-v"></Icon>
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#links"
                                    onClick={(ev) => shareStream(ev, video.relativePath)}
                                  >
                                    <Icon name="share"></Icon>
                                    <span>Share Stream</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#links"
                                    onClick={(e) => deleteVideo(video.userId, video.folder, e)}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#links"
                                    onClick={(ev) =>
                                      addToMediaLibrary(
                                        ev,
                                        video.folder,
                                        video.filepath,
                                        video.thumbnail_path,
                                        video.duration
                                      )
                                    }
                                  >
                                    <Icon name="plus"></Icon>
                                    <span>Add To Media Library</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="product-thumb">
                          <ReactPlayer
                            url={`${video.link}`}
                            muted={true}
                            width="100%"
                            height="180px"
                            poster={video.thumbnail}
                          />
                          <ul className="product-actions">
                            <Button
                              className="btn-round btn-icon cameralivefetchvideosbutton"
                              size="lg"
                              onClick={() => toggleModal(video, video.thumbnail, video.duration)}
                            >
                              <Icon name="play-fill" />
                            </Button>
                          </ul>
                        </div>
                      </Card>
                    </div>
                  ))
                )}
              </div> */}
              <hr />
              <div className="row cameralivefetchvideos">
                {webmvideos.length > 0 && <h6 className="mt-2">Recorded videos</h6>}

                {webmvideos.length === 0 ? (
                  <>
                    <div className="col-12 text-center">
                      <h3></h3>
                    </div>
                  </>
                ) : (
                  webmvideos.map((video) => (
                    <>
                      <div key={video.folder} className="col-sm-6 col-lg-4 col-xxl-3 pointer mt-1 ">
                        <Card
                          className="card-bordered d-flex align-items-center border-0 position-relative"
                          style={{
                            backgroundImage: `url(${video.thumbnail})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "211px 160px",
                          }}
                        >
                          <div className="cameralivefetchvideosbadge">
                            <Badge color="secondary">{video.date}</Badge>
                          </div>
                          <div className="cameralivefetchvideosbadgeduration fw-bold text-dark">
                            <Badge color="black border-0">{formatSeconds(video.duration)}</Badge>
                          </div>
                          <div className="cameralivefetchvideosdelete">
                            {/* <Button className="btn-icon" color="danger" size="md">
                          <Icon name="trash" />
                        </Button> */}
                            <UncontrolledDropdown>
                              <DropdownToggle className="dropdown-toggle btn btn-icon btn-light">
                                <Icon name="more-v"></Icon>
                              </DropdownToggle>
                              <DropdownMenu>
                                <ul className="link-list-opt">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#links"
                                      onClick={(ev) => shareStream(ev, video.fileURL)}
                                    >
                                      <Icon name="share"></Icon>
                                      <span>Share Stream</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" href="#links" onClick={() => deleteWebVideo(video.video_id)}>
                                      <Icon name="trash"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#links"
                                      onClick={(ev) =>
                                        addToMediaLibrary(
                                          ev,
                                          video.fileName,
                                          video.relative_path,
                                          video.thumbnails_relative,
                                          video.duration
                                        )
                                      }
                                    >
                                      <Icon name="plus"></Icon>
                                      <span>Add To Media Library</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <div className="product-thumb">
                            <ReactPlayer
                              url={`${video.link}`}
                              muted={true}
                              width="100%"
                              height="180px"
                              poster={video.thumbnail}
                            />
                            <ul className="product-actions">
                              {/* <li>
                            <Icon name="eye" onClick={() => toggleModal(video)} />
                          </li>
                          <li className="pointer" onClick={() => deleteVideo(video.userId, video.folder)}>
                            <Icon name="trash"></Icon>
                          </li> */}
                              <Button
                                className="btn-round btn-icon cameralivefetchvideosbutton"
                                size="lg"
                                onClick={() => handleModalOpen(video.m3u8Path, video.thumbnail, video.floatDuration)}
                              >
                                <Icon name="play-fill" />
                              </Button>
                            </ul>
                          </div>
                          {/* <div className="text-center">
                        <a href={video.link} target="_blank" rel="noopener noreferrer">
                          {video.video_time}
                        </a>
                      </div> */}
                        </Card>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
          </div>
          {/* <Modal size="lg" isOpen={modalVideo !== null} toggle={handleModalClose}>
            <ModalHeader toggle={handleModalClose}>Video Player</ModalHeader>
            <ModalBody style={{ height: "504px" }}>
              {modalVideo && (
                <ReactPlayer
                  url={modalVideo}
                  controls
                  muted={true}
                  width="100%"
                  height="100%"
                  light={require("../../images/Livestreaming/Placeholder.jpg")} // Assuming a placeholder image is provided
                />
              )}
            </ModalBody>
          </Modal> */}

          <Modal size="lg" isOpen={modalVideo !== null} toggle={() => setModalVideo(null)}>
            <button type="button" className="mfp-close" onClick={() => setModalVideo(null)}>
              ×
            </button>
            {modalVideo ? (
              <MediaPlayer
                title="StreamDeck"
                src={modalVideo}
                muted
                autoPlay
                playsInline
                poster={videoThumbnail}
                clipStartTime={0}
                clipEndTime={duration}
              >
                <MediaProvider>
                  <Poster className="vds-poster" />
                </MediaProvider>
                <DefaultAudioLayout icons={defaultLayoutIcons} />
                <DefaultVideoLayout icons={defaultLayoutIcons} />
              </MediaPlayer>
            ) : (
              ""
            )}
          </Modal>

          <div className="">
            {/* <div className="video-container position-relative">
            <video
              id="player"
              controls
              className="m-auto w-100"
              autoPlay
              muted={true}
              poster={require("../../images/Livestreaming/Placeholder.jpg")}
            />
            <h2 className="countdown" id="countdownid">
              {countdown}{" "}
            </h2>
          </div> */}
            {/* <Updatechannel userId={userId} setUserid={updatesetUserid} /> */}
          </div>
        </div>
      </div>
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom">
        <ModalHeader
          toggle={toggleZoom}
          className="d-flex justify-content-between border-0 ff-mono"
          close={
            <Button className="btn-round btn-icon" color="gray" size="md" onClick={toggleZoom}>
              <Icon name="cross" />
            </Button>
          }
        >
          Share this stream
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-around">
            <div className="d-flex flex-column">
              <Button
                className="btn-round btn-icon bg-gray-100 d-flex justify-content-center twitter-button"
                color=""
                size="xl"
                style={{ height: "54.53px", width: "54.53px" }}
                onClick={() => socialMediaSharing("Twitter")}
              >
                {/* <Icon name="twitter" /> */}
                <TwitterNewLogo />
              </Button>
              <span className="ff-mono text-center mt-2">X</span>
            </div>
            <div className="d-flex flex-column">
              <Button className="btn-round btn-icon bg-gray-100 reddit-button" color="" size="xl">
                <Icon name="reddit" />
              </Button>
              <span className="ff-mono text-center mt-2">Reddit</span>
            </div>
            <div className="d-flex flex-column">
              <Button className="btn-round btn-icon bg-gray-100 d-inline-block facebook-button" color="" size="xl">
                <Icon name="facebook-f" style={{ position: "relative", top: "4px" }} />
              </Button>
              <span className="ff-mono text-center mt-1">Facebook</span>
            </div>
            <div className="d-flex flex-column">
              <Button
                className="btn-round btn-icon bg-gray-100 d-inline-block whatsapp-button"
                color=""
                size="xl"
                onClick={() => socialMediaSharing("Whatsapp")}
              >
                <Icon name="whatsapp" style={{ position: "relative", top: "3px" }} />
              </Button>
              <span className="ff-mono text-center mt-1">WhatsApp</span>
            </div>
          </div>
          <div className="mt-1">
            <span>Or copy link</span>
            <div className="form-group mt-3">
              <div className="form-control-wrap">
                <div className="form-icon form-icon-right">
                  <Icon name="copy" className="pointer" onClick={copyStreamLink} />
                </div>
                <textarea
                  className="form-control border-0 bg-gray-100 shadow"
                  type="text"
                  id="default-3"
                  value={videoLink ? videoLink : ""}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LiveStream1;
