import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { Icon } from "../../components/Component";
import Dropzone from "react-dropzone";
import Tabmenu from "../app/Tabmenu";

const Medialibrary = () => {
  const [files, setFiles] = useState([]);
  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const [images, setImages] = useState([
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ]);

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0].original);
    }
  }, [images]);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handlePrevClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };
  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="row">
                <div className="col-lg-4 mediadropzone mb-2">
                  <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} className="dropzone upload-zone border-danger dz-clickable">
                          <input {...getInputProps()} />
                          {files.length === 0 && (
                            <div className="dz-message my-0">
                              <Icon name="plus" className="icon-circle icon-circle-lg bg-transparent text-dark" />
                            </div>
                          )}
                          {files.map((file) => (
                            <div
                              key={file.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img src={file.preview} alt="preview" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {images.map((image, index) => (
                  <div className="col-lg-4 mb-3 media-image-prview" key={index}>
                    <img
                      src={image.thumbnail}
                      alt={`Thumbnail ${index}`}
                      className={`pointer img-fluid ${selectedImageIndex === index ? "set-active" : ""}`}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6">
              <img src={images[selectedImageIndex].original} alt="" className="main-image" />
              <div className="prev-next-buttons my-2 text-center">
                <button onClick={handlePrevClick} className="btn bg-light mx-1">
                  <Icon name="first"></Icon>
                </button>
                <button onClick={handleNextClick} className="btn bg-light mx-1">
                  <Icon name="last"></Icon>
                </button>
                {/* <button onClick={toggleFullscreen} className="btn bg-light mx-1">
                  {isFullscreen ? <Icon name="minimize "></Icon> : <Icon name="maximize"></Icon>}
                </button> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="row line-height">
                <div className="col-6">
                  <ul className="text-end">
                    <li>File Name :</li>
                    <li>File Size :</li>
                    <li>Format :</li>
                    <li>Streamkey :</li>
                    <li>RTMP Attachment :</li>
                    {/* <li>Parameters :</li> */}
                    <li>File Place :</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="">
                    <li>City View</li>
                    <li>250 mb</li>
                    <li>MP4</li>
                    <li>bcuiysdfiojlkauio</li>
                    <li>Jgsyb56dafggoibcav</li>
                    {/* <li>null</li> */}
                    <li>D://Download/video</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <Tabmenu /> */}
        </div>
      </section>
    </>
  );
};

export default Medialibrary;
