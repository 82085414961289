// import React, { useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
// import Hls from "hls.js";

// const VideoModal = ({
//   isOpen,
//   responseUrl,
//   userId,
//   sessionId,
//   fetchAndSaveSegment,
//   setActiveWebcam,
//   onHlsRef,
//   playerRef,
//   selectedDeviceId
// }) => {
//   const hlsRef = useRef(null);
//   useEffect(() => {
//     console.log("isOpen && sessionId", sessionId, responseUrl);
//     const initializeHls = () => {
//       const player = playerRef.current.getInternalPlayer();
//       if (Hls.isSupported()) {
//         const hls = new Hls();
//         hlsRef.current = hls;
//         hls.loadSource(responseUrl);
//         hls.attachMedia(player);

//         hls.on(Hls.Events.MANIFEST_PARSED, () => {
//           if (isOpen) {
//             player.play().catch((error) => {
//               console.error("Error playing video:", error);
//             });
//           }
//         });

//         hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
//           const segmentUrl = data.frag.url;
//           console.log("Segment URL:", segmentUrl);
//           fetchAndSaveSegment(segmentUrl, sessionId, userId)
//             .then(() => {
//               console.log(`Segment uploaded successfully: ${segmentUrl}`);
//             })
//             .catch((error) => {
//               console.error(`Error uploading segment ${segmentUrl}:`, error);
//             });
//         });

//         // Send hlsRef to parent component
//         if (onHlsRef) {
//           onHlsRef(hlsRef.current);
//         }
//       } else if (player && player.canPlayType("application/vnd.apple.mpegurl")) {
//         player.src = responseUrl;
//         player.addEventListener("loadedmetadata", () => {
//           if (isOpen) {
//             player.play().catch((error) => {
//               console.error("Error playing video:", error);
//             });
//           }
//         });
//       }
//     };

//     if (responseUrl && selectedDeviceId) {
//       initializeHls();
//     } else if (hlsRef.current) {
//       hlsRef.current.destroy();
//       hlsRef.current = null;
//     }

//     return () => {
//       if (hlsRef.current) {
//         hlsRef.current.destroy();
//         hlsRef.current = null;
//       }
//     };
//   }, [responseUrl,isOpen, sessionId, userId,selectedDeviceId]);

//   return (
//     <div style={{ display: isOpen ? "block" : "none", width: "100%", height: "100%", marginTop: -15 }}>
//       <ReactPlayer
//         ref={playerRef}
//         url={responseUrl}
//         playing={isOpen}
//         controls
//         volume={0.5}
//         width="100%"
//         height="100%"
//         config={{
//           file: {
//             attributes: {
//               poster: require("../../images/Livestreaming/Placeholder.jpg"),
//             },
//           },
//         }}
//       />
//     </div>
//   );
// };

// export default VideoModal;


import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const VideoModal = ({
  isOpen,
  responseUrl,
  userId,
  sessionId,
  fetchAndSaveSegment,
  setActiveWebcam,
  onHlsRef,
  selectedDeviceId
}) => {
  const videoRef = useRef(null);
  const hlsRef = useRef(null);

  useEffect(() => {
    console.log("isOpen && sessionId", sessionId, responseUrl);
    const initializeHls = () => {
      if (Hls.isSupported() && videoRef.current) {
        const hls = new Hls();
        hlsRef.current = hls;
        hls.loadSource(responseUrl);
        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (isOpen) {
            videoRef.current.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        });

        hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
          const segmentUrl = data.frag.url;
          console.log("Segment URL:", segmentUrl);
          fetchAndSaveSegment(segmentUrl, sessionId, userId)
            .then(() => {
              console.log(`Segment uploaded successfully: ${segmentUrl}`);
            })
            .catch((error) => {
              console.error(`Error uploading segment ${segmentUrl}:`, error);
            });
        });

        // Send hlsRef to parent component
        if (onHlsRef) {
          onHlsRef(hlsRef.current);
        }
      } else if (videoRef.current && videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.src = responseUrl;
        videoRef.current.addEventListener("loadedmetadata", () => {
          if (isOpen) {
            videoRef.current.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }
        });
      }
    };

    if (responseUrl && selectedDeviceId) {
      initializeHls();
    } else if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [responseUrl, isOpen, sessionId, userId, selectedDeviceId]);

  return (
    <div style={{ display: isOpen ? "block" : "none", width: "100%", height: "100%", marginTop: -15 }}>
      <video
        ref={videoRef}
        controls
        width="100%"
        height="100%"
        style={{ backgroundColor: "black" }}
        poster={require("../../images/Livestreaming/Placeholder.jpg")}
      />
    </div>
  );
};

export default VideoModal;

