import React, { useState } from "react";
import { Card, CardBody, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { findUpper } from "../../../utils/Utils";
import { UserAvatar, Icon } from "../../Component"; // Adjust the import path as needed
import "../../../css/streamdeck_css/streamdeck.css";
import ReactPlayer from "react-player"; // Import React Player

const VideoGalleryCard = ({ img, userName, theme, userImg, userEmail, heartCount, link, onDelete }) => {
  const [heart, setHeart] = useState(false);
  const [modal, setModal] = useState(false); // State for modal visibility
  const [activeStreamKeyPlayback, setActiveStreamKeyPlayback] = useState(null); // State to store video URL for playback

  const toggleModal = () => {
    setModal(!modal);
  };

  const onHeartClick = () => {
    setHeart(!heart);
  };

  const openModal = (videoLink) => {
    setActiveStreamKeyPlayback(videoLink);
    setModal(true);
  };

  return (
    <div>
      <Card className="card-bordered gallery">
        <img src={img} alt={userName ? `${userName}'s Video` : "User's Video"} className="card-img-top" />
        <CardBody className="card-inner align-center justify-between flex-wrap g-2">
          {userName && userEmail ? (
            <div className="user-card">
              <UserAvatar theme={theme} text={findUpper(userName)} image={userImg}></UserAvatar>
              <div className="user-info">
                <CardTitle tag="h5" className="lead-text">
                  {userName}
                </CardTitle>
                <CardText className="sub-text">{userEmail}</CardText>
              </div>
            </div>
          ) : null}
          {link && (
            <div className="d-flex justify-content-between mt-2" style={{ width: "100%" }}>
              <Button color="primary" onClick={() => openModal(link)}>
                Watch Video
              </Button>
              <Button color="danger" onClick={onDelete}>
                Delete
              </Button>
            </div>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Playback Stream</ModalHeader>
        <ModalBody style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ReactPlayer url={activeStreamKeyPlayback} playing controls width="100%" style={{ height: "auto" }} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VideoGalleryCard;
