import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { fetchAPI } from "../../utils/api_helper";
import { Icon } from "../../components/Component";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const LiveFeedChannel = ({ stream_id, toggleOffcanvas, onAccept }) => {
  const [destinationID, setaddChannelId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const navigate = useNavigate();
  // const { stream_id } = useParams();
  const location = useLocation();

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({ ...checkbox, isChecked: !selectAll }));
    const ids = updatedCheckboxes.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.id);
    setCheckboxes(updatedCheckboxes);
    setaddChannelId(ids);
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
    );
    const ids = updatedCheckboxes.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.id);
    setCheckboxes(updatedCheckboxes);
    setaddChannelId(ids);
    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.isChecked));
  };

  const getChannelList = async () => {
    try {
      const getChannelData = await fetchAPI("", "get-channel");
      if (getChannelData.status) {
        const getdata = getChannelData.data.destination_channels;
        if (getdata.length > 0) {
          const selectedChannels = location.state?.selectedChannels || [];
          console.log(selectedChannels);
          if (selectedChannels.length > 0) {
            const destinationIds = selectedChannels.map(String);
            const updatedCheckboxes = getdata.filter((checkbox) => {
              return !destinationIds.includes(String(checkbox.id));
            });
            setCheckboxes(updatedCheckboxes);
          } else {
            setCheckboxes(getdata);
          }
        } else {
          setCheckboxes([]);
          toast.error(getChannelData.message);
        }
      }
    } catch (error) {}
  };

  const addChannelList = async () => {
    try {
      if (destinationID.length === 0) {
        toast.error("No channels to add.");
        return false;
      }
      const channelFormdata = new FormData();
      channelFormdata.append("stream_id", stream_id);
      channelFormdata.append("destination_id", destinationID.join(","));
      const channelListApi = await fetchAPI(channelFormdata, "update-live-stream");
      if (channelListApi.status) {
        const channelId = channelListApi?.data?.livestream?.stream_key_id;
        navigate(`/live-streams/create/${channelId}`);

        const destinationIds = channelListApi?.data?.livestream?.destination_id.map(String);
        const listData = checkboxes.filter((checkbox) => {
          return !destinationIds.includes(String(checkbox.id));
        });
        setCheckboxes(listData);
        onAccept();
        toggleOffcanvas();
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getChannelList();
  }, []);

  return (
    <>
      <section className="bg-white m-3">
        <div className="container-fluid p-3 addchannellivesstep">
          {/* <BackTo link="/live-streams/first" icon="arrow-left"></BackTo> */}
          <h5 className="mb-3">Send the live feed to these channels</h5>
          <div className="">
            <label>
              <input type="checkbox" checked={selectAll} className="ms-2" onChange={handleSelectAll} />
              <span className="fs-5 ps-1 fw-semibold">Select All</span>
            </label>
            <ul>
              {checkboxes.length > 0
                ? checkboxes.map((checkbox) => (
                    <li key={checkbox.id} className="border mb-2 pointer shadow-sm">
                      <label className="w-100  d-flex align-items-center p-2">
                        <input
                          type="checkbox"
                          checked={checkbox.isChecked}
                          onChange={() => handleCheckboxChange(checkbox.id)}
                        />
                        <div className="ps-1">
                          {checkbox.channel_type === "0" ? (
                            checkbox.linear_channel_type === "0" ? (
                              <Icon name="calendar" className="fs-4 text-warning" />
                            ) : (
                              <Icon name="redo" className="fs-4 text-warning" />
                            )
                          ) : checkbox.channel_type === "1" ? (
                            <Icon name="spark" className="fs-4 text-success" />
                          ) : checkbox.channel_type === "2" ? (
                            <Icon name="view-list-wd" className="fs-4 text-primary" />
                          ) : (
                            <Icon name="redo" className="fs-4 text-warning" />
                          )}
                        </div>
                        <span className="ps-1">{checkbox.channel_name}</span>
                      </label>
                    </li>
                  ))
                : "No channels to add."}
            </ul>
          </div>
          <div className="mt-3 mt-xl-4 text-end">
            <Button className="mx-1" color="primary" onClick={addChannelList}>
              Accept
            </Button>
            <Button className="mx-1" color="light" onClick={toggleOffcanvas}>
              Cancel
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveFeedChannel;
