import React, { useEffect, useRef, useState } from "react";
import { MediaPlayer, MediaProvider, useMediaRemote } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import "@vidstack/react/player/styles/base.css";
import "@vidstack/react/player/styles/plyr/theme.css";

import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";

function VideoPlayer({
  currentChannel,
  videoSrc,
  handleOnPlay,
  setStreamEnded,
  startTimeInSeconds,
  muted,
  isMute,
  setIsMute,
}) {
  const remote = useMediaRemote();

  const playerRef = useRef(null);
  const [showIcon, setShowIcon] = useState(null); // 'mute' or 'unmute'
  const [prevMuteState, setPrevMuteState] = useState(isMute); // Track previous mute state
  const [lastClicked, setLastClicked] = useState(null); // Track last mute toggle

  const handleMuteToggle = (event) => {
    if (event.target === event.currentTarget && playerRef.current) {
      const { muted, volume } = playerRef.current;
      const newMuted = !muted;

      // Update the mute status
      playerRef.current.muted = newMuted;

      // Adjust the volume based on the new mute status
      if (newMuted) {
        setIsMute(true);
        playerRef.current.volume = 0; // Ensure volume is 0 when muted
      } else {
        setIsMute(false);
        playerRef.current.volume = volume === 0 ? 0.5 : volume; // Restore previous volume or set to a default
      }

      // Log the updated status for debugging
      setShowIcon(newMuted ? "mute" : "unmute");
      setLastClicked(new Date().getTime()); // Update last clicked time

      // console.log(`Muted: ${newMuted}, Volume: ${playerRef.current.volume}`);
    }
  };

  const onVolumeChange = (e) => {
    // console.log("Volume change detected", e);

    if (playerRef.current) {
      const volume = e.volume;
      if (volume === 0) {
        playerRef.current.muted = true; // Mute if volume is 0
      } else {
        // playerRef.current.muted = false; // Unmute if volume is greater than 0
      }

      // Update volume
      playerRef.current.volume = volume;
    }
  };

  useEffect(() => {
    // Show the icon for 800ms only when lastClicked is set
    if (lastClicked !== null) {
      const timer = setTimeout(() => {
        setShowIcon(null); // Hide the icon after 800ms
      }, 800); // Match duration to animation (800ms)
      return () => clearTimeout(timer);
    }
  }, [lastClicked]);

  // useEffect(() => {
  //   if (isMute !== null) {
  //     setShowIcon(isMute ? "mute" : "unmute");
  //     const timer = setTimeout(() => {
  //       setShowIcon(null);
  //     }, 800);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isMute]);

  // useEffect(() => {
  //   if (isMute !== prevMuteState) {
  //     // Check if mute state has changed
  //     setShowIcon(isMute ? "mute" : "unmute");
  //     const timer = setTimeout(() => {
  //       setShowIcon(null);
  //     }, 800); // Show icon for 800ms
  //     setPrevMuteState(isMute); // Update previous mute state
  //     return () => clearTimeout(timer);
  //   }
  // }, [isMute, prevMuteState]);

  return (
    <>
      {currentChannel && (
        <>
          <MediaPlayer
            onClick={handleMuteToggle}
            ref={playerRef}
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            title={currentChannel.channel_name}
            playsInline
            src={videoSrc}
            autoPlay
            muted={muted}
            loop
            onPlay={() => {
              handleOnPlay();
            }}
            onEnded={() => {
              setStreamEnded(true);
            }}
            fullscreenOrientation="none"
            keyDisabled
            {...(currentChannel.is_live
              ? {
                  streamType: "live",
                }
              : {
                  currentTime: startTimeInSeconds,
                })}
            onMediaMuteRequest={() => {
              playerRef.current.muted = true;
              console.log("mr");
            }}
            onMediaUnmuteRequest={() => {
              playerRef.current.muted = false;
              console.log("umr");
            }}
            onVolumeChange={onVolumeChange}
          >
            <MediaProvider />
            <DefaultVideoLayout
              icons={defaultLayoutIcons}
              disableTimeSlider="true"
              playbackRates={{
                min: 1,
                max: 1,
                step: 0,
              }}
            />
          </MediaPlayer>
          {showIcon && <div className="icon-container">{showIcon === "mute" ? <FaVolumeMute /> : <FaVolumeUp />}</div>}
        </>
      )}
    </>
  );
}

export default VideoPlayer;
