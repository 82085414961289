import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Spinner } from "reactstrap";
import { fetchAPI } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { StreamAddContext } from "../app/StremContext";
import { ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";



const LiveStream = () => {
  const navigate = useNavigate("");
  const { setStreamKey, setChannelKey, setStreamId, createChannel } = useContext(StreamAddContext);
  const [fetchingList, setFetchingList] = useState(false);
  const handleSubmitLive = async () => {
    navigate(`/live-streams/create/${random_key}`);
    try {
      if (`${random_key}` === "") {
        toast.error("Please enter title");
        return false;
      }
      const formdata = new FormData();
      formdata.append("stream_title", `${random_key}`);
      formdata.append("stream_key_id", `${random_key}`);
      const getLiveData = await fetchAPI(formdata, "create-live-stream");

      if (!getLiveData.status) {
        throw new Error("Failed to Create Stream");
      }
      if (getLiveData?.status) {
        toast.success(getLiveData?.message);
      } else {
        toast.error(getLiveData?.data?.error);
      }

      const stream_key = getLiveData.data.stream_key.length > 0 ? getLiveData.data.stream_key : null;
      const playback_url_key = getLiveData.data.playback_url_key.length > 0 ? getLiveData.data.playback_url_key : null;
      const StreamId = getLiveData?.data?.id?.length > 0 ? getLiveData?.data?.id : null;

      setStreamKey(stream_key);
      setChannelKey(playback_url_key);
      setStreamId(StreamId);
    } catch (error) {}
  };
  useEffect(() => {
    if (!createChannel) {
      setFetchingList(true);
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    try {
      const getLiveData = await fetchAPI("", "get-live-stream");
      if (getLiveData.status) {
        const getdata = getLiveData?.data?.livestreams;
        if (getdata.length > 0) {
          navigate(`/lives`);
        }
      } else {
        console.log(`Error fetching data: ${getLiveData}`);
      }
    } catch (error) {}
    setFetchingList(false);
  };
  // const char = "a@skjh83";
  const random_key = Math.floor(Math.random() * 10000) * Math.floor(Math.random() * 10000); //
  // const random_key= Math.floor(Math.random()* 25);
  const [isDarkMode, setIsDarkMode] = useState(true);
  useEffect(() => {
    const body = document.body;
    setIsDarkMode(body.classList.contains("dark-mode"));
    console.log(body);
  }, []);

  if (fetchingList) {
    return (
      <div className="bg-white m-4 p-4">
        <ShimmerThumbnail height={30} width={250} rounded />
        <div className="d-flex justify-content-start align-items-center">
          <ShimmerThumbnail height={300} width={500} rounded />
          <div className="ms-4" style={{ width: "50%" }}>
            <ShimmerThumbnail height={25} width={250} rounded />
            <ShimmerText line={13} gap={10} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <section className="m-3">
        <div className="p-3 pt-1 bg-white">
          <h4 className="fs-3 my-3">Live Streaming</h4>
          <div className="row">
            <div className="col-md-6">{isDarkMode ? <img src="dark.png" alt="" /> : <img src="live.png" alt="" />}</div>

            <div className="col-md-6 col-lg-5">
              <h5>Take advantage of the following features</h5>
              <ul className="ps-3 list">
                <li>Broadcast live via any RTMP hardware or software encoder such as OBS, Wirecast, vMix.</li>
                <li>
                  Embed the live stream directly on a website or send the live feed to one or multiple Streamdeck
                  channels. The channel automatically will switch to a live state and once the live ends the channel
                  will switch to its normal state.
                </li>
                <li>
                  All live broadcasts will be recorded so you can easily use it like on demand content in your channels.
                </li>
              </ul>
              <div className="d-flex justify-content-start mt-4">
                <input
                  className="form-control w-65 mx-2"
                  type="text"
                  id="default-0"
                  defaultValue={random_key}
                  readOnly
                />
                <Button color="primary" className="" onClick={handleSubmitLive}>
                  Start Live Stream
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveStream;
