import React from "react";
import { Icon } from "../../components/Component"; // Adjust the import path as needed

const SocialShare = ({ streamName }) => {
  const socialLinks = [
    {
      platform: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(streamName)}`,
      icon: "facebook-fill",
    },
    {
      platform: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(streamName)}&text=Check%20out%20this%20video`,
      icon: "twitter",
    },
    {
      platform: "LinkedIn",
      url: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(streamName)}&title=Your%20Video`,
      icon: "linkedin",
    },
    { platform: "Instagram", url: "https://www.instagram.com", icon: "instagram" },
    { platform: "Pinterest Circle", url: "https://www.pinterest.com", icon: "pinterest-circle" },
    { platform: "Tumblr", url: "https://www.tumblr.com", icon: "tumblr" },
    { platform: "Skype", url: "https://www.skype.com", icon: "skype" },
    { platform: "Viber", url: "https://www.viber.com", icon: "viber" },
    { platform: "Snapchat Fill", url: "https://www.snapchat.com", icon: "snapchat-fill" },
    { platform: "Telegram Circle", url: "https://www.telegram.com", icon: "telegram-circle" },
    { platform: "YouTube Fill", url: "https://www.youtube.com", icon: "youtube-fill" },
    { platform: "Vimeo Fill", url: "https://www.vimeo.com", icon: "vimeo-fill" },
  ];

  return (
    <div className="social-share">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`social-icon ${social.platform.toLowerCase().replace(" ", "-")}`}
        >
          <Icon name={social.icon} />
        </a>
      ))}
    </div>
  );
};

export default SocialShare;
