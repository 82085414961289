import React, { useEffect, useState } from "react";
// import { AudienceLineChart } from "../../charts/default/DefaultCharts";
import { Icon, RSelect } from "../../components/Component";
import { analyticOvData, analyticOvDataSet2, analyticOvDataSet3 } from "./DefaultData";
import { Line } from "react-chartjs-2";
import { apiRequest } from "../../utils/apiHelper";
import { getSolidLineChart } from "./utilData";
import { combineViews, downloadPDF, downloadXLS, formatViewsData } from "./utils";

const AudienceLineChart = ({ data }) => {
  // console.log(data);
  // const [data, setData] = useState(analyticOvData);

  // useEffect(() => {
  //   let object;
  //   if (state === "day-7") {
  //     object = analyticOvDataSet2;
  //   } else {
  //     object = analyticOvDataSet3;
  //   }
  //   setData(object);
  // }, [state]);

  return (
    <Line
      className="analytics-line-large "
      data={data}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "13px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "12px",
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: true,
              color: "#9eaecf",
              font: {
                size: "12px",
              },
              padding: 8,
              stepSize: 2400,
            },
            gridLines: {
              color: "rgba(82, 100, 132, 0.2)",
              tickMarkLength: 0,
              zeroLineColor: "rgba(82, 100, 132,0.2)",
            },
          },
          x: {
            display: false,
            ticks: {
              color: "#9eaecf",
              font: {
                size: "12px",
              },
              source: "auto",
              padding: 0,
            },
            grid: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
              offsetGridLines: true,
            },
          },
        },
      }}
    ></Line>
  );
};

const ViewsOverview = () => {
  const [period, setPeriod] = useState(null);
  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [keyDates, setKeyDates] = useState([]);

  const fetchViews = async (selectedOption) => {
    setSelectedChannel(selectedOption);

    try {
      // Check if the selected option is "All Channels"
      if (selectedOption.value === "all") {
        // Fetch and display data for all channels
        const response = await apiRequest("analytics/all-channels/views"); // Adjust URL if needed
        // setSolidLineChart(getSolidLineChart(response.data));

        setData(response.data);
        return;
      }

      // Fetch channel views data based on selected channel ID
      const response = await apiRequest(`analytics/channel/${selectedOption.value}/views`);

      if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format");
      }

      const { data } = response;
      console.log(data);

      if (data.length === 0) {
        console.warn("No data available for the selected channel.");
        // setSolidLineChart([]); // Optionally set an empty chart or handle accordingly
        setData([]);
        return;
      }

      if (!("hour" in data[0]) || !("views" in data[0])) {
        throw new Error("Invalid data format received from server");
      }

      // setSolidLineChart(getSolidLineChart(combineViewsByMinute(data)));
      setData(data);
    } catch (error) {
      console.error("Error fetching channel views:", error);
      setData([]);
    }
  };

  useEffect(() => {
    // fetchAllChannelsViews();
    if (selectedChannel) fetchViews(selectedChannel);
  }, [selectedChannel]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await apiRequest("get-channelfor-view"); // Adjust URL if needed
        const options = [{ value: "all", label: "All Channels" }, ...response.data];
        setChannelOptions(options);

        // Set default selection to "All Channels"
        setSelectedChannel(options[0]);

        // Fetch and display data for all channels
        // handleChannelChange(options[0]);
      } catch (error) {
        console.error("Error fetching channels:", error);
      }
    };

    fetchChannels();
  }, []);

  useEffect(() => {
    if (data.length > 0) setGroupedData(combineViews(data));
    else setGroupedData([]);
  }, [data]);

  useEffect(() => {
    function getThreeDates(data) {
      data.sort((a, b) => new Date(a.hour) - new Date(b.hour));

      const firstDate = data[0].hour;
      const middleIndex = Math.floor(data.length / 2);
      const middleDate = data[middleIndex].hour;
      const lastDate = data[data.length - 1].hour;

      return [firstDate, middleDate, lastDate];
    }
    if (groupedData.length > 0) setKeyDates(getThreeDates(groupedData));
    else setKeyDates([]);
  }, [groupedData]);

  return (
    <React.Fragment>
      <div className="card-title-group g-2 align-items-baseline pb-1">
        <div className="card-title">
          <h5 className="title">Viewers Overview</h5>
        </div>
        <div className="card-tools shrink-0 d-none d-sm-block">
          <ul className="nav nav-switch-s2 nav-tabs bg-white">
            <li className="nav-item">
              <a
                href="#navitem"
                className={period === "7D" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setPeriod("7D");
                }}
              >
                7 D
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#navitem"
                className={period === "1M" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setPeriod("1M");
                }}
              >
                1 M
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#navitem"
                className={period === "ALL" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setPeriod("ALL");
                }}
              >
                All
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-title-group pb-3">
        <div className="w-25">
          <RSelect
            width="200px"
            placeholder="All channels"
            options={channelOptions}
            value={selectedChannel}
            onChange={(e) => {
              setSelectedChannel(e);
            }}
          />
        </div>
        <div>
          <button
            className="btn btn-primary mx-2"
            onClick={() => {
              downloadPDF("line", period);
            }}
            disabled={!period}
            // onClick={() => handleDownloadFile("line", selectedPeriodLine)}
            // disabled={!isChartDataAvailable(solidLineChart) || isDownloading || !selectedPeriodLine}
          >
            <Icon name="file-pdf"></Icon>
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              downloadXLS("line", period);
            }}
            disabled={!period}
            // onClick={() => downloadFile("line", selectedPeriodLine)}
            // disabled={!isChartDataAvailable(solidLineChart) || isDownloading || !selectedPeriodLine}
          >
            <Icon name="file-xls"></Icon>
          </button>
        </div>
      </div>
      <div className="analytic-ov">
        {/* <div className="analytic-data-group analytic-ov-group g-3">
          <div className="analytic-data analytic-ov-data">
            <div className="title">Users</div>
            <div className="amount">{auOverview === "month-1" ? "2.57" : "1.21"}K</div>
            <div className="change up">
              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "12.31" : "5.21"}%
            </div>
          </div>
          <div className="analytic-data analytic-ov-data">
            <div className="title">Sessions</div>
            <div className="amount">{auOverview === "month-1" ? "3.98" : "1.6"}K</div>
            <div className="change up">
              <Icon name="arrow-long-up"></Icon> {auOverview === "month-1" ? "47.5" : "80.6"}%
            </div>
          </div>
          <div className="analytic-data analytic-ov-data">
            <div className="title">Users</div>
            <div className="amount">{auOverview === "month-1" ? "28.25" : "10.25"}%</div>
            <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "12.57" : "18.21"}%
            </div>
          </div>
          <div className="analytic-data analytic-ov-data">
            <div className="title">Users</div>
            <div className="amount">{auOverview === "month-1" ? "7m 28" : "2m 36"}s</div>
            <div className="change down">
              <Icon name="arrow-long-down"></Icon> {auOverview === "month-1" ? "0.35" : "1.21"}%
            </div>
          </div>
        </div> */}
        <div className="analytic-ov-ck">
          <AudienceLineChart data={formatViewsData(groupedData)} />
        </div>
        <div className="chart-label-group ms-5">
          {keyDates.map((date, index) => (
            <div key={index} className={`chart-label`}>
              {/* {new Date(date).toLocaleDateString()} */}
              {date}
            </div>
          ))}
        </div>
        {/* <div className="chart-label-group ms-5">
          <div className="chart-label">01 Jan, 2020</div>
          <div className="chart-label d-none d-sm-block">{auOverview === "month-1" ? "15" : "4"} Jan, 2020</div>
          <div className="chart-label"> {auOverview === "month-1" ? "30" : "7"} Jan, 2020</div>
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default ViewsOverview;
