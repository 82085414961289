import React, { useContext, useEffect, useState, useRef } from "react";
import { Icon, PreviewCard, TooltipComponent, UserAvatar } from "../../components/Component";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import { fetchAPI } from "../../utils/api_helper";
import { useChannelList } from "../../utils/useChannelList";
import TooltipItem from "../channel/TooltipItem";
import { useHeaderTextUpdate } from "../HeaderTextContext";
import ScheduledChannelContext from "../ScheduledChannelContext";
import WebcamSettingsContext from "../WebcamSettingsContext";
import SocialShare from "./SocialShare";
import { StreamContext } from "../StreamContext";
import ReactPlayer from "react-player";
import { formatTallyTime, getCookie } from "../../utils/Utils";
import { apiRequest } from "../../utils/apiHelper";
import NoChannel from "../../images/NoChannel";
const SchedularChannel = ({
  streamName,
  startTime,
  endTime,
  linkduration,
  setIsBroadcastEnabled,
  isBroadcastEnabled,
  savedEpg,
  isloading,
}) => {
  const [schEpg, setSchEpg] = useState([]);

  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const { activestreamkey, activeplaybackurl, activestreamkeyPlayback } = useContext(StreamContext);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const openModal = () => {
    if (activestreamkeyPlayback) {
      setModal(true);
    } else {
      toast.error("No Playback URL available.");
    }
  };

  const [activeSetting, setactiveSetting] = useState("1");
  const toggleSetting = (tab) => {
    if (activeSetting !== tab) setactiveSetting(tab);
  };

  const [activeSharing, setActiveSharing] = useState("1");
  const toggleSharing = (tab) => {
    if (activeSharing !== tab) setActiveSharing(tab);
  };
  const rtmpUrl = "rtmp://silolivestream.com:1936/stream";

  const [loading, setLoading] = useState(true);
  const NavigateChannel = useNavigate("");
  const [allvideos, setAllVideos] = useState([]);
  const [totalvideo, setTotalVideo] = useState("");
  const navigate = useNavigate();
  const parms = useParams();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedchannel, setSelectedchannel] = useState(null);
  const [channelList, setChannelList] = useChannelList(setSelectedchannel, setLoading);
  const setHeaderText = useHeaderTextUpdate();
  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);

  const {
    volume,
    setVolume,
    echoCancellation,
    setEchoCancellation,
    noiseSuppression,
    setNoiseSuppression,
    autoGainControl,
    setAutoGainControl,
    videoWidth,
    setVideoWidth,
    videoHeight,
    setVideoHeight,
    facingMode,
    setFacingMode,
    aspectRatio,
    setAspectRatio,
  } = useContext(WebcamSettingsContext);

  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(streamName);
      setCopied(true);
      toast.success("Stream Name copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error("Failed to copy Stream Name. Please try again.");
    }
  };

  const handleAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  const handleVolumeChange = (value) => {
    setVolume(value);
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = value;
    }
  };
  const handleVideoWidthChange = (e) => {
    setVideoWidth(parseInt(e.target.value));
  };

  const handleVideoHeightChange = (e) => {
    setVideoHeight(parseInt(e.target.value));
  };

  const handleEchoCancellationChange = (e) => {
    setEchoCancellation(e.target.checked);
  };

  const handleNoiseSuppressionChange = (e) => {
    setNoiseSuppression(e.target.checked);
  };

  const handleAutoGainControlChange = (e) => {
    setAutoGainControl(e.target.checked);
  };
  const copyToClipboard = (text, label) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Copied to clipboard:", text);
        toast.success(`${label} copied!`); // Toastify success notification
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Copy failed"); // Toastify error notification
      });
  };

  const handleCopyRtmpUrl = () => {
    copyToClipboard(rtmpUrl, "RTMP URL");
  };

  const handleCopyStreamKey = () => {
    copyToClipboard(activestreamkey, "Stream Key");
  };
  const handleCopyplaybackurl = () => {
    copyToClipboard(activeplaybackurl, "Playback Url");
  };
  const handleCopyactivestreamkeyPlayback = () => {
    copyToClipboard(activestreamkeyPlayback, "Active Streamkey Playback Url");
  };
  // console.log(channelList.length);
  const deletChanneldata = async (deletechannelId, indexID) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleFormData = new FormData();
        deleFormData.append("channel_id", deletechannelId);
        const deleteData = await fetchAPI(deleFormData, "delete-channel");
        if (deleteData.status) {
          toast.success(deleteData.message);

          const updatedChannelList = channelList.filter((channel) => channel.id !== deletechannelId);
          setChannelList(updatedChannelList);
          if (updatedChannelList.length > 0) {
            setSelectedId(updatedChannelList[0].id);
          } else {
            setSelectedId(null);
          }
          if (updatedChannelList?.[0]) {
            setSelectedchannel(updatedChannelList[0]);
          }
        } else {
          if (deleteData.data.broadcasting_today) {
            Swal.fire({
              icon: "info",
              title: "Warning",
              text: "You Have Already Broadcasted This Program You Cant Delete It.",
            });
          } else {
            toast.error(deleteData.message);
          }
        }
      }
    });
  };

  async function downloadPdf(id) {
    try {
      // setIsDownloading(true);
      const formData = new FormData();
      formData.append("channel_id", id);
      const response = await apiRequest("get-broadcast-pdf", "POST", formData);
      console.log(id, response);
      window.open(response.data.data.path, "_blank");
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      // setIsDownloading(false);
    }
  }

  async function sharePdf(id) {
    try {
      const formData = new FormData();
      formData.append("channel_id", id);
      const response = await apiRequest("get-broadcast-pdf", "POST", formData);
      const pdfLink = response.data.data.path;

      if (navigator.share) {
        await navigator.share({
          title: "Share Schedule PDF",
          text: "Check out this schedule!",
          url: pdfLink,
        });
        // console.log("PDF shared successfully");
      } else {
        console.log("Web Share API is not supported in your browser.");
        // Fallback: you could copy the link to clipboard or provide another method to share the link.
      }
    } catch (error) {
      console.error("Error sharing PDF:", error);
    }
  }

  const [deletechannel, setDelete] = useState(false);
  const [editchannel, setEdit] = useState(false);

  const toggledelete = () => setDelete(!deletechannel);
  const toggleEdit = () => setEdit(!editchannel);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedchannelschedule, setSelectedChannelSchedule] = useState();
  const addScheduleList = (channel, id) => {
    setSelectedchannel(channel);
  };
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  const { selectedId, setSelectedId } = useContext(ScheduledChannelContext);

  const handleSelectionChange = (id) => {
    setSelectedId(id);
    setIsBroadcastEnabled(false);
  };

  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => setModalZoom(!modalZoom);
  // const navigate = useNavigate();
  useEffect(() => {
    if (channelList.length > 0) {
      setSelectedId(channelList[0].id);
    }
  }, [channelList]);

  useEffect(() => {
    if (channelList.length > 0 && !isloading) {
      // setSelectedId(channelList[0].id);
    } else if (!isloading) {
      toast.error("Add Channel First");
      setModalZoom(true);
      Swal.fire({
        title: "Create a new channel",
        text: "Create a new channel to schedule programs",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Create a channel",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/channels");
        } else {
          navigate("/channels");
        }
      });
    }
  }, [channelList, selectedId, setSelectedId]);

  useEffect(() => {
    const newEpg = [...savedEpg];
    newEpg.sort((a, b) => new Date(a.since) - new Date(b.since));
    setSchEpg(newEpg);
  }, [savedEpg]);

  function convertDate(dateString) {
    const [year, month, day] = dateString.split("-");
    return `${month}-${day}-${year}`;
  }

  return (
    <>
      <PreviewCard className="schedule-channel-tabs mt-1">
        <Nav tabs className="bg-light border-0 justify-content-around">
          <NavItem className="d-flex flex-fill p-0">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" d-block text-center px-2 py-1 text-body fs-12px border border-2 flex-grow-1", {
                active: activeTab === "1",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("1");
              }}
            >
              Channels
            </Link>
          </NavItem>
          <NavItem className="d-flex flex-fill p-0">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" d-block text-center px-2 py-1 text-body fs-12px border border-2 flex-grow-1", {
                active: activeTab === "2",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("2");
              }}
            >
              Schedule
            </Link>
          </NavItem>
          <NavItem className="d-flex flex-fill p-0">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" d-block text-center px-2 py-1 text-body fs-12px border border-2 flex-grow-1", {
                active: activeTab === "3",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              RTMP Streams
            </Link>
          </NavItem>
        </Nav>
        {/* <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" backdrop={false}>
          <ModalHeader className="d-flex justify-content-between">Alert</ModalHeader>
          <ModalBody className="d-flex justify-content-center flex-column align-items-center">
            <NoChannel />
            <button className="btn btn-danger">Create Your Channel Now</button>
          </ModalBody>
        </Modal> */}
        <div className="tab-content-height">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="p-1">
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <UncontrolledDropdown>
                      <div className="btn-group">
                        <DropdownToggle
                          className="dropdown-toggle-split rounded-2 btn-sm"
                          color="primary"
                          onMouseEnter={() => setHeaderText("Add New Channels in streams")}
                          onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
                        >
                          <Icon name="plus" className="fs-6" />
                          <span className="fs-11px">Create Channel</span>
                        </DropdownToggle>
                      </div>
                      <DropdownMenu>
                        <ul className="link-list-opt">
                          <li>
                            <Link to="/create-channel/looped?type=1" className="w-100 p-1">
                              <Icon name="redo" className="text-success pe-1" />
                              <span className="fs-12px">Looped (linear)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/create-channel/vod" className="w-100 p-1">
                              <Icon name="view-list-wd" className="text-primart pe-1" />
                              <span className="fs-12px">Playlist (on demand)</span>
                            </Link>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div>
                    <div>0 total views</div>
                  </div>
                </div>
                <>
                  {channelList.length > 0 ? (
                    channelList.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={`d-flex bg-secondary-dim align-items-center shadow-sm mt-3 justify-content-between pointer setHover ${
                            index === selectedItemIndex ? "hover-theme" : ""
                          }`}
                          onClick={() => addScheduleList(item, item.id)}
                        >
                          <div className="d-flex p-1 align-items-center">
                            {/* <div className="align-self-center">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1"></label>
                              </div>
                            </div> */}
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                id={`channelscheduled${item.id}`}
                                name="customRadio"
                                defaultChecked={index === 0}
                                className="custom-control-input"
                                onChange={() => handleSelectionChange(item.id)}
                              />
                              <label className="custom-control-label" htmlFor={`channelscheduled${item.id}`}></label>
                            </div>
                            <div>
                              {item.channel_type === "0" && parms.type !== "looped" ? (
                                item.linear_channel_type === "0" ? (
                                  <Icon name="calendar-booking" className="fs-4 text-warning" />
                                ) : (
                                  <Icon name="redo" className="fs-4 text-success" />
                                )
                              ) : item.channel_type === "1" ? (
                                <Icon name="spark" className="fs-4 text-warning" />
                              ) : item.channel_type === "2" ? (
                                <Icon name="view-list-wd" className="fs-4 text-primary" />
                              ) : (
                                <Icon name="redo" className="fs-4 text-warning" />
                              )}
                            </div>
                            <div className="ms-1">
                              <TooltipItem key={index} placement="top" text={item.channel_name} id={index} />
                              <span className="fs-12px">
                                <Icon name="eye" /> 0
                              </span>
                            </div>
                          </div>
                          <ul className="list-unstyled d-flex mx-1 g-1 list-channel">
                            {/* <li>
                              {item.channel_type === "1" ? (
                                <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                  <Icon name="play" />
                                </Button>
                              ) : (
                                <Link to={`/embed-channel/${item.id}`}>
                                  <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                    <Icon name="code" />
                                  </Button>
                                </Link>
                              )}
                            </li> */}
                            <li>
                              {item.channel_type === "2" ? (
                                ""
                              ) : (
                                // <Link to="/stream-link">
                                //   <TooltipItem
                                //     key={"m3" + index}
                                //     placement="top"
                                //     text="Get.m3u8 Link"
                                //     toolTipList={true}
                                //     btntext={[{ name: "external" }, { text: "" }]}
                                //     id={"m3" + index}
                                //   />
                                // </Link>
                                <></>
                              )}
                            </li>
                            <li>
                              {item.channel_type === "1" ? (
                                // <Link to="">
                                //   <TooltipItem
                                //     key={"epg" + index}
                                //     placement="top"
                                //     text="Get EPG guide link"
                                //     toolTipList={true}
                                //     btntext={[{ name: "view-list-wd" }, { text: "" }]}
                                //     id={"epg" + index}
                                //   />
                                // </Link>
                                <></>
                              ) : item.channel_type === "2" ? (
                                ""
                              ) : (
                                // <Link to="/tv-channel">
                                //   <TooltipItem
                                //     key={"tv" + index}
                                //     placement="top"
                                //     text="Distribute the channel on TV Apps"
                                //     toolTipList={true}
                                //     btntext={[{ name: "plus" }, { text: "" }]}
                                //     id={"tv" + index}
                                //   />
                                // </Link>
                                <></>
                              )}
                            </li>
                            <li>
                              {/* <Link to={`/copy-channel/${item.id}`}>
                                <TooltipItem
                                  key={"copy" + index}
                                  placement="top"
                                  text="Duplicate"
                                  toolTipList={true}
                                  btntext={[{ name: "copy" }]}
                                  id={"copy" + index}
                                />
                              </Link> */}
                              <></>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  item.channel_type === "0"
                                    ? item.linear_channel_type === "0"
                                      ? "/edit-channel/scheduled/"
                                      : "/edit-channel/looped/"
                                    : item.channel_type === "1"
                                    ? "/edit-channel/fast/"
                                    : item.channel_type === "2"
                                    ? "/edit-channel/vod/"
                                    : "/edit-channel/unknown/"
                                }${window.btoa(item.id)}`}
                              >
                                <TooltipItem
                                  key={"edit" + index}
                                  // onClick={() => deletChanneldata(item.id)}
                                  placement="top"
                                  text="Edit"
                                  toolTipList={true}
                                  btntext={[{ name: "edit" }]}
                                  id={"edit" + index}
                                />
                              </Link>
                            </li>
                            <li id="trash">
                              <Link to="">
                                <Button
                                  className="btn-icon btn-dim fs-10px px-1"
                                  onClick={() => deletChanneldata(item.id, index)}
                                  color="dark"
                                  size="sm"
                                >
                                  <Icon name="trash" />
                                </Button>
                                <Tooltip placement="top" isOpen={deletechannel} target="trash" toggle={toggledelete}>
                                  Delete
                                </Tooltip>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-3">
                      <ShimmerThumbnail height={30} rounded />
                      <ShimmerThumbnail height={30} rounded />
                      <ShimmerThumbnail height={30} rounded />
                    </div>
                  )}
                </>
              </div>
            </TabPane>

            <TabPane tabId="2" className="p-1">
              {isloading ? (
                <div className="mt-3">
                  <ShimmerThumbnail height={30} rounded />
                  <ShimmerThumbnail height={30} rounded />
                  <ShimmerThumbnail height={30} rounded />
                </div>
              ) : schEpg.length == 0 ? (
                <div style={{ textAlign: "center", marginTop: "110px" }}>
                  <p>No schedule saved on server</p>
                </div>
              ) : (
                <>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      id={"download-schedule"}
                      className="btn btn-secondary mb-10 mx-1 btn-sm"
                      onClick={() => {
                        downloadPdf(selectedId);
                      }}
                    >
                      <Icon name="download-cloud" className="" />
                    </Button>
                    <TooltipComponent id={"download-schedule"} text={"Download Schedule"} direction="top" />
                    <Button
                      id={"share-schedule"}
                      className="btn btn-secondary mb-10 mx-1 btn-sm"
                      onClick={() => {
                        sharePdf(selectedId);
                      }}
                    >
                      <Icon name="share" className="" />
                    </Button>
                    <TooltipComponent id={"share-schedule"} text={"Share Schedule"} direction="top" />
                  </div>

                  {schEpg.map((item) => (
                    <div
                      key={item.id}
                      className="p-1 bg-white tallycontainer border border-light round-lg mb-3 d-flex justify-content-between align-items-center text-white"
                    >
                      <div className="d-flex align-items-center">
                        <UserAvatar theme="primary" image={item.image} className="sq tallyavatar"></UserAvatar>
                        <span className="ms-2 me-2 tallytitle text-dark">{item.title}</span>
                      </div>
                      <div className="d-flex flex-column align-items-end timestarttally me-2">
                        <span className="text-dark">{convertDate(item.date)}</span>
                        <span className="text-dark">{formatTallyTime(item.since)}</span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </TabPane>

            <TabPane tabId="3" className="p-1"></TabPane>
          </TabContent>
        </div>
      </PreviewCard>

      <PreviewCard className="schedule-channel-tabs mt-1">
        <Nav tabs className="bg-light border-0 justify-content-around">
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSetting === "1",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSetting("1");
              }}
              onMouseEnter={() => setHeaderText("Configure your Audio preferences here.")}
              onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
            >
              Tally Settings
            </Link>
          </NavItem>
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSetting === "2",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSetting("2");
              }}
              onMouseEnter={() => setHeaderText("Configure your Audio preferences here.")}
              onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
            >
              Audio Settings
            </Link>
          </NavItem>
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSetting === "3",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSetting("3");
              }}
              onMouseEnter={() => setHeaderText("Configure your cameras here.")}
              onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
            >
              Camera Settings
            </Link>
          </NavItem>
        </Nav>
        <div className="tab-content-height">
          <TabContent activeTab={activeSetting}>
            <TabPane tabId="1" className="p-1">
              <div className="text-center">
                <Icon name="folder" className="fs-1" />
              </div>
            </TabPane>

            <TabPane tabId="2" className="p-1">
              <div className="form-container">
                <div className="form-group">
                  <label className="form-label">
                    Volume:
                    <input
                      className="form-range"
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={volume}
                      onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <label className="form-label">
                    Echo Cancellation:
                    <input
                      className="form-checkbox mx-2"
                      type="checkbox"
                      checked={echoCancellation}
                      onChange={handleEchoCancellationChange}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Noise Suppression:
                    <input
                      className="form-checkbox mx-2"
                      type="checkbox"
                      checked={noiseSuppression}
                      onChange={handleNoiseSuppressionChange}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Auto Gain Control:
                    <input
                      className="form-checkbox mx-2"
                      type="checkbox"
                      checked={autoGainControl}
                      onChange={handleAutoGainControlChange}
                    />
                  </label>
                </div>
              </div>
            </TabPane>

            <TabPane tabId="3" className="p-1">
              <div className="form-container">
                <div className="form-group">
                  <label className="form-label">
                    Video Width:
                    <input
                      className="form-range"
                      type="range"
                      min="0"
                      max="485"
                      step="1"
                      value={videoWidth}
                      onChange={handleVideoWidthChange}
                    />
                    {videoWidth}
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Video Height:
                    <input
                      className="form-range"
                      type="range"
                      min="0"
                      max="360"
                      step="1"
                      value={videoHeight}
                      onChange={handleVideoHeightChange}
                    />
                    {videoHeight}
                  </label>
                </div>

                <div className="form-group">
                  <label className="form-label">
                    Aspect Ratio:
                    <input
                      className="form-range"
                      type="range"
                      min="1.0"
                      max="2.0"
                      step="0.01"
                      value={aspectRatio}
                      onChange={handleAspectRatioChange}
                    />
                    {aspectRatio}
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Camera Facing Mode:
                    <select className="mx-1" value={facingMode} onChange={(e) => setFacingMode(e.target.value)}>
                      <option value="user">User</option>
                      <option value="environment">Environment</option>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                    </select>
                  </label>
                </div>
              </div>
            </TabPane>
          </TabContent>
          <div className="row py-2 line-height border-top border-3">
            <div className="col-6">
              <ul className="text-end ">
                <li>File Name :</li>
                <li>File Size :</li>
                <li>Format :</li>
                <li>Streamkey :</li>
                <li>RTMP Attachment :</li>
                {/* <li>Parameters :</li> */}
                <li>File Place :</li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="">
                <li>City View</li>
                <li>250 mb</li>
                <li>MP4</li>
                <li>bcuiysdfiojlkauio</li>
                <li>Jgsyb56dafggoibcav</li>
                {/* <li>null</li> */}
                <li>D://Download/video</li>
              </ul>
            </div>
          </div>
        </div>
      </PreviewCard>

      <PreviewCard className="schedule-channel-tabs mt-1">
        <Nav tabs className="bg-light border-0 justify-content-around">
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSharing === "1",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSharing("1");
              }}
              onMouseEnter={() =>
                setHeaderText("This are Broadcast settings you can set Broadcast settings for streaming")
              }
              onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
            >
              Broadcast Settings
            </Link>
          </NavItem>
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSharing === "2",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSharing("2");
              }}
              onMouseEnter={() =>
                setHeaderText("This is Social sharing tab you can share your streaming to social media applications")
              }
              onMouseLeave={() => setHeaderText("Schedule your channels using drag and drop in EPG")}
            >
              Social Sharing
            </Link>
          </NavItem>
          <NavItem className="p-0 d-flex flex-fill">
            <Link
              tag="a"
              href="#tab"
              className={classnames(" px-2 text-center py-1 text-body fs-12px border border-2 flex-grow-1 d-block", {
                active: activeSharing === "3",
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleSharing("3");
              }}
            >
              RTMP ID
            </Link>
          </NavItem>
        </Nav>
        <div className="tab-content-height position-relative">
          <TabContent activeTab={activeSharing}>
            <TabPane tabId="1" className="p-1">
              <div className="text-center">
                <Icon name="folder" className="fs-1" />
              </div>
              <div className="broadcastdetail">
                <ul className="d-flex flex-column">
                  <li className="fs-12px d-flex align-items-center justify-content-between px-0">
                    <span className="label-width">Stream Name</span>
                    <Button className="btn-sm" onClick={handleCopyToClipboard}>
                      Copy Stream
                    </Button>
                    <span className="fs-11px ms-1 text-success">{streamName}</span>
                  </li>
                  <li className="fs-12px d-flex align-items-center justify-content-between px-0">
                    <span className="label-width">Start Time</span>
                    <span className="fs-11px ms-1 text-success">{startTime}</span>
                  </li>
                  <li className="fs-12px d-flex align-items-center justify-content-between px-0">
                    <span className="label-width">End Time</span>
                    <span className="fs-11px ms-1 text-success">{endTime}</span>
                  </li>
                  <li className="fs-12px d-flex align-items-center justify-content-between px-0">
                    <span className="label-width">Duration</span>
                    <span className="fs-11px ms-1 text-success">{linkduration}</span>
                  </li>
                </ul>
              </div>
            </TabPane>

            <TabPane tabId="2" className="p-1">
              <SocialShare streamName={streamName} />
            </TabPane>
            <TabPane tabId="3" className="p-1">
              <div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button className="input-group-text btn btn-dark" onClick={handleCopyRtmpUrl}>
                      Copy RTMP URL
                    </button>
                  </div>
                  <input type="text" className="form-control" value={rtmpUrl} readOnly />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button className="input-group-text btn btn-dark" onClick={handleCopyStreamKey}>
                      Copy Stream Key
                    </button>
                  </div>
                  <input type="text" className="form-control" value={activestreamkey} readOnly />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button className="input-group-text btn btn-dark" onClick={handleCopyplaybackurl}>
                      Copy Playback Url
                    </button>
                  </div>
                  <input type="text" className="form-control" value={activeplaybackurl} readOnly />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button className="input-group-text btn btn-dark" onClick={handleCopyactivestreamkeyPlayback}>
                      Copy Streamkey Playback
                    </button>
                  </div>
                  <input type="text" className="form-control" value={activestreamkeyPlayback} readOnly />
                </div>
                <Button color="primary" onClick={openModal}>
                  Play Stream
                </Button>

                <Modal isOpen={modal} toggle={toggleModal}>
                  <ModalHeader toggle={toggleModal}>Playback Stream</ModalHeader>
                  <ModalBody style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ReactPlayer
                      url={activestreamkeyPlayback}
                      playing
                      controls
                      width="100%"
                      style={{ height: "auto" }}
                    />
                  </ModalBody>
                </Modal>
              </div>
            </TabPane>
            <div className="position-absolute text-center end-0 bottom-0">
              <div className="fs-11px text-gray">Command</div>
              <Icon name="bootstrap" className="fs-1 text-warning" />
              <div className="fs-11px text-gray">Broadcast</div>
            </div>
          </TabContent>
        </div>
      </PreviewCard>
    </>
  );
};

export default SchedularChannel;
