import React, { useRef, useState, useContext, useEffect } from "react";
import { Block, Icon } from "../../components/Component";
import { Button, Collapse, Label, Tooltip } from "reactstrap";
import Dropzone from "react-dropzone";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { ColorPicker } from "primereact/colorpicker";
import { ChannelIDContext, StreamAddContext } from "../app/StremContext";
import { MakeApiCallBlobImage } from "../../utils/api_helper";
import { streamdeck_logo } from "../../images/applogos";
const PlayerSetting = () => {
  const { formData, setFormData } = useContext(StreamAddContext);
  const [open, setIsOpen] = useState("1");
  const [openSection, setOpenSection] = useState(null); // For the dropdown icon
  const { channel_id } = useContext(ChannelIDContext);
  function handleTagChange(tags) {
    setFormData({ ...formData, add_tag_url: tags });
  }

  const moveImageRef = useRef("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggle = () => {
    setIsOpen(!tooltipOpen);
  };

  const [colorHEX, setColorHEX] = useState("");
  const handleColor = (e) => {
    let selectColorValue = e.value;
    setColorHEX(selectColorValue);
    setFormData({ ...formData, main_color: selectColorValue });
  };

  const [files4, setFiles4] = useState([]);
  const handleDropChange = (acceptedFiles, set) => {
    setFormData({ ...formData, logo: acceptedFiles[0] });
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const [disableddropzone, setDisabled] = useState(false);
  const imageShow = (e) => {
    if (e.target.checked) {
      setFormData({ ...formData, logo_on_off: "1" });
      moveImageRef.current.classList.remove("d-none");
      setDisabled(false);
      document.getElementById("leftside").removeAttribute("disabled");
      document.getElementById("rightside").removeAttribute("disabled");
    } else {
      setFormData({ ...formData, logo_on_off: "0" });
      moveImageRef.current.classList.add("d-none");
      setDisabled(true);
      document.getElementById("leftside").setAttribute("disabled", true);
      document.getElementById("rightside").setAttribute("disabled", true);
    }
  };
  const imageFileAdd = async () => {
    if (channel_id !== "") {
      const getImageBlob = new FormData();
      getImageBlob.append("channel_id", channel_id);
      const result = await MakeApiCallBlobImage(getImageBlob, `get-logo`);
      if (result) {
        setFiles4([{ preview: result, name: formData.channel_name }]);
      }
    }
  };

  // for video
  const [showIcon, setShowIcon] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const handlePlay = () => {
    setShowIcon(false);
    setShowControls(true);
    const video = document.getElementById("videoPlayer");
    video.play();
  };

  const handlePause = () => {
    setShowIcon(true);
    setShowControls(false);
    const video = document.getElementById("videoPlayer");
    video.pause();
  };

  useEffect(() => {
    setColorHEX(formData.main_color);
    imageFileAdd();
  }, [formData.main_color]);
  return (
    <>
      <div className="row">
        <h6 className="fw-semibold">Player settings</h6>
        <div className="col-lg-4">
          <div className="">
            <Block size="lg">
              <div className="accordion accordian-s2">
                <div className="accordion-item">
                  <div
                    className={`accordion-head p-2 ${openSection === "1" ? "open" : ""}`}
                    onClick={() => setIsOpen(open === "1" ? "" : "1")}
                    style={{ cursor: "pointer" }}
                  >
                    <h6 className="title">Player Customization </h6>
                    {/* Icon with rotation effect */}
                    <span className={`accordion-icon ${open === "1" ? "rotate" : ""}`}></span>
                  </div>
                  <Collapse className="accordion-body" isOpen={open === "1"}>
                    <div className="accordion-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <div>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={formData.logo_on_off === "1"}
                                id="customSwitch2"
                                onChange={imageShow}
                              />
                              <label className="custom-control-label" htmlFor="customSwitch2">
                                Logo
                              </label>
                            </div>
                            <Dropzone
                              disabled={disableddropzone}
                              onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles4)}
                              accepte="image/*"
                              maxSize={4194304}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section className="mt-2 text-center">
                                  <div {...getRootProps()} className="dropzone upload-zone dz-clickable p-0">
                                    <input {...getInputProps()} />
                                    {files4.length === 0 && (
                                      <div className="dz-message">
                                        <span className="dz-message-text">Drag and drop file</span>
                                        <span className="dz-message-or">or</span>
                                      </div>
                                    )}
                                    {files4.map((file) => (
                                      <div
                                        key={file.name}
                                        className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                      >
                                        <div className="dz-image">
                                          <img src={file.preview} alt="preview" />
                                        </div>
                                      </div>
                                    ))}
                                    <Button color="primary" className="">
                                      <Icon name="upload" />
                                      <span className="text-white">Upload logo</span>
                                    </Button>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div>
                            {/* <div className="form-group">
                              <Label htmlFor="default-0" className="form-label">
                                <p className="fw-normal">Logo link</p>
                              </Label>
                              <div className="form-control-wrap">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="default-0"
                                  placeholder="http://streamdeck.com"
                                  value={formData.logo_link}
                                  onChange={(e) => setFormData({ ...formData, logo_link: e.target.value })}
                                />
                              </div>
                            </div> */}
                            <div>
                              <p>Logo position</p>

                              <ul className="custom-control-group g-3 align-center">
                                <li>
                                  <div className="custom-control custom-control-sm custom-radio">
                                    <input
                                      type="radio"
                                      onChange={(e) => setFormData({ ...formData, logo_position: "1" })}
                                      className="custom-control-input"
                                      id="leftside"
                                      name="position"
                                      checked={formData.logo_position === "1"}
                                    />
                                    <label className="custom-control-label" htmlFor="leftside">
                                      Left
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="custom-control custom-control-sm custom-radio">
                                    <input
                                      type="radio"
                                      onChange={(e) => setFormData({ ...formData, logo_position: "0" })}
                                      className="custom-control-input"
                                      id="rightside"
                                      name="position"
                                      checked={formData.logo_position === "0"}
                                    />
                                    <label className="custom-control-label" htmlFor="rightside">
                                      Right
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="my-2">
                              <p className="mb-0">Main color</p>
                              <ColorPicker
                                inputId="cp-hex"
                                format="hex"
                                value={formData.main_color}
                                onChange={handleColor}
                                className="mb-1"
                                pt={{
                                  colorHandler: { className: "w-1rem h-1rem" },
                                }}
                              />
                              <span>#{colorHEX}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>

                {/* <div className="accordion-item">
                  <div
                    className={`accordion-head collapsed p-2 ${openSection === "2" ? "open" : ""}`}
                    onClick={() => setIsOpen(open === "2" ? "" : "2")} // Toggle between open and close
                    style={{ cursor: "pointer" }}
                  >
                    <h6 className="title">Privacy Settings</h6>
                    Icon with rotation effect
                    <span className={`accordion-icon ${open === "2" ? "rotate" : ""}`}></span>
                  </div>
                  <Collapse className="accordion-body" isOpen={open === "2"}>
                    <div className="accordion-inner">
                      <p className="fw-semibold">Where can this channel be embedded?</p>
                      <ul className="custom-control-group g-3 flex-column align-items-start">
                        <li>
                          <div className="custom-control custom-control-sm custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="anywhere"
                              onChange={(e) => setFormData({ ...formData, channel_embedded: "0" })}
                              name="embedded"
                              checked={formData.channel_embedded === "0"}
                            />
                            <label className="custom-control-label" htmlFor="anywhere">
                              Anywhere
                              <Icon name="help" className="p-1" id="id" />
                              <Tooltip placement="right" isOpen={tooltipOpen} target="id" toggle={toggle}>
                                Enable people to embed this channel on any site.
                              </Tooltip>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-radio">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="choose"
                              onChange={(e) => setFormData({ ...formData, channel_embedded: "1" })}
                              name="embedded"
                              checked={formData.channel_embedded === "1"}
                            />
                            <label className="custom-control-label" htmlFor="choose">
                              Only on domains I choose
                              <Icon name="help" className="p-1" id="id" />
                              <Tooltip placement="right" isOpen={tooltipOpen} target="id" toggle={toggle}>
                                A whitelist with sites on which people can embed this channel. 5 domains limit
                              </Tooltip>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Collapse>
                </div>

                <div className="accordion-item">
                  <div
                    className={`accordion-head collapsed p-2 ${openSection === "3" ? "open" : ""}`}
                    onClick={() => setIsOpen(open === "3" ? "" : "3")} // Toggle between open and close
                    style={{ cursor: "pointer" }}
                  >
                    <h6 className="title">Ads Monetization</h6>
                    Icon with rotation effect
                    <span className={`accordion-icon ${open === "3" ? "rotate" : ""}`}></span>
                  </div>
                  <Collapse className="accordion-body" isOpen={open === "3"}>
                    <div className="accordion-inner">
                      <div className="form-group">
                        <label className="form-label">Add tag URL</label>
                        <div className="form-control-wrap">
                          <TagsInput
                            value={Array.isArray(formData.add_tag_url) ? formData.add_tag_url : [formData.add_tag_url]}
                            onChange={handleTagChange}
                          />
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div> */}
              </div>
            </Block>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="player-image position-relative">
            <video
              id="videoPlayer"
              src="/sample-video.mp4"
              controls={showControls}
              className="fit-content-img"
              onPlay={handlePlay}
              onPause={handlePause}
            ></video>
            {showIcon && (
              <Icon
                name="play-circle-fill"
                style={{ color: "#" + colorHEX }}
                className="position-absolute display-1 play-circle-fill pointer"
                onClick={handlePlay}
              ></Icon>
            )}
            <div
              className={`position-image position-absolute top-5 ${formData.logo_position == 1 ? "left-2" : "right-2"}`}
              ref={moveImageRef}
            >
              {files4.length > 0
                ? files4.map((file, index) => <img src={file.preview} alt="preview" key={index} />)
                : formData.logo_on_off === "1" && <img src={streamdeck_logo} alt="" className="" id="" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayerSetting;
