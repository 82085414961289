import axios from "axios";
import { getCookie } from "./Utils";

export const apiRequest = async (
  url,
  method = "GET",
  data = null,
  contentType = "application/json",
  customHeaders = {}
) => {
  try {
    const authToken = getCookie("authToken", process.env.REACT_APP_TOKEN);

    const config = {
      method: method,
      headers: {
        "Content-Type": contentType,
        authToken: authToken,
        ...customHeaders,
      },
      data: data,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to make API request");
  }
};


