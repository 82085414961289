import axios from "axios";
import { apiRequest } from "../../utils/apiHelper";
import { getCookie } from "../../utils/Utils";

export const formatViewsData = (data) => {
  // Check if data is defined and is an array

  if (!Array.isArray(data) || data.length === 0) {
    // Return default or empty chart configuration
    return {
      labels: ["0"],
      dataUnit: "Views",
      datasets: [
        {
          label: "Channel Views",
          color: "#9d72ff",
          borderWidth: 2,
          lineTension: 0,
          fill: true,
          dash: 0,
          borderColor: "#9d72ff",
          backgroundColor: "rgba(157, 114, 255, 0.15)",
          borderCapStyle: "square",
          pointBorderColor: "transparent",
          pointBackgroundColor: "transparent",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "#9d72ff",
          pointBorderWidth: 2,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 4,
          data: [0],
        },
      ],
    };
  }

  // Assuming data is an array with valid entries
  const labels = data.map((entry) => `${entry.channel_name} (${entry.hour})`); // Assuming 'date' field in data
  const viewsData = data.map((entry) => entry.views); // Use 'views' for data

  const datasets = [
    {
      label: "Channel Views",
      color: "#9d72ff",
      borderWidth: 2,
      lineTension: 0.1,
      fill: true,
      dash: 0,
      borderColor: "#9d72ff",
      backgroundColor: "rgba(157, 114, 255, 0.15)",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: viewsData,
    },
  ];

  return {
    labels: labels,
    dataUnit: "Views",
    datasets: datasets,
  };
};

export const combineViews = (data) => {
  const combinedData = {};

  data.forEach((entry) => {
    const date = new Date(entry.hour);
    const roundedDate = roundToNearestThreeMinutes(date);
    const key = roundedDate.toISOString().slice(0, 16).replace("T", " ");

    if (!combinedData[key]) {
      combinedData[key] = { ...entry, views: 0 };
      combinedData[key].hour = key;
    }

    combinedData[key].views += entry.views;
  });

  return Object.values(combinedData);
};

function roundToNearestThreeMinutes(date) {
  const ms = date.getTime();
  const roundedMs = Math.round(ms / (3 * 60 * 1000)) * (3 * 60 * 1000);
  return new Date(roundedMs);
}

export const downloadPDF = async (type, period) => {
  try {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("period", period); // Pass the selected period

    const response = await apiRequest("analytics/download-pdf", "POST", formData);
    window.open(response.data.url, "_blank");
  } catch (error) {
    console.error("Error downloading file:", error);
  } finally {
  }
};

export const downloadXLS = async (type, period) => {
  try {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("period", period); // Pass the selected period

    const authToken = getCookie("authToken", process.env.REACT_APP_TOKEN);
    const response = await axios.post(`${process.env.REACT_APP_API_URL}analytics/download-excel`, formData, {
      headers: {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        authToken: authToken,
      },
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${type}_chart.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Failed to download Excel file:", error.message);
  } finally {
  }
};
