import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import TagsInput from "react-tagsinput";
import { Button, Label, Spinner } from "reactstrap";
import { Icon } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiHelper";
const EditVideo = () => {
  const { id } = useParams();
  const [videoData, setVideoData] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    tags: [],
    thumbnailURL: "",
    videoURL: "",
  });

  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchVideoUrl = async (video_id) => {
      try {
        const authToken = getCookie("authToken", null);
        const response = await apiRequest("get-video", "POST", { video_id }, "application/json");
        if (response.data.data) {
          setVideoUrl(response.data.data.videoStream);
        } else {
          const blobResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}get-video`,
            { video_id },
            {
              headers: {
                authToken: authToken,
              },
              responseType: "blob",
            }
          );
          // const blob = new Blob([blobResponse.data], {
          //   type: blobResponse.headers["content-type"],
          // });
          // const blobUrl = URL.createObjectURL(blob);
          setVideoUrl(response.data.data.videoStream);
        }
      } catch (error) {
        console.error("Error fetching video URL", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoUrl(id);
  }, [id]);

  const fetchVideoData = async () => {
    try {
      const authToken = getCookie("authToken", null);
      const formData = new FormData();
      formData.append("video_id", id);

      const response = await apiRequest("get-all-video", "POST", formData, "application/json");

      const video = response.data.data[0];

      setFormData({
        name: video.title || "",
        description: video.description || "",
        tags: video.tags ? JSON.parse(video.tags) : [],
        thumbnailURL: video.thumbnailURL || "",
        videoURL: video.video_url || "",
      });
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  useEffect(() => {
    fetchVideoData();
  }, []);

  const [showTooltip, setShowTooltip] = useState(false);

  function handleClick() {
    const inputField = document.getElementById("video-input");
    inputField.select();
    document.execCommand("copy");
    setShowTooltip(true);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTagChange = (tags) => {
    setFormData({
      ...formData,
      tags: tags,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Show confirmation dialog using SweetAlert
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the video data?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (confirmResult.isConfirmed) {
      try {
        const authToken = getCookie("authToken", null);
        const formDataWithStreamId = new FormData();
        formDataWithStreamId.append("stream_id", id);
        formDataWithStreamId.append("title", formData.name);
        formDataWithStreamId.append("description", formData.description);
        formDataWithStreamId.append("tags", formData.tags);
        formDataWithStreamId.append("thumbnailURL", formData.thumbnailURL);
        formDataWithStreamId.append("videoURL", formData.videoURL);
        const response = await apiRequest("update-video", "POST", formDataWithStreamId, "application/json");
        toast.success("Your video data has been successfully updated!");
        navigate("/videos");
      } catch (error) {
        console.error("Error updating video:", error);
      }
    }
  };

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <div className="mb-3">
              <h5 className="pointer mb-3 d-inline-block" onClick={() => navigate(-1)}>
                <Icon name="arrow-left" className="pe-1"></Icon>
                Edit video
              </h5>
              <div className="player-wrapper">
                {loading ? (
                  <div style={{ textAlign: "center" }}>
                    <Spinner color="dark" />
                  </div>
                ) : (
                  <ReactPlayer url={videoUrl} controls width="100%" height="360px" className="react-player" />
                )}
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <Label htmlFor="default-0" className="form-label">
                  <h6 className="fw-semibold">Name</h6>
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    id="default-name"
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="form-label fw-semibold">
                  <span className="fw-bold">Description</span>
                </label>
                <div className="form-control-wrap">
                  <textarea
                    className="form-control"
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="mb-3 tagsurl">
                <div className="form-group">
                  <label className="form-label fw-semibold">
                    <p>Add tag URL</p>
                  </label>
                </div>
                <TagsInput value={formData.tags} onChange={handleTagChange} />
              </div>
              {/* <div className="form-group mb-3">
                <label className="form-label fw-semibold">
                  <p className="">Thumbnail URL</p>
                </label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    name="thumbnailURL"
                    value={formData.thumbnailURL}
                    onChange={handleInputChange}
                    id="default-thumbnail"
                  />
                </div>
              </div> */}
              <div className="form-control-wrap mb-3 d-none">
                <label className="form-label fw-semibold">Video URL</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="video-input"
                    name="videoURL"
                    value={formData.videoURL}
                    onChange={handleInputChange}
                    readOnly
                  />
                  <div className="input-group-prepend pointer tooltip-container">
                    <Button
                      color="dark"
                      className="input-group-text  relative"
                      onClick={handleClick}
                      id="inputGroup-sizing-lg-2"
                    >
                      <Icon name="copy" />
                    </Button>
                    {showTooltip && <div className="tooltip-theme">Copied!</div>}
                  </div>
                </div>
              </div>
              <div>
                <h6>Custom Metadata Fields</h6>
                <p>
                  If you want to add custom metadata fields to your content library to be displayed from the Delivery
                  API, <Link to="">contact support.</Link>
                </p>
              </div>
              <div className="mt-3 mt-xl-4 text-end">
                <Button type="submit" className="mx-1" color="primary">
                  Submit
                </Button>
                {/* <Button className="mx-1 d-none" color="light">
                  Cancel
                </Button> */}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditVideo;
