import axios from "axios";

export async function increaseView(channel) {
  // console.log(channel);

  const { channel_id, livestream_id } = channel; // Assuming channel ID is available in the channel object

  // Get device and browser info
  const deviceType = getDeviceType();
  const browserInfo = navigator.userAgent;

  // Function to get the country from latitude and longitude
  async function getCountryFromLatLng(lat, lon) {
    try {
      const resp = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
      const data = await resp.json();
      return data.address?.country ?? "Unknown";
    } catch (e) {
      return "Unknown";
    }
  }

  // Function to get the user's location
  function getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }

  let locationData;

  try {
    const position = await getLocation();
    const country = await getCountryFromLatLng(position.latitude, position.longitude);

    locationData = [
      {
        latitude: position.latitude,
        longitude: position.longitude,
        description: "User's current location",
        country: country,
      },
    ];
  } catch (error) {
    console.error("Error getting location or country:", error);
    // Default location data if geolocation is not allowed or fails
    locationData = [
      {
        latitude: 0, // Default latitude
        longitude: 0, // Default longitude
        description: "Location not provided",
        country: "Unknown",
      },
    ];
  }

  const formData = new FormData();
  formData.append("channel_id", channel_id);
  formData.append("livestream_id", livestream_id);
  formData.append("device_type", deviceType);
  formData.append("browser_info", browserInfo);
  formData.append("location", JSON.stringify(locationData));

  try {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      url: `${process.env.REACT_APP_PUBLIC_URL_API}track-tv-view`,
    };

    const response = await axios(config);
    console.log("View tracked successfully:", response);
  } catch (error) {
    console.error("Error tracking view:", error);
  }
}

function getDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for mobile devices
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  // Check for tablets
  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return "Tablet";
  }

  // Check for desktops
  if (/macintosh|mac os x|windows nt|linux/i.test(userAgent)) {
    return "Desktop";
  }

  return "Unknown";
}
