import React, { useEffect, useState } from "react";
import { Button, Input, Label, Spinner, Tooltip } from "reactstrap";
import { Icon, RSelect } from "../../components/Component";
import { duration } from "../Video/DropDown";
import { fetchAPI, handleSelectChange } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";

const Fast = () => {
  const Navigate = useNavigate("");
  const [formData, setFormData] = useState({
    channel_type: "1",
    channel_name: "Untitled Channel",
    schedule_duration: "0",
  });

  const [fastChannelId, setfastChannelId] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function nextbtn() {
    setLoading(true);
    if (formData.channel_name === "") {
      toast.error("Please enter channel name");
      return false;
    }
    if (formData.schedule_duration === "") {
      toast.error("Please select schedule duration");
      return false;
    }

    let formdata = new FormData();
    formdata.append("channel_type", formData.channel_type);
    formdata.append("channel_name", formData.channel_name);
    formdata.append("schedule_duration", formData.schedule_duration);
    formdata.append("no_of_adds_in_hour", numberofvideo);
    formdata.append("Seconds_per_add_break", durationofads);
    console.log(formData.schedule_duration);
    const channelApi = await fetchAPI(formdata, "create-channel");
    if (channelApi.status) {
      toast.success(channelApi.message);
      navigate("/channels");
    } else {
      if (channelApi?.errors) {
        toast.error(channelApi?.errors?.[0]);
      } else {
        toast.error(channelApi.message);
      }
    }
  }

  // const parms = useParams();
  // let decoded = window.atob(parms.id);
  // const [channel_id, setChannel_id] = useState(decoded);

  const [numberofvideo, setnumberofvideo] = useState("0");
  const [durationofads, setdurationofads] = useState("15");

  const channelListData = async (channel_id) => {
    try {
      const formChannelId = new FormData();
      formChannelId.append("channel_id", channel_id);
      const getChannelData = await fetchAPI(formChannelId, "get-channel");
      if (getChannelData.status) {
        setFormData({
          channel_type: getChannelData?.data?.channel?.channel_type,
          channel_name: getChannelData?.data?.channel?.channel_name,
          schedule_duration: getChannelData?.data?.channel?.schedule_duration,
        });
        setdurationofads(getChannelData?.data?.channel?.Seconds_per_add_break);
        setnumberofvideo(getChannelData?.data?.channel?.no_of_adds_in_hour);
      }
    } catch (error) {}
  };

  async function update_channel() {
    setLoading(true);
    if (formData.channel_name === "") {
      toast.error("Please enter channel name");
      return false;
    }
    if (formData.schedule_duration === "") {
      toast.error("Please select schedule duration");
      return false;
    }

    let formdata = new FormData();
    formdata.append("channel_id", fastChannelId);
    formdata.append("channel_type", formData.channel_type);
    formdata.append("channel_name", formData.channel_name);
    formdata.append("schedule_duration", formData.schedule_duration);
    formdata.append("no_of_adds_in_hour", numberofvideo);
    formdata.append("Seconds_per_add_break", durationofads);
    const channelApi = await fetchAPI(formdata, "update-channel");
    if (channelApi.status) {
      toast.success(channelApi.message);
      navigate("/channels");
    } else {
      if (channelApi?.errors) {
        toast.error(channelApi?.errors?.[0]);
      } else {
        toast.error(channelApi.message);
      }
    }
  }

  const [question, setlearmore] = useState(false);
  const togglequestion = () => setlearmore(!question);

  const [set, setSelect] = useState(false);
  useEffect(() => {
    if (params?.id) {
      let channel_id = window.atob(params.id);
      setfastChannelId(channel_id);
      channelListData(channel_id);
    }
  }, []);

  function calculateTotalAdDuration(noOfAds, adLength) {
    const totalSeconds = noOfAds * adLength;
    let minutes = totalSeconds / 60;
    minutes = Math.round(minutes * 10) / 10;
    console.log(minutes);
    return minutes + " minutes";
  }

  const [typingTimer, setTypingTimer] = useState(null);
  const doneTypingInterval = 100;

  function checkName(channelTitle) {
    clearTimeout(typingTimer);
    if (channelTitle.trim() !== "") {
      const newTimer = setTimeout(() => doneTyping(channelTitle), doneTypingInterval);
      setTypingTimer(newTimer);
    }
  }

  async function doneTyping(channelTitle, showtoster = true) {
    const channelData = new FormData();
    channelData.append("channel_name", channelTitle);

    const channelnameData = await fetchAPI(channelData, "check-channel-name");
    if (channelnameData.status) {
      if (channelnameData.data) {
        if (showtoster) {
          toast.error(channelnameData.message);
        }
        setFormData((prevData) => ({ ...prevData, channel_name: channelnameData.data.channelName }));
      } else {
        if (showtoster) {
          toast.error(channelnameData.message);
        }
      }
    }
  }

  useEffect(() => {
    doneTyping(formData.channel_name, false);
  }, []);

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5 className="pointer mb-3 d-inline-block" onClick={() => Navigate(-1)}>
              <Icon name="arrow-left" className="pe-1"></Icon>
              {fastChannelId === "" ? "Create channel" : "Edit channel"}
            </h5>
            <div className="">
              <h6>FAST</h6>

              <div className="mb-3">
                <div className="form-group">
                  <Label htmlFor="default-0" className="form-label">
                    <h6 className="fw-semibold">Name</h6>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      value={formData.channel_name}
                      onChange={(e) => {
                        setFormData({ ...formData, channel_name: e.target.value });
                        checkName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <h6 className="fw-semibold">Scheduler duration</h6>
                <div className="d-flex g-2 align-items-center mb-3">
                  <div className="preview-item w-25">
                    <select
                      name=""
                      id=""
                      onChange={(event) => {
                        const selectedValue = event.target.value;
                        setFormData({ ...formData, schedule_duration: selectedValue });
                        if (selectedValue === "0") {
                          setSelect(false);
                        } else {
                          setSelect(true);
                        }
                      }}
                      className="form-select"
                    >
                      {duration.map((item, index) => (
                        <option
                          key={item.value}
                          value={item.value}
                          selected={formData.schedule_duration === item.value}
                          style={{ padding: "25px" }}
                          className=""
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  {set ? (
                    <p className="m-0">You will schedule a whole week 24/7 and content will be repeated weekly.</p>
                  ) : (
                    <p className="m-0">You will schedule one day (24 hours) and content will be repeated daily.</p>
                  )}
                </div>
              </div>
              <>
                <h6>SCTE-35 ad markers</h6>
                <div className="bg-azure-dim p-2 mb-3">
                  <div className="d-flex flew-wrap align-items-center my-2">
                    <div className="form-control-wrap me-2">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          name="select"
                          className=""
                          value={numberofvideo}
                          onChange={(e) => setnumberofvideo(e.target.value)}
                        >
                          {[...Array(11).keys()].map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <p>Number of advertising breaks per hour.</p>
                  </div>
                  <div className="d-flex flew-wrap align-items-center my-2">
                    <div className="form-control-wrap me-2">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          name="select"
                          className=""
                          value={durationofads}
                          onChange={(e) => setdurationofads(e.target.value)}
                        >
                          {[15, 30, 45, 60, 90, 120].map((duration) => (
                            <option key={duration} value={duration}>
                              {duration}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <p>Seconds of duration per advertising break.</p>
                  </div>
                  <p>
                    This channel will include{" "}
                    <span className="fw-bold">{calculateTotalAdDuration(numberofvideo, durationofads)}</span> of ads per
                    hour.
                  </p>
                </div>
              </>
              <h6 className="">Server-side ad insertion (SSAI)</h6>
              <div className="bg-azure-dim p-2 mb-3">
                <div className="my-2">
                  <p className="fw-bold">
                    Besides the channel with SCTE-35 ad markers, do you plan to serve the ads through Stream Deck's SSAI
                    partner? <span className="fw-normal">No, I already have SSAI partners.</span>{" "}
                    <Icon className="text-black" name="question" id="question" />
                  </p>
                  <Tooltip placement="bottom" isOpen={question} target="question" toggle={togglequestion}>
                    Please note that this option cannot be edited once the channel has been created You need to create a
                    new channel to activate or deactivate this option.
                  </Tooltip>
                </div>
                <p className="fw-bold">TAd tag URL (optional)</p>
              </div>
            </div>
            <div className="mt-3 mt-xl-4">
              {fastChannelId === "" ? (
                <Button className="mx-1" color="primary" onClick={() => nextbtn()}>
                  {loading ? <Spinner size="sm" /> : <span>Next</span>}
                </Button>
              ) : (
                <Button className="mx-1" color="primary" onClick={() => update_channel()}>
                  {loading ? <Spinner size="sm" /> : <span>Update</span>}
                </Button>
              )}

              <Button className="mx-1" color="light">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Fast;
