import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Icon } from "../components/Component";

const ShortcutSection = ({ heading, shortcuts, activeIndex, offset }) => {
  if (shortcuts.length === 0) return null; // Do not render if there are no shortcuts

  return (
    <div className="mt-3 fs-5 p-2 bg-light rounded-2">
      <small className="fs-14px fw-medium text-muted" style={{ marginLeft: "2px" }}>
        {heading}
      </small>
      {shortcuts.map((shortcut, index) => (
        <div
          key={index}
          className={`d-flex justify-content-between mt-2 p-1 rounded-2 fs-16px align-items-center ${
            index + offset === activeIndex ? "bg-dark-dim" : ""
          }`}
        >
          <div className="ms-1">{shortcut.name}</div>
          <div className="me-1 d-flex align-items-center">
            {shortcut.key.split(" + ").map((keyPart, idx, arr) => (
              <React.Fragment key={idx}>
                <kbd className="bg-black text-white fw-bold">{keyPart}</kbd>
                {idx < arr.length - 1 && <span className="mx-1">+</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default function KeyboardLayout({ isOpen, toggle }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  const shortcuts = {
    quickActions: [
      { name: "Open shortcuts", key: "Ctrl + /" },
      { name: "Close shortcuts", key: "esc" },
    ],
    mediaLibrary: [
      { name: "Select all", key: "Ctrl + A" },
      { name: "Delete", key: "Ctrl + del" },
      { name: "Delete All", key: "Ctrl + A + del" },
    ],
  };

  const filteredQuickActions = shortcuts.quickActions.filter((shortcut) =>
    shortcut.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredMediaLibrary = shortcuts.mediaLibrary.filter((shortcut) =>
    shortcut.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        toggle();
      } else if (event.key === "ArrowDown") {
        setActiveIndex((prevIndex) => (prevIndex + 1) % (filteredQuickActions.length + filteredMediaLibrary.length));
      } else if (event.key === "ArrowUp") {
        setActiveIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredQuickActions.length + filteredMediaLibrary.length) %
            (filteredQuickActions.length + filteredMediaLibrary.length)
        );
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, filteredQuickActions.length, filteredMediaLibrary.length, toggle]);

  useEffect(() => {
    setActiveIndex(0);
  }, [searchTerm]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="zoom" className="">
        <ModalBody>
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="search" />
              </div>
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus={true}
              />
            </div>
          </div>
          {filteredQuickActions.length > 0 && (
            <ShortcutSection
              heading="Quick Actions"
              shortcuts={filteredQuickActions}
              activeIndex={activeIndex}
              offset={0}
            />
          )}
          {filteredMediaLibrary.length > 0 && (
            <ShortcutSection
              heading="Media Library"
              shortcuts={filteredMediaLibrary}
              activeIndex={activeIndex}
              offset={filteredQuickActions.length}
            />
          )}
        </ModalBody>
        <ModalFooter className="p-1 d-flex justify-content-between">
          <div>
            <span className="ms-3 fs-14px fw-medium text-muted">
              Navigate{" "}
              <span>
                <kbd className="bg-black text-white fw-bold ms-1">
                  <Icon name="arrow-up" />
                </kbd>
                <kbd className="bg-black text-white fw-bold ms-1">
                  <Icon name="arrow-down" />
                </kbd>
              </span>
            </span>
          </div>
          <div></div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
