import { getResources } from "./epg-data";
import {useFetchUserVideos} from "./uservideos";
export const COLORS = Object.freeze({
  WHITE: "#fff",
  TEAL: "rgb(93, 218, 219)",
  RED: "#E53E3E",
  GREY: "#7180961a",
});

export const channelStyles = {
  box: Object.freeze({
    border: "1px solid transparent",
    borderTopColor: "transparent",
    borderBottomColor: COLORS.GREY,
    borderRightColor: COLORS.GREY,
  }),
  boxGroupTree: Object.freeze(({ isOpen, isNestedChild, isLastNestedChild, groupTree }) => {
    if (isLastNestedChild) {
      return Object.freeze({
        ...channelStyles.box,
        borderLeftColor: COLORS.TEAL,
        borderRightColor: COLORS.TEAL,
        borderBottomColor: COLORS.TEAL,
      });
    } else if (groupTree || isNestedChild) {
      return Object.freeze({
        ...channelStyles.box,
        borderTopColor: isNestedChild ? "transparent" : COLORS.TEAL,
        borderLeftColor: COLORS.TEAL,
        borderRightColor: COLORS.TEAL,
        borderBottomColor: isOpen || isNestedChild ? COLORS.GREY : COLORS.TEAL,
      });
    }
    return channelStyles.box;
  }),
  arrowRight: Object.freeze((isFirstNestedChild) => ({
    position: "absolute",
    top: "52%",
    left: "10%",
    transform: `translate(50%, -50%)`,
    color: isFirstNestedChild ? COLORS.RED : COLORS.TEAL,
  })),
  arrowDown: Object.freeze((isOpen) => ({
    position: "absolute",
    top: "52%",
    right: 0,
    marginLeft: 2,
    color: COLORS.TEAL,
    transform: `translate(-50%, -50%) ${isOpen ? `rotate(180deg)` : `rotate(0deg)`}`,
  })),
};

// export const fetchResources = async () => {
//   const resources = getResources();
//   return new Promise((res) => setTimeout(() => res(resources), 1000));
// };

export const ELEMENTS = Object.freeze([
  {
    id: "efed1fba-1d58-4ad6-af6a-17b110e0b987",
    title: "Element 1",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
    duration: "30",
  },
  {
    id: "ef3f-34f-34f-34f",
    title: "Element 2",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
    duration: "30",
  },
  {
    id: "rtf3f-tr3f-34f-34f",
    title: "Element 3",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
    duration: "30",
  },
  {
    id: "rtf3f-tr3f-34f-3gvjhf",
    title: "Element 4",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    image: "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
    duration: "30",
  },
  {
    id: "rtf3f-tr3f-34f-gyjgbh",
    title: "Element 5",
    description: "WOW",
    image: "https://www.themoviedb.org/t/p/w1066_and_h600_bestv2/sjx6zjQI2dLGtEL0HGWsnq6UyLU.jpg",
    duration: "30",
  },
]);
