import React, { useState, useEffect } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import { getCookie } from "../../../../utils/Utils";
import { linkItems } from "../../../../components/links/LinkItemsData";
import AccountAvatar from "../../../../pages/AccountAvatar";
import Cookies from "js-cookie";
import { fetchAPI } from "../../../../utils/Utils";
import { toast } from "react-toastify";

const User = () => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const setLogout = (e) => {
    e.preventDefault();
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });

    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };

  const themeChange = async (value) => {
    try {
      const data = new FormData();
      data.append("action", "update");
      data.append("column", "theme");
      data.append("columnValue", value);
      const addTheme = await fetchAPI(data, "api/v1/public/frontend-settings");
      console.log("API Response:", addTheme);
      if (addTheme.status) {
        console.log("Theme updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const data = new FormData();
        data.append("action", "fetch");
        const response = await fetchAPI(data, "api/v1/public/frontend-settings");
        if (response.status) {
          const fetchedTheme = response?.data?.frontend_settings?.theme;
          themeUpdate.skin(fetchedTheme == "1" ? "light" : "dark");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchTheme();
  }, []);

  const handleThemeToggle = (ev) => {
    ev.preventDefault();
    const newThemeValue = theme.skin === "dark" ? 1 : 2;
    themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
    themeChange(newThemeValue);
  };
  const handleOldClick = (link, event) => {
    event.preventDefault();
    toast.info(
      <div>
        Very soon transforming to Silo 5.0,
        <br />
        in mean time enjoy Silo 4.2
      </div>,
      // {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 2000,
      // }
    );
    setTimeout(() => {
      window.location.href = link;
    }, 2000);
  };

  const handleClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.link, event);
    } else {
      toggle();
    }
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle bg-transparent"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <AccountAvatar profileName={getCookie("username", "John")} />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <AccountAvatar profileName={getCookie("username", "John")} />
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "john.silocloud.io")}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner px-0">
          <LinkList className="profile-menus">
            {linkItems.map((item, index) => (
              <LinkItem
                key={index}
                link={item.link}
                icon={item.icon}
                onClick={(event) => handleClick(item, event)}
                className={` ${item.className}`}
              >
                {item.img ? (
                  <img src={item.img} alt={item.children} className="link-item-img" />
                ) : (
                  <i className={`icon ${item.icon}`}></i>
                )}
                <span className="m-0">{item.text}</span>
              </LinkItem>
            ))}

            <li className="">
              <a
                className={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                href="#"
                onClick={handleThemeToggle}
              >
                {theme.skin === "dark" ? (
                  <>
                    <em className="icon ni ni-sun"></em>
                    <span>Light Mode</span>
                  </>
                ) : (
                  <>
                    <em className="icon ni ni-moon"></em>
                    <span>Dark Mode</span>
                  </>
                )}
              </a>
            </li>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a className="pointer" onClick={setLogout}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
