import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Webcam from "react-webcam";
import "../../css/streamdeck_css/streamdeck.css";
import ReactPlayer from "react-player";
import ScheduledChannelContext from "../ScheduledChannelContext";

const Footer = ({
  activeWebcam,
  startTimestamp,
  setActiveWebcam,
  recording,
  setRecording,
  handleStartRecording,
  handleStopRecording,
  handleVolumeChange,
  stopCurrentStream,
  responseUrl,
  stream,
  streamRef,
  mediaRecorderRef,
  setStream,
  streamnew,
  setnewstrem,
  devices,
  selectedId,
  selectedDeviceId,
  setSelectedDeviceId,
  switchCamera,
  truncateLabel,
  handleDeviceChange,
  handleProgramClick,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [activeWebcamnew, setActiveWebcamnew] = useState(null);
  const videoRef = useRef(null);
  const toggleModal = () => setModal(!modal);
  const handleNavigateToPlayoutGallery = () => {
    navigate("/Playout-gallery");
  };

  const handleRecordClick = () => {
    switchCamera();
    handleProgramClick();
  };

  return (
    <div className="nk-footer nk-channel-footer position-fixed bottom-0 py-0" style={{ zIndex: 99 }}>
      <div className="container-fluid">
        <div className="nk-footer-wrap justify-center">
          <div className="footer-hide d-flex align-items-center">
            <ul className="d-flex tab-footer bg-light">
              <li>
                <Link
                  to="/videos"
                  className={`btn py-3 nav-item text-body border-0 rounded-0 ${
                    location.pathname === "/videos" ? "active" : ""
                  }`}
                >
                  Media Library
                </Link>
              </li>
              <li>
                <Link
                  to="/lives"
                  className={`btn py-3 nav-item text-body border-0 rounded-0 ${
                    location.pathname === "/lives" ? "active" : ""
                  }`}
                >
                  RTMP Streams
                </Link>
              </li>
              <li className="">
                <Link
                  to="/schedule-channel/timeline"
                  className={`btn py-3 nav-item text-body border-info bold border-end-0 rounded-0 ${
                    location.pathname === "/schedule-channel/timeline" ? "" : ""
                  }`}
                >
                  Scheduler
                </Link>
              </li>
              <li>
                <Link
                  to={`/channel-view/${selectedId}`}
                  className={`btn py-3 nav-item text-body border-info bold border-start-0 rounded-0 ${
                    location.pathname === "/channel-view" ? "" : ""
                  }`}
                >
                  Channel View
                </Link>
              </li>
            </ul>
            <div className="d-flex bg-light ms-3 py-1 px-3">
              <div className="">
                <p className="fs-12 mb-0">Command</p>
                <div className="d-flex align-items-center">
                  {/* <div className="pointer">
                    <span className="fs-1 bg-danger px-3 rounded-3" onClick={handleProgramClick}>
                      R
                    </span>
                    <p className="mb-0 fs-12px text-center opacity-50">Record</p>
                  </div> */}
                  <div className="pointer">
                    <span className="fs-1 bg-danger px-3 rounded-3 text-white" onClick={handleRecordClick}>
                      R
                    </span>
                    <p className="mb-0 fs-12px text-center opacity-50">Record</p>
                  </div>
                  <div className="pointer" onClick={handleNavigateToPlayoutGallery}>
                    <span className="fs-1 bg-success px-3 ms-1 rounded-3 text-white">P</span>
                    <p className="mb-0 fs-12px text-center opacity-50">Program</p>
                  </div>
                </div>
              </div>
              <div className="ms-2 CamName">
                <p className="fs-12 mb-0">View</p>
                <ul className="d-flex tab-footer bg-light">
                  {devices.map((device, index) => (
                    <li key={device.deviceId} className={activeWebcam === device.deviceId ? "active-camera" : ""}>
                      <button
                        className={`btn py-3 nav-item text-body border-0  rounded-0`}
                        onClick={() => handleDeviceChange(device.deviceId)}
                      >
                        Cam{index + 1}
                        {/* {truncateLabel(device.label || `Cam ${device.deviceId}`)} */}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="ms-2">
                <button className="button4 bg-danger text-light" onClick={switchCamera}>
                  <span className="button_text text-white">Toggle</span>
                </button>
              </div>
              <div className="ms-2">
                <button className="button4 bg-danger text-light" onClick={toggleModal} disabled={!responseUrl}>
                  <span className="button_text text-white">On Air</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Live Stream</ModalHeader>
        <ModalBody>{responseUrl && <ReactPlayer url={responseUrl} playing controls width="100%" />}</ModalBody>
      </Modal>
    </div>
  );
};
export default Footer;
