import React, { useEffect, useState } from "react";
import { Card, Col, Collapse, Dropdown, DropdownItem, Row } from "reactstrap";
import TooltipItem from "./TooltipItem";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Block, CodeBlock, Icon, OutlinedInput } from "../../components/Component";
import { Accordion, Button, DropdownMenu, DropdownToggle, Tooltip, UncontrolledDropdown } from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { fetchAPI } from "../../utils/api_helper";
import { useChannelList } from "../../utils/useChannelList";
import RecordAndUploadModal from "../app/RecordAndUploadModal";
import UploadFromStorageModal from "../app/UploadFromStorageModal";

const AccordionTab = ({ className, variation }) => {
  const [loading, setLoading] = useState(true);
  const NavigateChannel = useNavigate("");
  const [allvideos, setAllVideos] = useState([]);
  const [totalvideo, setTotalVideo] = useState("");
  const navigate = useNavigate();
  const parms = useParams();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedchannel, setSelectedchannel] = useState(null);
  const [channelList, setChannelList] = useChannelList(setSelectedchannel, setLoading);

  const deletChanneldata = async (deletechannelId, indexID) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleFormData = new FormData();
        deleFormData.append("channel_id", deletechannelId);
        const deleteData = await fetchAPI(deleFormData, "delete-channel");
        if (deleteData.status) {
          toast.success(deleteData.message);
          const updatedChannelList = channelList.filter((channel) => channel.id !== deletechannelId);
          setChannelList(updatedChannelList);
          if (updatedChannelList?.[0]) {
            setSelectedchannel(updatedChannelList[0]);
          }
        } else {
          toast.error(deleteData.message);
        }
      }
    });
  };

  const [deletechannel, setDelete] = useState(false);
  const [editchannel, setEdit] = useState(false);

  const toggledelete = () => setDelete(!deletechannel);
  const toggleEdit = () => setEdit(!editchannel);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const addScheduleList = (channel) => {
    setSelectedchannel(channel);
  };
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  // video
  const toggle1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggle2 = () => {
    setIsOpen2(!isOpen2);
  };
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

  const DropDowntoggle = () => {
    setDropDownIsOpen(!dropDownIsOpen);
  };

  const getAllVideo = async () => {
    try {
      const formdata = new FormData();
      const videoDataList = await fetchAPI(formdata, "get-all-video");
      if (videoDataList.status) {
        const getdata = videoDataList?.data?.videostreams;
        setAllVideos(getdata);
        setTotalVideo(videoDataList?.data?.total_records_count);
      } else {
        setAllVideos([]);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   getAllVideo();
  // }, []);

  return (
    <>
      <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}>
        <div className="accordion-item ">
          <div
            className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""} bg-blue-dim px-2 mb-1 pointer`]}
            onClick={() => toggleCollapse("1")}
          >
            <h6 className="title">Channels</h6>
            <span className="accordion-icon"></span>
          </div>
          <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
            <div className="accordion-inner p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <UncontrolledDropdown>
                    <div className="btn-group">
                      <DropdownToggle className="dropdown-toggle-split rounded-2" color="primary">
                        <Icon name="plus" />
                        <span>Create Channel</span>
                      </DropdownToggle>
                    </div>
                    <DropdownMenu>
                      <ul className="link-list-opt">
                        <li>
                          <Link to="/create-channel/looped?type=1" className="w-100">
                            <Icon name="redo" className="text-success pe-1" />
                            <span>Looped (linear)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-channel/vod" className="w-100">
                            <Icon name="view-list-wd" className="text-primart pe-1" />
                            <span>Playlist (on demand)</span>
                          </Link>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div>
                  <div>0 total views</div>
                </div>
              </div>
              <>
                {loading ? (
                  <div className="mt-3">
                    <ShimmerThumbnail height={30} rounded />
                    <ShimmerThumbnail height={30} rounded />
                    <ShimmerThumbnail height={30} rounded />
                  </div>
                ) : channelList.length > 0 ? (
                  channelList.map((item, index) => {
                    return (
                      <div
                        key={item.id}
                        className={`d-flex bg-secondary-dim align-items-center shadow-sm mt-3 justify-content-between pointer setHover ${
                          index === selectedItemIndex ? "hover-theme" : ""
                        }`}
                        onClick={() => addScheduleList(item)}
                      >
                        <div className="d-flex p-1">
                          <div>
                            {item.channel_type === "0" && parms.type !== "looped" ? (
                              item.linear_channel_type === "0" ? (
                                <Icon name="calendar-booking" className="fs-4 text-warning" />
                              ) : (
                                <Icon name="redo" className="fs-4 text-success" />
                              )
                            ) : item.channel_type === "1" ? (
                              <Icon name="spark" className="fs-4 text-warning" />
                            ) : item.channel_type === "2" ? (
                              <Icon name="view-list-wd" className="fs-4 text-primary" />
                            ) : (
                              <Icon name="redo" className="fs-4 text-warning" />
                            )}
                          </div>
                          <div className="ms-1">
                            <TooltipItem key={index} placement="top" text={item.channel_name} id={index} />
                            <span className="fs-12px">
                              <Icon name="eye" /> 0
                            </span>
                          </div>
                        </div>
                        <ul className="list-unstyled d-flex mx-1 g-1 list-channel">
                          <li>
                            {item.channel_type === "1" ? (
                              <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                <Icon name="play" />
                                {/* <span>Preview</span> */}
                              </Button>
                            ) : (
                              <Link to={`/embed-channel/${item.id}`}>
                                <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                  <Icon name="code" />
                                  {/* <span>Preview & Embed</span> */}
                                </Button>
                              </Link>
                            )}
                          </li>
                          <li>
                            {item.channel_type === "2" ? (
                              ""
                            ) : (
                              <Link to="/stream-link">
                                <TooltipItem
                                  key={"m3" + index}
                                  placement="top"
                                  text="Get.m3u8 Link"
                                  toolTipList={true}
                                  btntext={[{ name: "external" }, { text: "" }]}
                                  id={"m3" + index}
                                />
                              </Link>
                            )}
                          </li>
                          <li>
                            {item.channel_type === "1" ? (
                              <Link to="">
                                <TooltipItem
                                  key={"epg" + index}
                                  placement="top"
                                  text="Get EPG guide link"
                                  toolTipList={true}
                                  btntext={[{ name: "view-list-wd" }, { text: "" }]}
                                  id={"epg" + index}
                                />
                              </Link>
                            ) : item.channel_type === "2" ? (
                              ""
                            ) : (
                              <Link to="/tv-channel">
                                <TooltipItem
                                  key={"tv" + index}
                                  placement="top"
                                  text="Distribute the channel on TV Apps"
                                  toolTipList={true}
                                  btntext={[{ name: "plus" }, { text: "" }]}
                                  id={"tv" + index}
                                />
                              </Link>
                            )}
                          </li>
                          <li>
                            <Link to={`/copy-channel/${item.id}`}>
                              <TooltipItem
                                key={"copy" + index}
                                placement="top"
                                text="Duplicate"
                                toolTipList={true}
                                btntext={[{ name: "copy" }]}
                                id={"copy" + index}
                              />
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${
                                item.channel_type === "0"
                                  ? item.linear_channel_type === "0"
                                    ? "/edit-channel/scheduled/"
                                    : "/edit-channel/looped/"
                                  : item.channel_type === "1"
                                  ? "/edit-channel/fast/"
                                  : item.channel_type === "2"
                                  ? "/edit-channel/vod/"
                                  : "/edit-channel/unknown/"
                              }${window.btoa(item.id)}`}
                            >
                              <TooltipItem
                                key={"edit" + index}
                                // onClick={() => deletChanneldata(item.id)}
                                placement="top"
                                text="Edit"
                                toolTipList={true}
                                btntext={[{ name: "edit" }]}
                                id={"edit" + index}
                              />
                            </Link>
                          </li>
                          <li id="trash">
                            <Link to="">
                              <Button
                                className="btn-icon btn-dim fs-10px px-1"
                                onClick={() => deletChanneldata(item.id, index)}
                                color="dark"
                                size="sm"
                              >
                                <Icon name="trash" />
                              </Button>
                              <Tooltip placement="top" isOpen={deletechannel} target="trash" toggle={toggledelete}>
                                Delete
                              </Tooltip>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <div className="mt-2">Record Not Found</div>
                )}
              </>
            </div>
          </Collapse>
        </div>
        <div className="accordion-item ">
          <div
            className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""} bg-blue-dim px-2 mb-1 pointer`]}
            onClick={() => toggleCollapse("2")}
          >
            <h6 className="title">Video Preview Information</h6>
            <span className="accordion-icon"></span>
          </div>
          <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
            <div className="accordion-inner p-2">
              <div className="row py-2 line-height">
                <div className="col-6">
                  <ul className="text-end ">
                    <li>File Name :</li>
                    <li>File Size :</li>
                    <li>Format :</li>
                    <li>Streamkey :</li>
                    <li>RTMP Attachment :</li>
                    {/* <li>Parameters :</li> */}
                    <li>File Place :</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul className="">
                    <li>City View</li>
                    <li>250 mb</li>
                    <li>MP4</li>
                    <li>bcuiysdfiojlkauio</li>
                    <li>Jgsyb56dafggoibcav</li>
                    {/* <li>null</li> */}
                    <li>D://Download/video</li>
                  </ul>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="accordion-item">
          <div
            className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""} bg-blue-dim px-2 mb-1 pointer`]}
            onClick={() => toggleCollapse("3")}
          >
            <h6 className="title">Videos</h6>
            <span className="accordion-icon"></span>
          </div>
          <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
            <div className="accordion-inner p-2">
              <h5 className="mb-3 fs-15px">{totalvideo} Videos</h5>
              <Row className="accordion-video">
                <Col xl={4} sm={6} className="mb-2 text-center">
                  <Dropdown isOpen={dropDownIsOpen} toggle={DropDowntoggle} className="h-100">
                    <DropdownToggle className="" color="secondery">
                      <div className="bg-white d-flex align-items-center justify-content-center">
                        <Icon name="icon ni ni-plus-circle" className="fs-2"></Icon>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <ul className="link-list-opt">
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#links"
                            onClick={(ev) => {
                              ev.preventDefault();
                              navigate("/uploads");
                            }}
                          >
                            <span>Upload Video</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#links"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setIsOpen1(true);
                            }}
                          >
                            <span>Use Camera</span>
                          </DropdownItem>
                        </li>
                        <li>
                          <DropdownItem
                            tag="a"
                            href="#links"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setIsOpen2(true);
                            }}
                          >
                            <span>Import From SiloCloud</span>
                          </DropdownItem>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                {allvideos.length === 0
                  ? "No more videos to load."
                  : allvideos.map((item, index) => {
                      return (
                        <Col xl={4} sm={6} key={index} className="mb-2">
                          <div className="product-thumb position-relative">
                            <Link key={item.id} to={`/product-details/${item.id}`}>
                              <img src={`data:image/png;base64, ${item.thumbnail_base64}`} alt="" className="pointer" />
                            </Link>
                          </div>
                          <div className="d-flex justify-content-between p-1">
                            <p className="mb-0 fs-11px">{item.title}</p>
                            {/* <p>{formatDuration(item.duration)}</p> */}
                          </div>
                        </Col>
                      );
                    })}
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
      {/* <RecordAndUploadModal isOpen={isOpen1} toggle={toggle1} fetchVideoStreams={fetchVideoStreams} />
      <UploadFromStorageModal isOpen={isOpen2} toggle={toggle2} /> */}
    </>
  );
};

export default AccordionTab;
function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDuration;
}
