import React, { useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import ScheduledChannelContext from "../../pages/ScheduledChannelContext";

const Footer = () => {
  const location = useLocation();
  const { selectedId } = useContext(ScheduledChannelContext);
  return (
    <div className="nk-footer position-fixed bottom-0 py-0" style={{ zIndex: 99 }}>
      <div className="container-fluid">
        <div className="nk-footer-wrap justify-center">
          <div className="footer-hide d-flex align-items-center">
            <ul className="d-flex tab-footer bg-light">
              <li>
                <Link
                  to="videos"
                  className={`btn py-3 nav-item text-body border-0 rounded-0  ${
                    location.pathname === "/videos" ? "active" : ""
                  }`}
                >
                  Media Library
                </Link>
              </li>
              <li>
                <Link
                  to="/lives"
                  className={`btn py-3 nav-item text-body border-0 rounded-0  ${
                    location.pathname === "/lives" ? "active" : ""
                  }`}
                >
                  RTMP Streams
                </Link>
              </li>
              <li>
                <Link
                  to="schedule-channel/timeline"
                  className={`btn py-3 nav-item text-body border-0 rounded-0  ${
                    location.pathname === "/schedule-channel/timeline" ? "active" : ""
                  }`}
                >
                  Scheduler
                </Link>
              </li>
              <li>
                <Link
                  to={`/channel-view/${selectedId}`}
                  className={`btn py-3 nav-item text-body border-0 rounded-0  ${
                    location.pathname === "/channel-view" ? "active" : ""
                  }`}
                >
                  Channel View
                </Link>
              </li>
            </ul>
            <div className="d-flex align-items-center bg-light ms-3 py-1 px-3 d-none">
              <div className="d-none">
                <p className="fs-12 mb-0">Command</p>
                <span className="fs-1 bg-danger px-3 ms-1">R</span>
                <span className="fs-1 bg-success px-3 ms-1">P</span>
              </div>
              <div className="d-none">
                <p className="fs-12 mb-0">View</p>
                <ul className="d-flex tab-footer">
                  <li className="ps-1 pointer">
                    <Button className="camera-btn btn py-2">Cam1</Button>
                  </li>
                  <li className="ps-1 pointer">
                    <Button className="camera-btn btn py-2">Cam2</Button>
                  </li>
                  <li className="ps-1 pointer">
                    <Button className="camera-btn btn py-2">Cam3</Button>
                  </li>
                  <li className="ps-1 pointer">
                    <Button className="camera-btn btn py-2">Cam4</Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
