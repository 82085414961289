import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "axios";
import { Icon } from "../../components/Component";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";
import { apiRequest } from "../../utils/apiHelper";
const ChannelModal = (props) => {
  const [channels, setChannels] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  useEffect(() => {
    props.selectedChannels.forEach((channel) => {
      const checkbox = document.getElementById(`channelCheckbox-${channel.id}`);
      console.log("isChecked", checkbox, props.selectedChannels);
      if (checkbox) {
        const isChecked = props.selectedChannels.some((selectedChannel) => selectedChannel.id === channel.id);
        checkbox.checked = isChecked;
      }
    });
  }, [props]);

  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    setSelectAll(props.selectedChannels.length === channels.length && channels.length > 0);
  }, [props.selectedChannels, channels]);

  const fetchChannels = async () => {
    try {
      const response = await apiRequest("get-channel", "POST", null, "application/json");

      if (response.data && response.data.type === "success") {
        setChannels(response.data.data.channel);
      } else {
        console.error("Failed to fetch channels:", response.data.message);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching channels:", error);
      toast.error(error);
    }
  };

  const toggleCheckbox = (channelId, channelName) => {
    let updatedSelectedChannels;
    if (props.selectedChannels.some((channel) => channel.id === channelId)) {
      updatedSelectedChannels = props.selectedChannels.filter((channel) => channel.id !== channelId);
    } else {
      updatedSelectedChannels = [...props.selectedChannels, { id: channelId, channel_name: channelName }];
    }
    props.setSelectedChannels(updatedSelectedChannels);

    const allChecked = updatedSelectedChannels.length === channels.length;
    setSelectAll(allChecked);
  };

  // const toggleSelectAll = () => {
  //     setSelectAll(prevSelectAll => !prevSelectAll);
  //     setChannels(prevChannels => {
  //         if (!selectAll) {
  //             props.setSelectedChannels(prevChannels.map(channel => ({ id: channel.id, channel_name: channel.channel_name })));
  //         } else {
  //             props.setSelectedChannels([]);
  //         }
  //         return prevChannels;
  //     });
  // };

  //   const toggleSelectAll = () => {
  //     const newSelectAll = !selectAll;
  //     setSelectAll(newSelectAll);
  //     const updatedChannels = newSelectAll ? channels : [];
  //     props.setSelectedChannels(
  //       updatedChannels.map((channel) => ({ id: channel.id, channel_name: channel.channel_name }))
  //     );
  //   };

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    const updatedChannels = newSelectAll ? channels : [];
    setSelectAll(newSelectAll);

    props.setSelectedChannels((prevSelectedChannels) => {
      return updatedChannels.map((channel) => ({
        id: channel.id,
        channel_name: channel.channel_name,
      }));
    });
  };

  const renderChannels = () => {
    return channels.map((channel) => (
      <li key={channel.id} className="list-group-item mb-1">
        <div key={channel.id} className="custom-control custom-control-sm custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`channelCheckbox-${channel.id}`}
            checked={props.selectedChannels.some((selectedChannel) => selectedChannel.id === channel.id)}
            onChange={() => toggleCheckbox(channel.id, channel.channel_name)}
          />
          <label className="custom-control-label" htmlFor={`channelCheckbox-${channel.id}`}>
            {channel.channel_name}
          </label>
        </div>
      </li>
    ));
  };

  return (
    <section className="m-3">
      <Modal isOpen={props.open} toggle={props.modalopen}>
        <ModalHeader
          toggle={props.modalopen}
          close={
            <button className="close ml-auto" onClick={props.modalopen}>
              <Icon name="cross" />
            </button>
          }
        >
          Add one or multiple channels to the website
        </ModalHeader>
        <ModalBody>
          <div className="my-3">
            <ul className="list-group">
              <li className="list-group-item mb-1">
                <div className="custom-control custom-control-sm custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="selectAllCheckbox"
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                  <label className="custom-control-label" htmlFor="selectAllCheckbox">
                    Select All
                  </label>
                </div>
              </li>
            </ul>
            <ul className="list-group">{renderChannels()}</ul>
          </div>
        </ModalBody>
        <ModalFooter className="bg-light">
          <div className="nk-modal-action m-0 justify-end">
            <ul className="btn-toolbar align-center">
              <li className="mx-1">
                <Button toggle={props.modalopen} color="primary" outline onClick={props.modalopen}>
                  Done
                </Button>
              </li>
            </ul>
          </div>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default ChannelModal;
