import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

//url for production
export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "";
} else {
  url = window.location.host.split("/")[1];
  if (url) {
    url = `/${window.location.host.split("/")[1]}`;
  } else url = process.env.PUBLIC_URL; /// ADD YOUR CPANEL SUB-URL
}

//Function to validate and return errors for a form
export const checkForm = (formData) => {
  let errorState = {};
  Object.keys(formData).forEach((item) => {
    if (formData[item] === null || formData[item] === "") {
      errorState[item] = "This field is required";
    }
  });
  return errorState;
};

export const getCookie = (cookieName, defaultValue = "") => {
  let value = defaultValue;
  let tempValue = Cookies.get(cookieName);
  if (tempValue) {
    value = tempValue;
  }
  return value;
};
//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

//Function that calculates the from current date
export const setDeadline = (days) => {
  let todayDate = new Date();
  var newDate = new Date(todayDate);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

// Function to structure date ex : Jun 4, 2011;
export const getDateStructured = (date) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  let final = monthNames[m] + " " + d + ", " + y;
  return final;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

//Date formatter function
export const dateFormatterAlt = (date, reverse) => {
  let d = date.getDate();
  let m = date.getMonth();
  let y = date.getFullYear();
  reverse ? (date = m + "-" + d + "-" + y) : (date = y + "-" + d + "-" + m);
  return date;
};

//Date formatter function
export const dateFormatter = (date, reverse, string) => {
  var dateformat = date.split("-");
  //var date = dateformat[1]+"-"+dateformat[2]+"-"+dateformat[0];
  reverse
    ? (date = dateformat[2] + "-" + dateformat[0] + "-" + dateformat[1])
    : (date = dateformat[1] + "-" + dateformat[2] + "-" + dateformat[0]);

  return date;
};

//todays Date
export const todaysDate = new Date();

//current Time
export const currentTime = () => {
  var hours = todaysDate.getHours();
  var minutes = todaysDate.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

//Percentage calculation
export const calcPercentage = (str1, str2) => {
  let result = Number(str2) / Number(str1);
  result = result * 100;
  return Math.floor(result);
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + " " + truncate(str.substr(n - 1, str.length), n) : str;
};

// returns upload url
export const getUploadParams = () => {
  return { url: "https://httpbin.org/post" };
};

export const bulkActionOptions = [
  { value: "suspend", label: "Suspend User" },
  { value: "delete", label: "Delete User" },
];

// Converts KB to MB
export const bytesToMegaBytes = (bytes) => {
  let result = bytes / (1024 * 1024);
  return result.toFixed(2);
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export function getTimeFromSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return { hours, minutes, remainingSeconds };
}
export const formatTallyTime = (since) => {
  let timePart = "";
  if (since.includes("T")) {
    timePart = since.split("T")[1];
  } else if (since.includes(" ")) {
    timePart = since.split(" ")[1];
  } else {
    // Handle the case where 'since' is not in expected formats
    return "Invalid date format";
  }

  const date = new Date(`1970-01-01T${timePart}Z`);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`;
};

const getHeaders = () => ({
  authToken: getCookie("authToken", null),
  "Content-Type": "application/json",
});

// Reusable POST function
export const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, data, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

// Reusable GET function
export const getRequest = async (url) => {
  try {
    const response = await axios.get(url, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

// Reusable DELETE function
export const deleteRequest = async (url, data) => {
  try {
    const response = await axios.delete(url, {
      headers: getHeaders(),
      data: data,
    });
    return response.data;
  } catch (error) {
    handleAxiosError(error);
  }
};

// Error handling function
const handleAxiosError = (error) => {
  if (error.response) {
    const errorData = error.response.data;
    const customError = new Error("Please try again.");
    customError.info = errorData;
    customError.code = `${error.response.status}`;
    throw customError;
  } else {
    throw new Error("Please try again.");
  }
};

export function shortenString(str, maxLen) {
  if (str.length <= maxLen) {
    return str;
  } else {
    return str.slice(0, maxLen - 1) + "...";
  }
}

export function responseErrorHandler(isError, error, showTryAgain = true) {
  if (isError) {
    if (error?.status === 429 || error?.status === 204) {
      if (showTryAgain) {
        toast.error("Try after in some time");
      }
    } else {
      const message = error?.message || "";
      const messageArr = message?.split(" ");
      if (messageArr?.length > 1) {
        if (messageArr.includes("occurred")) {
          toast.error("Trying again...");
        } else if (error?.message?.toLowerCase() === "validation failed") {
          const errors = error?.data?.errors || [];
          if (errors.length > 0) {
            toast.error(errors[0]); // Show only the first error
          }
        } else {
          toast.error(error?.message);
        }
      }
    }
  }
}

export function setOffset({ page = 0, limit }) {
  let offset;

  if (page === 0 || page === 1) {
    offset = 0;
  } else if (page > 1) {
    offset = (page - 1) * limit;
  }

  return offset;
}
export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL_Logo}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};
export const convertSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return {
    hours: hours,
    minutes: minutes,
    seconds: secs,
  };
};
// utils.js
export function formatSeconds(seconds) {
  const pad = (num) => String(num).padStart(2, "0");

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (hours > 0) {
    return `${hours}:${pad(minutes)}:${pad(secs)}`;
  } else {
    return `${pad(minutes)}:${pad(secs)}`;
  }
}
