import React, { createContext, useState } from 'react';

// Create the context
export const StreamContext = createContext();

// Create the provider component
export const StreamProvider = ({ children }) => {
  const [activestreamkey,setActiveStreamKey] = useState("");
  const [activeplaybackurl,setActiveplaybackurl] = useState("");
  const [activestreamkeyPlayback,setActivestreamkeyPlayback] = useState("");

  return (
    <StreamContext.Provider value={{ activestreamkey,setActiveStreamKey,activeplaybackurl,setActiveplaybackurl,activestreamkeyPlayback,setActivestreamkeyPlayback}}>
      {children}
    </StreamContext.Provider>
  );
};
