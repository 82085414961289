// import React, { createContext, useState } from 'react';

// const ScheduledChannelContext = createContext();

// export const ScheduledChannelProvider = ({ children }) => {
//   const [selectedId, setSelectedId] = useState(null);
//   const [schedulerData, setSchedulerData] = useState([]);

//   // Function to update the scheduler data
//   const updateSchedulerData = (newData) => {
//     setSchedulerData((prevData) => [...prevData, ...newData]);
//   };

//   return (
//     <ScheduledChannelContext.Provider value={{ selectedId, setSelectedId,schedulerData,updateSchedulerData}}>
//       {children}
//     </ScheduledChannelContext.Provider>
//   );
// };

// export default ScheduledChannelContext;

import React, { createContext, useState } from "react";

const ScheduledChannelContext = createContext();

export const ScheduledChannelProvider = ({ children }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [schedulerData, setSchedulerData] = useState([]);

  // Function to update the scheduler data
  const updateSchedulerData = (newData) => {
    setSchedulerData((prevData) => [...prevData, ...newData]);
  };

  // Function to reset the scheduler data
  const resetSchedulerData = () => {
    // setSelectedId(null);
  };

  return (
    <ScheduledChannelContext.Provider
      value={{ selectedId, setSelectedId, schedulerData, updateSchedulerData, resetSchedulerData }}
    >
      {children}
    </ScheduledChannelContext.Provider>
  );
};

export default ScheduledChannelContext;
