import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  ReactDataTable,
  UserAvatar,
} from "../../components/Component";
import { Col, Row } from "reactstrap";
import { DataTableData, dataTableColumns, dataTableColumns2, userData } from "../../components/table/TableData";
import ReactPlayer from "react-player";
const VIDEO_PATH = "https://api.silocloud.io/api/v1/public/channel-video";


function ChannelStatus() {
  const [playerWidth, setPlayerWidth] = useState(500);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const newWidth = screenWidth < 500 ? screenWidth - 100 : 500;
      setPlayerWidth(newWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <Head title="Channel status" />
      {/* <CustomLoader/> */}
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <h3 className="newFont fw-bold">Streams</h3>
              <BlockDes className="text-soft">
                {/* <p className="newFont">Welcome to Channels.</p> */}
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          {/* <Row>
            <Col md="3">
              <div className="d-flex flex-row card p-1 shadow">
                <UserAvatar text="AB" className="lg sq bg-danger newFont"></UserAvatar>
                <div className="d-flex flex-column justify-content-center ms-3 newFont">
                  <small>Total Channels</small>
                  <h3 className="newFont">45</h3>
                </div>
                <div></div>
              </div>
            </Col>
            <Col md="3"></Col>
            <Col md="3"></Col>
          </Row> */}
          <Row className="mt-2">
            <Col md="6">
              <ReactDataTable
                data={DataTableData}
                columns={dataTableColumns}
                className="newFont"
                expandableRows
                pagination
              />
            </Col>
            <Col md="6">
            <ReactPlayer
              url={VIDEO_PATH}
              controls={true}
              // width={playerWidth}
              // height={251}
              style={{ marginTop: "0px", paddingTop: "0px" }}
            />
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
}

export default ChannelStatus;
