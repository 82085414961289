export const basicData = {
  header: ["#", "First", "Last", "Handle"],
  data: [
    {
      id: "1",
      first: "Mark",
      last: "Otto",
      handle: "@mdo",
    },
    {
      id: "2",
      first: "Jacob",
      last: "Thornton",
      handle: "@fat",
    },
    {
      id: "3",
      first: "Larry",
      last: "the bird",
      handle: "@twitter",
    },
  ],
};

export const transactionData = {
  header: ["#", "Bill for", "Issue Date", "Due date", "total", "status"],
  data: [
    {
      id: "4947",
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: "4904",
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: "4829",
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: "4830",
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: "4840",
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
  ],
};

export const orderData = [
  {
    id: "#746F5K2",
    date: "23 Jan 2019, 10:45pm",
    amount: "2300.00",
    status: "Complete",
  },
  {
    id: "#546H74W",
    date: "12 Jan 2020, 10:45pm",
    amount: "120.00",
    status: "Pending",
  },
  {
    id: "#87X6A44",
    date: "26 Dec 2019, 12:15 pm",
    amount: "560.00",
    status: "Complete",
  },
  {
    id: "#986G531",
    date: "21 Jan 2019, 6:12 am",
    amount: "3654.00",
    status: "Cancelled",
  },
  {
    id: "#326T4M9",
    date: "21 Jan 2019, 6:12 am",
    amount: "200.00",
    status: "Complete",
  },
];

export const loginData = [
  {
    browser: "Chrome on Window",
    ip: "192.149.122.128",
    time: "11:34 PM",
    date: "",
    action: false,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "10:34 PM",
    date: "Nov 20, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "04:16 PM",
    date: "Oct 23, 2019",
    action: true,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "10:34 PM",
    date: "Nov 20, 2019",
    action: true,
  },
  {
    browser: "Chrome on Window",
    ip: "192.149.122.128",
    time: "04:16 PM",
    date: "Oct 23, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "11:41 PM",
    date: "Oct 15, 2019",
    action: true,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "05:43 AM",
    date: "Oct 13, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "04:12 AM",
    date: "Oct 03, 2019",
    action: true,
  },
];
export const dataTableColumns = [
  {
    name: "Sr.No",
    selector: (row) => row.id,
    width:"10%",
    sortable: true,
  },
  {
    name: "Stream Key",
    selector: (row) => row.stream_key,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    hide: 370,
    // width:"",
    cell: (row) => (
      <>
        <span className="badge-dot badge bg-success">Active</span>
      </>
    ),
  },
  {
    name: "Established Date",
    selector: (row) => row.estdate,
    sortable: true,
    hide: "sm",
    // width:"10%",
  },
];
export const DataTableData = (() => {
  const randomData = [];

  for (let i = 1; i < 100; i++) {
    const id = i;
    const randomStreamKey = Math.random().toString(36).substring(2, 20) + Math.random().toString(36).substring(2, 20);
    const randomStatus = Math.random() > 0.5 ? "Active" : "Inactive";
    const randomDate = new Date(
      Math.floor(Math.random() * (2025 - 2020)) + 2020,
      Math.floor(Math.random() * 12),
      Math.floor(Math.random() * 31) + 1
    )
      .toISOString()
      .split("T")[0];

    randomData.push({
      id: id,
      stream_key: randomStreamKey,
      status: randomStatus,
      estdate: randomDate,
    });
  }

  return randomData;
})();
