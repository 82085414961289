import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { RSelect } from "../../components/Component";
import { fetchAPI } from "../../utils/api_helper";
import { apiRequest } from "../../utils/apiHelper";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import { getCookie } from "../../utils/Utils";
// import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";

import { Card, Button, CardHeader, CardFooter, CardText, CardBody, CardTitle, Progress } from "reactstrap";
import {
  Icon,
  PreviewCard,
  ReactDataTable,
  Block,
  BackTo,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
} from "../../components/Component";

// import {
//   getSolidLineChart,
//   getFilledLineChart,
//   getStraightLineChart,
//   getBarChartData,
//   getDoughnutChartData,
//   getPolarChartData,
//   getBarChartMultipleData,
//   getBarChartStackedData,
// } from "./Analyticsdata";

import { BarChartExample, LineChartExample, PieChartExample, DoughnutExample, PolarExample } from "./Chart";
import { getSolidLineChart, getPieChartData, getDoughnutChartData } from "./utilData";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for marker icon issue
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Analytics = () => {
  const [solidLineChart, setSolidLineChart] = useState(getSolidLineChart());
  const [pieChartData, setPieChartData] = useState(getPieChartData());
  const [doughnutChartData, setDoughnutChartData] = useState(getDoughnutChartData());
  const examplePosition = [51.505, -0.09];
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedPeriodDnt, setSelectedPeriodDnt] = useState(null);
  const [selectedPeriodPie, setSelectedPeriodPie] = useState(null);
  const [selectedPeriodLine, setSelectedPeriodLine] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchPieData = async () => {
    try {
      const response = await apiRequest("analytics/overviewlive");
      const { unique_views, total_views, signed_out_views } = response.data;

      // Define labels and datasets
      const labels = ["Unique Views", "total_views", "Signed Out Views"];
      const datasets = [
        {
          label: "Views",
          data: [unique_views, total_views, signed_out_views],
          backgroundColor: ["rgba(156, 171, 255, 0.8)", "rgba(244, 170, 164, 0.8)", "rgba(143, 234, 197, 0.8)"],
        },
      ];

      // Set the chart data state
      if (unique_views === 0 && total_views === 0 && signed_out_views === 0) {
        setPieChartData({ labels, datasets: [] }); // Explicitly set datasets to empty
      } else {
        setPieChartData({ labels, datasets });
      }
      console.log("pieChartData", pieChartData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchPieData();
  }, []);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    // Fetch locations from your Laravel backend
    const fetchLocations = async () => {
      try {
        const response = await apiRequest("get-locations");
        setLocations(response.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const [channelOptions, setChannelOptions] = useState([]);

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await apiRequest("get-channelfor-view"); // Adjust URL if needed
        const options = [{ value: "all", label: "All Channels" }, ...response.data];
        setChannelOptions(options);

        // Set default selection to "All Channels"
        setSelectedChannel(options[0]);

        // Fetch and display data for all channels
        handleChannelChange(options[0]);
      } catch (error) {
        console.error("Error fetching channels:", error);
      }
    };

    fetchChannels();
  }, []);

  const [analyticsData, setAnalyticsData] = useState({
    totalChannels: 0,
    totalStreams: 0,
    totalStreamTime: "00H:00M:00S",
    avgStreamTime: "00H:00M:00S",
  });

  const fetchAnalyticsOverview = async () => {
    try {
      const response = await apiRequest("analytics/overview");
      const data = response.data;
      setAnalyticsData(data);
    } catch (error) {
      console.error("Error fetching analytics overview:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsOverview();
  }, []);

  useEffect(() => {
    const fetchLineData = async () => {
      try {
        const response = await fetch("/data.json");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const { labels, datasets } = data;

        setSolidLineChart(getSolidLineChart(labels, datasets));
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };
  }, []);

  const fetchDoughnutData = async () => {
    try {
      const response = await apiRequest("tvlivestreams/chartdata");
      const { labels, datasets } = response.data;
      setDoughnutChartData(getDoughnutChartData(labels, datasets));
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchDoughnutData();
  }, []);

  const fetchAllChannelsViews = async () => {
    try {
      const response = await apiRequest("analytics/all-channels/views");

      // Check if the response is valid
      if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format");
      }

      // Update solidLineChart state with processed data
      let rawData = response.data;
      console.log(rawData);

      setSolidLineChart(getSolidLineChart(response.data));
    } catch (error) {
      console.error("Error fetching all channels views:", error);
      // Handle error state or display error message to user
    }
  };

  useEffect(() => {
    fetchAllChannelsViews();
  }, []);

  const handleChannelChange = async (selectedOption) => {
    setSelectedChannel(selectedOption);

    try {
      // Check if the selected option is "All Channels"
      if (selectedOption.value === "all") {
        // Fetch and display data for all channels
        const response = await apiRequest("analytics/all-channels/views"); // Adjust URL if needed
        setSolidLineChart(getSolidLineChart(response.data));
        return;
      }

      // Fetch channel views data based on selected channel ID
      const response = await apiRequest(`analytics/channel/${selectedOption.value}/views`);

      if (!response || !response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid response format");
      }

      const { data } = response;
      console.log(data);

      if (data.length === 0) {
        console.warn("No data available for the selected channel.");
        setSolidLineChart([]); // Optionally set an empty chart or handle accordingly
        return;
      }

      if (!("hour" in data[0]) || !("views" in data[0])) {
        throw new Error("Invalid data format received from server");
      }

      setSolidLineChart(getSolidLineChart(combineViewsByMinute(data)));
    } catch (error) {
      console.error("Error fetching channel views:", error);
    }
  };

  function combineViewsByMinute(data) {
    const combinedData = {};

    data.forEach((entry) => {
      const date = new Date(entry.hour);
      const key = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;

      if (!combinedData[key]) {
        combinedData[key] = { ...entry, views: 0 };
        combinedData[key].hour = `${key}:00`;
      }

      combinedData[key].views += entry.views;
    });

    return Object.values(combinedData);
  }

  // const handleDownloadPdf = () => handleDownloadFile("pdf");
  // const handleDownloadFile = async (type) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("type", type);
  //     const response = await apiRequest("analytics/download-pdf", "POST", formData);
  //     window.open(response.data.url, "_blank");
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };
  const handleDownloadFile = async (type, period) => {
    try {
      setIsDownloading(true);
      const formData = new FormData();
      formData.append("type", type);
      formData.append("period", period); // Pass the selected period

      const response = await apiRequest("analytics/download-pdf", "POST", formData);
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  // const downloadFile = async (type) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("type", type);

  //     const authToken = getCookie("authToken", process.env.REACT_APP_TOKEN);
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}analytics/download-excel`, formData, {
  //       headers: {
  //         Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //         authToken: authToken,
  //       },
  //       responseType: "blob",
  //     });

  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", `${type}_chart.xlsx`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //   } catch (error) {
  //     console.error("Failed to download Excel file:", error.message);
  //   }
  // };
  const downloadFile = async (type, period) => {
    try {
      setIsDownloading(true);
      const formData = new FormData();
      formData.append("type", type);
      formData.append("period", period); // Pass the selected period

      const authToken = getCookie("authToken", process.env.REACT_APP_TOKEN);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}analytics/download-excel`, formData, {
        headers: {
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          authToken: authToken,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}_chart.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Failed to download Excel file:", error.message);
    } finally {
      setIsDownloading(false);
    }
  };

  const isChartDataAvailable = (chartData) => {
    console.log("pieChartData in function", chartData);
    return (
      chartData &&
      Array.isArray(chartData.labels) &&
      chartData.labels.length > 0 &&
      Array.isArray(chartData.datasets) &&
      chartData.datasets.length > 0 &&
      chartData.datasets.some((dataset) => Array.isArray(dataset.data) && dataset.data.length > 0)
    );
  };

  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [browserData, setBrowserData] = useState({});

  useEffect(() => {
    const fetchCountryAndBrowserData = async () => {
      try {
        const response = await apiRequest("get-country-percentages");
        setCountryData(response.data.countryPercentages);
        setBrowserData(response.data.browserPercentages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchCountryAndBrowserData();
  }, []);

  const [overviewData, setOverviewData] = useState({ avg_stream_views: 0, max_stream_views: 0 });

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        const response = await apiRequest("analytics/overview-stream", "POST");
        setOverviewData(response.data);
      } catch (error) {
        setOverviewData({
          avg_stream_views: 0,
          max_stream_views: 0,
        });
        console.error("Error fetching overview data:", error);
      }
    };

    fetchOverviewData();
  }, []);

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <h3>Analytics</h3>
          <div className="container mt-5">
            <h4>Overview</h4>
            <Row className="g-gs mt-1">
              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>Channels</CardText>
                    <CardTitle tag="h2">{analyticsData.totalChannels}</CardTitle>
                    <Button color="primary" className="mt-3">
                      See All
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>All Streams</CardText>
                    <CardTitle tag="h2">{analyticsData.totalStreams}</CardTitle>
                    <Button color="primary" className="mt-3">
                      See All
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>Total Stream Time</CardText>
                    <CardTitle tag="h2">{analyticsData.totalStreamTime}</CardTitle>
                    <Button color="primary" className="mt-3">
                      See All
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>Average Stream Time</CardText>
                    <CardTitle tag="h2">{analyticsData.avgStreamTime}</CardTitle>
                    <Button color="primary" className="mt-3">
                      See All
                    </Button>
                  </CardBody>
                </Card>
              </Col>

              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>Average Views per Stream</CardText>
                    <CardTitle tag="h2">{overviewData.avg_stream_views}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-bordered">
                  <CardBody className="card-inner text-center">
                    <CardText>Maximum Views</CardText>
                    <CardTitle tag="h2">{overviewData.max_stream_views}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="container mt-5">
            <Block size="lg" className="mt-5">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h4">Views vs Time</BlockTitle>
                  <BlockDes>
                    <p>The below graph shows how much views your channel gets with respect to times.</p>
                  </BlockDes>
                </BlockHeadContent>
              </BlockHead>
              <PreviewCard>
                {/* <div className="float-end">
                  <button
                    className="btn btn-primary mb-10 mx-2"
                    onClick={() => handleDownloadFile("line")}
                    disabled={!isChartDataAvailable(solidLineChart)}
                  >
                    Download Line Chart PDF
                  </button>
                  <button
                    className="btn btn-primary mb-10"
                    onClick={() => downloadFile("line")}
                    disabled={!isChartDataAvailable(solidLineChart)}
                  >
                    Download Line Chart Excel
                  </button>
                  <ul class="nav nav-switch-s2 nav-tabs bg-white"><li class="nav-item"><a href="#navitem" class="nav-link">7 D</a></li><li class="nav-item"><a href="#navitem" class="nav-link active">1 M</a></li><li class="nav-item"><a href="#navitem" class="nav-link active">ALL</a></li></ul>
                </div> */}
                <div className="float-end">
                  <button
                    className="btn btn-primary mb-10 mx-2"
                    onClick={() => handleDownloadFile("line", selectedPeriodLine)}
                    disabled={!isChartDataAvailable(solidLineChart) || isDownloading || !selectedPeriodLine}
                  >
                    <Icon name="file-pdf"></Icon>
                  </button>
                  <button
                    className="btn btn-primary mb-10"
                    onClick={() => downloadFile("line", selectedPeriodLine)}
                    disabled={!isChartDataAvailable(solidLineChart) || isDownloading || !selectedPeriodLine}
                  >
                    <Icon name="file-xls"></Icon>
                  </button>
                  <ul className="nav nav-switch-s2 nav-tabs bg-white mx-2">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${selectedPeriodLine === "7D" ? "active" : ""}`}
                        onClick={() => setSelectedPeriodLine("7D")}
                        disabled={isDownloading}
                      >
                        7 D
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${selectedPeriodLine === "1M" ? "active" : ""}`}
                        onClick={() => setSelectedPeriodLine("1M")}
                        disabled={isDownloading}
                      >
                        1 M
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${selectedPeriodLine === "ALL" ? "active" : ""}`}
                        onClick={() => setSelectedPeriodLine("ALL")}
                        disabled={isDownloading}
                      >
                        ALL
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="w-25 mb-3">
                  <RSelect
                    placeholder="Channel"
                    options={channelOptions}
                    value={selectedChannel}
                    onChange={handleChannelChange}
                  />
                </div>
                <div className="nk-ck">
                  <LineChartExample legend={true} data={solidLineChart} />
                </div>

                {/* <button className="btn btn-primary" onClick={handleDownloadExcel}>
                  Download Excel
                </button>
                <button className="btn btn-primary" onClick={handleDownloadImage}>
                  Download Image
                </button> */}
              </PreviewCard>
            </Block>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-md-6">
                <MapContainer center={examplePosition} zoom={13} style={{ height: "500px", width: "100%" }}>
                  <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://carto.com/">Carto</a> contributors'
                    minZoom={3}
                    maxZoom={17}
                  />
                  <Marker position={examplePosition}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                  {locations.map(
                    (location, index) =>
                      location.latitude &&
                      location.longitude && (
                        <Marker key={index} position={[location.latitude, location.longitude]}>
                          <Popup>{location.description || "No description"}</Popup>
                        </Marker>
                      )
                  )}
                </MapContainer>
              </div>
              <div className="col-md-6">
                <div className="Overflowchart">
                  <h4>Top Countries by Percentage of Total Visitors</h4>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <div>
                      <h5>Countries</h5>
                      {Object.keys(countryData).map((country, index) => (
                        <div key={index} className="mb-2">
                          <h6>
                            {country}: {countryData[country]}%
                          </h6>
                          <Progress value={countryData[country]} className="h-25">
                            {countryData[country]}%
                          </Progress>
                        </div>
                      ))}

                      <h4 className="mt-5">Top Browsers by Percentage of Total Visits</h4>
                      {Object.keys(browserData).map((browser, index) => (
                        <div key={index} className="mb-2">
                          <h6>
                            {browser}: {browserData[browser]}%
                          </h6>
                          <Progress value={browserData[browser]} className="h-25">
                            {browserData[browser]}%
                          </Progress>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 flex align-center justify-content-around column-gap-5">
            <Block size="lg" className="pt-0 w-100">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h4">Views per Channel</BlockTitle>
                  <BlockDes>
                    <p>The below graph shows how much views your channels gets in comparison.</p>
                  </BlockDes>
                </BlockHeadContent>
              </BlockHead>
              <PreviewCard>
                <div className="card-head text-center">
                  <h6 className="title">Pie Chart</h6>
                  <div className="float-end">
                    <button
                      className="btn btn-primary mb-10 mx-2"
                      onClick={() => handleDownloadFile("pie", selectedPeriodPie)}
                      disabled={!isChartDataAvailable(pieChartData) || isDownloading || !selectedPeriodPie}
                    >
                      <Icon name="file-pdf" />
                    </button>
                    <button
                      className="btn btn-primary mb-10"
                      onClick={() => downloadFile("pie", selectedPeriodPie)}
                      disabled={!isChartDataAvailable(pieChartData) || isDownloading || !selectedPeriodPie}
                    >
                      <Icon name="file-xls" />
                    </button>

                    <ul className="nav nav-switch-s2 nav-tabs bg-white mx-2">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodPie === "7D" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodPie("7D")}
                          disabled={isDownloading}
                        >
                          7 D
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodPie === "1M" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodPie("1M")}
                          disabled={isDownloading}
                        >
                          1 M
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodPie === "ALL" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodPie("ALL")}
                          disabled={isDownloading}
                        >
                          ALL
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="nk-ck-sm" style={{ height: "350px" }}>
                  <PieChartExample data={pieChartData} />
                </div>
              </PreviewCard>
            </Block>

            <Block size="lg" className="pt-0 w-100">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h4">Streams per Channel</BlockTitle>
                  <BlockDes>
                    <p>The below graph shows how many streams your channels have in comparison.</p>
                  </BlockDes>
                </BlockHeadContent>
              </BlockHead>
              <PreviewCard>
                <div className="card-head text-center">
                  <h6 className="title">Doughnut Chart</h6>

                  <div className="float-end">
                    <button
                      className="btn btn-primary mb-10 mx-2"
                      onClick={() => handleDownloadFile("doughnut", selectedPeriodDnt)}
                      disabled={!isChartDataAvailable(doughnutChartData) || isDownloading || !selectedPeriodDnt}
                    >
                      <Icon name="file-pdf" />
                    </button>

                    <button
                      className="btn btn-primary mb-10"
                      onClick={() => downloadFile("doughnut", selectedPeriodDnt)}
                      disabled={!isChartDataAvailable(doughnutChartData) || isDownloading || !selectedPeriodDnt}
                    >
                      <Icon name="file-xls" />
                    </button>

                    <ul className="nav nav-switch-s2 nav-tabs bg-white mx-2">
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodDnt === "7D" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodDnt("7D")}
                          disabled={isDownloading}
                        >
                          7 D
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodDnt === "1M" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodDnt("1M")}
                          disabled={isDownloading}
                        >
                          1 M
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={`nav-link ${selectedPeriodDnt === "ALL" ? "active" : ""}`}
                          onClick={() => setSelectedPeriodDnt("ALL")}
                          disabled={isDownloading}
                        >
                          ALL
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="nk-ck-sm" style={{ height: "350px" }}>
                  <DoughnutExample data={doughnutChartData} />
                </div>
              </PreviewCard>
            </Block>
          </div>
        </div>
      </section>
    </>
  );
};

export default Analytics;
