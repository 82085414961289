import React, { useEffect, useRef, useState } from "react";
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  useProgram,
  ProgramResizeHandle,
} from "@nessprim/planby-pro";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";

import { v4 as uuidv4 } from "uuid";

export const Program = ({ isVerticalMode, program, isedited, ...rest }) => {
  const {
    isLive,
    isMinWidth,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    set12HoursTimeFormat,
    getMouseEventTempTime,
  } = useProgram({
    isVerticalMode,
    program,
    ...rest,
  });

  const popId = useRef(uuidv4()).current;

  const { data } = program;
  // console.log(data, styles);
  const { image, title, since, till, duration, id, program_type } = data;
  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();
  const dragTime = getMouseEventTempTime();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);
  const formatTimes = (timestamp1, timestamp2) => {
    function extractTime(timestamp) {
      const date = new Date(timestamp);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
    const time1 = extractTime(timestamp1);
    const time2 = extractTime(timestamp2);

    return `${time1} - ${time2}`;
  };
  // const [programtype, setProgramType] = useState(false);

  const [left, setLeft] = useState(0);
  const [className, setClassName] = useState("");

  useEffect(() => {
    // if (program_type === "commercialad") {
    //   setProgramType("Commercial");
    // }
    let newClassName = "";
    if (duration < 120 && program_type === "programad") {
      newClassName += "lessdurationprogram " + program_type;
    } else {
      newClassName += program_type + " ";
    }
    // if (programtype === "Commercial") {
    //   newClassName += "commercialprogram";
    // }
    setClassName(newClassName.trim());
    // console.log(program_type);
  }, [duration, isedited, program_type]);

  const islivestyles = {
    container: {
      opacity: isLive ? "" : "0.9",
      cursor: isLive ? "pointer" : "default",
    },
  };

  // useEffect(() => {
  //   // Create a timeout to delay the execution
  //   console.log(styles);
  //   const handler = setTimeout(() => {
  //     setLeft(styles.position.left);
  //   }, 500); // Delay of 500ms

  //   // Cleanup the timeout if styles change before the delay ends
  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [styles]);

  return (
    <>
      <ProgramBox
        width={styles.width}
        // style={{ paddingInline: "1px", ...styles.position, left: styles.position.left + "px" }}
        style={{ paddingInline: "1px", ...styles.position }}
        {...getMouseEvents()}
      >
        <ProgramContent
          isVerticalMode={isVerticalMode}
          width={styles.width}
          // isLive={isLive}
          style={{ ...islivestyles.container, paddingInline: "10px" }}
          className={className}
          id={`Popover${popId}`}
          onMouseEnter={(e) => setPopoverOpen(true)}
          onMouseLeave={(e) => setPopoverOpen(false)}
        >
          <ProgramResizeHandle {...resizeEvents.eventsLeft} />
          <ProgramResizeHandle {...resizeEvents.eventsRight} />
          <ProgramFlex isVerticalMode={isVerticalMode}>
            {isMinWidth && (
              <ProgramImage
                isVerticalMode={isVerticalMode}
                src={image}
                alt="Preview"
                style={{
                  height: "100%",
                  width: "100px",
                  objectFit: "cover",
                }}
              />
            )}
            <ProgramStack>
              <ProgramTitle>{title}</ProgramTitle>
              <ProgramText>
                {isMouseEvent ? (
                  <>
                    {dragTime.since} - {dragTime.till}
                  </>
                ) : (
                  <>
                    {sinceTime} - {tillTime}
                  </>
                )}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
      </ProgramBox>
      <Popover isOpen={popoverOpen} placement="bottom" target={`Popover${popId}`} toggle={toggle}>
        <PopoverHeader>{title.toUpperCase()}</PopoverHeader>
        <PopoverBody>
          <img src={image} alt="popoverimage" className="img-fluid" />
          <div className="mt-3">
            {isMouseEvent ? (
              <>
                {dragTime.since} - {dragTime.till}
              </>
            ) : (
              <>{formatTimes(since, till)}</>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};
