import React from "react";
import LogoSmall from "../../images/streamDeckLogo.png";
import LogoDark from "../../images/streamDeckLogo.png";
import SimpleBar from "simplebar-react";
import menu from "../menu/MenuData";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { UserAvatar, LinkList, LinkItem, Icon, TooltipComponent } from "../../components/Component";
import "../../css/custom.scss";
import { Homelightlogo, d_home_logo } from "../../images/applogos";
import { useTheme } from "../provider/Theme";

const Appbar = () => {
  const theme = useTheme();

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const appSidebarClass = classNames({
    "nk-apps-sidebar": true,
    [`is-light`]: theme.appbar === "white",
    // [`is-${theme.appbar}`]: theme.appbar !== "white" && theme.appbar !== "light",
  });
  const { skin } = useTheme();
  return (
    <div className={appSidebarClass}>
      {/* <div className="nk-apps-brand text-center">
        <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
          <img className="logo-light logo-img" src={LogoSmall} alt="logo" />
          <img className="logo-dark logo-img" src={LogoDark} alt="logo-dark" />
        </Link>
      </div> */}
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-body">
          <SimpleBar className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu apps-menu">
                {menu.map((item, index) => {
                  if (item.dashboard) {
                    return (
                      <React.Fragment key={index}>
                        <TooltipComponent id={"dashboard" + index} text={item.text} direction="right" />
                        <li
                          className={`nk-menu-item ${
                            currentUrl === process.env.PUBLIC_URL + item.link ? "active current-page" : ""
                          }`}
                          key={index}
                          id={"dashboard" + index}
                        >
                          <Link to={`${process.env.PUBLIC_URL + item.link}`} className="nk-menu-link">
                            <span className={`nk-menu-icon ${"menuicon" + index}`}>
                              <Icon name={item.icon}></Icon>
                            </span>
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  } else if (item.text === "Applications") {
                    return (
                      <React.Fragment key={index}>
                        <li className="nk-menu-hr"></li>
                        {item.subMenu.map((sub, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <TooltipComponent id={"app" + idx} text={sub.text} direction="right" />
                              <li
                                className={`nk-menu-item ${
                                  currentUrl === process.env.PUBLIC_URL + sub.link ? "active current-page" : ""
                                }`}
                                key={idx}
                                id={"app" + idx}
                              >
                                <Link to={`${process.env.PUBLIC_URL + sub.link}`} className="nk-menu-link">
                                  <span className="nk-menu-icon">
                                    <Icon name={sub.icon}></Icon>
                                  </span>
                                </Link>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  } else return <React.Fragment key={index}></React.Fragment>;
                })}

                <TooltipComponent id={"componentTooltip"} text="Go to component" direction="right" />
                <li
                  className={`nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/components" ? "active current-page" : ""
                  }`}
                  id="componentTooltip"
                ></li>
              </ul>
            </div>
          </SimpleBar>
          <ul className="nk-menu d-flex justify-center" style={{ height: "77px", alignItems: "center" }}>
            <li className="nk-menu-item" id="settingsTooltip">
              <div className="pointer">
                <Link to="https://silocloud.io/" className="home_icon">
                  <img
                    src={`${process.env.REACT_APP_API_URL_Logo}logo/apps/home-logo.png`}
                    alt=""
                    className="homeicon"
                    style={{ height: "36px" }}
                  />
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
