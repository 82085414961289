import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Spinner } from "reactstrap";
import { BackTo, Icon } from "../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCookie } from "../../utils/Utils";
import ReactPlayer from "react-player";
import "../../css/streamdeck_css/streamdeck.css";
import ShareButtons from "./ShareButtons";
import { apiRequest } from "../../utils/apiHelper";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { toast } from "react-toastify";

const EmbedChannel = () => {
  const inputRef = useRef(null);
  const { type } = useParams();
  const [videoStreams, setVideoStreams] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videotitle, setVideoTitle] = useState(null);
  const [showControls, setShowControls] = useState(true);
  const [showContentTitle, setShowContentTitle] = useState(true);
  const [showShareButtons, setShowShareButtons] = useState(true);
  const [openPlaylist, setOpenPlaylist] = useState(false);
  const [playing, setAutoplay] = useState(true);
  const [volume, setVolume] = useState(1);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState("");
  const [selectedRatio, setSelectedRatio] = useState("16:9");
  const [responsive, setResponsive] = useState(true);
  const [channelData, setChannelData] = useState(null);

  // useEffect(() => {
  //   console.log(selectedRatio);
  // }, [selectedRatio]);

  const navigate = useNavigate("");

  // const handleResponsiveChange = () => {
  //   setResponsive(!responsive);
  // };

  // const toggleShowControls = () => {
  //   setShowControls(!showControls);
  // };

  // const toggleVolumeControl = () => {
  //   setShowVolumeControl(!showVolumeControl);
  // };

  // const handleVolumeChange = (event) => {
  //   setVolume(parseFloat(event.target.value));
  // };

  // const toggleAutoplay = () => {
  //   setAutoplay(!playing);
  // };

  // const toggleShowContentTitle = () => {
  //   setShowContentTitle(!showContentTitle);
  //   console.log("showContentTitle", showContentTitle);
  // };

  // const toggleShowShareButtons = () => {
  //   setShowShareButtons(!showShareButtons);
  // };

  // const toggleOpenPlaylist = () => {
  //   setOpenPlaylist(!openPlaylist);
  // };

  // const handleRatioChange = (event) => {
  //   setSelectedRatio(event.target.value);
  // };

  // const toggleControls = () => {
  //   setShowControls((prev) => !prev);
  // };

  const [showTooltip, setShowTooltip] = useState(false);
  const handleClick = () => {
    setShowTooltip(true);
  };
  // const getPaddingTop = (ratio) => {
  //   const [width, height] = ratio.split(":").map(Number);

  //   console.log((height / width) * 100);

  //   return (height / width) * 100;
  // };

  // useEffect(() => {
  //   const delay = 1000;

  //   const timer = setTimeout(() => {
  //     async function fetchVideoUrl(video_id) {
  //       try {
  //         const authToken = getCookie("authToken", null);
  //         const response = await axios.post(
  //           `${process.env.REACT_APP_API_URL}get-video`,
  //           { video_id },
  //           {
  //             headers: {
  //               authToken: authToken,
  //             },
  //             responseType: "json", // Set responseType to "json"
  //           }
  //         );

  //         if (response.data.data) {
  //           setVideoUrl(response.data.data.video_url);
  //           // console.log(response.data.data)
  //           setVideoTitle(response.data.data.video_title);
  //           console.log("videotitle", videotitle);
  //         } else {
  //           // If not "url", treat it as a blob
  //           const blobResponse = await axios.post(
  //             `${process.env.REACT_APP_API_URL}get-video`,
  //             { video_id },
  //             {
  //               headers: {
  //                 authToken: authToken,
  //               },
  //               responseType: "blob",
  //             }
  //           );
  //           const blob = new Blob([blobResponse.data], {
  //             type: blobResponse.headers["content-type"],
  //           });
  //           // setVideoUrl(URL.createObjectURL(blob));
  //           const blobUrl = URL.createObjectURL(blob);

  //           setVideoUrl(blobUrl);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching video URL", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }

  //     fetchVideoUrl(type);
  //   }, delay);

  //   return () => clearTimeout(timer);
  // }, [type, videotitle]);

  const fetchChannelData = async (id) => {
    try {
      const response = await apiRequest("get-channel", "POST", { channel_id: id });

      if (response.data && response.data.data && response.data.data.channel?.length > 0) {
        setChannelData(response.data.data.channel[0]);
      }
    } catch (error) {
      console.error("Error fetching channel data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(channelData);
  }, [channelData]);

  useEffect(() => {
    type && fetchChannelData(type);
  }, [type]);

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showTooltip]);

  const copyToClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      handleClick();
    }
  };

  const error = () => {
    toast.error("Something went wrong. Video can't be fetched.");
  };

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5 className="pointer mb-3 d-inline-block" onClick={() => navigate(-1)}>
              <Icon name="arrow-left" className="pe-1"></Icon>Preview & Embed Channel
            </h5>
            <div className="p-2">
              <div>
                {loading ? (
                  <div className="position-relative">
                    <ShimmerThumbnail height={40} width={300} />
                    <ShimmerThumbnail height={450} />
                  </div>
                ) : !channelData ? (
                  error()
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={channelData.logo}
                        style={{
                          height: "35px",
                          width: "35px",
                          objectFit: "contain",
                          borderRadius: "50%",
                        }}
                      />
                      <h5>{channelData.channel_name}</h5>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        // paddingBottom: "56.25%",
                        height: "450px",
                        overflow: "hidden",
                        maxWidth: "100%",
                        // height: "auto",
                        background: "black",
                      }}
                    >
                      <iframe
                        src={`/embed/${type}`}
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="row my-3">
                <div className="col-md-6 col-lg-4">
                  <div>
                    <h6>Size</h6>
                    <ul className="custom-control-group g-3 align-center">
                      <li>
                        <div className="custom-control custom-control-sm custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="responsive"
                            name="reg-public"
                            checked={responsive}
                            onChange={handleResponsiveChange}
                          />
                          <label className="custom-control-label" defaultChecked htmlFor="responsive">
                            Responsive
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="custom-control custom-control-sm custom-radio">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="com-sms"
                            name="reg-public"
                            checked={!responsive}
                            onChange={handleResponsiveChange}
                          />
                          <label className="custom-control-label" htmlFor="com-sms">
                            Fixed
                          </label>
                        </div>
                      </li>
                    </ul>
                    <div>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <Input
                            type="select"
                            name="select"
                            className="p-1 my-2"
                            onChange={(event) => {
                              handleRatioChange(event);
                            }}
                            id="aspect-ratio"
                            value={selectedRatio}
                          >
                            <option value="16:9">16:9</option>
                            <option value="4:3">4:3</option>
                            <option value="1:1">1:1</option>
                          </Input>
                        </div>
                      </div>
                      <p>Aspect Ratio</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div>
                    <h6>Playback options</h6>
                    <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={playing}
                        onChange={toggleAutoplay}
                      />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        Autoplay (if possible)
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                        checked={showVolumeControl}
                        onChange={toggleVolumeControl}
                      />
                      <label className="custom-control-label" htmlFor="customCheck2">
                        Volume
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div>
                    <h6>Display options</h6>
                    <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="showcontrol"
                        checked={showControls}
                        onChange={toggleShowControls}
                      />
                      <label className="custom-control-label" htmlFor="showcontrol">
                        Show controls
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-sm d-block mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="showcontent"
                        checked={showContentTitle}
                        onChange={toggleShowContentTitle}
                      />
                      <label className="custom-control-label" htmlFor="showcontent">
                        Show content title
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-sm mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="showshare"
                        checked={showShareButtons}
                        onChange={toggleShowShareButtons}
                      />
                      <label className="custom-control-label" htmlFor="showshare">
                        Show share buttons
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-sm d-none mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="openplaylist"
                        checked={openPlaylist}
                        onChange={toggleOpenPlaylist}
                      />
                      <label className="custom-control-label" htmlFor="openplaylist">
                        Open playlist on load
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <h6 className="my-3">Code</h6>
              <div className="form-control-wrap my-3">
                <textarea
                  ref={inputRef}
                  className="form-control"
                  type="text"
                  name="site-url"
                  defaultValue={`<div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto;"> <iframe src="https://streamdeck.silocloud.io/embed/${type}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0" allowfullscreen> </iframe> </div>`}
                ></textarea>

                <div className="tooltip-container mt-3">
                  <Button color="primary" onClick={copyToClipboard}>
                    <Icon name="copy" className="me-1 pointer" />
                    Copy Embed Code
                  </Button>
                  {showTooltip && <div className="tooltip-theme">Copied!</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmbedChannel;
