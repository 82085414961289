import React, { createContext, useState, useEffect } from 'react';

// Create context
export const SessionContext = createContext();

// Provider component
export const SessionProvider = ({ children }) => {
  const [sessionId, setSessionId] = useState('');

  const generateSessionId = () => {
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    return `${currentDate}${randomString}`;
  };

  const updateSessionId = () => {
    const newSessionId = generateSessionId();
    setSessionId(newSessionId);
  };

  useEffect(() => {
    // Check if session ID exists in localStorage
    const storedSessionId = localStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      updateSessionId();
    }
  }, []);

  // Save session ID to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('sessionId', sessionId);
  }, [sessionId]);

  return (
    <SessionContext.Provider value={{ sessionId, updateSessionId }}>
      {children}
    </SessionContext.Provider>
  );
};

