import React, { useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";
import Hls from "hls.js";
import VideoAdd from "./VideoAdd";
import { Link } from "react-router-dom";
import { Icon } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import { apiRequest } from "../../utils/apiHelper";
import { Alert, Card, CardBody, CardText } from "reactstrap";
import "../../css/streamdeck_css/streamdeck.css";

const Upload = () => {
  const [autoplay, setAutoplay] = useState(true);
  const [files, setFiles] = useState([]);
  const [videoDuration, setVideoDuration] = useState(null);
  const [showStorageMessage, setShowStorageMessage] = useState(false);
  const [totalDuration, setTotalDuration] = useState(0);

  const [files4, setFiles4] = useState([]);
  const handleDropChange = (acceptedFiles, set) => {
    set(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.round(durationInSeconds % 60);
    return `${minutes} minutes ${seconds} seconds`;
  };

  useEffect(() => {
    const fetchVideoDuration = async () => {
      try {
        const authToken = getCookie("authToken", null);
        const response = await apiRequest("get-all-video", "POST", null, "application/json");
        console.log(response);
        if (response.data && response.data.data && response.data.data.videostreams) {
          setTotalDuration(formatDuration(response.data.data.total_duration));
        }
      } catch (error) {
        console.error("Error fetching video durations:", error);
      }
    };
    fetchVideoDuration();
  }, []);

  const handleVideoUploadSuccess = (fileName, duration) => {
    const formattedDuration = formatDuration(duration);
    console.log(formattedDuration);
    setVideoDuration(formattedDuration);
    setShowStorageMessage(true);
  };

  useEffect(() => {
    const video = document.getElementById("my-video");
    const hls = new Hls();

    if (Hls.isSupported()) {
      // hls.loadSource('http://localhost:8080/hls2/playlist.m3u8');
      // hls.loadSource('http://64.23.175.246:8080/manifest/76eceb05d1b1679687053c2921354d020459244e87c8b07a19655157af604a7f/stream.m3u8');
      // hls.loadSource("http://64.23.175.246:8080/hls2/playlist.m3u8");
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (autoplay) {
          video.play();
        }
      });
    }
  }, [autoplay]);

  const removeFromList = (name) => {
    let defaultFiles = files;
    defaultFiles = defaultFiles.filter((item) => item.name !== name);
    setFiles([...defaultFiles]);
  };

  return (
    <React.Fragment>
      <section className="m-3">
        <Card className="card-bordered bg-azure-dim  text-body" inverse>
          <CardBody className="card-inner">
            <CardText className="flex justify-content-start align-items-center">
              <Icon name="alert-circle" className="me-2" style={{ fontSize: "18px" }} />
              {showStorageMessage && (
                <p>
                  You have {videoDuration ? videoDuration + " minutes" : "0 minutes"} stored (among video uploads and
                  live streams) min included in your plan. If you need to store more minutes{" "}
                  <strong>
                    <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions?tab=packageSubscriptions`}>
                      upgrade your account.
                    </Link>
                  </strong>
                </p>
              )}
              {!showStorageMessage && (
                <p>
                  You have {totalDuration ? totalDuration : "0 minutes"} stored (among video uploads and live streams)
                  min included in your plan. If you need to store more minutes{" "}
                  <strong>
                    {" "}
                    <Link to={`${process.env.REACT_APP_ACCOUNT_URL}subscriptions?tab=packageSubscriptions`}>
                      upgrade your account.
                    </Link>
                  </strong>
                </p>
              )}
            </CardText>
          </CardBody>
        </Card>
        <VideoAdd onVideoUploadSuccess={handleVideoUploadSuccess} />
      </section>
    </React.Fragment>
  );
};

export default Upload;
