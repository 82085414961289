import React, { useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { VectorMap } from "react-jvectormap";
import { worldMap } from "./DefaultData";

export const Map = ({ set }) => {
  return (
    <VectorMap
      map={"world_mill"}
      backgroundColor="transparent"
      borderColor="#dee6ed"
      borderOpacity={1}
      // height={550}
      borderWidth={1}
      color="#ccd7e2"
      containerClassName="vector-map"
      zoomButtons={true}
      zoomOnScroll={true}
      tooltip={true}
      containerStyle={{
        width: "100%",
        height: "200px",
      }}
      regionStyle={{
        initial: {
          fill: "#e4e4e4",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0,
        },
        hover: {
          "fill-opacity": 0.8,
          hoverColor: "#9cabff",
          hoverOpacity: null,
          cursor: "pointer",
        },
        selectedHover: {},
      }}
      series={{
        regions: [
          {
            values: set === "30" ? worldMap.data2 : set === "7" ? worldMap.data3 : worldMap.data4,
            // values: worldMap.data,
            scale: ["#ccd7e2", "#798bff"],
            normalizeFunction: "polynomial",
          },
        ],
      }}
    ></VectorMap>
  );
};

const UserMap = () => {
  const [mapState, setMapState] = useState("30");
  return (
    <React.Fragment>
      <div className="card-title-group">
        <div className="card-title">
          <h5 className="title">Viewers by country</h5>
        </div>
        {/* <UncontrolledDropdown>
          <DropdownToggle className="dropdown-indicator btn btn-sm btn-outline-light btn-white">
            {mapState} Days
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xs">
            <ul className="link-list-opt no-bdr">
              <li className={mapState === "7" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("7");
                  }}
                >
                  <span>Last 7 Days</span>
                </DropdownItem>
              </li>
              <li className={mapState === "15" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("15");
                  }}
                >
                  <span>15 Days</span>
                </DropdownItem>
              </li>
              <li className={mapState === "30" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMapState("30");
                  }}
                >
                  <span>30 Days</span>
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown> */}
      </div>
      <div className="analytics-map">
        <Map set={mapState} />
        <table className="analytics-map-data-list">
          <div className="card-title">
            <h6 className="title">Top Countries</h6>
          </div>
          <tbody>
            <tr className="analytics-map-data">
              <td className="country">United States</td>
              <td className="amount">{mapState === "7" ? "8,005" : mapState === "15" ? "10,025" : "12,094"}</td>
              <td className="percent"> {mapState === "7" ? "5.96" : mapState === "15" ? "15.96" : "23.54"}%</td>
            </tr>
            <tr className="analytics-map-data">
              <td className="country">India</td>
              <td className="amount"> {mapState === "7" ? "2,505" : mapState === "15" ? "5,058" : "7,852"}</td>
              <td className="percent"> {mapState === "7" ? "2.96" : mapState === "15" ? "4.18" : "7.19"}%</td>
            </tr>
            <tr className="analytics-map-data">
              <td className="country">Turkey</td>
              <td className="amount"> {mapState === "7" ? "1,502" : mapState === "15" ? "4,252" : "6,383"}</td>
              <td className="percent"> {mapState === "7" ? "2.16" : mapState === "15" ? "3.96" : "6.54"}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default UserMap;
