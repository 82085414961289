import React, { useContext, useEffect, useRef, useState } from "react";
import Head from "../../layout/head/Head";
import { useNavigate, useParams } from "react-router";
import { Spinner } from "reactstrap";
import { streamdeck_logo } from "../../images/applogos";
import video2 from "./assets/sample-video.mp4";

import "./css/player.css";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import { getData } from "./util";
import { increaseView } from "./increaseViews";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";

const Embed = () => {
  const navigate = useNavigate();

  const [viewIncreased, setViewIncreased] = useState(false);

  const [startTimeInSeconds, setStartTimeInSeconds] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [video2src, setVideo2src] = useState(video2);

  const [streamEnded, setStreamEnded] = useState(false);
  const [streamNotStarted, setStreamNotStarted] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [countdownOver, setCountdownOver] = useState(false);

  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const [noProgram, setNoProgram] = useState(false);

  const [currentChannel, setCurrentChannel] = useState(null);

  const [data, setData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [epg, setEpg] = useState([]);
  const [vdoSrc, setVdoSrc] = useState(null);
  const urlParams = useParams();

  const [rawEpg, setRawEpg] = useState([]);

  const [isMute, setIsMute] = useState(true);

  useEffect(() => {
    setVideo2src(video2);
  }, [streamNotStarted, streamEnded, noProgram, data]);

  useEffect(() => {
    if (urlParams.id && urlParams.id !== "null") {
      setNoProgram(false);
      setStreamEnded(false);
      setIsLoading(true);
      setVdoSrc(null);
      setEpg([]);
      setChannels([]);
      getData(urlParams.id)
        .then((data) => {
          if (data.status) setData(data.data);
          else {
            setStreamEnded(false);
            setNoProgram(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [urlParams]);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setEpg(data.epg_data);
      setRawEpg(data.epg_data);

      setVdoSrc(data.playlistpathLink);
      setCurrentChannel(data.channelData);
      setChannels([data.channelData]);
      setIsLoading(false);
    }
  }, [data, countdownOver]);

  useEffect(() => {
    if (data) {
      // console.log(data);

      function addSinceVideoStart(rawData) {
        if (rawData.length > 0) {
          const sinceVideoStart = rawData[0].since; // Assuming the first since time as sinceVideoStart for all entries

          return rawData.map((entry) => ({
            ...entry,
            sinceVideoStart,
          }));
        } else {
          return rawData;
        }
      }

      function generateFullDayEPG(rawData) {
        const ONE_DAY = 24 * 60 * 60 * 1000; // milliseconds in one day

        // Start time based on the first EPG entry
        const startTime = new Date(rawData[0].since).getTime();

        // End time set to today's midnight
        let endDate = new Date();
        endDate.setHours(24, 0, 0, 0); // Set to midnight of the next day
        const endTime = endDate.getTime();

        let currentTime = startTime;
        let newEPGData = [];

        let initialSinceVideoStart = new Date(rawData[0].sinceVideoStart).getTime();

        while (currentTime < endTime) {
          rawData.forEach((entry, index) => {
            let newEntry = { ...entry };
            let entryDuration = new Date(entry.till) - new Date(entry.since);

            newEntry.since = new Date(currentTime).toISOString();
            newEntry.till = new Date(currentTime + entryDuration).toISOString();
            newEntry.sinceVideoStart = new Date(initialSinceVideoStart).toISOString();

            newEPGData.push(newEntry);

            currentTime += entryDuration;

            // Handle the gap between current entry and the next original entry
            if (index < rawData.length - 1) {
              let nextEntryStart = new Date(rawData[index + 1].since).getTime();
              let currentEntryEnd = new Date(rawData[index].till).getTime();
              let gap = nextEntryStart - currentEntryEnd;
              currentTime += gap;
            }

            if (currentTime >= endTime) {
              return;
            }
          });

          // Calculate total duration of the original schedule including gaps
          initialSinceVideoStart += rawData.reduce((acc, entry, index) => {
            let entryDuration = new Date(entry.till) - new Date(entry.since);
            acc += entryDuration;

            // Add gap duration if not the last entry
            if (index < rawData.length - 1) {
              let nextEntryStart = new Date(rawData[index + 1].since).getTime();
              let currentEntryEnd = new Date(rawData[index].till).getTime();
              acc += nextEntryStart - currentEntryEnd;
            }

            return acc;
          }, 0);
        }

        return newEPGData;
      }

      const modifiedEpg = addSinceVideoStart(rawEpg);

      const filledEpg = modifiedEpg.length ? generateFullDayEPG(modifiedEpg) : [];

      setEpg(filledEpg);
      // console.log(filledEpg);
      // setEpg(rawEpg);
    }
  }, [rawEpg, countdownOver, streamEnded, vdoSrc, selectedChannel]);

  useEffect(() => {
    const checkCurrentVideo = () => {
      const currentTime = new Date();

      const sortedVideos = epg.sort((a, b) => new Date(a.since) - new Date(b.since));

      const startedVideos = sortedVideos.filter((video) => new Date(video.since) <= currentTime);

      const earliestStartedVideo = startedVideos[0];
      const latestVideo = sortedVideos[sortedVideos.length - 1];
      const firstVideo = sortedVideos[0];

      const currentVideo = startedVideos.find((video, index) => {
        const nextVideo = startedVideos[index + 1];
        return currentTime >= new Date(video.since) && (!nextVideo || currentTime < new Date(nextVideo.since));
      });

      if (firstVideo && new Date(firstVideo.since) > currentTime) {
        setStreamNotStarted(true);

        const startTime = new Date(firstVideo.since);
        const countdownTime = (startTime - currentTime) / 1000; // Time in seconds

        setCountdown(countdownTime);
        setVdoSrc(null);
      } else {
        setStreamNotStarted(false);
        setCountdown(null);
      }

      // if (earliestStartedVideo) {
      //   const startTime = new Date(earliestStartedVideo.since);
      //   const gap = (currentTime - startTime) / 1000; // Gap in seconds
      //   setStartTimeInSeconds(gap);
      //   setStreamNotStarted(false);
      // }

      if (currentVideo) {
        // const startTime = new Date(currentVideo.since);
        const startTime = new Date(currentVideo.sinceVideoStart);
        const gap = (currentTime - startTime) / 1000; // Gap in seconds
        setStartTimeInSeconds(gap);
        setStreamNotStarted(false);
      }

      if (latestVideo) {
        const endTime = new Date(latestVideo.till);
        if (currentTime > endTime) {
          setStreamEnded(true);
        } else {
          setStreamEnded(false);
        }
      }
    };

    checkCurrentVideo();
  }, [epg, countdownOver, streamEnded, vdoSrc, selectedChannel]);

  useEffect(() => {
    let countdownInterval;

    if (streamNotStarted && countdown !== null) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            setCountdownOver(true);
            return null;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [streamNotStarted, countdown]);

  const handleOnPlay = () => {
    if (!viewIncreased) {
      setTimeout(() => {
        increaseView(currentChannel);
        setViewIncreased(true);
      }, 10000); // Increase views after 10 seconds
    }
  };

  if (isLoading) {
    return (
      <div className="loader-spinner-custom d-flex justify-content-center align-items-center">
        <Spinner color="dark" />
        {/* <Spinner /> */}

        {/* <span className=".lds-dual-ring"></span> */}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Head title="Embed" />
      <div
        style={{ position: "relative", height: "100vh", overflow: "hidden", background: "black" }}
        className="video-box"
      >
        {streamNotStarted && countdown !== null ? (
          <div
            style={{
              position: "absolute",
              bottom: "2%",
              right: "2%",
              opacity: "0.6",
              zIndex: 1,
            }}
          >
            <h1>
              Get Set! {Math.floor(countdown / 3600)}:{Math.floor((countdown % 3600) / 60)}:
              {Math.floor(countdown % 60)
                .toString()
                .padStart(2, "0")}
            </h1>
          </div>
        ) : null}

        {streamNotStarted || streamEnded || noProgram || !vdoSrc ? (
          <>
            <MediaPlayer
              aspectRatio="16/9"
              style={{ height: "100%", display: "flex", justifyContent: "center" }}
              title=""
              // src="https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
              src={video2src}
              autoPlay
              muted
              loop
              fullscreenOrientation="none"
            >
              <MediaProvider />
              {/* <DefaultVideoLayout
                // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                icons={defaultLayoutIcons}
              /> */}
            </MediaPlayer>
          </>
        ) : (
          <>
            {/* <MediaPlayer
              // aspectRatio="16/9"
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              title={currentChannel.channel_name}
              playsInline
              src={vdoSrc}
              autoPlay
              muted
              loop
              onPlay={handleOnPlay}
              onEnded={() => {
                // setStreamEnded(true);
                console.log("stream end");
              }}
              fullscreenOrientation="none"
              {...(currentChannel.is_live
                ? {
                    streamType: "live",
                  }
                : {
                    currentTime: startTimeInSeconds,
                  })}
            >
              <MediaProvider />
              <DefaultVideoLayout
                // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                icons={defaultLayoutIcons}
                disableTimeSlider="true"
                playbackRates={{
                  min: 1,
                  max: 1,
                  step: 0,
                }}
              />
            </MediaPlayer> */}

            <VideoPlayer
              key={vdoSrc}
              currentChannel={currentChannel}
              videoSrc={vdoSrc}
              handleOnPlay={handleOnPlay}
              setStreamEnded={setStreamEnded}
              startTimeInSeconds={startTimeInSeconds}
              muted={isMute}
              isMute={isMute}
              setIsMute={setIsMute}
            />
          </>
        )}

        {/* <div
          className="channel-loo-view position-absolute"
          style={{ top: "2%", left: "2%", zIndex: "100", width: "4%", minWidth: "40px", maxWidth: "60px" }}
        >
          {currentChannel?.logo && <img src={currentChannel?.logo} alt="channel_logo" />}
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Embed;
