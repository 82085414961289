import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Icon } from "../components/Component";
import { useQuery } from "@tanstack/react-query";
import { getVideosList } from "../http/getApis/getApis";
import { ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";
import { getCookie } from "../utils/Utils";
import Tour from "reactour";

const Homepage = () => {
  const navigate = useNavigate();
  const [isTourOpen, setIsTourOpen] = useState(true);

  const {
    data: videosList,
    isLoading: videosListIsLoading,
    isError: videosListIsError,
  } = useQuery({
    queryKey: ["get-videos-list"],
    queryFn: () => getVideosList(),
  });

  const tourSteps = [
    ...(videosList?.data?.videostreams
      ? []
      : [
          {
            selector: "",
            content: () => (
              <div style={{ fontSize: "19px" }}>
                <div className="text-center">
                  <h3 className="ff-mono">Welcome to Silo StreamDeck!</h3>
                  <p className="mt-1">
                    This is a guided tour to get you started quickly with Streamdeck. Click the <strong>Next</strong>{" "}
                    button below to begin!
                  </p>
                </div>
              </div>
            ),
          },
        ]),
    {
      selector: videosList?.data?.videostreams ? ".create-channel-step" : ".addvideosstep",
      content: () => (
        <p style={{ fontSize: "19px" }}>
          {videosList?.data?.videostreams ? "🚀 Create your first channel here!" : "🎥 Start by uploading videos here!"}
        </p>
      ),
    },
  ];

  if (videosListIsLoading) {
    return (
      <div className="p-4 m-4 bg-white">
        <ShimmerSectionHeader />
        <ShimmerThumbnail height={30} width={150} rounded />
      </div>
    );
  }

  return (
    <>
      {!videosListIsLoading && (
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          accentColor="#e14954"
          prevButton={
            <button
              className="btn btn-secondary"
              onClick={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Prev
            </button>
          }
          nextButton={
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Next
            </button>
          }
          lastStepNextButton={
            <Button
              color="primary"
              onClick={() => navigate(videosList?.data?.videostreams ? "/create-channel" : "/uploads")}
              className={videosList?.data?.videostreams ? "create-channel-step" : "addvideosstep"}
            >
              <Icon name="plus" />
              <span>{videosList?.data?.videostreams ? "Create Channel" : "Add Videos"}</span>
            </Button>
          }
        />
      )}
      <section className="bg-white m-4">
        {videosList?.data?.videostreams ? (
          <div className="p-4">
            <h1 style={{ fontSize: "50px" }}>
              Hello <span className="text-primary">{getCookie("username", "John")}</span>,
            </h1>
            <p style={{ fontSize: "19px" }} className="my-4">
              It seems your channels list is currently empty. Let's change that by creating our first channel right
              away. Get ready to embark on this exciting journey with us!
            </p>
            <Button color="primary" onClick={() => navigate("/create-channel")} className="create-channel-step">
              <Icon name="plus" />
              <span>Create Channel</span>
            </Button>
          </div>
        ) : (
          <div className="p-4">
            <h1 style={{ fontSize: "50px" }}>
              Hey <span className="text-primary">{getCookie("username", "John")}</span>,
            </h1>
            <p style={{ fontSize: "19px" }} className="my-4">
              We're thrilled to have you here. Since the video library is empty, let's begin by uploading some videos to
              make it awesome. Feel free to start uploading your videos, and we'll help you through the process!
            </p>
            <Button color="primary" onClick={() => navigate("/uploads")} className="addvideosstep">
              <Icon name="plus" />
              <span>Add Videos</span>
            </Button>
          </div>
        )}
      </section>
    </>
  );
};

export default Homepage;
