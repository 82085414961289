export const matchTags = [
  { value: "Match any tag", label: "Match any tag" },
  { value: "Match all tags", label: "Match all tags" },
];

export const sorting = [
  { value: "Newest first", label: "Newest first" },
  { value: "Oldest first", label: "Oldest first" },
  { value: "A → Z", label: "A → Z" },
  { value: "Z → A", label: "Z → A" },
  { value: "Shortest first", label: "Shortest first" },
  { value: "Longest first", label: "Longest first" },
];

export const mediaType = [
  { value: "All media Types", label: "All media Types" },
  { value: "Hosted Videos", label: "Hosted Videos" },
  { value: "External Links", label: "External Links" },
];

export const dateType = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

export const duration = [
  { value: "0", label: "daily" },
  { value: "1", label: "weekly" },
];

export const durationStart = [
  { value: "0", label: "00" },
  { value: "1", label: "01" },
  { value: "2", label: "02" },
  { value: "3", label: "03" },
  { value: "4", label: "04" },
  { value: "5", label: "05" },
  { value: "6", label: "06" },
  { value: "7", label: "07" },
  { value: "8", label: "08" },
  { value: "9", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

export const durationMinute = [
  { value: "0", label: "00" },
  { value: "1", label: "15" },
  { value: "2", label: "30" },
  { value: "3", label: "45" },
];

export const edittype = [
  { value: "Looped linear", label: "Looped linear" },
  { value: "My content", label: "My content" },
];
