
import FCalender from "../app/FCalender";

const TimeLine = () => {
  return (
    <FCalender />
  );
};

export default TimeLine;
