import React, { useEffect, useState } from "react";
import { browserUserData, browserUserDataSet2, browserUserDataSet3 } from "./DefaultData";
import { DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { DataTableRow, DataTableHead, DataTableItem, Progress, Icon } from "../../components/Component";

const BrowserUser = () => {
  const [browser, setBrowser] = useState("30");
  const [data, setData] = useState(browserUserData);
  useEffect(() => {
    let newData;
    if (browser === "7") {
      newData = browserUserDataSet2;
    } else if (browser === "15") {
      newData = browserUserDataSet3;
    } else {
      newData = browserUserData;
    }
    setData(newData);
  }, [browser]);
  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title">
            <h5 className="title">Browser Used by Viewers</h5>
          </div>
        </div>
      </div>

      <div className="nk-tb-list is-loose">
        <DataTableHead>
          <DataTableRow>
            <span>Browser</span>
          </DataTableRow>
          <DataTableRow className="text-end">
            <span>Users</span>
          </DataTableRow>
          <DataTableRow>
            <span>% Users</span>
          </DataTableRow>
        </DataTableHead>
        {data.map((item) => {
          return (
            <DataTableItem key={item.id}>
              <DataTableRow>
                <div className="icon-text">
                  <Icon className={`text-${item.theme}`} name="globe"></Icon>
                  <span className="tb-lead">{item.browser}</span>
                </div>
              </DataTableRow>
              <DataTableRow className="text-end">
                <span className="tb-sub tb-amount">
                  <span>{item.users}</span>
                </span>
              </DataTableRow>
              <DataTableRow>
                <Progress value={item.userPercentage} size="md" className="progress-alt bg-transparent" />
              </DataTableRow>
            </DataTableItem>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default BrowserUser;
