import React, { useContext, useEffect, useRef, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Block, Icon } from "../../components/Component";
import ReactPlayer from "react-player";
import { useEpg, Epg, Layout } from "@nessprim/planby-pro";
import { CustomTimeline, Line, LiveTime, Program } from "../Scheduler/components";
import { theme, theme2 } from "../Scheduler/helpers/theme";
import { useNavigate, useParams } from "react-router";
import { getAllChannels, getChannelData } from "../../utils/usechannelEpg";
import { ChannelLogo } from "planby";
import ScheduledChannelContext from "../ScheduledChannelContext";
import { Spinner } from "reactstrap";
import { Card, CardBody, CardText } from "reactstrap";
import { streamdeck_logo } from "../../images/applogos";
import video2 from "./assets/sample-video.mp4";
import { apiRequest } from "../../utils/apiHelper";
import { isMobile, isBrowser, isTablet } from "react-device-detect";
import "./css/customPlayer.css";

import Select, { components } from "react-select";

import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import { toast } from "react-toastify";
import VideoPlayer from "../../components/videoPlayer/VideoPlayer";

const Option = (props) => (
  <components.Option {...props} className="channel-option">
    {!props.data.logo ? (
      <img src={streamdeck_logo} alt="logo" className="channel-logo object-fit-contain mx-3" height={30} width={30} />
    ) : (
      <img src={props.data.logo} alt="logo" className="channel-logo object-fit-contain mx-3" height={30} width={30} />
    )}
    {props.data.channel_name}
  </components.Option>
);

// function increaseView(channel) {
//   // Assuming channel object is passed containing channel_id and livestream_id
//   const { id: channel_id } = channel; // Assuming channel ID is available in the channel object
//   const livestream_id = null; // Example livestream ID, adjust as needed

//   const formData = new FormData();
//   formData.append('channel_id', channel_id);
//   formData.append('livestream_id', livestream_id);

//   // Make API request using apiRequest utility function
//   apiRequest('get-trackView', 'POST', formData, 'application/json')
//     .then(response => {
//       console.log('View tracked successfully:', response);
//       // You can handle the response here
//     })
//     .catch(error => {
//       console.error('Error tracking view:', error);
//       // Handle error appropriately
//     });
// }

const ChannelView = () => {
  const [viewIncreased, setViewIncreased] = useState(false);

  const navigate = useNavigate();
  const [position, setPosition] = useState([51.505, -0.09]);
  const playerWrapperRef = useRef(null);
  const playerRef = useRef(null);
  const [startTimeInSeconds, setStartTimeInSeconds] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);

  const [video2src, setVideo2src] = useState(video2);

  const [streamEnded, setStreamEnded] = useState(false);
  const [streamNotStarted, setStreamNotStarted] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [countdownOver, setCountdownOver] = useState(false);

  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);

  // const [location, setLocation] = useState("Location not available");

  const [isMute, setIsMute] = useState(true);

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLocation(`${latitude}, ${longitude}`);
  //       },
  //       (error) => {
  //         console.error("Error retrieving location:", error);
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation not supported");
  //   }
  // }, []);

  // function increaseView(channel) {
  //   const { id: channel_id } = channel; // Assuming channel ID is available in the channel object
  //   const livestream_id = null; // Example livestream ID, adjust as needed

  //   // Get device and browser info
  //   const deviceType = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop';
  //   const browserInfo = navigator.userAgent;

  //   // For location, you might need to use a geolocation API or library
  //   // This is a placeholder for location information
  // // You can use navigator.geolocation API here
  // const locationData = [
  //   {
  //     "latitude": 18.5204,
  //     "longitude": 73.8567,
  //     "description": "Example Location 1"
  //   }
  // ];

  //   const formData = new FormData();
  //   formData.append('channel_id', channel_id);
  //   formData.append('livestream_id', livestream_id);
  //   formData.append('device_type', deviceType);
  //   formData.append('browser_info', browserInfo);
  //   formData.append('location', JSON.stringify(locationData));

  //   // Make API request using apiRequest utility function
  //   apiRequest('get-trackView', 'POST', formData, 'application/json')
  //     .then(response => {
  //       console.log('View tracked successfully:', response);
  //       // You can handle the response here
  //     })
  //     .catch(error => {
  //       console.error('Error tracking view:', error);
  //       // Handle error appropriately
  //     });
  // }

  async function increaseView(channel) {
    const { id: channel_id } = channel; // Assuming channel ID is available in the channel object
    const livestream_id = null; // Example livestream ID, adjust as needed

    // Get device and browser info
    const deviceType = isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop";
    const browserInfo = navigator.userAgent;

    // Function to get the country from latitude and longitude
    async function getCountryFromLatLng(lat, lon) {
      try {
        const resp = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
        const data = await resp.json();
        return data.address?.country ?? "Unknown";
      } catch (e) {
        return "Unknown";
      }
    }

    // Function to get the user's location
    function getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    }

    try {
      let locationData = [];
      let position, country;

      try {
        position = await getLocation();
        country = await getCountryFromLatLng(position.latitude, position.longitude);
        locationData = [
          {
            latitude: position.latitude,
            longitude: position.longitude,
            description: "User's current location",
            country: country,
          },
        ];
      } catch (locationError) {
        console.warn("Location access denied or unavailable:", locationError);
        locationData = [
          {
            latitude: null,
            longitude: null,
            description: "Location access denied or unavailable",
            country: "Unknown",
          },
        ];
      }

      const formData = new FormData();
      formData.append("channel_id", channel_id);
      formData.append("livestream_id", livestream_id);
      formData.append("device_type", deviceType);
      formData.append("browser_info", browserInfo);
      formData.append("location", JSON.stringify(locationData));

      // Make API request using apiRequest utility function
      apiRequest("get-trackView", "POST", formData, "application/json")
        .then((response) => {
          console.log("View tracked successfully:", response);
          // toast.success("View tracked successfully");
          // You can handle the response here
        })
        .catch((error) => {
          console.error("Error tracking view:", error);
          // toast.success("Error tracking view");
          // Handle error appropriately
        });
    } catch (error) {
      console.error("Error getting location or country:", error);
      // Handle error appropriately
    }
  }

  const handleChannelChange = (selectedOption) => {
    setSelectedChannel(selectedOption);
    console.log(selectedOption);
    navigate(`/channel-view/${selectedOption.id}`);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {!props.data.logo ? (
        <img
          src={streamdeck_logo}
          alt="logo"
          className="selected-logo object-fit-contain mx-3"
          height={30}
          width={30}
        />
      ) : (
        <img
          src={selectedChannel.logo}
          alt="s-logo"
          className="selected-logo object-fit-contain mx-3"
          height={30}
          width={30}
        />
      )}

      {selectedChannel.channel_name}
    </components.SingleValue>
  );

  const [noProgram, setNoProgram] = useState(false);

  const [data, setData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [epg, setEpg] = useState([]);
  const [vdoSrc, setVdoSrc] = useState(null);
  const urlParams = useParams();

  const [rawEpg, setRawEpg] = useState([]);

  const { getEpgProps, getLayoutProps } = useEpg({
    epg,
    channels,
    startDate: `${new Date().toISOString().slice(0, 10)}T00:00:00`,
    endDate: `${new Date().toISOString().slice(0, 10)}T24:00:00`,
    isResize: false,
    isLine: true,
    height: "auto",
    isBaseTimeFormat: true,
    isLine: true,
    liveRefreshTime: 120,
    isInitialScrollToNow: true,
    theme: theme2,
  });

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // const handleReady = () => {
  //   if (vdoSrc) playerRef.current.seekTo(startTimeInSeconds);
  // };

  // const handleStart = () => {
  //   setVideoLoading(false);
  // };

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   handleReady();
  // }, [countdownOver]);

  useEffect(() => {
    setViewIncreased(false);
  }, [selectedChannel]);

  useEffect(() => {
    getAllChannels().then((data) => {
      if (data.data.channel.length == 0) {
        // console.log("first");
        navigate("/");
      }
      setChannelOptions(data.data.channel);
    });
  }, [urlParams]);

  useEffect(() => {
    setVideo2src(video2);
  }, [streamNotStarted, streamEnded, noProgram, data]);

  useEffect(() => {
    if (urlParams.id && urlParams.id !== "null") {
      setNoProgram(false);
      setStreamEnded(false);
      setIsLoading(true);
      setVdoSrc(null);
      setVideoLoading(true);
      setEpg([]);
      setChannels([]);
      getChannelData(urlParams.id)
        .then((data) => {
          if (data.status) setData(data.data);
          else {
            setStreamEnded(false);
            setNoProgram(true);
            setVideoLoading(false);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [urlParams]);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setEpg(data.epg_data);
      setRawEpg(data.epg_data);

      setVdoSrc(data.playlistpathLink);
      setChannels([data.channelData]);
      setIsLoading(false);
    }
  }, [data, countdownOver]);

  useEffect(() => {
    if (data) {
      // console.log(data);

      function addSinceVideoStart(rawData) {
        if (rawData.length > 0) {
          const sinceVideoStart = rawData[0].since; // Assuming the first since time as sinceVideoStart for all entries

          return rawData.map((entry) => ({
            ...entry,
            sinceVideoStart,
          }));
        } else {
          return rawData;
        }
      }

      function generateFullDayEPG(rawData) {
        const ONE_DAY = 24 * 60 * 60 * 1000; // milliseconds in one day

        // Start time based on the first EPG entry
        const startTime = new Date(rawData[0].since).getTime();

        // End time set to today's midnight
        let endDate = new Date();
        endDate.setHours(24, 0, 0, 0); // Set to midnight of the next day
        const endTime = endDate.getTime();

        let currentTime = startTime;
        let newEPGData = [];

        let initialSinceVideoStart = new Date(rawData[0].sinceVideoStart).getTime();

        while (currentTime < endTime) {
          rawData.forEach((entry, index) => {
            let newEntry = { ...entry };
            let entryDuration = new Date(entry.till) - new Date(entry.since);

            newEntry.since = new Date(currentTime).toISOString();
            newEntry.till = new Date(currentTime + entryDuration).toISOString();
            newEntry.sinceVideoStart = new Date(initialSinceVideoStart).toISOString();

            newEPGData.push(newEntry);

            currentTime += entryDuration;

            // Handle the gap between current entry and the next original entry
            if (index < rawData.length - 1) {
              let nextEntryStart = new Date(rawData[index + 1].since).getTime();
              let currentEntryEnd = new Date(rawData[index].till).getTime();
              let gap = nextEntryStart - currentEntryEnd;
              currentTime += gap;
            }

            if (currentTime >= endTime) {
              return;
            }
          });

          // Calculate total duration of the original schedule including gaps
          initialSinceVideoStart += rawData.reduce((acc, entry, index) => {
            let entryDuration = new Date(entry.till) - new Date(entry.since);
            acc += entryDuration;

            // Add gap duration if not the last entry
            if (index < rawData.length - 1) {
              let nextEntryStart = new Date(rawData[index + 1].since).getTime();
              let currentEntryEnd = new Date(rawData[index].till).getTime();
              acc += nextEntryStart - currentEntryEnd;
            }

            return acc;
          }, 0);
        }

        // console.log(newEPGData);
        return newEPGData;
      }
      const modifiedEpg = addSinceVideoStart(rawEpg);

      const filledEpg = modifiedEpg.length ? generateFullDayEPG(modifiedEpg) : [];

      setEpg(filledEpg);
      // console.log(filledEpg);
      // setEpg(rawEpg);
    }
  }, [rawEpg, countdownOver, streamEnded, vdoSrc, selectedChannel]);

  useEffect(() => {
    if (channelOptions.length) {
      if (urlParams?.id && urlParams.id !== "null") {
        const channel = channelOptions.find((ch) => ch.id == urlParams.id);
        if (channel) {
          // increaseView(channel);
          setSelectedChannel(channel);
        } else {
          setSelectedChannel(channelOptions[0]);
          navigate(`/channel-view/${channelOptions[0].id}`);
        }
      } else {
        setSelectedChannel(channelOptions[0]);
        navigate(`/channel-view/${channelOptions[0].id}`);
      }
    }
  }, [data, channelOptions]);

  useEffect(() => {
    const checkCurrentVideo = () => {
      const currentTime = new Date();

      const sortedVideos = epg.sort((a, b) => new Date(a.since) - new Date(b.since));

      const startedVideos = sortedVideos.filter((video) => new Date(video.since) <= currentTime);

      const earliestStartedVideo = startedVideos[0];
      const latestVideo = sortedVideos[sortedVideos.length - 1];
      const firstVideo = sortedVideos[0];

      const currentVideo = startedVideos.find((video, index) => {
        const nextVideo = startedVideos[index + 1];
        return currentTime >= new Date(video.since) && (!nextVideo || currentTime < new Date(nextVideo.since));
      });

      if (currentVideo) {
        // const startTime = new Date(currentVideo.since);
        const startTime = new Date(currentVideo.sinceVideoStart);
        const gap = (currentTime - startTime) / 1000; // Gap in seconds
        setStartTimeInSeconds(gap);
        setStreamNotStarted(false);
      }

      if (firstVideo && new Date(firstVideo.since) > currentTime) {
        setStreamNotStarted(true);

        const startTime = new Date(firstVideo.since);
        const countdownTime = (startTime - currentTime) / 1000; // Time in seconds

        setCountdown(countdownTime);
        setVdoSrc(null);
        setVideoLoading(false);
      } else {
        setStreamNotStarted(false);
        setCountdown(null);
      }

      // if (earliestStartedVideo) {
      //   const startTime = new Date(earliestStartedVideo.since);
      //   const gap = (currentTime - startTime) / 1000; // Gap in seconds
      //   setStartTimeInSeconds(gap);
      //   setStreamNotStarted(false);
      // }

      if (latestVideo) {
        const endTime = new Date(latestVideo.till);
        if (currentTime > endTime) {
          setStreamEnded(true);
          // setVdoSrc(null);
          setVideoLoading(false);
        } else {
          setStreamEnded(false);
        }
      }
    };

    checkCurrentVideo();
  }, [epg, countdownOver, streamEnded, vdoSrc, selectedChannel]);

  useEffect(() => {
    let countdownInterval;

    if (streamNotStarted && countdown !== null) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            setCountdownOver(true);
            return null;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [streamNotStarted, countdown]);

  const handleOnPlay = () => {
    if (!viewIncreased) {
      setTimeout(() => {
        increaseView(selectedChannel);
        setViewIncreased(true);
      }, 10000); // Increase views after 10 seconds
    }
  };

  return (
    <React.Fragment>
      <Head title="Channel" />
      <Content>
        <Block>
          <div className="channel-view-container" style={{ width: "100%" }}>
            <div className="flex justify-content-between align-items-center">
              <h4>{selectedChannel?.channel_name}</h4>
              <div className="w-25">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Select Channel"
                  value={selectedChannel}
                  options={channelOptions}
                  onChange={handleChannelChange}
                  inputValue=""
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
              </div>
            </div>

            {noProgram && (
              <Card className="card-bordered bg-azure-dim  text-body mt-2" inverse>
                <CardBody className="card-inner">
                  <CardText className="flex justify-content-start align-items-center">
                    <Icon name="alert-circle" className="me-2" style={{ fontSize: "18px" }} />
                    <p>This channel doesn't have any stream going on.</p>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {streamEnded && (
              <Card className="card-bordered bg-azure-dim  text-body mt-2" inverse>
                <CardBody className="card-inner">
                  <CardText className="flex justify-content-start align-items-center">
                    <Icon name="alert-circle" className="me-2" style={{ fontSize: "18px" }} />
                    <p>Stream ended. Next stream will be live soon.</p>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {streamNotStarted && (
              <Card className="card-bordered bg-azure-dim  text-body mt-2" inverse>
                <CardBody className="card-inner">
                  <CardText className="flex justify-content-start align-items-center">
                    <Icon name="alert-circle" className="me-2" style={{ fontSize: "18px" }} />
                    <p>Streaming isn't started yet.</p>
                  </CardText>
                </CardBody>
              </Card>
            )}
            <div className="video-player-block video-box mt-2" style={{ position: "relative" }}>
              {/* {videoLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                >
                  <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
                </div>
              )} */}

              {streamNotStarted && countdown !== null ? (
                <div
                  style={{
                    position: "absolute",
                    bottom: "2%",
                    right: "2%",
                    opacity: "0.6",
                    zIndex: 1,
                  }}
                >
                  <h1>
                    Stream starts in {Math.floor(countdown / 3600)}:{Math.floor((countdown % 3600) / 60)}:
                    {Math.floor(countdown % 60)
                      .toString()
                      .padStart(2, "0")}
                  </h1>
                </div>
              ) : null}

              {streamEnded && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "2%",
                    right: "2%",
                    opacity: "0.9",
                    zIndex: 1,
                  }}
                >
                  <h1>Next stream will be live soon...</h1>
                </div>
              )}

              {noProgram && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "2%",
                    right: "2%",
                    opacity: "0.9",
                    zIndex: 1,
                  }}
                >
                  <h1>No stream going on</h1>
                </div>
              )}

              {streamNotStarted || streamEnded || noProgram || !vdoSrc || !selectedChannel ? (
                <>
                  {/* <ReactPlayer
                    className="react-player r2"
                    url={video2src}
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={false}
                    autoPlay={true}
                    loop={true}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                  /> */}
                  <MediaPlayer
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    aspectRatio="16/9"
                    title=""
                    // src="https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
                    src={video2src}
                    autoPlay
                    muted
                    loop
                    fullscreenOrientation="none"
                  >
                    <MediaProvider />
                    {/* <DefaultVideoLayout
                // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                icons={defaultLayoutIcons}
              /> */}
                  </MediaPlayer>
                </>
              ) : (
                <>
                  {/* <ReactPlayer
                    className="react-player"
                    url={vdoSrc}
                    width="100%"
                    height="100%"
                    playing={true}
                    controls={true}
                    autoPlay={true}
                    loop={false}
                    playsinline={true}
                    volume={0.5}
                    muted={true}
                    ref={playerRef}
                    onReady={handleReady}
                    onStart={handleStart}
                    onEnded={() => {
                      setStreamEnded(true);
                    }}
                  /> */}
                  {/* <MediaPlayer
                    aspectRatio="16/9"
                    style={{ height: "100%" }}
                    playsInline
                    src={vdoSrc}
                    currentTime={startTimeInSeconds}
                    autoPlay
                    muted
                    loop
                    onPlay={handleOnPlay}
                    onEnded={() => {
                      // setStreamEnded(true);
                      console.log("stream end");
                    }}
                  >
                    <MediaProvider />
                    <DefaultVideoLayout
                      // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                      icons={defaultLayoutIcons}
                      disableTimeSlider="true"
                      playbackRates={{
                        min: 1,
                        max: 1,
                        step: 0,
                      }}
                    />
                  </MediaPlayer> */}

                  <VideoPlayer
                    key={vdoSrc}
                    currentChannel={selectedChannel}
                    videoSrc={vdoSrc}
                    handleOnPlay={handleOnPlay}
                    setStreamEnded={setStreamEnded}
                    startTimeInSeconds={startTimeInSeconds}
                    muted={isMute}
                    isMute={isMute}
                    setIsMute={setIsMute}
                  />
                </>
              )}

              {/* <div className="seekbar-overlay"></div> */}

              <div className="channel-logo-view position-absolute">
                {selectedChannel?.logo && <img src={selectedChannel?.logo} alt="channel_logo" />}
              </div>
            </div>
            <div className="epg-block mt-2">
              <Epg {...getEpgProps()} isLine="true" isLoading={isLoading}>
                <Layout
                  {...getLayoutProps()}
                  renderLine={(props) => <Line {...props} />}
                  renderCurrentTime={(props) => <LiveTime {...props} />}
                  renderTimeline={(props) => <CustomTimeline {...props} />}
                  renderProgram={({ program, ...rest }) => (
                    <Program
                      key={`${program.data.channelUuid}-${program.data.id}+${program.data.since}`}
                      program={program}
                      {...rest}
                    />
                  )}
                />
              </Epg>
            </div>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ChannelView;
