import { getCookie } from "./Utils";

export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken", process.env.REACT_APP_TOKEN),
    },
  });
  return await res.json();
};

export const reArrangeVideoApi = async (videos, channel_id) => {
  let data = { videos, channel_id };
  let res = await fetch(`${process.env.REACT_APP_API_URL}rearrange-schedule-video`, {
    method: "POST",
    cache: "no-store",
    body: JSON.stringify(data),
    headers: {
      authToken: getCookie("authToken", process.env.REACT_APP_TOKEN),
      "Content-Type": "application/json",
    },
  });
  return await res.json();
};

export const MakeApiCallBlobImage = async (formData = "", route) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}` + route, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", null),
    },
    cache: "no-store",
    body: formData,
  });

  if (response.ok) {
    const blob = await response.blob();
    if (blob.type !== "application/json") {
      if (blob) {
        const imageUrl = URL.createObjectURL(blob);
        if (imageUrl) return imageUrl;
      }
    } else {
      return false;
    }
  }
};

export function handleSelectChange(selectvaluename, setFormData, formData, setSelect) {
  console.log("selectvaluename", selectvaluename);
  setFormData({ ...formData, schedule_duration: selectvaluename.value });
  if (selectvaluename.value === "0") {
    setSelect(false);
  } else {
    setSelect(true);
  }
}

export function setTimeDatePicker(dateTime) {
  const date = new Date(dateTime);
  const hours = date.getHours().toString().padStart(2, "0"); // Ensure 2 digits, padding with zero if necessary
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}
