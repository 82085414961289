import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import VideoGalleryCard from "../../components/partials/gallery/VideoGalleryCard";
import SocialShare from "../../pages/app/SocialShare"; // Import SocialShare component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/apiHelper";
import Swal from "sweetalert2";
import {
  BlockBetween,
  BlockDes,
  Block,
  BlockHeadContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
  Icon,
  Button,
} from "../../components/Component";

const PlayoutGallery = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await apiRequest(`get-videos-gallegry`);
      setData(response.data); // Ensure you access the 'data' field from the response
    } catch (error) {
      setData([]);
      console.error("Error fetching videos:", error);
    }
  };
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDelete = async (folder) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await apiRequest(`delete-video/${folder}`, "DELETE");
          if (response.data.success) {
            fetchVideos();
            toast.success("Your file has been deleted.");
          } else {
            toast.error(response.error || "Error deleting video");
          }
        } catch (error) {
          Swal.fire("Error!", "Error deleting video: " + error.message, "error");
          console.error("Error deleting video:", error);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Gallery"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle page>Video Gallery</BlockTitle>
              <BlockDes className="text-soft">
                <p>
                  You have total <span className="text-base">{data.length}</span> Media.
                </p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
                <Icon name="download-cloud"></Icon>
                <span>Download All</span>
              </Button>
              <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                <Icon name="download-cloud"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {data.map((item, index) => (
              <Col sm={6} lg={4} xxl={3} key={item.folder}>
                <div
                  className="gallery-card"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <VideoGalleryCard
                    img={item.thumbnail}
                    userName={null}
                    userEmail={null}
                    theme="video"
                    userImg={null}
                    heartCount={0}
                    link={item.relativePath}
                    onDelete={() => handleDelete(item.folder)}
                  />
                  {hoveredItem === index && (
                    <div className="social-share-popover">
                      <SocialShare streamName={item.relativePath} />
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default PlayoutGallery;
