import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import { getCookie } from "../../utils/Utils";
import { apiRequest } from "../../utils/apiHelper";
import { toast } from "react-toastify";
import { useParams } from "react-router";
const HlsPlayer = ({ src, userId, channelKey, setIsPlaying, titleName }) => {
  const params = useParams();
  const [titleNames, settitleName] = useState(params.stream_name);
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const handleVideoPause = () => {
    setIsPlaying(true);
  };
  const handleVideoPlay = () => {
    setIsPlaying(false);
  };
  const updateStreamStatus = async (identifierKey, streamStatus = "0") => {
    try {
      const formData = new FormData();
      formData.append("identifier_key", identifierKey);
      formData.append("stream_status", streamStatus);
      const response = await apiRequest("set-stream-status", "POST", formData, "multipart/form-data");
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      return response.data.status;
    } catch (error) {
      toast.error(`Error updating stream status: ${error.message}`);
      return false;
    }
  };
  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsRef.current = hls;
      const video = videoRef.current;

      hls.loadSource(src);
      hls.attachMedia(video);
      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.type === Hls.ErrorTypes.NETWORK_ERROR && data.fatal) {
          console.error("Failed to load HLS manifest:", data.response);
          const handleNetworkError = async () => {
            const isUpdated = await updateStreamStatus(titleNames, "0");
            if (isUpdated) {
              window.location.reload();
            }
          };

          handleNetworkError();
        }
      });

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
          const segmentUrl = data.frag.url;
          fetch(segmentUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const apiUrl = `${process.env.REACT_APP_LARAVEL_URL}upload-m3u8`;
              // const apiUrl = `${process.env.REACT_APP_EXPRESS_URL}upload-m3u8`;
              const formData = new FormData();
              formData.append("file", blob, "segment.ts");
              formData.append("userId", userId);
              formData.append("channel_key", channelKey);
              const authToken = getCookie("authToken", null); // Make sure getCookie is defined
              // const authToken=localStorage.getItem("authToken");
              const headers = new Headers();
              headers.append("authToken", authToken);

              fetch(apiUrl, {
                method: "POST",
                body: formData,
                headers: headers,
              })
                .then((response) => {
                  if (response.ok) {
                    console.log("Segment saved successfully.");
                  } else {
                    console.error("Failed to save segment.");
                  }
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            })
            .catch((error) => {
              console.error("Error fetching segment:", error);
            });
        });
        setIsPlaying(true);
      });

      video.addEventListener("loadeddata", handleVideoLoadedData);
      video.addEventListener("pause", handleVideoPause);
      video.addEventListener("play", handleVideoPlay);
      video.play().catch((error) => console.error("Error playing video:", error));
    } else {
      console.error("HLS not supported");
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadeddata", handleVideoLoadedData);
      }
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [src, channelKey, titleName]);

  const handleVideoLoadedData = () => {
    videoRef.current.play();
  };

  return (
    <video
      className="w-100 hlsvideo"
      ref={videoRef}
      controls
      autoPlay
      poster={require("../../images/Livestreaming/Placeholder.jpg")}
    />
  );
};

export default HlsPlayer;
