import React, { useContext, useEffect, useState } from "react";
import { BackTo, RSelect } from "../../components/Component";
import { Button, Label, Spinner } from "reactstrap";
import PlayerSetting from "./PlayerSetting";
import { duration } from "../Video/DropDown";
import DatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router";
import { ChannelIDContext, StreamAddContext } from "../app/StremContext";
import { fetchAPI, handleSelectChange, setTimeDatePicker } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const EditChannel = () => {
  const [set, setSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate("");
  const parms = useParams();

  let decoded = window.atob(parms.id);
  const [channel_id, setChannel_id] = useState(decoded);

  const { formData, setFormData } = useContext(StreamAddContext);

  const channelListData = async () => {
    try {
      const formChannelId = new FormData();
      formChannelId.append("channel_id", decoded);
      const getChannelData = await fetchAPI(formChannelId, "get-channel");
      console.log("getChannelData", getChannelData?.data?.channel?.[0]?.channel_name);
      if (getChannelData.status) {
        const channel = getChannelData?.data?.channel?.[0];
  
        var timeComponents = channel?.start_time.split(":");
        const date = new Date();
        date.setHours(parseInt(timeComponents[0], 10));
        date.setMinutes(parseInt(timeComponents[1], 10));
        date.setSeconds(parseInt(timeComponents[2], 10));
  
        setFormData({
          channel_name: channel?.channel_name,
          schedule_duration: channel?.schedule_duration,
          channel_type: channel?.channel_type,
          start_time: date,
          // logo: channel?.logo,
          logo_on_off: channel?.logo_on_off,
          logo_position: channel?.logo_position,
          main_color: channel?.main_color,
          channel_embedded: channel?.channel_embedded,
          add_tag_url: channel?.add_tag_url ? channel?.add_tag_url.split(",") : [],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const updateChannel = async () => {
    setLoading(true);
    const channelUpdate = new FormData();
    channelUpdate.append("channel_id", decoded);
    channelUpdate.append("channel_name", formData.channel_name);
    channelUpdate.append("channel_type", formData.channel_type);
    channelUpdate.append("schedule_duration", formData.schedule_duration);
    channelUpdate.append("start_time", setTimeDatePicker(formData.start_time));
    channelUpdate.append("logo_position", formData.logo_position);
    channelUpdate.append("logo_on_off", formData.logo_on_off);
    channelUpdate.append("main_color", formData.main_color);
    channelUpdate.append("channel_embedded", formData.channel_embedded);
    channelUpdate.append("add_tag_url", formData.add_tag_url);

    // channelUpdate.append("logo_link", formData.logo_link);
    if (
      formData.logo !== undefined &&
      formData.logo !== null &&
      formData.logo !== "undefined" &&
      formData.logo !== "null" &&
      formData.logo !== ""
    ) {
      channelUpdate.append("logo", formData.logo);
    }
    const getChannelUpdate = await fetchAPI(channelUpdate, "update-channel");

    if (getChannelUpdate.status) {
      toast.success(getChannelUpdate.message);
      Navigate("/channels");
    } else {
      toast.error(getChannelUpdate.errors[0]);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--bs-gray-200)",
    }),
  };

  useEffect(() => {
    setFormData((formData) => ({ ...formData, channel_id: decoded }));
    channelListData();
    return () => {
      setFormData({
        channel_type: "0",
        linear_channel_type: "0",
        channel_name: "Untitled Channel",
        schedule_duration: "",
        start_time: new Date(),
        logo: "",
        logo_link: "",
        logo_position: "0",
        logo_on_off: "1",
        main_color: "878796",
        channel_embedded: "",
        add_tag_url: [],
      });
    };
  }, []);
  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <BackTo className="" link="/channels" icon="arrow-left">
              <h5>Edit channel</h5>
            </BackTo>
            <div className="p-2">
              <div className="row my-3">
                {/* <div className="">
                  <h6 className="fw-semibold">Type</h6>
                  <div className="d-flex g-2 align-items-center">
                    <div className="preview-item w-25">
                      <RSelect
                        placeholder="Looped linear"
                        onChange={handleClickSelect}
                        style={customStyles}
                        options={edittype}
                      ></RSelect>
                    </div>
                    {set ? (
                      <p className="m-0">
                        You have changed the channel type and some settings may be lost. The content of the channel will
                        be maintained.
                      </p>
                    ) : (
                      <p className="m-0"></p>
                    )}
                  </div>
                </div> */}
                <div className="">
                  <div className="form-group">
                    <Label htmlFor="default-0" className="form-label">
                      <h6 className="fw-semibold">Name</h6>
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        className="form-control"
                        type="text"
                        id="default-0"
                        value={formData.channel_name}
                        onChange={(e) => setFormData({ ...formData, channel_name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {parms.type === "scheduled" ? (
                <div className="mb-3">
                  <h6 className="fw-semibold">Scheduler duration</h6>
                  <div className="d-flex g-2 align-items-center mb-3">
                    <div className="preview-item w-25">
                      {/* <select
                        name=""
                        id=""
                        onChange={(selected) => handleSelectChange(selected, setFormData, formData, setSelect)}
                        className="form-select"
                      >
                        {duration.map((item, index) => (
                          <option
                            key={item.value}
                            value={item.value}
                            selected={formData.schedule_duration === item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      </select> */}
                      <RSelect
                        placeholder="daily"
                        style={customStyles}
                        options={duration}
                        onChange={(selected) => handleSelectChange(selected, setFormData, formData, setSelect)}
                      ></RSelect>
                    </div>

                    {set ? (
                      <p className="m-0">You will schedule a whole week 24/7 and content will be repeated weekly.</p>
                    ) : (
                      <p className="m-0">You will schedule one day (24 hours) and content will be repeated daily.</p>
                    )}
                  </div>
                </div>
              ) : parms.type === "looped" ? (
                <div className="mb-3">
                  <h6>Start time</h6>
                  <div className="d-flex align-items-center">
                    <div className="form-group mb-0 z-3">
                      <div className="form-control-wrap has-timepicker focused">
                        <DatePicker
                          selected={formData.start_time}
                          onChange={(date) => setFormData({ ...formData, start_time: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <p className="mb-0 ms-2">
                      Your current timezone is <Link to="">Asia/Kolkata</Link>{" "}
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <ChannelIDContext.Provider value={{ channel_id }}>
                <PlayerSetting />
              </ChannelIDContext.Provider>

              <div className="mt-3 mt-xl-4">
                <Button className="mx-1" color="primary" onClick={updateChannel}>
                  {loading ? <Spinner size="sm" /> : <span>Update</span>}
                </Button>
                <Button className="mx-1" onClick={() => Navigate("/channels")} color="light">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditChannel;
