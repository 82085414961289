import React, { createContext, useState, useContext } from "react";

const HeaderTextContext = createContext();
const HeaderTextUpdateContext = createContext();

export const useHeaderText = () => useContext(HeaderTextContext);
export const useHeaderTextUpdate = () => useContext(HeaderTextUpdateContext);

export const HeaderTextProvider = ({ children }) => {
  const [text, setText] = useState(
    "Welcome to our SiloStreamDeck! Explore endless entertainment and discover new favorites. Enjoy your streaming journey with us!"
  );

  return (
    <HeaderTextContext.Provider value={text}>
      <HeaderTextUpdateContext.Provider value={setText}>{children}</HeaderTextUpdateContext.Provider>
    </HeaderTextContext.Provider>
  );
};
