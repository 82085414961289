import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";
import { Icon } from "../../components/Component";
import { Link } from "react-router-dom";
import Tour from "reactour";
import Cookies from "js-cookie";
const CreateChannel = () => {
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();

  function renavigate() {
    if (activeTab === "1") {
      if (activeButton === "0") {
        navigate("/create-channel/scheduled?type=" + activeButton);
      } else if (activeButton === "1") {
        navigate("/create-channel/looped?type=" + activeButton);
      }
    } else if (activeTab === "2") {
      navigate("/create-channel/fast");
    } else if (activeTab === "3") {
      navigate("/create-channel/vod");
    }
  }

  const [tooltipOpenLeft, setTooltipOpenLeft] = useState(false);
  const [tooltipOpenRight, setTooltipOpenRight] = useState(false);
  const [streamplayer, setstreamplayer] = useState(false);
  const [streamlayer, setstreamlayer] = useState(false);
  const [streamdemand, setstreamdemand] = useState(false);

  const toggleTooltipLeft = () => setTooltipOpenLeft(!tooltipOpenLeft);
  const toggleTooltipRight = () => setTooltipOpenRight(!tooltipOpenRight);
  const togglestreamplayer = () => setstreamplayer(!streamplayer);
  const togglestreamlinear = () => setstreamlayer(!streamlayer);
  const togglestreamdemand = () => setstreamdemand(!streamdemand);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      const prevNavLink = document.getElementById(`nav-link-${activeTab}`);
      if (prevNavLink) {
        prevNavLink.classList.remove("active-tab");
      }

      setActiveTab(tab);

      const currentNavLink = document.getElementById(`nav-link-${tab}`);
      if (currentNavLink) {
        currentNavLink.classList.add("active-tab");
      }
    }
  };

  const [activeButton, setActiveButton] = useState("1");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const [isTourOpen, setIsTourOpen] = useState(false);
  const setNotShowAgain = () => {
    Cookies.set("createChannelTut", "true", { expires: 7 });
  };
  useEffect(() => {
    const tutorialSeen = Cookies.get("createChannelTut");
    if (!tutorialSeen) {
      setIsTourOpen(true);
      // Cookies.set("createChannelTut", "true", { expires: 7 });
    }
  }, []);
  const tourSteps = [
    {
      selector: ".linearchannelstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            These are Linear Channels.
          </p>
          <Alert className="" color="primary">
            <strong>Linear channels</strong> can be delivered via Silo Player, an HLS link compatible with any player
            and TV Apps like Silo TV.
          </Alert>
          <div className="custom-control custom-control-sm custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck7"
              onChange={(e) => setNotShowAgain()}
            />
            <label className="custom-control-label" htmlFor="customCheck7">
              Do not show again.
            </label>
          </div>
        </>
      ),
    },
    {
      selector: ".demandChannelstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            These are Demand Channels.
          </p>
          <Alert className="" color="primary">
            <strong>Demand channels </strong> viewers can interact with the player controls and jump to the next or
            previous video in the playlist. On demand channels are delivered via Streamdeck Player.
          </Alert>
        </>
      ),
    },
    {
      selector: ".typeofschedulestep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            You can select that you programs will be in looped or fixed in schedule Time.
          </p>
        </>
      ),
    },
    {
      selector: ".leftsidestep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This will create schedule Programs.
          </p>
          <Alert className="" color="primary">
            You can schedule the content to a fixed time.
          </Alert>
        </>
      ),
      position: "bottom",
    },
    {
      selector: ".rightsidestep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            This will create schedule Programs.
          </p>
          <Alert className="" color="primary">
            Content will be shown in loop mode.
          </Alert>
        </>
      ),
      position: "bottom",
    },
    {
      selector: ".makechannelstep",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Lets Create One Channel Which is <strong className="text-danger">Linear</strong> and{" "}
            <strong className="text-danger">Looped</strong>. Click on <strong className="text-danger">Next</strong>{" "}
            button to continue.
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        showDots={false}
        accentColor="#e14954"
        prevButton={
          <button
            className="btn btn-secondary"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Prev
          </button>
        }
        nextButton={
          <button
            className="btn btn-primary"
            callbackFunc={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            Next
          </button>
        }
        lastStepNextButton={
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              setIsTourOpen(false);
              renavigate();
              return false;
            }}
          >
            Done
          </button>
        }
      />
      <section className="m-3 create-channel">
        <div className="bg-white p-3">
          <h5 className="pointer" onClick={() => navigate(-1)}>
            <Icon name="arrow-left" className="pe-1"></Icon>Create channel
          </h5>
          <div className="mt-4">
            <p className="fs-5">1. Choose the channel type</p>

            {/* <div>
              <Card className="card-bordered">
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Card with stretched link</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 ff-base">
                    Card subtitle
                  </CardSubtitle>
                  <CardText>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </CardText>
                  <Button color="primary">Go somewhere</Button>
                </CardBody>
              </Card>

              <Card className="card-bordered">
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Card with stretched link</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 ff-base">
                    Card subtitle
                  </CardSubtitle>
                  <CardText>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </CardText>
                  <Button color="primary">Go somewhere</Button>
                </CardBody>
              </Card>

              <Card className="card-bordered">
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Card with stretched link</CardTitle>
                  <CardSubtitle tag="h6" className="mb-2 ff-base">
                    Card subtitle
                  </CardSubtitle>
                  <CardText>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </CardText>
                  <Button color="primary">Go somewhere</Button>
                </CardBody>
              </Card>

            </div> */}

            <div className="mt-4">
              <ButtonGroup className="mt-3">
                <Button
                  id="nav-link-1"
                  color={activeTab === "1" ? "primary" : "light"}
                  outline={activeTab === "1" ? false : true}
                  className="flex-column justify-content-center align-items-center p-3 w-50 linearchannelstep"
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <p style={{ fontSize: "1.5rem" }}>Linear</p>
                  <p>Streamdeck Player - HLS link - TV Apps</p>
                  <Tooltip placement="top" isOpen={streamlayer} target="nav-link-1" toggle={togglestreamlinear}>
                    Linear channels can be delivered via Silo Player, an HLS link compatible with any player and TV Apps
                    like Roku.
                  </Tooltip>
                </Button>
                {/* <Button
                  id="nav-link-2"
                  color={activeTab === "2" ? "primary" : "light"}
                  outline={activeTab === "2" ? false : true}
                  className="flex-column justify-content-center align-items-center p-3 w-50 mx-4"
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <p style={{ fontSize: "1.5rem" }}>FAST</p>
                  <p>HLS link with SCTE-35 ad markers</p>
                  <Tooltip placement="top" isOpen={streamplayer} target="nav-link-2" toggle={togglestreamplayer}>
                    FAST (Free Ad-supported Streaming TV) is a type of linear channel that provides free content to
                    viewers, supported by advertising. FAST channels are delivered via HLS link with SCTE-35 ad markers
                    inserted at regular intervals.
                  </Tooltip>
                </Button> */}
                <Button
                  id="nav-link-3"
                  color={activeTab === "3" ? "primary" : "light"}
                  outline={activeTab === "3" ? false : true}
                  className="flex-column justify-content-center align-items-center p-3 w-50 mx-4 demandChannelstep"
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <p style={{ fontSize: "1.5rem" }}>On demand</p>
                  <p>Streamdeck Player</p>
                  <Tooltip placement="top" isOpen={streamdemand} target="nav-link-3" toggle={togglestreamdemand}>
                    In on demand channels viewers can interact with the player controls and jump to the next or previous
                    video in the playlist. On demand channels are delivered via Streamdeck Player.
                  </Tooltip>
                </Button>
              </ButtonGroup>
              {/* <Nav tabs className="border-0  justify-content-center row">
                <NavItem id="id" className="col">
                  <NavLink
                    id="nav-link-1"
                    href="#"
                    className={`classnames({ active: activeTab === '1' }) flex-column bg-secondary-dim p-4 w-100 active-tab`}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <h5>Linear</h5>
                    <p>Streamdeck Player - HLS link - TV Apps</p>
                  </NavLink>
                  <Tooltip placement="top" isOpen={streamlayer} target="nav-link-1" toggle={togglestreamlinear}>
                    Linear channels can be delivered via Silo Player, an HLS link compatible with any player and TV Apps
                    like Roku.
                  </Tooltip>
                </NavItem>
                <NavItem className="col">
                  <NavLink
                    id="nav-link-2"
                    href="#"
                    className={`classnames({ active: activeTab === '2' }) flex-column bg-secondary-dim p-4 w-100`}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <h5>FAST</h5>
                    <p>HLS link with SCTE-35 ad markers</p>
                  </NavLink>
                  <Tooltip placement="top" isOpen={streamplayer} target="nav-link-2" toggle={togglestreamplayer}>
                    FAST (Free Ad-supported Streaming TV) is a type of linear channel that provides free content to
                    viewers, supported by advertising. FAST channels are delivered via HLS link with SCTE-35 ad markers
                    inserted at regular intervals.
                  </Tooltip>
                </NavItem>
                <NavItem className="col">
                  <NavLink
                    id="nav-link-3"
                    href="#"
                    className={`classnames({ active: activeTab === '3' }) flex-column bg-secondary-dim p-4 w-100`}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <h5>On demand</h5>
                    <p>Streamdeck Player</p>
                  </NavLink>
                  <Tooltip placement="top" isOpen={streamdemand} target="nav-link-3" toggle={togglestreamdemand}>
                    In on demand channels viewers can interact with the player controls and jump to the next or previous
                    video in the playlist. On demand channels are delivered via Streamdeck Player.
                  </Tooltip>
                </NavItem>
              </Nav> */}
            </div>
            <TabContent className="mt-5" activeTab={activeTab}>
              <TabPane tabId="1" className="typeofschedulestep">
                <p className="fs-5">2. Do you want the channel content to repeat on a fixed schedule or in a loop?</p>
                <ButtonGroup className="mb-2 mt-4">
                  <Button
                    color={activeButton === "0" ? "primary" : "light"}
                    id="leftside"
                    outline={activeButton === "0" ? false : true}
                    onClick={() => handleButtonClick("0")}
                    className="leftsidestep"
                  >
                    <Icon name="calender-date-fill" />
                    <span>Scheduled</span>
                    <Tooltip placement="top" isOpen={tooltipOpenLeft} target="leftside" toggle={toggleTooltipLeft}>
                      You can schedule the content to a fixed time.
                    </Tooltip>
                  </Button>
                  <Button
                    color={activeButton === "1" ? "primary" : "light"}
                    id="rightside"
                    outline={activeButton === "1" ? false : true}
                    onClick={() => handleButtonClick("1")}
                    className="rightsidestep"
                  >
                    <Icon name="redo" />
                    <span>Looped</span>
                    <Tooltip placement="top" isOpen={tooltipOpenRight} target="rightside" toggle={toggleTooltipRight}>
                      Content will be shown in loop mode.
                    </Tooltip>
                  </Button>
                </ButtonGroup>
              </TabPane>
              <TabPane tabId="2"></TabPane>
              <TabPane tabId="3"></TabPane>
            </TabContent>
          </div>

          <div className="flex justify-content-end">
            <Button color="primary" className="makechannelstep" onClick={() => renavigate()}>
              <span>Next</span>
              <em class="icon ni ni-arrow-long-right"></em>
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateChannel;
