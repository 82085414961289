import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import { Icon } from "../../components/Component";

const TooltipItem = (props) => {
  const { placement, text, id, toolTipList, btntext, onClk, clicfunction } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const addEllipsis = (text) => {
    if (text.length <= 10) {
      return text;
    } else {
      return text.slice(0, 5) + "...";
    }
  };
  return (
    <>
      {toolTipList === true ? (
        <>
          {clicfunction ? (
            <Button color="dark" size="sm" className="btn-dim fs-10px p-0" id={`buttonName-${id}`} onClick={onClk}>
              <Icon name={btntext?.[0]?.name} className="bg-transparent" />
            </Button>
          ) : (
            <Button color="dark" size="sm" className="btn-dim fs-10px px-1" id={`buttonName-${id}`}>
              <Icon name={btntext?.[0]?.name} className="bg-transparent" />
              {btntext?.[1]?.text && btntext?.[1]?.text !== "" ? <span>{btntext?.[1]?.text}</span> : ""}
            </Button>
          )}

          <Tooltip placement={placement} isOpen={tooltipOpen} target={"buttonName-" + id} toggle={toggle}>
            {text}
          </Tooltip>
        </>
      ) : (
        <>
          <p className="mb-0 fs-12px" id={`channelName-${id}`}>
            {text}
          </p>
          <Tooltip placement={placement} isOpen={tooltipOpen} target={"channelName-" + id} toggle={toggle}>
            {text}
          </Tooltip>
        </>
      )}
    </>
  );
};

export default TooltipItem;
