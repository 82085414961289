import { getCookie, postRequest } from "../../utils/Utils";

const baseUrl = process.env.REACT_APP_API_URL;
const token =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTIxMjQzNTMsImlhdCI6MTcxMjAzNzk1MywianRpIjoiODI5YTMzZWNmOTVkZjIzMDNkZTRkNjAwOTIyZjJkZWMiLCJ1c2VybmFtZSI6InhhaW8iLCJlbWFpbCI6Im5peWlrb2I2NzhAb3RlbWRpLmNvbSIsImNsb3VkVXNlcklkIjo2Niwicm9sZSI6eyJpZCI6MywibmFtZSI6Ik5vcm1hbCBVc2VyIiwia2V5Ijoibm9ybWFsX3VzZXIifX0.qulIe_Hg9rS6B7TirxlAXG5AUVwFendIddAhl5CA4T8";

// API for geting video List =====================================
export const getVideosList = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
  };

  const response = await fetch(`${baseUrl}get-all-video`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

// API for geting Channel List =====================================
export const getChannelList = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken", token),
    },
  };

  const response = await fetch(`${baseUrl}get-channel`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  return response.json();
};

export async function getAllNotificationHandler({ limit = 10, offset = 0, module }) {
  return await postRequest(`${process.env.REACT_APP_PUBLIC_URL_API}get-notifications`, {
    limit,
    offset,
    module,
  });
}

export async function setNotificationCountHandler({ status = 0, notification_id: notifcation_id = 0 }) {
  return await postRequest(`${process.env.REACT_APP_PUBLIC_URL_API}set-notification-status`, {
    status,
    notifcation_id,
  });
}
