import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BackTo, Icon } from '../../components/Component';
import { useNavigate } from 'react-router';

const Delete = (props) => {
    return (
        <>
            <section className="m-3">                
                <Modal isOpen={props.open} toggle={props.toggleopen}>
                    <ModalHeader
                        toggle={props.toggleopen}
                        close={
                            <button className="close" onClick={props.toggleopen}>
                                <Icon name="cross" />
                            </button>
                        }
                    >
                        Delete Channel
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-3">
                            <p className='text-center mb-3'>Are you sure you want to delete <span className='fw-bold'>"Untitled Channel"</span>?</p>

                        </div>
                    </ModalBody>
                    <ModalFooter className="bg-light">
                        <div className="nk-modal-action m-0 justify-end">
                            <ul className="btn-toolbar align-center">
                                <li className='mx-1'>
                                    <Button
                                        toggle={props.toggleopen}
                                        color='primary'
                                        outline
                                        onClick={props.toggleopen}
                                    >
                                        No
                                    </Button>
                                </li>
                                <li className='mx-1'>
                                    <Button color="primary" toggle={props.toggleopen}>
                                        Yes
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </ModalFooter>
                </Modal>
            </section>
        </>
    )
}

export default Delete