import React, { useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { getCookie } from "../../../utils/Utils";
import { DATA } from "../helpers/epg-data/data";

export function useResources(selectedId) {
  const [channels, setChannels] = useState([]);
  const data = DATA;

  const fetchChannels = async () => {
    try {
      const formData = new FormData();
      formData.append("epgchannel", selectedId);
      const response = await fetch(`${process.env.REACT_APP_API_URL}get-channel`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status && responseData.data) {
        console.log("Hooks Channels", responseData.data);
        const formattedChannels = responseData.data.channel.map((channel) => ({
          uuid: channel.channelUuid,
          logo: channel.logo,
          channel_name: channel.channel_name,
        }));
        setChannels(formattedChannels);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchChannels = debounce(fetchChannels, 700);

  useEffect(() => {
    debouncedFetchChannels();
    return () => {
      debouncedFetchChannels.cancel();
    };
  }, [selectedId]);

  return { channels, epgs: data };
}

export default useResources;
