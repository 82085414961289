import React, { useState, useEffect } from "react";
import { Icon, OutlinedInput, RSelect } from "../../components/Component";
import { matchTags, mediaType, sorting } from "../Video/DropDown";
import { getCookie } from "../../utils/Utils";
import axios from "axios";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { apiRequest } from "../../utils/apiHelper";
const FilterMenu = ({
  searchQuery,
  handleSearchChange,
  tagQuery,
  handleSearchChangeTag,
  sortingOption,
  handleSortingChange,
  handleSearchChangefirst,
  handleSearchChangeTagQuery,
  SortingOptionMedia,
  handleMediaTypeChange,
  handleMediaTypes,
  selectedMatchedTag,
  handleMatchedTag,
  clearAllFilters,
  isFilter,
  setIsFilter,
  className,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // backgroundColor: "var(--bs-gray-100)",
    }),
  };

  return (
    <>
      <div className={`row pt-2 scheduler-section ${className}`}>
        <div className="col-md-6 col-lg-4 col-xl-3">
          <div className="form-control-wrap outline-input">
            <OutlinedInput
              id="outlined-icon"
              label="Search by name"
              size=""
              icon="search"
              value={searchQuery}
              onChange={(event) => {
                handleSearchChange(event);
                handleSearchChangefirst(event);
              }}
            />
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-5">
          <div className="input-group">
            <div className="w-tag-value">
              <TagsInput
                value={tagQuery}
                onChange={(event) => {
                  handleSearchChangeTagQuery(event);
                }}
              />
            </div>
            <div className="form-group w-125px">
              <RSelect
                placeholder="Match any tag"
                styles={customStyles}
                options={matchTags}
                value={selectedMatchedTag}
                onChange={(event) => {
                  handleMatchedTag(event);
                  setIsFilter(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
          <div className="d-flex justify-content-around">
            <div className="form-group w-100 ms-1">
              <RSelect
                placeholder="Newest first"
                styles={customStyles}
                options={sorting}
                value={sortingOption}
                onChange={(event) => {
                  handleSortingChange(event);
                  setIsFilter(true);
                }}
              />
            </div>
            <div className="form-group w-100 ms-1">
              <RSelect
                placeholder="Types"
                styles={customStyles}
                options={mediaType}
                value={SortingOptionMedia || "All media Types"}
                onChange={(event) => {
                  handleMediaTypeChange(event);
                  handleMediaTypes(event);
                  setIsFilter(true);
                }}
              />
            </div>
            <div className="">
              {isFilter && (
                <button className="btn btn-outline-secondary p-1" onClick={clearAllFilters}>
                  <Icon name="cross" className=""></Icon>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterMenu;
