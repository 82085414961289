import React, { useContext, useEffect, useState } from "react";
import { Button, Label, Spinner } from "reactstrap";
import PlayerSetting from "./PlayerSetting";
import { Icon, RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import { duration } from "../Video/DropDown";
import { useLocation, useNavigate, useParams } from "react-router";
import { fetchAPI, handleSelectChange, setTimeDatePicker } from "../../utils/api_helper";
import { ChannelIDContext, StreamAddContext } from "../app/StremContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Scheduled = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const type = queryParams.get("type");

  const { formData, setFormData } = useContext(StreamAddContext);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const channelUrlType = params.type === "scheduled" ? "0" : params.type === "vod" ? "2" : "0";
  const channelinearype = type != null ? type : "0";

  const [set, setSelect] = useState(false);
  const [channel_id, setChannel_id] = useState("");
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--bs-gray-200)",
    }),
  };

  async function nextbtn() {
    setLoading(true);
    // navigate("/schedule-channel/timeline");
    if (formData.channel_name == "") {
      toast.error("Please enter channel name");
      setLoading(false);
      return false;
    }
    if (formData.logo == "") {
      toast.error("Please choose a channel logo");
      setLoading(false);
      return false;
    }

    let formdata = new FormData();
    formdata.append("channel_type", channelUrlType);
    formdata.append("channel_name", formData.channel_name);
    formdata.append("schedule_duration", formData.schedule_duration);
    formdata.append("start_time", setTimeDatePicker(formData.start_time));
    formdata.append("logo_position", formData.logo_position);
    formdata.append("logo_on_off", formData.logo_on_off);
    formdata.append("main_color", formData.main_color);
    formdata.append("channel_embedded", formData.channel_embedded);
    formdata.append("add_tag_url", formData.add_tag_url);
    formdata.append("logo", formData.logo);

    if (formData.linear_channel_type === channelinearype) {
      formdata.append("linear_channel_type", formData.linear_channel_type);
    } else {
      formdata.append("linear_channel_type", channelinearype);
    }

    if (formData.logo_link != "") {
      formdata.append("logo_link", formData.logo_link);
    }
    const channelApi = await fetchAPI(formdata, "create-channel");
    if (channelApi.status) {
      toast.success(channelApi.message);
      setFormData({
        channel_type: "0",
        linear_channel_type: "0",
        channel_name: "Untitled Channel",
        schedule_duration: "",
        start_time: new Date(),
        logo: "",
        logo_position: "0",
        logo_on_off: "1",
        logo_link: "",
        main_color: "878796",
        channel_embedded: "",
        add_tag_url: [],
      });
      navigate("/channels");
    } else {
      if (channelApi?.errors) {
        toast.error(channelApi?.errors?.[0]);
      } else {
        toast.error(channelApi.message);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }
  const [typingTimer, setTypingTimer] = useState(null);
  const doneTypingInterval = 500;

  function checkName(channelTitle) {
    clearTimeout(typingTimer);
    if (channelTitle.trim() !== "") {
      const newTimer = setTimeout(() => doneTyping(channelTitle), doneTypingInterval);
      setTypingTimer(newTimer);
    }
  }

  async function doneTyping(channelTitle, showtoster = true) {
    const channelData = new FormData();
    channelData.append("channel_name", channelTitle);

    const channelnameData = await fetchAPI(channelData, "check-channel-name");
    if (channelnameData.status) {
      if (channelnameData.data) {
        if (showtoster) {
          toast.error(channelnameData.message);
        }
        setFormData((prevData) => ({ ...prevData, channel_name: channelnameData.data.channelName }));
      } else {
        if (showtoster) {
          toast.error(channelnameData.message);
        }
      }
    }
  }

  // useEffect(() => {
  //   setFormData({ ...formData, linear_channel_type: channelinearype });
  //   setFormData({ ...formData, channel_type: channelUrlType });
  //   doneTyping(false);
  // }, []);

  useEffect(() => {
    setFormData({ ...formData, linear_channel_type: channelinearype });
    setFormData({ ...formData, channel_type: channelUrlType });
    doneTyping(formData.channel_name, false);
  }, []);

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5 className="pointer mb-3 d-inline-block" onClick={() => navigate(-1)}>
              <Icon name="arrow-left" className="pe-1"></Icon>Create channel
            </h5>
            <div className="mb-3">
              <h6>
                {params.type === "scheduled"
                  ? "Scheduled (Linear)"
                  : params.type === "vod"
                  ? "Playlist (On demand)"
                  : "Looped (Linear)"}
              </h6>

              <div className="mb-3">
                <div className="form-group">
                  <Label htmlFor="default-0" className="form-label">
                    <h6 className="fw-semibold">Name</h6>
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      value={formData.channel_name}
                      onChange={(e) => {
                        setFormData({ ...formData, channel_name: e.target.value });
                        checkName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* {params.type === "scheduled" ? (
                <div className="mb-3">
                  <h6 className="fw-semibold">Scheduler duration</h6>
                  <div className="d-flex g-2 align-items-center">
                    <div className="preview-item w-25">
                      <RSelect
                        placeholder="daily"
                        style={customStyles}
                        options={duration}
                        onChange={(selected) => handleSelectChange(selected, setFormData, formData, setSelect)}
                      ></RSelect>
                    </div>

                    {set ? (
                      <p className="m-0">You will schedule a whole week 24/7 and content will be repeated weekly.</p>
                    ) : (
                      <p className="m-0">You will schedule one day (24 hours) and content will be repeated daily.</p>
                    )}
                  </div>
                </div>
              ) : params.type === "looped" ? (
                <div className="mb-3">
                  <h6>Start time</h6>
                  <div className="d-flex align-items-center">
                    <div className="form-group mb-0 z-3">
                      <div className="form-control-wrap has-timepicker focused">
                        <DatePicker
                          selected={formData.start_time}
                          onChange={(date) => setFormData({ ...formData, start_time: date })}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                    </div>
                    <p className="mb-0 ms-2">
                      Your current timezone is <Link to="">Asia/Kolkata</Link>{" "}
                    </p>
                  </div> */}
              {/* <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="">
                        <RSelect
                          placeholder="00"
                          className="w-80px"
                          style={customStyles}
                          options={durationStart}
                          onChange={(selected) => handleSelectChange()}
                        ></RSelect>
                      </div>
                      <span className="mx-1">:</span>
                      <div className="">
                        <RSelect
                          placeholder="00"
                          className="w-80px"
                          style={customStyles}
                          options={durationMinute}
                          onChange={(selected) => handleSelectChange()}
                        ></RSelect>
                      </div>
                    </div>
                    
                  </div> */}
              {/* </div>
              ) : (
                <div></div>
              )} */}
              <ChannelIDContext.Provider value={{ channel_id }}>
                <PlayerSetting />
              </ChannelIDContext.Provider>
              <div className="mt-3 mt-xl-4">
                <Button className="mx-1" color="primary" onClick={() => nextbtn()}>
                  {loading ? <Spinner size="sm" /> : <span>Next</span>}
                </Button>
                <Button className="mx-1" onClick={() => navigate(-1)} color="light">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Scheduled;
