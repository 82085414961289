export const getData = async (id) => {
  let bodyContent = new FormData();

  const today = new Date().toISOString().slice(0, 10);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  bodyContent.append("date", today);
  bodyContent.append("channel_id", id);
  bodyContent.append("time_zone", tz);

  let response = await fetch(`${process.env.REACT_APP_PUBLIC_URL_API}fetch-tv-programs`, {
    method: "POST",
    body: bodyContent,
  });

  let data = await response.json();
  console.log(data);
  return data;
};
