import React, { useEffect, useState } from "react";
import FilterMenu from "../app/FilterMenu";
import { Button, Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import { fetchAPI } from "../../utils/api_helper";
import { toast } from "react-toastify";
import { ShimmerCategoryItem, ShimmerCategoryList, ShimmerThumbnail } from "react-shimmer-effects";

const AddVideo = () => {
  const [destinationID, setaddChannelId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cutomOrder, setCutomOrder] = useState("");
  const [allvideos, setAllVideos] = useState([]);
  const [filterarray, setFilterArray] = useState([]);
  const [shimmer, setShimmer] = useState(true);

  const [selectedOption, setSelectedOption] = useState("last");
  const navigate = useNavigate("");

  const { channelid } = useParams();
  useEffect(() => {
    getAllVideosList();
  }, []);

  const getAllVideosList = async () => {
    try {
      const formdata = new FormData();
      formdata.append("channel_id", channelid);
      const videoDataList = await fetchAPI(formdata, "get-all-video");
      setShimmer(false);
      if (videoDataList.status) {
        const getdata = videoDataList.data.videostreams;

        if (getdata.length > 0) {
          if (videoDataList.data.video_ids.length > 0) {
            const destinationIds = videoDataList.data.video_ids.map(String);
            const updatedCheckboxes = getdata.filter((checkbox) => {
              return !destinationIds.includes(String(checkbox.id));
            });
            setCheckboxes(updatedCheckboxes);
            setFilterArray(updatedCheckboxes);
          } else {
            setCheckboxes(getdata);
            setFilterArray(getdata);
          }
          setAllVideos(getdata);
        } else {
          setCheckboxes(getdata);
          toast.error(getdata.data.message);
        }
        toast.success(getdata.data.message);
      } else {
        setCheckboxes([]);
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({ ...checkbox, isChecked: !selectAll }));
    const ids = updatedCheckboxes.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.id);
    setCheckboxes(updatedCheckboxes);
    setaddChannelId(ids);
    setSelectAll(!selectAll);
  };

  const hidevideolist = (event) => {
    if (!event.target.checked) {
      setCheckboxes(allvideos);
    } else {
      setCheckboxes(filterarray);
    }
  };

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) =>
      checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
    );
    const ids = updatedCheckboxes.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.id);
    setaddChannelId(ids);
    setCheckboxes(updatedCheckboxes);
    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.isChecked));
  };

  const addVideolList = async (selectedValue) => {
    setLoading(true);
    try {
      if (destinationID.length === 0) {
        setLoading(false);
        toast.error("Please select at least one video");
        return false;
      }
      const channelFormdata = new FormData();
      if (destinationID.length === 1) {
        channelFormdata.append("channel_id", channelid);
        channelFormdata.append("video_ids", destinationID?.[0]);
        channelFormdata.append("order", selectedValue);
        if (selectedValue === "custom") {
          channelFormdata.append("custom_order", cutomOrder);
        }
      } else {
        channelFormdata.append("channel_id", channelid);
        channelFormdata.append("video_ids", destinationID.join(","));
      }
      const channelListApi = await fetchAPI(channelFormdata, "looped-add-schedule-video");
      if (channelListApi.status) {
        navigate("/channels");
        const destinationIds = channelListApi?.data?.videos_data?.scheduler_id.map(String);
        const listData = checkboxes.filter((checkbox) => {
          return !destinationIds.includes(String(checkbox.id));
        });
        setCheckboxes(listData);
        setAllVideos(channelListApi?.data?.videos_data);
        setFilterArray(listData);
      } else {
        toast.error(channelListApi?.message);
      }
    } catch (error) {
    } finally {
    }
  };

  const selectposition = async (selectedValue) => {
    setSelectedOption(selectedValue);
    // addVideolList(selectedValue);
  };

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5>Add video</h5>
            <div>
              <label>
                <input type="checkbox" className="ms-2" onChange={() => handleSelectAll()} />
                <span className="fs-6 ps-1">Select All</span>
              </label>

              <label>
                <input type="checkbox" onChange={hidevideolist} className="ms-2" defaultChecked={true} />
                <span className="fs-6 ps-1">Hide videos added to the channel</span>
              </label>
              <ul className="mt-3">
                {console.log("chec", checkboxes)}
                {checkboxes.length === 0 ? (
                  <div>
                    <ShimmerCategoryList imageType="thumbnail" title items={3} categoryStyle="STYLE_SEVEN" />
                  </div>
                ) : (
                  checkboxes.map((checkbox) => (
                    <li key={checkbox.id} className="border mb-2 p-2 pointer">
                      <label className="w-100  d-flex">
                        <input
                          type="checkbox"
                          checked={checkbox.isChecked}
                          onChange={() => handleCheckboxChange(checkbox.id)}
                        />
                        <span className="ps-1">
                          <img
                            src={`data:image/png;base64, ${checkbox.thumbnail_base64}`}
                            alt=""
                            className="w-max-80px"
                            id=""
                          />
                        </span>
                        <span className="ps-1">{checkbox.title}</span>
                      </label>
                    </li>
                  ))
                )}
              </ul>
            </div>
            <div className="p-3 bg-azure-dim">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="fw-bold">
                  Add to:
                </label>
                <select
                  name=""
                  id=""
                  value={selectedOption}
                  onChange={(e) => selectposition(e.target.value)}
                  className="form-control ps-1 w-20 ms-1"
                >
                  <option value="last">Last position</option>
                  <option value="first">First position</option>
                  <option value="custom">Custom position</option>
                </select>
                {selectedOption === "custom" && (
                  <input type="text" className="w-max-40px ms-2" onChange={(e) => setCutomOrder(e.target.value)} />
                )}
              </div>
            </div>
            <div className="mt-3 mt-xl-4 text-end">
              <Button className="mx-1" color="primary" onClick={() => addVideolList(selectedOption)}>
                {loading ? <Spinner size="sm" /> : <span>Accept</span>}
              </Button>
              <Button className="mx-1" onClick={() => navigate("/channels")} color="light">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddVideo;
