export const DATA = [
  [
    {
      id: "86bfcaa4-07ce-4a54-a09b-058e5d9a06ab",
      title: "file_example_MP4_1280_10MG",
      image: "https://placehold.co/89x50?font=roboto&text=Thumbnail",
      since: "2024-05-31T12:00:00",
      till: "2024-05-31T12:43:00",
      channelUuid: "d4e23215-1805-4e81-9447-47b16289ec6b",
    },
    {
      id: "7ae4c2ca-e634-4117-b1b5-c6ae7d05fc22",
      title: "file_example_MP4_1280_10MG",
      image: "https://placehold.co/89x50?font=roboto&text=Thumbnail",
      since: "2024-05-31T13:00:00",
      till: "2024-05-31T13:38:00",
      channelUuid: "d4e23215-1805-4e81-9447-47b16289ec6b",
    },
  ],
];
