import { useEffect, useState } from "react";
import { fetchAPI } from "./api_helper";
import { useNavigate } from "react-router";

export const useChannelList = (setSelectedchannel, setLoading, setChannelCount) => {
  const [channelList, setChannelList] = useState([]);
  const NavigateChannel = useNavigate("");

  useEffect(() => {
    channelListData();
  }, []);

  const channelListData = async () => {
    try {
      const getChannelData = await fetchAPI("", "get-channel");
      const allVideoList = await fetchAPI("", "get-all-video");
      if (allVideoList.status) {
        // NavigateChannel("/channels");
      } else {
        const getdata = getChannelData.data.channel;
        if (!getdata.length > 0) {
          NavigateChannel("/");
        }
      }
      if (getChannelData.status) {
        const getdata = getChannelData.data.channel;
        if (getdata.length > 0) {
          setChannelList(getdata);
          console.log(getdata.length);
          setChannelCount(getdata.length);
          setSelectedchannel(getdata[0]);
          setLoading(true);
        } else {
          setChannelList([]);
          NavigateChannel("/");
        }
      } else {
        setChannelList([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching channel list:", error);
    }
  };

  // Return both channel list and the setter function
  return [channelList, setChannelList];
};
