import React, { useState } from "react";
import { Badge, Col, Row, Card, Button } from "reactstrap";
const SubscriptionPackage = () => {
  const packagedata = [
    {
      id: 1,
      title: "PRO",
      amount:29,
      caption: "Billed as one payment of $348",
      minute: 1000,
      minstored: 0.03,
      minutepermonth: 10,
      minview: 0.005,
      return: "Yes",
      totalReturn: 125,
      feature:"Top features include:",
      tags: false,
      list1:"Up to 5 channels",
      list2:"Up to 200 video links",
      list3:"Player customization",
      list4:"Program scheduler",
      list5:"Branded website",
    },
    {
      id: 2,
      title: "BUSINESS",
      amount:79,
      caption: "Billed as one payment of $948",
      minute: 3000,
      minstored: 0.04,
      minutepermonth: 40,
      minview: 0.004,
      return: "Yes",
      totalReturn: 200,
      feature:"All PRO features, plus:",
      tags: false,
      list1:"Unlimited channels",
      list2:"Unlimited video links",
      list3:"Live streaming",
      list4:"Player embed restrictions",
    },
    {
      id: 3,
      title: "PREMIUM",
      amount:199,
      caption: "Billed as one payment of $2,388",
      minute:10000,
      minstored: 0.05,
      minutepermonth: 120,
      minview: 0.003,
      return: "Yes",
      totalReturn: 300,
      feature:"All BUSINESS features, plus:",
      tags: false,
      list1:"M3U8 links of linear channels",
      list2:"Ads monetization",
      list3:"Priority support",
    }
  ];
  return (
    <>
      <React.Fragment>
        <section className="m-3">
          <div className="container-fluid">
            <Row className="g-gs">
              {packagedata.map((item) => {
                return (
                  <Col md={6} lg={4} key={item.id}>
                    <Card className={`card-bordered pricing text-center ${item.tags ? "recommend" : ""}`}>
                      {item.tags && (
                        <Badge color="primary" className="pricing-badge">
                          Recommend
                        </Badge>
                      )}
                      <div className="pricing-body">
                        <div className="pricing-title w-220px mx-auto">
                          <h5 className="title">{item.title}</h5>
                          <span className="sub-text">{item.desc}</span>
                        </div>
                        <div className="pricing-amount">
                          <div className="amount">
                            ${item.amount} <span>/month</span>
                          </div>
                          <span className="bill">{item.caption}</span>
                        </div>
                        <div className="pricing-action">
                          <Button color="primary">Select {item.title}</Button>
                        </div>
                        <h6 className="mt-3">Storage</h6>
                        <span>{item.minute} minutes stored</span>
                        <p className="opacity-75">(${item.minstored} extra min stored per month)</p>
                        <h6 className="mt-3">Streaming</h6>
                        <span>{item.minutepermonth}k minutes viewed per month</span>
                        <p className="opacity-75">({item.minview} extra min viewed)</p>
                        <div className="my-3">
                          <h6 className="text-center">{item.feature}</h6>
                          <ul className="mt-2">
                            <li className="mb-2">{item.list1}</li>
                            <li className="mb-2">{item.list2}</li>
                            <li className="mb-2">{item.list3}</li>
                            <li className="mb-2">{item.list4}</li>
                            <li className="mb-2">{item.list5}</li>
                          </ul>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      </React.Fragment>
    </>
  );
};

export default SubscriptionPackage;
