export const theme = {
  primary: {
    600: "#1a202c",
    900: "#171923",
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  teal: {
    100: "#5DDADB",
  },
  green: {
    200: "#389493",
    300: "#2C7A7B",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1",
    },
  },
  loader: {
    teal: "#5DDADB",
    purple: "#5DDADB",
    pink: "#5DDADB",
    bg: "#171923db",
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },

  text: {
    grey: {
      300: "#a0aec0",
      500: "#718096",
    },
  },

  timeline: {
    divider: {
      bg: "#718096",
    },
  },
  grid: {
    item: "#7180961a",
    divider: "#7180961a",
    highlight: "#38B2AC",
  },
};

export const theme2 = {
  primary: {
    600: "#f5f7fa", // very light grey
    900: "#fefefe", // lighter grey
  },
  grey: { 300: "#000" }, // light grey
  white: "#ffffff", // pure white
  teal: {
    100: "#d6f2f2", // very light teal with grey
  },
  green: {
    200: "#d5e8d6", // very light green with greyish tone
    300: "#b8d0b6", // light green with greyish tone
  },
  loader: {
    teal: "#d6f2f2", // very light teal with grey
    purple: "#cfc6de", // light purple with greyish tone
    pink: "#e3c7d1", // very light pink with grey
    bg: "#f2f4f8", // very light background
  },
  scrollbar: {
    border: "#d3d9e0", // lighter grey
    thumb: {
      bg: "#f5f7fa", // very light grey
    },
  },
  gradient: {
    blue: {
      300: "#f3f8fc", // very light blue with whitish tone
      600: "#e7f1f7", // lighter blue with whitish tone
      900: "#dfe8ef", // light blue with whitish tone
    },
  },
  text: {
    grey: {
      300: "#4a5568", // light greyish black
      500: "#2c3a4b", // greyish black
    },
  },
  timeline: {
    divider: {
      bg: "#e8edf3", // lighter grey
    },
  },
  grid: {
    item: "#f0f4f8", // very light grey
    divider: "#e8edf3", // lighter grey
    highlight: "#d6f2f2", // very light teal with greyish tone
  },
};
