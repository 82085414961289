import { ChannelBox } from "@nessprim/planby-pro";
import { channelStyles, COLORS } from "../helpers";
import { Icon, TooltipComponent } from "../../../components/Component";
import { useState } from "react";

export const ChannelItem = (props) => {
  const { isVerticalMode, channel, onOpenGroupTree } = props;
  const { isOpen, isNestedChild, isFirstNestedChild, isLastNestedChild } = channel;
  const { groupTree, position } = channel;

  const boxStyle = channelStyles.boxGroupTree({
    isOpen,
    isNestedChild,
    isLastNestedChild,
    groupTree,
  });
  const leftArrowStyle = channelStyles.arrowRight(isFirstNestedChild);
  const arrowDropdownStyle = channelStyles.arrowDown(isOpen);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      <ChannelBox
        id={"channelname" + channel.channel_id}
        isVerticalMode={isVerticalMode}
        groupTree={groupTree}
        onClick={() => onOpenGroupTree?.(channel)}
        style={boxStyle}
        {...position}
      >
        {isNestedChild && (
          <span style={leftArrowStyle}>
            <Icon className="fs-3" />
          </span>
        )}
        {imageError || !channel.logo ? (
          <img
            src={`${process.env.REACT_APP_API_URL_Logo}logo/apps/silo-streamdeck.png`}
            style={{ height: "4rem", objectFit: "contain" }}
            alt="Fallback Logo"
          />
        ) : (
          <img
            src={channel.logo}
            style={{ height: "4rem", objectFit: "contain" }}
            onError={handleImageError}
            alt="Channel Logo"
          />
        )}
        {groupTree && (
          <span style={arrowDropdownStyle}>
            <Icon name="chevron-down" className="fs-1"></Icon>
          </span>
        )}
      </ChannelBox>
      <TooltipComponent id={"channelname" + channel.channel_id} text={channel.channel_name} direction="top" />
    </>
  );
};
