import React from 'react';
import NVD3Chart from 'react-nvd3';
import 'nvd3/build/nv.d3.css';
import d3 from 'd3';

class MyBarChart extends React.Component {
    render() {
      const data = [{
        key: "Cumulative Return",
        values: [
          {
            "label" : "Feb-05" ,
            "value" : -29.765957771107
          } ,
          {
            "label" : "Feb-08" ,
            "value" : 0
          } ,
          {
            "label" : "Feb-11" ,
            "value" : 32.807804682612
          } ,
          {
            "label" : "Feb-14" ,
            "value" : 196.45946739256
          } ,
          {
            "label" : "Feb-17" ,
            "value" : 0.19434030906893
          } ,
          {
            "label" : "Feb-20" ,
            "value" : -98.079782601442
          } ,
          {
            "label" : "Feb-23" ,
            "value" : -13.925743130903
          } ,
          {
            "label" : "Feb-26" ,
            "value" : -13.925743130903
          } ,
          {
            "label" : "Feb-29" ,
            "value" : -13.925743130903
          } ,
          {
            "label" : "Mar-03 " ,
            "value" : -5.1387322875705
          }
        ]
      }];
  
      return (
        <div style={{ width: 'auto', height: 'screen' }}>
        
        
          <NVD3Chart
            id="lineChart"
            type="lineChart"
            datum={data}
            x="label"
            y="value"
            height={600}
            showValues
            valueFormat={(d) => d3.format(',.1f')(d)}
            margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
            xAxis={{ tickPadding: 10 }}
            yAxis={{ tickPadding: 10 }}
            tooltip={{ enabled: true, valueFormatter: (d) => d3.format('.2f')(d) }}
            interactive={true}
            barColor={['#2196F3']}
            
          />
        </div>
      );
    }
}

export default MyBarChart;
