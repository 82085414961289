export const getSolidLineChart = (data) => {
  // Check if data is defined and is an array
  if (!Array.isArray(data) || data.length === 0) {
      // Return default or empty chart configuration
      return {
          labels: [],
          dataUnit: "Views",
          lineTension: 0.4,
          legend: true,
          categoryPercentage: 0.9,
          barPercentage: 0.6,
          datasets: []
      };
  }

  // Assuming data is an array with valid entries
  const labels = data.map(entry => `${entry.channel_name} (${entry.hour})`); // Use 'hour' and 'channel_name' for labels
  const viewsData = data.map(entry => entry.views); // Use 'views' for data

  const datasets = [
      {
          label: "Channel Views",
          borderColor: "#5ce0aa",
          backgroundColor: "transparent",
          pointBorderWidth: 2,
          fill: false,
          data: viewsData
      }
  ];

  return {
      labels: labels,
      dataUnit: "Views",
      lineTension: 0.4,
      legend: true,
      categoryPercentage: 0.9,
      barPercentage: 0.6,
      datasets: datasets
  };
};


export const getPieChartData = (labels = [], datasets = []) => {
  const defaultDatasetSettings = {
    borderColor: "#fff",
  };

  const mergedDatasets = datasets.map((dataset, index) => ({
    ...defaultDatasetSettings,
    ...dataset,
  }));

  return {
    labels: labels.length ? labels : ["Unique Views", "total_views", "Signed Out Views"],
    dataUnit: "Views",
    legend: true,
    datasets: mergedDatasets,
  };
};



export const getDoughnutChartData = (labels = [], datasets = []) => {
  const defaultDatasetSettings = {
      borderColor: "#fff",
      backgroundColor: [
          "rgba(156, 171, 255, 0.8)",
          "rgba(244, 170, 164, 0.8)",
          "rgba(143, 234, 197, 0.8)",
      ],
  };

  const mergedDatasets = datasets.length
      ? datasets.map((dataset, index) => ({
          ...defaultDatasetSettings,
          ...dataset,
          data: dataset.data, // Assuming dataset.data is already in correct format
      }))
      : [
          {
              ...defaultDatasetSettings,
              data: [110, 80, 125], // Default data values
          },
      ];

  return {
      labels: labels.length ? labels : ["Channel A", "Channel B", "Channel C"], // Default labels
      dataUnit: "Streams",
      legend: false,
      datasets: mergedDatasets,
  };
};
