import React from "react";
import Scheduled from "./Scheduled";

const PlayList = () => {
  return (
    <>
      <Scheduled />
    </>
  );
};

export default PlayList;
