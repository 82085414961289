import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Tooltip } from "reactstrap";
import { Icon } from "../components/Component";

export default function KeyboardShortcut() {
  const [open, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!open);
  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => {
    setModalZoom(!modalZoom);
    setSearchTerm("");
  };
  const [searchTerm, setSearchTerm] = useState("");
  const shortcuts = [
    { name: "Open shortcuts", key: "Ctrl + /" },
    { name: "Select all", key: "Ctrl + A" },
    { name: "Delete", key: "Ctrl + del" },
    { name: "Delete All", key: "Ctrl + A + del" },
  ];

  // Filter shortcuts based on the search term
  const filteredShortcuts = shortcuts.filter((shortcut) =>
    shortcut.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Button className="btn-icon bg-transparent" size="md" id="keyshort" onClick={toggleZoom}>
        <Icon name="cmd" className="text-dark" />
      </Button>
      <Tooltip placement="bottom" isOpen={open} target="keyshort" toggle={toggleTooltip}>
        Show Keyboard Shortcuts
      </Tooltip>
      <Modal isOpen={modalZoom} toggle={toggleZoom} modalClassName="zoom" className="ff-mono">
        <ModalHeader
          className="d-flex justify-content-between border-0 ff-mono"
          toggle={toggleZoom}
          close={
            <button className="close" onClick={toggleZoom}>
              <Icon name="cross" />
            </button>
          }
        >
          Keyboard Shortcuts
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <Icon name="search" />
              </div>
              <input
                className="form-control input-border"
                type="text"
                placeholder="Search Shortcuts"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-1 fs-5">
            {filteredShortcuts.length > 0 ? (
              filteredShortcuts.map((shortcut, index) => (
                <div key={index} className="d-flex justify-content-between mt-1">
                  <div className="ms-1">{shortcut.name}</div>
                  <div className="me-1 d-flex align-items-center">
                    {shortcut.key.split(" + ").map((keyPart, idx, arr) => (
                      <React.Fragment key={idx}>
                        <kbd className="bg-light text-dark fw-bold">{keyPart}</kbd>
                        {idx < arr.length - 1 && <span className="mx-1">+</span>}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-3 text-center">No shortcuts found</div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
