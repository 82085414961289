import React, { useEffect, useState } from "react";
import AccordionTab from "./AccordionTab";
import WebcamCapture from "./WebcamCapture";
import Footer from "./Footer";
import Webcam from "react-webcam";
const Schedulartab = () => {
  const [activeWebcam, setActiveWebcam] = useState(null);
  useEffect(() => {
  console.log("activeWebcam1",activeWebcam)
  }, [activeWebcam]);
  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <h5>Channel Name</h5>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <div className="video-label">
                    <span className="bg-secondary-dim">Preview</span>
                  </div>
                  <div className="video-schedule">
                     {/* <WebcamCapture activeWebcam={activeWebcam} setActiveWebcam={setActiveWebcam} /> */}
                    {activeWebcam && (
                      <Webcam
                        audio={false}
                        videoConstraints={{ deviceId: activeWebcam }}
                        width={640}
                        height={480}
                        screenshotFormat="image/jpeg"
                      />
                    )} 
                    <Footer activeWebcam={activeWebcam} setActiveWebcam={setActiveWebcam}  />
                    <video src="sample-video.mp4" className="w-100" controls muted={true}></video>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="video-label playout-video-label">
                    <span className="bg-secondary-dim">Playout</span>
                  </div>
                  <div className="video-schedule">
                    <video src="sample-video.mp4" className="w-100" controls muted={true}></video>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <AccordionTab variation="2" />
              </div>
            </div>
          </div>
          {/* <Tabmenu /> */}
        </div>
      </section>
    </>
  );
};

export default Schedulartab;
