import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";
import ScheduledChannelContext from "../../ScheduledChannelContext";
import { getTimeFromSeconds } from "../../../utils/Utils";
import { Button } from "reactstrap";
import { Icon } from "../../../components/Component";
import Swal from "sweetalert2";

const ItemType = "DRAGGABLE_ITEM";

BoxWithHandle.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    channel_id: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    sinceTime: PropTypes.string.isRequired,
    file_path: PropTypes.string.isRequired,
  }).isRequired,
  removeFromScheduler: PropTypes.func.isRequired,
};

function formatTime(date) {
  return date.toTimeString().split(" ")[0];
}

function addSecondsToTime(time, seconds) {
  const date = new Date(time);
  date.setSeconds(date.getSeconds() + seconds);
  return date;
}

export function BoxWithHandle({ data, sinceTime, removeFromScheduler }) {
  const { id, title, image, duration, file_path, user_id, program_type, playback_path, video_id } = data;
  const [till, setTill] = useState("");
  const [newTitle, setNewTitle] = useState("");
  useEffect(() => {
    setNewTitle(title.slice(0, 50));
  }, [title]);

  useEffect(() => {
    if (sinceTime) {
      const sinceDate = new Date(sinceTime);
      const tillDate = addSecondsToTime(sinceDate, duration);
      setTill(formatTime(tillDate));
    }
  }, [sinceTime, duration]);

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: ItemType,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    item: { id },
  }));
  const seconds = duration;
  const { hours, minutes, remainingSeconds } = getTimeFromSeconds(seconds);

  const { selectedId } = useContext(ScheduledChannelContext);

  const handleRemoveClick = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove video from scheduler?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeFromScheduler(id);
      }
    });
  };

  return (
    <div className="card-bordered position-relative mt-2 product-card col-md-3 border-0 shadow-sm card scheduler-card">
      <div ref={preview}>
        <div
          className="product-thumb"
          style={{ height: "100px" }}
          ref={drag}
          id={id}
          onDragStart={(e) => e.dataTransfer.setData("text/plain", id)}
          data-title={newTitle}
          data-image={image}
          data-channel_id={selectedId}
          data-duration={duration}
          data-file_path={file_path}
          data-user_id={user_id}
          data-program_type={program_type || "programad"}
          data-playback_path={playback_path}
          // data-till = {till}
        >
          <a>
            <img src={image} alt="video thumbnail" />
          </a>
          <div className="overlay">
            <Button
              className="btn-icon trashbutton"
              color="danger"
              size="sm"
              onClick={() => handleRemoveClick(video_id)}
            >
              <Icon name="trash" />
            </Button>
            <Button className="btn-round btn-icon" color="gray" size="sm">
              <Icon name="move" />
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between p-1">
        <p
          className="mb-0 fs-12px"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "24ch",
          }}
        >
          {newTitle}
        </p>
        <p className="fs-12px mb-0" style={{ whiteSpace: "nowrap" }}>
          {hours}h {minutes}m {remainingSeconds}s
        </p>
      </div>
      <div className="p-1" />
    </div>
  );
}

export default BoxWithHandle;
