import { useDrop } from "react-dnd";

const ItemType = "DRAGGABLE_ITEM";

export const DroppableArea = ({ removeElement, children }) => {
  const [, drop] = useDrop({
    accept: ItemType,
    drop: (item) => removeElement(item.id),
  });

  return (
    <div
      ref={drop}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};
