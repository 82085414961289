import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Icon, PreviewCard, ReactDataTable } from "../../components/Component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import New from "./New";
import { apiRequest } from "../../utils/apiHelper";

import { ShimmerThumbnail } from "react-shimmer-effects";

const Website = () => {
  const [websites, setWebsites] = useState([]);
  const navigate = useNavigate();
  const [refreshKey, setRefreshKey] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchWebsites();
  }, []);

  const fetchWebsites = async () => {
    try {
      const response = await apiRequest("get-website", "POST", null, null);
      if (response.data && response.data.data && Array.isArray(response.data.data.website_data)) {
        setWebsites(response.data.data.website_data);
      } else {
        setWebsites([]);
      }
    } catch (error) {
      console.error("Error fetching websites:", error);
      toast.error("Failed to fetch websites. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteWebsite = async (websiteId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this website!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const authToken = getCookie("authToken", null);
        const formData = new FormData();
        formData.append("website_id", websiteId);

        const response = await apiRequest("delete-website", "POST", formData, "multipart/form-data");
        if (response.data && response.data.type === "success") {
          toast.success(response.data.message);
          setWebsites((prevWebsites) => prevWebsites.filter((website) => website.id !== websiteId));
          setRefreshKey((prevKey) => prevKey + 1);
        } else {
          console.error("Failed to delete website:", response.data.message);
          toast.error("Failed to delete website. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error deleting website:", error);
      toast.error("Failed to delete website. Please try again.");
    }
  };

  const handleCreateWebsite = () => {
    navigate("/websites/new");
  };

  const URLCell = ({ row }) => {
    const textRef = useRef(null);

    const copyToClipboard = () => {
      const text = textRef.current;
      navigator.clipboard
        .writeText(text.innerText)
        .then(() => {
          text.focus();
          const range = document.createRange();
          range.selectNodeContents(text);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    };

    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <p
          ref={textRef}
          onClick={() => window.open(row.domain, "_blank")}
          title="Go to website"
          style={{ cursor: "pointer", margin: 0 }}
        >
          {row.domain}
        </p>

        <Icon
          name="copy"
          onClick={copyToClipboard}
          title="Copy to clipboard"
          style={{ cursor: "pointer", marginInline: "30px 20px", fontSize: "1.3rem" }}
        />
      </div>
    );
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title || "",
      sortable: true,
    },
    {
      name: "Channels",
      selector: (row) => row.channels.map((channel) => channel.channel_name).join(", "),
      sortable: true,
    },
    {
      name: "URL",
      cell: (row) => <URLCell row={row} />,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "stretch", gap: "25px", fontSize: "1.1rem" }}>
          <div className="icon-link-container" style={{ cursor: "pointer" }}>
            <Link to={`website-edit/${row.domain_id}`} element={<New />} className="website-edit-icon">
              <Icon name="edit" />
            </Link>
          </div>
          <Icon
            name="trash-fill"
            onClick={() => handleDeleteWebsite(row.id)}
            style={{ cursor: "pointer", color: "#e14954" }}
          />
          <a href={row.domain} target="_blank" title="Go to website" style={{ color: "#008dd2", cursor: "pointer" }}>
            <Icon name="arrow-up-fill-c" />
          </a>
        </div>
      ),
    },
  ];

  return (
    <>
      <section className="m-3">
        <div className="container-fluid">
          <h5>Amplify</h5>
          <Button color="primary" onClick={handleCreateWebsite} className="my-2">
            <Icon name="plus" />
            <span>Create Host Network</span>
          </Button>
          <div className="bg-white mt-3 p-2">
            {isLoading ? (
              <PreviewCard>
                <ShimmerThumbnail height={40} rounded />
                <ShimmerThumbnail height={40} rounded />
              </PreviewCard>
            ) : (
              <PreviewCard>
                {websites.length > 0 ? (
                  <ReactDataTable data={websites} key={refreshKey} columns={columns} expandableRows pagination />
                ) : (
                  "No websites found. Create a website to explore more."
                )}
              </PreviewCard>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Website;
