// ShareButtons.jsx
import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../components/Component";

const ShareButtons = ({ videoUrl }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const shareOnFacebook = () => {
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(videoUrl), "_blank");
  };

  const shareOnTwitter = () => {
    window.open("https://twitter.com/intent/tweet?url=" + encodeURIComponent(videoUrl), "_blank");
  };

  const shareOnLinkedIn = () => {
    window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(videoUrl), "_blank");
  };

  return (
    <div className="">
      <Dropdown isOpen={dropdownOpen} className="share-buttons" toggle={toggle}>
        <DropdownToggle className="btn-action p-0" color="text-body">
          <Icon name="share"></Icon>
        </DropdownToggle>
        <DropdownMenu>
          <ul className="link-list-opt">
            <li>
              <DropdownItem tag="a" href="#links" className="" onClick={shareOnFacebook}>
                <span>Facebook</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem tag="a" href="#links" className="" onClick={shareOnTwitter}>
                <span>Twitter</span>
              </DropdownItem>
            </li>
            <li>
              <DropdownItem tag="a" href="#links" className="" onClick={shareOnLinkedIn}>
                <span>LinkedIn</span>
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </Dropdown>

      {/* <button className="share-button btn" onClick={shareOnFacebook}>
        Facebook
      </button>
      <button className="share-button btn" onClick={shareOnTwitter}>
        Twitter
      </button>
      <button className="share-button btn" onClick={shareOnLinkedIn}>
        LinkedIn
      </button> */}
    </div>
  );
};

export default ShareButtons;
