import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Spinner, Tooltip } from "reactstrap";
import { CodeBlock, Icon } from "../../components/Component";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/apiHelper";
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { toast } from "react-toastify";
import "../../css/streamdeck_css/streamdeck.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getCookie } from "../../utils/Utils";
import ReactPlayer from "react-player";
import defaultVideo from "../../assets/default.mp4";
import M3U8Modal from "./M3U8Modal";
import RecordAndUploadModal from "./RecordAndUploadModal";
import UploadFromStorageModal from "./UploadFromStorageModal";
import SchedularChannel from "./SchedularChannel";
import Webcam from "react-webcam";
import Footer from "../channel/Footer";
import StreamScheduler from "../Scheduler/StreamScheduler";
import MediaStreamRecorder from "msr";
import "react-calendar/dist/Calendar.css";
import DatePicker from "../CalenderComponent/DatePicker";
import VideoModal from "./VideoModal";
import ScheduledChannelContext from "../ScheduledChannelContext";
import { useApp } from "../Scheduler/useApp";
import WebcamSettingsContext from "../WebcamSettingsContext";
import { SessionContext } from "../SessionContext";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import debounce from "lodash.debounce";
import Swal from "sweetalert2";

const ZOOM_INCREMENT = 0.5;
const RESOURCES = [{ id: "a", title: "Channel Schedule" }];
const INITIAL_EVENTS = [{}];
let eventGuid = 0;
let todayStr = new Date().toISOString().slice(0, 10);

function createEventId() {
  return String(eventGuid++);
}
const FCalender = () => {
  // const [isLoading, setIsLoading] = useState(false);

  const [planEpg, setPlanEpg] = useState([]);
  const [newSince, setNewSince] = useState(null);

  const [deletes, setDeletes] = useState(false);
  const [swap, setswap] = useState(false);
  const [zoomcout, setZoomcout] = useState(7200);
  const [editedData, setEditedData] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [videoStreams, setVideoStreams] = useState([]);
  const [eventsvideoStreams, setVideoStreamsEvents] = useState([]);
  const [duration, setDuration] = useState(null);
  const calendarRef = useRef(null);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const { id } = useParams();
  const [channelName, setChannelName] = useState("");
  const [schedule_duration, setScheduleduration] = useState("");
  const [schedule_day, setScheduleday] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [tagQuery, setTagQuery] = useState([]);
  const [sortingOption, setSortingOption] = useState(null);
  const [SortingOptionMedia, setSortingOptionMedia] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [total_records_count, totalRecordsCount] = useState("");
  const [selectedMatchedTag, setSelectedMatchedTag] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [currentVideo, setCurrentVideo] = useState();
  const [videoIndex, setVideoIndex] = useState(0);
  const [videoPlayout, setVideoPlayout] = useState();
  const [togglePlayer, setTogglePlayer] = useState("single");
  const [startVideo, setStartVideo] = useState();
  const [recording, setRecording] = useState(false);
  const [streamData, setStreamData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [activeWebcam, setActiveWebcam] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [responseUrl, setResponseUrl] = useState(null);
  const [userId, setUserId] = useState(null);
  // const [sessionId, setSessionId] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [showCountdown, setShowCountdown] = useState(false);
  const startTimestamp = useRef(null);
  const stopTimestamp = useRef(null);
  const timeoutRef = useRef(null);
  const hlsRefFromChild = useRef(null);
  const playerRef = useRef(null);
  const timeDifferenceRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [streamnew, setnewstrem] = useState(null);
  const { selectedId, schedulerData, resetSchedulerData } = useContext(ScheduledChannelContext);
  const audioContextRef = useRef(null);
  const gainNodeRef = useRef(null);
  const [stopRecording, setStopRecording] = useState(false);
  const [linkduration, setlinkDuration] = useState("");
  const { sessionId, updateSessionId } = useContext(SessionContext);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [epgJson1, setepgJson] = useState([]);
  const [devices, setDevices] = useState([]);
  const [reset, setReset] = useState(false);
  const {
    volume,
    setVolume,
    echoCancellation,
    setEchoCancellation,
    noiseSuppression,
    setNoiseSuppression,
    autoGainControl,
    setAutoGainControl,
    videoWidth,
    setVideoWidth,
    videoHeight,
    setVideoHeight,
    facingMode,
    setFacingMode,
    aspectRatio,
    setAspectRatio,
  } = useContext(WebcamSettingsContext);

  const [saveLoading, setSaveLoading] = useState(false);

  const [recentVideo, setRecentVideo] = useState(null);
  const [recentVideoLoading, setRecentVideoLoading] = useState(true);

  const [swapEdit, setSwapEdit] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [epgJson, setEpgJson] = useState([]);

  const [savedEpg, setSavedEpg] = useState([]);

  const [toDelete, setToDelete] = useState([]);

  useEffect(() => {
    // console.log("changed vdo:", recentVideo);
    setRecentVideoLoading(true);
  }, [recentVideo]);

  // const { elements, getEpgProps, getLayoutProps, removeElement, sinceTime } = useApp(
  //   selectedDate,
  //   deletes,
  //   setDeletes,
  //   swap,
  //   setswap,
  //   zoomcout,
  //   editedData,
  //   setEditedData,
  //   // reset,
  //   // setReset,
  //   saveLoading,
  //   planEpg,
  //   newSince,
  //   setNewSince,
  //   setRecentVideo,
  //   swapEdit
  // );

  useEffect(() => {
    // console.log(epgJson, planEpg);
    // if (planEpg?.length > 0) setIsBroadcastEnabled(true);
    // else setIsBroadcastEnabled(false);
    if (planEpg?.length > 0 && !recentVideo) setRecentVideo(planEpg[0].playback_path);
    else if (recentVideo) setRecentVideo(null);
  }, [planEpg]);

  const [stopBroadcastLoading, setStopBroadcastLoading] = useState(false);
  const [broadcastLoading, setBroadcastLoading] = useState(false);
  const [isBroadcastEnabled, setIsBroadcastEnabled] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const handleDevices = useCallback((mediaDevices) => {
    const videoDevices = mediaDevices.filter(
      ({ kind, label }) => kind === "videoinput" && !label.includes("OBS Virtual Camera")
    );
    const uniqueDevices = [];
    const seenLabels = new Set();
    for (const device of videoDevices) {
      if (!seenLabels.has(device.label)) {
        seenLabels.add(device.label);
        uniqueDevices.push(device);
      }
    }
    setDevices(uniqueDevices);
    setActiveWebcam(uniqueDevices[0]?.deviceId);
  }, []);

  const handleDeviceChange = (deviceId) => {
    setSelectedDeviceId(deviceId);
    setActiveWebcam(deviceId);
    // stopCurrentStream(deviceId);
  };

  const truncateLabel = (label, maxLength = 6) => {
    if (label.length <= maxLength) return label;
    return label.slice(0, maxLength) + "...";
  };

  const switchCamera = () => {
    const currentDeviceIndex = devices.findIndex((device) => device.deviceId === selectedDeviceId);
    const nextDeviceIndex = (currentDeviceIndex + 1) % devices.length;
    setSelectedDeviceId(devices[nextDeviceIndex].deviceId);
    setActiveWebcam(devices[nextDeviceIndex].deviceId);
    // stopCurrentStream(devices[nextDeviceIndex].deviceI);
  };
  const handleProgramClick = () => {
    if (selectedDeviceId && devices.length > 0) {
      setTimeout(() => {
        startTimestamp.current = new Date();
        handleStartRecording(selectedDeviceId);
      }, "1500");
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("newSinceTime");
    setSavedEpg([]);
  }, [selectedId]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);
  useEffect(() => {
    console.log("activeWebcam", activeWebcam);
  }, [activeWebcam]);

  // useEffect(() => {
  //   console.log("schedulerData", schedulerData);
  // }, [schedulerData]);
  const setPrograms = async () => {
    setSaveLoading(true);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}delete-programs`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: JSON.stringify(toDelete),
      });
    } catch (error) {
      console.log(error);
    }

    const promise = fetch(`${process.env.REACT_APP_API_URL}set-programs-channels`, {
      method: "POST",
      headers: {
        authToken: getCookie("authToken", null),
      },
      // body: JSON.stringify(schedulerData),
      body: JSON.stringify(planEpg),
    }).then(async (response) => {
      const responseData = await response.json();
      if (response.status) {
        setIsBroadcastEnabled(true);
        return responseData;
      } else {
        throw new Error("Failed to add program schedule");
      }
    });

    toast.promise(promise, {
      pending: "Adding program schedule...",
      success: "Program schedule added successfully",
      error: "Failed to add program schedule",
    });

    try {
      await promise;
    } catch (error) {
      console.error(error);
    } finally {
      setSaveLoading(false);
    }
  };

  const randomString = () => {
    return (~~(Math.random() * Math.pow(32, 6))).toString(32);
  };
  const [name, setName] = useState(randomString());
  const DT = 10000;
  const serverLink = process.env.REACT_APP_EXPRESS_URL;
  let count = 0;
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggle1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggle2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleResetShedular = () => {
    // resetSchedulerData();
    setReset(true);
  };

  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);

  const DropDowntoggle = () => {
    setDropDownIsOpen(!dropDownIsOpen);
  };

  function removeBlobPrefix(blobUrl) {
    console.log(blobUrl);
    // const url = blobUrl.slice(5);
    return blobUrl;
  }

  // useEffect(() => {
  //   fetchChannelData();
  //   console.log("userID", userId);
  // }, [id, schedule_duration, userId]);

  // useEffect(() => {
  //   fetchVideoStreams();
  // }, [page, sortingOption, selectedMatchedTag]);

  // useEffect(() => {
  //   setPage(1);
  //   fetchVideoStreams();
  // }, [searchQuery, tagQuery]);

  const fetchChannelData = async () => {
    try {
      const response = await apiRequest("get-channel", "POST", { channel_id: id });

      if (response.data && response.data.data && response.data.data.channel) {
        const { channel_name, schedule_duration } = response.data.data.channel;
        setUserId(response.data.data.channel[0].user_id);
        setChannelName(channel_name);

        setScheduleduration(schedule_duration);
      }
    } catch (error) {
      console.error("Error fetching channel data", error);
    }
  };

  const fetchVideoStreams = async () => {
    var daysToAdd;
    try {
      // setIsLoading(true);
      const formData = new FormData();
      formData.append("page", page);
      const mapSortingMediaOptionToBackendValue = (frontendValue) => {
        switch (frontendValue) {
          case "Hosted Videos":
            return 1;
          case "External Links":
            return 1;
          default:
            return "default";
        }
      };
      const mapSortingOptionToBackendValue = (frontendValue) => {
        switch (frontendValue) {
          case "Newest first":
            return "newest";
          case "Oldest first":
            return "oldest";
          case "A → Z":
            return "az";
          case "Z → A":
            return "za";
          case "Shortest first":
            return "shortest";
          case "Longest first":
            return "longest";
          default:
            return "newest";
        }
      };
      if (SortingOptionMedia) {
        switch (SortingOptionMedia.value) {
          case "Hosted Videos":
            formData.append("hosted_video", 1);
            break;
          case "External Links":
            formData.append("external_link", 1);
            break;
          default:
            break;
        }
      }
      var requestBody;

      if (selectedMatchedTag && selectedMatchedTag.value === "Match all tags" && tagQuery.length > 0) {
        console.log("selectedMatchedTag", selectedMatchedTag);
        requestBody = JSON.stringify({ tags: tagQuery, alltags: 1, page: page });
      } else if (tagQuery.length > 0) {
        requestBody = JSON.stringify({ tags: tagQuery, page: page });
      } else {
        if (searchQuery.trim() !== "") {
          formData.append("title", searchQuery);
        }
        if (sortingOption !== null) {
          formData.append("order_by", mapSortingOptionToBackendValue(sortingOption.value));
        }
        if (SortingOptionMedia !== null) {
          if (SortingOptionMedia.value === "External Links") {
            formData.append("external_link", mapSortingMediaOptionToBackendValue(SortingOptionMedia.value));
          }
          if (SortingOptionMedia.value === "Hosted Videos") {
            formData.append("hosted_video", mapSortingMediaOptionToBackendValue(SortingOptionMedia.value));
          }
        }
        requestBody = formData;
      }
      const response = await apiRequest("get-all-video", "POST", requestBody, "application/json");

      if (!response.data.status && page === 1) {
        setVideoStreams([]);
        setHasMore(false);
        return;
      }
      if (response.data.data.videostreams) {
        totalRecordsCount(response.data.data.total_records_count);
        if (page === 1) {
          setVideoStreams(response.data.data.videostreams);
          setHasMore(false);
        } else {
          setVideoStreams((prevStreams) => [...prevStreams, ...response.data.data.videostreams]);
        }
        setPage(page + 1);
        if (response.data.data.videostreams.length < 10) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
      const scheduleResponse = await apiRequest("get-schedule-channel", "POST", { channel_id: id });
      if (scheduleResponse.data && scheduleResponse.data.data && scheduleResponse.data.data.videos_data) {
        const scheduleData = scheduleResponse.data.data.videos_data;
        setStartVideo(scheduleData[0].id);
        console.log("scheduleDatascheduleData", scheduleData);
        const events = scheduleData.map((video) => {
          const totalSeconds = parseInt(video.duration, 10);
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;

          const durationFormatted = `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
          let startTime;
          let endTime;

          if (schedule_duration === "1") {
            const calendarApi = calendarRef.current.getApi();
            if (calendarApi) {
              const today = calendarApi.getDate();
              const dayOfWeek = parseInt(video.day, 10) + 1;
              daysToAdd = dayOfWeek - today.getDay();
              console.log("daysToAdd", daysToAdd);
              const targetDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
              startTime = new Date(`${targetDate.toISOString().substr(0, 10)}T${video.start_time}`);
              endTime = new Date(startTime.getTime() + totalSeconds * 1000);
              console.log("startTime", startTime);
              console.log("endTime", endTime);
            } else {
              console.error("Calendar ref is not initialized");
              toast.error("Calendar ref is not initialized");
              return null;
            }
          } else {
            startTime = new Date(`${todayStr} ${video.start_time}`);
            endTime = new Date(startTime.getTime() + totalSeconds * 1000);
          }
          return {
            id: video.scheduler_id,
            title: durationFormatted,
            start: startTime,
            end: endTime,
            resourceId: "a",
            videoid: video.id,
            thumbnail_base64: video.thumbnail_base64,
          };
        });
        setVideoStreamsEvents(events);
      }
    } catch (error) {
      console.error("Error fetching video streams and schedule data:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleDurationClick = async (duration, videoid, thumbnail_base64) => {
    setDuration(duration);
    if (duration === null) {
      console.error("Duration is null.");
      toast.error("Duration is null.");
      return;
    }

    const videoLengthInSeconds = duration;
    const videoLengthInMilliseconds = videoLengthInSeconds * 1000;
    const calendarApi = calendarRef.current.getApi();
    let startTime; // Define startTime here
    try {
      if (calendarApi) {
        const selectedDate = calendarApi.getDate();
        const existingEvents = calendarApi.getEvents();

        // Filter events based on the selected date
        const eventsOfDay = existingEvents.filter((event) => {
          const eventStartTime = event.start;
          const eventDate = new Date(eventStartTime.getTime());
          // Compare the year, month, and day of the event start date with the selected date
          return (
            eventDate.getFullYear() === selectedDate.getFullYear() &&
            eventDate.getMonth() === selectedDate.getMonth() &&
            eventDate.getDate() === selectedDate.getDate()
          );
        });

        // Get the start time for the new event
        let calculatedStartTime;
        if (eventsOfDay.length > 0) {
          const lastEvent = eventsOfDay[eventsOfDay.length - 1];
          const lastEventEndTime = lastEvent.end;
          calculatedStartTime = new Date(lastEventEndTime.getTime() + 60 * 60 * 1000);
        } else {
          calculatedStartTime = new Date(selectedDate.getTime());
          calculatedStartTime.setHours(0, 0, 0, 0);
        }

        startTime = calculatedStartTime; // Assign calculatedStartTime to startTime

        const endTime = new Date(startTime.getTime() + videoLengthInMilliseconds);

        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
          console.error("Invalid start time or end time");
          return;
        }

        const formData = new FormData();

        const startHours = String(startTime.getHours()).padStart(2, "0");
        const startMinutes = String(startTime.getMinutes()).padStart(2, "0");
        const startSeconds = String(startTime.getSeconds()).padStart(2, "0");
        const startTimeString = `${startHours}:${startMinutes}:${startSeconds}`;

        const endHours = String(endTime.getHours()).padStart(2, "0");
        const endMinutes = String(endTime.getMinutes()).padStart(2, "0");
        const endSeconds = String(endTime.getSeconds()).padStart(2, "0");
        const endTimeString = `${endHours}:${endMinutes}:${endSeconds}`;

        console.log(startTimeString);
        console.log(endTimeString);

        formData.append("start_time", startTimeString);
        formData.append("video_id", videoid);
        formData.append("channel_id", id);
        formData.append("end_time", endTimeString);

        if (schedule_duration === "1") {
          const selectedDay = startTime.getDay();
          formData.append("day", selectedDay - 1);
        }

        const response = await apiRequest("schedule-channel", "POST", formData, "application/json");
        if (!response.data) {
          throw new Error("Failed to send event data. Server response does not contain event data.");
        }
        const data = response.data;
        const newEvent = {
          id: data.data.id,
          title: endTimeString,
          start: startTime,
          end: endTime,
          resourceId: "a",
          videoid: videoid,
          thumbnail_base64: thumbnail_base64,
        };

        toast.success("Event data sent successfully.");
        setVideoStreamsEvents((prevStreams) => [...prevStreams, newEvent]);
        calendarApi.scrollToTime(startTime);
      } else {
        console.error("Calendar ref is not initialized");
        toast.error("Calendar ref is not initialized");
      }
    } catch (error) {
      console.error("Error sending event data:", error.message);
      toast.error("Error sending event data:", error.message);
    }
  };

  const handleDragEnd = async (duration, videoid, thumbnail_base64) => {
    if (duration === null) {
      console.error("Duration is null.");
      toast.error("Duration is null.");
      return;
    }
    try {
      const formData = new FormData();
      const videoLengthInSeconds = duration;
      const videoLengthInMilliseconds = videoLengthInSeconds * 1000;
      const calendarApi = calendarRef.current.getApi();

      if (calendarApi) {
        const selectedDate = calendarApi.getDate();
        const existingEvents = calendarApi.getEvents();

        const eventsOfDay = existingEvents.filter((event) => {
          const eventStartTime = event.start;
          const eventDate = new Date(eventStartTime.getTime());
          return (
            eventDate.getFullYear() === selectedDate.getFullYear() &&
            eventDate.getMonth() === selectedDate.getMonth() &&
            eventDate.getDate() === selectedDate.getDate()
          );
        });

        let startTime;
        if (eventsOfDay.length > 0) {
          const lastEvent = eventsOfDay[eventsOfDay.length - 1];
          const lastEventEndTime = lastEvent.end;
          startTime = new Date(lastEventEndTime.getTime() + 60 * 60 * 1000);
        } else {
          startTime = new Date(selectedDate.getTime());
          startTime.setHours(0, 0, 0, 0);
        }

        const endTime = new Date(startTime.getTime() + videoLengthInMilliseconds);

        console.log(startTime);
        console.log(endTime);

        if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
          console.error("Invalid start time or end time");
          return;
        }

        const startHours = String(startTime.getHours()).padStart(2, "0");
        const startMinutes = String(startTime.getMinutes()).padStart(2, "0");
        const startSeconds = String(startTime.getSeconds()).padStart(2, "0");
        const startTimeString = `${startHours}:${startMinutes}:${startSeconds}`;

        const endHours = String(endTime.getHours()).padStart(2, "0");
        const endMinutes = String(endTime.getMinutes()).padStart(2, "0");
        const endSeconds = String(endTime.getSeconds()).padStart(2, "0");
        const endTimeString = `${endHours}:${endMinutes}:${endSeconds}`;

        formData.append("start_time", startTimeString);
        formData.append("video_id", videoid);
        formData.append("channel_id", id);
        formData.append("end_time", endTimeString);

        if (schedule_duration === "1") {
          const selectedDay = startTime.getDay();
          formData.append("day", selectedDay - 1);
        }

        const response = await apiRequest("schedule-channel", "POST", formData, "application/json");
        if (!response.data) {
          throw new Error("Failed to send event data. Server response does not contain event data.");
        }

        const data = response.data;
        const newEvent = {
          id: data.data.id,
          title: endTimeString,
          start: startTime,
          end: endTime,
          resourceId: "a",
          videoid: videoid,
          thumbnail_base64: thumbnail_base64,
        };

        toast.success("Event data sent successfully.");
        setVideoStreamsEvents((prevStreams) => [...prevStreams, newEvent]);
        calendarApi.scrollToTime(startTime);
      } else {
        console.error("Calendar ref is not initialized");
        toast.error("Calendar ref is not initialized");
      }
    } catch (error) {
      console.error("Error sending event data:", error.message);
      toast.error("Error sending event data:", error.message);
    }
  };

  const handleEventMouseEnter = (arg) => {
    const content = arg.event.title;
    const position = {
      top: arg.jsEvent.clientY,
      left: arg.jsEvent.clientX,
    };
    setTooltipContent(content);
    setTooltipPosition(position);
    setTooltipVisible(true);

    const detailsElement = arg.el.getElementsByClassName("event-details")[0];
    if (detailsElement) {
      detailsElement.classList.remove("hidden");
    }
  };

  const handleEventMouseLeave = () => {
    setTooltipVisible(false);

    const detailsElements = document.getElementsByClassName("event-details");
    for (let i = 0; i < detailsElements.length; i++) {
      detailsElements[i].classList.add("hidden");
    }
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const handleEventDrop = async (dropInfo) => {
    const draggedEvent = dropInfo.event;

    try {
      const formData = new FormData();
      const startHours = String(draggedEvent.start.getHours()).padStart(2, "0");
      const startMinutes = String(draggedEvent.start.getMinutes()).padStart(2, "0");
      const startSeconds = String(draggedEvent.start.getSeconds()).padStart(2, "0");
      const startTime = `${startHours}:${startMinutes}:${startSeconds}`;
      const endHours = String(draggedEvent.end.getHours()).padStart(2, "0");
      const endMinutes = String(draggedEvent.end.getMinutes()).padStart(2, "0");
      const endSeconds = String(draggedEvent.end.getSeconds()).padStart(2, "0");
      const endTime = `${endHours}:${endMinutes}:${endSeconds}`;
      formData.append("start_time", startTime);
      formData.append("end_time", endTime);
      formData.append("schedule_id", draggedEvent.id);
      formData.append("channel_id", id);

      if (schedule_duration === "1") {
        const startTimeDate = new Date(draggedEvent.start);
        const selectedDay = startTimeDate.getDay();
        formData.append("day", selectedDay - 1);
      }

      const response = await apiRequest("update-schedule-channel", "POST", formData, "multipart/form-data");

      if (response.data.type !== "success") {
        toast.error("Failed to update event data.");
        throw new Error("Failed to update event data.");
      }

      toast.success("Event data updated successfully.");
    } catch (error) {
      console.error("Error updating event data:", error.message);
      toast.error("Error updating event data:", error.message);
    }
  };

  const handleEventPositioned = (eventPositionedInfo) => {};

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + ZOOM_INCREMENT);
  };

  const renderEventContent = (eventContent) => {
    const event = eventContent.event;
    const timeText = eventContent.timeText;

    console.log("eventContent", eventContent);
    // Format start and end time if available
    const startTime = event.start ? event.start.toLocaleTimeString() : "N/A";
    const endTime = event.end ? event.end.toLocaleTimeString() : "N/A";
    const thumbnailBase64 = event.extendedProps.thumbnail_base64;

    const divStyle = {
      backgroundImage: thumbnailBase64 ? `url('data:image/png;base64,${thumbnailBase64}')` : "none",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "20px",
      // Add padding for better visibility of content
    };
    const color = {
      // color: "red",
      color: "white",
    };
    return (
      <div>
        <div>
          <b>{timeText}</b>
          <i>{event.title}</i>
        </div>
        <div className="event-details" style={divStyle}>
          <p style={color}>Start Time: {startTime}</p>
          <p style={color}>End Time: {endTime}</p>
        </div>
      </div>
    );
  };

  const handleRemoveAllEvents = async () => {
    const calendarApi = calendarRef.current.getApi();
    const allEvents = calendarApi.getEvents();
    if (allEvents.length === 0) {
      toast.info("There are no events to delete.");
      return;
    }
    const schedularIds = allEvents
      .map((event) => {
        if (event.id) {
          return parseInt(event.id);
        } else {
          return null;
        }
      })
      .filter((id) => id !== null);

    try {
      const schedularIdsString = JSON.stringify(schedularIds);
      const response = await apiRequest("delete-schedule-channel", "POST", { schedular_ids: schedularIdsString });

      if (response.data.type !== "success") {
        throw new Error("Failed to delete event data.");
      }
      toast.success("All event data deleted successfully.");
      window.location.reload();
      calendarApi.removeAllEvents();
    } catch (error) {
      console.error("Error deleting event data:", error.message);
      toast.error("Failed to delete event data.");
    }
  };

  const handleEventsClick = (clickInfo) => {
    const eventId = clickInfo.event.id;
    const isSelected = selectedEventIds.includes(eventId);
    if (isSelected) {
      const updatedSelectedEventIds = selectedEventIds.filter((id) => id !== eventId);
      setSelectedEventIds(updatedSelectedEventIds);

      clickInfo.event.setProp("backgroundColor", "");
    } else {
      setSelectedEventIds([...selectedEventIds, eventId]);
      setCurrentVideo(clickInfo.event.extendedProps.videoid);
      clickInfo.event.setProp("backgroundColor", "red");
    }
  };

  const handleSelectAllEvents = async () => {
    try {
      const schedularIdsString = JSON.stringify(selectedEventIds);
      if (selectedEventIds.length === 0) {
        toast.info("No events selected for deletion.");
        return;
      }

      const response = await apiRequest("delete-schedule-channel", "POST", { schedular_ids: schedularIdsString });
      if (response.data.type !== "success") {
        throw new Error("Failed to delete event data.");
      }
      window.location.reload();
      toast.success("All event data deleted successfully.");
      setSelectedEventIds([]);
    } catch (error) {
      console.error("Error deleting event data:", error.message);
      toast.error("Failed to delete event data.");
    }
  };

  const RemoveBlankSpace = async () => {
    try {
      const formData = new FormData();
      formData.append("channel_id", id);

      const calendarApi = calendarRef.current.getApi();
      if (!calendarApi) {
        console.error("Calendar ref is not initialized");
        toast.error("Calendar ref is not initialized");
        return;
      }

      // Check if no events exist on the selected date
      const selectedDate = calendarApi.getDate();
      const eventsOfDay = calendarApi.getEvents().filter((event) => {
        const eventStartTime = event.start;
        const eventDate = new Date(eventStartTime.getTime());
        // Compare the year, month, and day of the event start date with the selected date
        return (
          eventDate.getFullYear() === selectedDate.getFullYear() &&
          eventDate.getMonth() === selectedDate.getMonth() &&
          eventDate.getDate() === selectedDate.getDate()
        );
      });

      if (eventsOfDay.length === 0) {
        toast.info("No events on the selected date to remove blank space.");
        return; // Exit the function
      }

      const currentDayOfWeek = selectedDate.getDay();
      if (schedule_duration === "1") {
        formData.append("day", currentDayOfWeek - 1);
      }

      const response = await apiRequest("remove-blank-space", "POST", formData, null);
      if (response.data.type !== "success") {
        throw new Error("Failed to delete event data.");
      }

      window.location.reload();
      toast.success("Removed blank space");
    } catch (error) {
      console.error("Error deleting event data:", error.message);
      toast.error("Failed to Remove blank space.");
    }
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - ZOOM_INCREMENT, 0.5));
  };

  const handleSearchChangefirst = (event) => {
    setPage(1);
    setSearchQuery(event);
  };

  const handleMediaTypes = (media) => {
    // setPage(1);
  };
  const handleSortingChange = (selectedOption) => {
    setPage(1);
    setSortingOption(selectedOption);
  };
  const handleSearchChangeTagQuery = (selectedOption) => {
    setPage(1);
    setTagQuery(selectedOption);
  };

  const handleMediaTypeChange = (selectedOption) => {
    setPage(1);
    setSortingOptionMedia(selectedOption);
  };
  const handleMatchedTag = (MatchedTag) => {
    console.log("MatchedTag", MatchedTag);
    setPage(1);
    setSelectedMatchedTag(MatchedTag);
  };

  // useEffect(() => {
  //   fetchVideoUrl(currentVideo);
  // }, [currentVideo]);

  useEffect(() => {
    if (videoStreams.length > 0) setCurrentVideo(videoStreams[videoStreams.length - 1]?.id);
  }, []);

  useEffect(() => {
    if (eventsvideoStreams.length > 0) {
      fetchVideoUrlWithoutID();
    }
  }, [videoIndex, eventsvideoStreams, videoStreams]);

  const fetchVideoUrl = async (id) => {
    try {
      const authToken = getCookie("authToken", null);
      let video_id = id;
      const response = await apiRequest("get-video", "POST", { video_id }, "application/json");
      if (response.data.data) {
        setVideoUrl(response.data.data.video_url);
      } else {
        const blobResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}get-video`,
          { video_id },
          {
            headers: {
              authToken: authToken,
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([blobResponse.data], {
          type: blobResponse.headers["content-type"],
        });
        const blobUrl = URL.createObjectURL(blob);
        setVideoUrl(blobUrl);
      }
    } catch (error) {
      console.error("Error fetching video URL", error);
    }
  };

  const fetchVideoUrlWithoutID = async () => {
    try {
      const authToken = getCookie("authToken", null);
      let video_id = eventsvideoStreams[videoIndex].videoid;
      const response = await apiRequest("get-video", "POST", { video_id }, "application/json");
      if (response.data.data) {
        setVideoPlayout(response.data.data.video_url);
      } else {
        const blobResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}get-video`,
          { video_id },
          {
            headers: {
              authToken: authToken,
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([blobResponse.data], {
          type: blobResponse.headers["content-type"],
        });
        const blobUrl = URL.createObjectURL(blob);
        setVideoPlayout(blobUrl);
      }
    } catch (error) {
      console.error("Error fetching video URL", error);
    }
  };

  const zeroPad = (n, chars) => {
    const s = "" + n;
    const l = s.length;
    return new Array(chars - l + 1).join("0") + s;
  };

  const log = (msg) => {
    console.log(msg);
  };

  const send = (url, blob) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.responseType = "text/plain";
    xhr.setRequestHeader("Content-Type", "video/webm");

    xhr.onload = function (e) {
      if (this.status === 200) {
        console.log(this.response);
        const responseData = JSON.parse(this.response);
        console.log(responseData.filePath);
        setResponseUrl(responseData.filePath);
        setShowCountdown(false);
      }
    };

    xhr.send(blob);
  };

  const handleVolumeChange = (value) => {
    setVolume(value);
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = value;
    }
  };
  const streamRef = useRef(null);
  useEffect(() => {
    if (selectedDeviceId) {
      const videoConstraints = {
        deviceId: { exact: selectedDeviceId },
        // width: { exact: videoWidth },
        // height: { exact: videoHeight },
        frameRate: { ideal: 30 }, // Assuming 30fps as a standard
        facingMode: facingMode,
        aspectRatio: { ideal: aspectRatio }, // 16:9 aspect ratio
        resizeMode: "crop-and-scale",
      };

      const audioConstraints = {
        echoCancellation,
        noiseSuppression,
        autoGainControl,
        sampleRate: 48000, // Standard sample rate for high quality audio
        channelCount: 2, // Stereo audio
      };

      navigator.mediaDevices
        .getUserMedia({ video: videoConstraints, audio: audioConstraints })
        .then((stream) => {
          const videoElement = document.querySelector("video");
          if (videoElement) {
            setnewstrem(stream);
            videoElement.srcObject = streamnew;
            videoElement.width = videoWidth; // Set initial width
            videoElement.height = videoHeight; // Set initial height
          }

          if (videoWidth === 0) {
            toast.warn("Please increase width and height");
            return;
          }

          // Set up audio context and gain node for volume control
          if (!audioContextRef.current) {
            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
          }

          if (!gainNodeRef.current) {
            gainNodeRef.current = audioContextRef.current.createGain();
          }

          gainNodeRef.current.gain.value = volume;
          const audioSource = audioContextRef.current.createMediaStreamSource(stream);
          const destination = audioContextRef.current.createMediaStreamDestination();

          audioSource.connect(gainNodeRef.current).connect(destination);

          // Replace the original audio track with the one connected to the GainNode
          const mixedStream = new MediaStream([...stream.getVideoTracks(), ...destination.stream.getAudioTracks()]);

          videoElement.srcObject = mixedStream;
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }
  }, [
    volume,
    echoCancellation,
    noiseSuppression,
    autoGainControl,
    videoWidth,
    videoHeight,
    facingMode,
    selectedDeviceId,
  ]);

  const stopCurrentStream = (selectedDeviceId) => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
    setStream(null);
    stopTimestamp.current = new Date();
    const diffInMilliseconds = stopTimestamp.current - startTimestamp.current;
    timeDifferenceRef.current = diffInMilliseconds;
    setTimeout(() => {
      console.log("ppppppppppppppppppppppppppppppppppppppppppppppppppppp", selectedDeviceId);
      startTimestamp.current = new Date();
      handleStartRecording(selectedDeviceId);
    }, diffInMilliseconds);
  };
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
          track.enabled = false;
        });
      }
    };
  }, [stream]);

  const handleStartRecording = async (selectedDeviceId) => {
    if (selectedDeviceId) {
      console.log("startTimestamp.current.value", startTimestamp.current);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowCountdown(true);
      setCountdown(15);
      startCountdown();
      const videoConstraints = {
        deviceId: selectedDeviceId,
        width: { exact: videoWidth },
        height: { exact: videoHeight },
        facingMode: facingMode,
        aspectRatio: { ideal: aspectRatio },
      };

      if (videoWidth === 0) {
        toast.warn("Please increase width");
        return;
      }

      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: videoConstraints,
          audio: { echoCancellation, noiseSuppression, autoGainControl },
        });

        // Set width and height of the video element
        videoRef.current.srcObject = mediaStream;
        videoRef.current.width = videoWidth;
        videoRef.current.height = videoHeight;
        setMediaStream(mediaStream);
        setStream(mediaStream);

        streamRef.current = mediaStream;

        const currentDevice = devices.find((device) => device.deviceId === activeWebcam);
        console.log("Currently streaming from:", currentDevice ? currentDevice.label : "Unknown device");

        // Create an AudioContext and GainNode for volume control
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const audioContext = audioContextRef.current;
        gainNodeRef.current = audioContext.createGain();
        gainNodeRef.current.gain.value = volume;
        const source = audioContext.createMediaStreamSource(mediaStream);
        const destination = audioContext.createMediaStreamDestination();
        source.connect(gainNodeRef.current).connect(destination);
        // Use the modified stream for recording
        const mixedStream = new MediaStream([...mediaStream.getVideoTracks(), ...destination.stream.getAudioTracks()]);
        mediaRecorderRef.current = new MediaStreamRecorder(mixedStream);
        mediaRecorderRef.current.mimeType = "video/webm";
        mediaRecorderRef.current.ondataavailable = (blob) => {
          count++;
          const count2 = zeroPad(count, 5);
          log("sending chunk " + name + " #" + count2 + "...");
          send(`${serverLink}chunk/${name}/${count2}`, blob);
        };
        mediaRecorderRef.current.start(DT);
        setRecording(true);
        setName(randomString());
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    }
  };

  const handleStopRecording = () => {
    setStopRecording(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
    }

    stopTimestamp.current = new Date();
    const diffInMilliseconds = stopTimestamp.current - startTimestamp.current;
    timeDifferenceRef.current = diffInMilliseconds;
    console.log("diffInMilliseconds", diffInMilliseconds);
    timeoutRef.current = setTimeout(() => {
      setModalOpen(false);
      setActiveWebcam(null);
      setRecording(false);
      destroyHlsRef();
      destroyplayer();
      setStopRecording(false);
      updateSessionId();
      setSelectedDeviceId(null);
      timeDifferenceRef.current = 0;
    }, diffInMilliseconds);

    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
  };

  const destroyHlsRef = () => {
    if (hlsRefFromChild.current) {
      hlsRefFromChild.current.destroy();
      hlsRefFromChild.current = null;
      console.log("Destroyed hlsRef from ParentComponent");
    }
    setResponseUrl(null);
  };
  const destroyplayer = () => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      if (player) {
        player.pause();
        player.removeAttribute("src");
        player.load();
        console.log("Cleaned up ReactPlayer instance in VideoModal");
      }
    }
  };

  const handleHlsRef = (hlsRef) => {
    hlsRefFromChild.current = hlsRef;
    console.log("Received hlsRef from VideoModal:", hlsRef);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval);
          setShowCountdown(false);
          return null;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const fetchAndSaveSegment = (segmentUrl, sessionId, userId) => {
    fetch(segmentUrl)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        const blob = new Blob([arrayBuffer]);
        const formData = new FormData();
        formData.append("file", blob, "segment.ts");
        formData.append("sessionId", sessionId);
        formData.append("userId", userId);
        const authToken = getCookie("authToken", null);
        const headers = new Headers();
        headers.append("authToken", authToken);

        fetch(`${process.env.REACT_APP_LARAVEL_URL}upload-m3u8-stream`, {
          method: "POST",
          body: formData,
          headers: headers,
        })
          .then((response) => {
            if (response.ok) {
              console.log("Segment uploaded successfully");
            } else {
              console.error("Error uploading segment");
              toast.error("Error uploading segment");
            }
          })
          .catch((error) => {
            console.error("Error uploading segment:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching segment:", error);
      });
  };

  useEffect(() => {
    if (responseUrl) {
      setModalOpen(true);
    }
  }, [modalOpen, responseUrl]);

  useEffect(() => {}, [sessionId]);

  const handleReset = () => {
    setModalOpen(false);
    setActiveWebcam(null);
    setSelectedDeviceId(null);
  };

  const [modalZoom, setModalZoom] = useState(false);
  const toggleZoom = () => setModalZoom(!modalZoom);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setModalZoom(false);
    console.log("Selected Date:", date);
  };
  const handleClose = () => {
    setModalZoom(false);
  };
  const broadcastVideos = async () => {
    const formData = new FormData();
    formData.append("channel_id", selectedId);
    formData.append("date", selectedDate);
    try {
      setBroadcastLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}concate-videos`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      if (responseData.status) {
        setIsBroadcastEnabled(false);
        toast.success("Broadcasting Successful");
        setStreamData(responseData.data);
        calculateDuration(responseData.data);
        checkIfScheduled();
      }
      console.log(responseData);
    } catch (error) {
      console.log(error);
    } finally {
      setBroadcastLoading(false);
    }
  };

  const stopBroadCast = async () => {
    Swal.fire({
      title: "Stop broadcasting this channel?",
      text: "You'll need to reschedule to broadcast again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Stop!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("channel_id", selectedId);
        try {
          setStopBroadcastLoading(true);
          const response = await fetch(`${process.env.REACT_APP_API_URL}set-broadcast-status`, {
            method: "POST",
            headers: {
              authToken: getCookie("authToken", null),
            },
            body: formData,
          });
          const responseData = await response.json();
          if (responseData.status) {
            //
          }
          console.log(responseData);
        } catch (error) {
          console.log(error);
        } finally {
          debouncedCheckIfScheduled();
          setStopBroadcastLoading(false);
        }
      }
    });
  };

  useEffect(() => {}, [streamData]);

  const calculateDuration = (data) => {
    if (data && data.earliest_since && data.latest_till) {
      const startTime = new Date(data.earliest_since);
      const endTime = new Date(data.latest_till);

      const durationMilliseconds = endTime - startTime;
      const durationSeconds = Math.floor((durationMilliseconds / 1000) % 60);
      const durationMinutes = Math.floor((durationMilliseconds / (1000 * 60)) % 60);
      const durationHours = Math.floor((durationMilliseconds / (1000 * 60 * 60)) % 24);

      setlinkDuration(`${durationHours}H ${durationMinutes}M ${durationSeconds}S`);
    } else {
      setlinkDuration("");
    }
  };
  const [modalTop, setModalTop] = useState(false);
  const toggleTop = () => setModalTop(!modalTop);
  const [modalTopPreview, setModalTopPreview] = useState(false);
  const toggleTopPreview = () => setModalTopPreview(!modalTopPreview);
  //check if channek is scheduled today
  const [embededcode, setEmbededcode] = useState(false);
  const [dynamicm3u8, setDynamicm3u8] = useState(false);
  const checkIfScheduled = async () => {
    const formData = new FormData();
    formData.append("channel_id", selectedId);
    formData.append("date", selectedDate);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}is-scheduled`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });
      const responseData = await response.json();
      console.log(responseData);
      const isScheduled = responseData?.data?.isScheduled;
      if (!isScheduled) {
        setEmbededcode(responseData?.data?.output_blob);
        setDynamicm3u8(responseData?.data?.playlistpathLink);
      } else {
        setEmbededcode(false);
        setDynamicm3u8(false);
      }
      // console.log(responseData);
    } catch (error) {
      console.error(error);
      setEmbededcode(false);
      setDynamicm3u8(false);
    }
  };
  const debouncedCheckIfScheduled = debounce(checkIfScheduled, 800);
  useEffect(() => {
    debouncedCheckIfScheduled();
    return () => {
      debouncedCheckIfScheduled.cancel();
    };
  }, [selectedId]);

  const [buttonText, setButtonText] = useState("Copy Link");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(dynamicm3u8)
      .then(() => {
        setButtonText("Copied");
        setTimeout(() => setButtonText("Copy Link"), 2000); // Reset button text after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const toggles = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      {/* <div className="concatelinkprogress">
        <Alert className="alert-pro" color="info">
          <div className="alert-text">
            <h6>Please wait until your m3u8 link came....</h6>
            <p>
              <Progress value="25" />
            </p>
          </div>
        </Alert>
      </div> */}
      {/* m3u8 modal */}
      <Modal size="lg" isOpen={modalTop} toggle={toggleTop} modalClassName="zoom">
        <ModalHeader
          toggle={toggleTop}
          className="justify-content-between"
          close={
            <button className="close" onClick={toggleTop}>
              <Icon name="cross" />
            </button>
          }
        >
          Get .m3u8 link
        </ModalHeader>
        <ModalBody>
          <p className="fw-bold m-0">M3U8 stream link</p>
          <p> Stream your live linear channel in a third-party player, native apps or OTT platforms.</p>
          <div className="form-control-wrap">
            <div className="input-group">
              <input type="text" className="form-control" value={dynamicm3u8} />
              <div className="input-group-append">
                <Button className="bg-danger text-white" id="copym3u8" onClick={handleCopy}>
                  {buttonText}
                </Button>
                <Tooltip placement="top" isOpen={tooltipOpen} target="copym3u8" toggle={toggles}>
                  {buttonText === "Copy Link" ? "Copy" : "Copied"}
                </Tooltip>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={modalTopPreview} toggle={toggleTopPreview} modalClassName="zoom">
        <ModalHeader
          toggle={toggleTopPreview}
          className="justify-content-between"
          close={
            <button className="close" onClick={toggleTopPreview}>
              <Icon name="cross" />
            </button>
          }
        >
          Preview & Embed Channel
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <Col>
              <div>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    // height: "450px",
                    overflow: "hidden",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                >
                  <iframe
                    src={`/embed/${selectedId}`}
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </Col>
            <Col md="12" className="mt-2">
              <span>Code:</span>
              <CodeBlock language="jsx">
                {`<div 
  style="position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
  height: auto;"
> 
  <iframe 
    src="https://streamdeck.silocloud.io/embed/${selectedId}" 
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" 
    frameborder="0" 
    allowfullscreen
  ></iframe> 
</div>`}
              </CodeBlock>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <section className="m-3">
        <div className="container-fluid pb-lg-5">
          <div className="row">
            <div className="col-lg-9">
              <div className="z-3 bg-white p-3">
                <div className="">
                  <div className="col-md-3 d-none">
                    <h5>{channelName}</h5>
                    <Button color="primary" outline className="btn-dim mt-1 " onClick={() => toggle()}>
                      <Icon name="external"></Icon>
                      <span>Get.m3u8 link</span>
                    </Button>
                  </div>
                  <div className="text-end">
                    <Button className="fc-button fc-button-primary ms-1" onClick={handleResetShedular}>
                      Reset
                    </Button>
                    <Button
                      className="fc-button bg-success border-0 text-black fw-bolder ms-1"
                      disabled={saveLoading || broadcastLoading || embededcode}
                      onClick={setPrograms}
                    >
                      Save To Server
                      {saveLoading && <Spinner size="sm" className="ms-2" />}
                    </Button>
                    {embededcode ? (
                      <Button
                        className="fc-button border-0 ms-1 me-lg-3 btn-danger fw-bolder text-nowrap"
                        onClick={() => {
                          stopBroadCast();
                        }}
                        // disabled={stopBroadcastLoading}
                      >
                        Stop Broadcast
                        {stopBroadcastLoading ? (
                          <span>
                            <Spinner size="sm" className="ms-2" />
                          </span>
                        ) : (
                          ""
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="fc-button border-0 ms-1 me-lg-3 broadcast-bg fw-bolder"
                        onClick={broadcastVideos}
                        disabled={!isBroadcastEnabled || broadcastLoading}
                      >
                        Broadcast
                        {broadcastLoading ? (
                          <span>
                            <Spinner size="sm" className="ms-2" />
                          </span>
                        ) : (
                          ""
                        )}
                      </Button>
                    )}
                    {/* <Button
                      onClick={handleZoomIn}
                      className="btn-round btn-icon fc-button-primary bg-transparent border-0"
                      size="md"
                    >
                      <Icon name="plus-circle" />
                    </Button>
                    <Button
                      onClick={handleZoomOut}
                      className="btn-round btn-icon fc-button-primary bg-transparent border-0"
                      size="md"
                    >
                      <Icon name="minus-circle" />
                    </Button> */}
                  </div>
                </div>
                <div className="flex flex-column flex-lg-row justify-content-between player-preview">
                  <div className="w-100 me-lg-2">
                    <div class="video-label mb-1">
                      <span class="bg-secondary-dim">Preview</span>
                    </div>
                    <div className="video-schedule">
                      {/* <WebcamCapture activeWebcam={activeWebcam} setActiveWebcam={setActiveWebcam} /> */}
                      {selectedDeviceId ? (
                        <>
                          <Webcam
                            audio={true}
                            videoConstraints={{ deviceId: selectedDeviceId, facingMode }}
                            screenshotFormat="image/jpeg"
                            ref={videoRef}
                            onUserMedia={(stream) => setMediaStream(stream)}
                          />
                        </>
                      ) : (
                        <div className="w-100 countdown-wrapper position-relative">
                          {recentVideo ? (
                            <>
                              <MediaPlayer
                                aspectRatio="16/9"
                                style={{ height: "100%", display: "flex", justifyContent: "center" }}
                                title=""
                                // src="https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
                                src={recentVideo}
                                autoPlay
                                muted
                                loop
                              >
                                <MediaProvider />
                                <DefaultVideoLayout
                                  // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                                  icons={defaultLayoutIcons}
                                />
                              </MediaPlayer>
                            </>
                          ) : (
                            <>
                              <MediaPlayer
                                aspectRatio="16/9"
                                style={{ height: "100%", display: "flex", justifyContent: "center" }}
                                title=""
                                // src="https://files.vidstack.io/sprite-fight/hls/stream.m3u8"
                                src={defaultVideo}
                                autoPlay
                                muted
                                loop
                              >
                                <MediaProvider />
                                <DefaultVideoLayout
                                  // thumbnails="https://files.vidstack.io/sprite-fight/thumbnails.vtt"
                                  icons={defaultLayoutIcons}
                                />
                              </MediaPlayer>
                            </>
                          )}

                          {/* {recentVideoLoading && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                fontSize: "16px",
                                color: "#fff",
                              }}
                            >
                              <Spinner />
                            </div>
                          )} */}
                          {showCountdown && countdown !== null && <div className="countdown">{countdown}</div>}

                          {/* <video
                            src="sample-video.mp4"
                            className="w-100"
                            controls
                            muted={true}
                            poster={require("../../images/Livestreaming/Placeholder.jpg")}
                          ></video> */}
                        </div>
                      )}

                      <Footer
                        setActiveWebcam={setActiveWebcam}
                        activeWebcam={activeWebcam}
                        startTimestamp={startTimestamp}
                        handleVolumeChange={handleVolumeChange}
                        setResponseUrl={setResponseUrl}
                        handleStartRecording={handleStartRecording}
                        stopCurrentStream={stopCurrentStream}
                        setStream={setStream}
                        responseUrl={responseUrl}
                        stream={stream}
                        streamRef={streamRef}
                        streamnew={streamnew}
                        setnewstrem={setnewstrem}
                        mediaRecorderRef={mediaRecorderRef}
                        devices={devices}
                        selectedId={selectedId}
                        switchCamera={switchCamera}
                        truncateLabel={truncateLabel}
                        handleDeviceChange={handleDeviceChange}
                        handleProgramClick={handleProgramClick}
                      />
                      <div style={{ position: "relative" }}>
                        {selectedDeviceId && (
                          <>
                            {recording ? (
                              <>
                                <button
                                  className="fc-button ms-1 btn btn-primary video-stop"
                                  disabled={stopRecording}
                                  onClick={() => {
                                    handleStopRecording();
                                  }}
                                >
                                  Stop Recording
                                </button>
                                <span className="hover-text">
                                  {" "}
                                  Clicking will stop the recording. Playback will stop after a brief pause
                                </span>
                              </>
                            ) : (
                              <>
                                <button className="fc-button ms-1 btn btn-secondary" onClick={handleStartRecording}>
                                  Start Recording
                                </button>
                                <button
                                  className="fc-button fc-button-primary ms-1 btn btn-secondary"
                                  onClick={handleReset}
                                >
                                  Reset
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-100 ms-lg-2">
                    <div class="video-label playout-video-label mb-1">
                      <span class="bg-secondary-dim">Playout</span>
                    </div>
                    <>
                      {/* {modalOpen && activeWebcam  ? ( */}
                      {responseUrl ? (
                        <div className="w-100 h-100">
                          <VideoModal
                            isOpen={modalOpen}
                            responseUrl={responseUrl}
                            userId={userId}
                            sessionId={sessionId}
                            fetchAndSaveSegment={fetchAndSaveSegment}
                            onHlsRef={handleHlsRef}
                            playerRef={playerRef}
                            // setActiveWebcam={setActiveWebcam}
                            selectedDeviceId={selectedDeviceId}
                          />
                        </div>
                      ) : (
                        <div className="w-100" style={{ background: "black" }} key={selectedId}>
                          <div
                            style={{
                              position: "relative",
                              paddingBottom: "56.25%",
                              // height: "360px",
                              overflow: "hidden",
                              maxWidth: "100%",
                              height: "auto",
                            }}
                          >
                            {broadcastLoading || stopBroadcastLoading ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  fontSize: "16px",
                                  color: "#fff",
                                }}
                              >
                                <Spinner />
                              </div>
                            ) : (
                              <iframe
                                src={`/embed/${selectedId}`}
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                                frameBorder="0"
                                allowFullScreen
                              ></iframe>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>

                <div className="demo-app my-5">
                  <div className="demo-app-main m-0 position-relative">
                    <div className="text-end mb-3 d-none">
                      <Button className="fc-button fc-button-primary" onClick={handleRemoveAllEvents}>
                        Clear All
                      </Button>
                      <Button className="fc-button fc-button-primary ms-2" onClick={handleSelectAllEvents}>
                        Remove Selected Events
                      </Button>
                      <Button className="fc-button fc-button-primary ms-2" onClick={RemoveBlankSpace}>
                        Remove Blank Space
                      </Button>
                      <Button onClick={handleZoomIn} className="btn-round btn-icon fc-button-primary ms-2" size="md">
                        <Icon name="plus-circle" />
                      </Button>
                      <Button onClick={handleZoomOut} className="btn-round btn-icon fc-button-primary ms-2" size="md">
                        <Icon name="minus-circle" />
                      </Button>
                    </div>
                    {/* <FullCalendar
                      ref={calendarRef}
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                      headerToolbar={
                        schedule_duration === "0"
                          ? false
                          : {
                              left: "prev,next today",
                              center: "title",
                              right: "dayGridMonth,timeGridWeek,resourceTimelineDay",
                            }
                      }
                      resourceAreaWidth="15%"
                      initialView="resourceTimeline"
                      selectable={true}
                      selectMirror={false}
                      weekends={true}
                      initialEvents={INITIAL_EVENTS}
                      events={eventsvideoStreams}
                      eventContent={renderEventContent}
                      eventsSet={handleEvents}
                      eventDrop={handleEventDrop}
                      slotDuration={{ minutes: 1 * zoomLevel }}
                      eventDurationEditable={true}
                      slotEventOverlap={false}
                      eventPositioned={handleEventPositioned}
                      snapDuration="00:01:00"
                      eventMinDistance={1}
                      eventResizableFromStart={false}
                      eventResizableFromEnd={false}
                      resources={RESOURCES}
                      eventOverlap={false}
                      eventMouseEnter={handleEventMouseEnter}
                      eventMouseLeave={handleEventMouseLeave}
                      editable={true}
                      eventClick={handleEventsClick}
                      nowIndicator={true}
                      stickyHeaderDates={true}
                      className="full-calendar"
                    /> */}
                  </div>
                  <>
                    {tooltipVisible && (
                      <div className="tooltip" style={{ top: tooltipPosition.top, left: tooltipPosition.left }}>
                        {tooltipContent}
                      </div>
                    )}
                  </>
                  <div className="row mb-2 d-flex justify-content-between align-items-center">
                    {embededcode ? (
                      <div className="col-md-4 d-flex">
                        <Button className="bg-light" size="lg" onClick={toggleTopPreview}>
                          <Icon name="code" className="text-dark" />
                          <span className="text-dark">Preview & Embed</span>
                        </Button>
                        <Button className="bg-light ms-2" size="lg" onClick={toggleTop}>
                          <Icon name="external" className="text-dark" />
                          <span className="text-dark">Get .m3u8 link</span>
                        </Button>
                      </div>
                    ) : (
                      <div className="col-md-4"></div>
                    )}
                    {/* <div className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <Label htmlFor="default-2" className="form-label">
                          Select Date To schedule
                        </Label>
                        <div className="form-control-wrap schedulecalendarbox">
                          <div className="form-icon form-icon-right" onClick={toggleZoom}>
                            <CalenderIcon />
                          </div>
                          <input
                            className="form-control"
                            type="text"
                            id="schedulecalendar"
                            readOnly
                            value={selectedDate}
                            onClick={toggleZoom}
                          />
                          {selectedDate && <div>Selected Date: {selectedDate}</div>}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <Modal
                    isOpen={modalZoom}
                    toggle={toggleZoom}
                    modalClassName="zoom"
                    className="calendar-modal"
                    size="xl"
                  >
                    <ModalBody className="bg-transparent d-flex justify-content-center">
                      <DatePicker onDateSelect={handleDateSelect} onClose={handleClose} selectedDate={selectedDate} />
                    </ModalBody>
                  </Modal>
                  <div>
                    {/* <Epg {...getEpgProps()}  isLoading={loading}>
                      <Layout {...getLayoutProps()} renderTimeline={(props) => <CustomTimeline {...props} />} />
                    </Epg> */}
                    <div class="video-label media-video-label mb-1">
                      <span class="bg-secondary-dim">Video selector</span>
                    </div>
                    <StreamScheduler
                      selecteddate={selectedDate}
                      isSchedules={embededcode}
                      epgJson1={epgJson1}
                      setepgJson={setepgJson}
                      setPlanEpg={setPlanEpg}
                      newSince={newSince}
                      setNewSince={setNewSince}
                      planEpg={planEpg}
                      saveLoading={saveLoading}
                      setSaveLoading={setSaveLoading}
                      setRecentVideo={setRecentVideo}
                      setPrograms={setPrograms}
                      swapEdit={swapEdit}
                      setSwapEdit={setSwapEdit}
                      setIsLoading={setIsLoading}
                      setEpgJson={setEpgJson}
                      setSavedEpg={setSavedEpg}
                      toDelete={toDelete}
                      setToDelete={setToDelete}
                    />
                  </div>
                </div>
                {/* <div className="col-xl-4 py-2">
                  <div className="bg-black p-2">
                    <div className="row">
                      <div className="d-flex justify-content-between">
                        <Button className="">
                          <Icon name="layers" className="fs-5 me-1" />
                          Feed Sequencer
                        </Button>
                        <Button className="w-110px justify-center">Clear All</Button>
                        <Button className="">Remove Space</Button>
                      </div>

                      <div className="col-lg-12 mt-2">
                        <div className="row align-items-center justify-content-between mb-2">
                          <div className="col-4">
                            <Button className="px-1 py-0 me-1" color="primary" size="SM">
                              1
                            </Button>
                            <span className="fs-13px">Persona Video</span>
                          </div>
                          <div className="col-4">
                            <Badge className="badge-dot" color="success"></Badge>
                            <span className="12px ms-1">Start Time</span>
                          </div>
                          <div className="col-2">
                            <span className="12px">17:58:39</span>
                          </div>
                          <div className="col-2">
                            <UncontrolledDropdown>
                              <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu>
                                <ul className="link-list-opt">
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="trash"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-2">
                          <div className="col-4">
                            <Button className="px-1 py-0 me-1" color="primary" size="SM">
                              2
                            </Button>
                            <span className="fs-13px">YouTube Video</span>
                          </div>
                          <div className="col-4">
                            <Badge className="badge-dot" color="success"></Badge>
                            <span className="12px ms-1">Start Time</span>
                          </div>
                          <div className="col-2">
                            <span className="12px">17:58:39</span>
                          </div>
                          <div className="col-2">
                            <UncontrolledDropdown>
                              <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu>
                                <ul className="link-list-opt">
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="trash"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-2">
                          <div className="col-4">
                            <Button className="px-1 py-0 me-1" color="primary" size="SM">
                              3
                            </Button>
                            <span className="fs-13px">Live Video</span>
                          </div>
                          <div className="col-4">
                            <Badge className="badge-dot" color="success"></Badge>
                            <span className="12px ms-1">Start Time</span>
                          </div>
                          <div className="col-2">
                            <span className="12px">17:58:39</span>
                          </div>
                          <div className="col-2">
                            <UncontrolledDropdown>
                              <DropdownToggle className="dropdown-toggle btn btn-icon btn-light bg-transparent border-0">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu>
                                <ul className="link-list-opt">
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem tag="a" href="#links">
                                      <Icon name="trash"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-xl-8">
                      {/* <div class="video-label media-video-label mb-1">
                        <span class="bg-secondary-dim">Media</span>
                      </div>
                      <FilterMenu
                        searchQuery={searchQuery}
                        tagQuery={tagQuery}
                        handleSearchChange={setSearchQuery}
                        handleSearchChangeTag={setTagQuery}
                        handleSearchChangeTagQuery={handleSearchChangeTagQuery}
                        handleSearchChangefirst={handleSearchChangefirst}
                        sortingOption={sortingOption}
                        SortingOptionMedia={SortingOptionMedia}
                        handleSortingChange={handleSortingChange}
                        handleMediaTypeChange={handleMediaTypeChange}
                        handleMediaTypes={handleMediaTypes}
                        selectedMatchedTag={selectedMatchedTag}
                        handleMatchedTag={handleMatchedTag}
                      />
                      <h5 className="mb-3 d-none">{total_records_count} Videos</h5>
                      <InfiniteScroll
                        dataLength={videoStreams.length}
                        next={fetchVideoStreams}
                        hasMore={hasMore}
                        loader={
                          <div style={{ textAlign: "center" }}>
                            <Spinner color="dark" />
                          </div>
                        }
                        style={{ overflowX: "hidden", overflowY: "hidden" }}
                        endMessage={hasMore && <h6 className="text-center mt-5">No more videos to load.</h6>}
                      >
                        <div className="video-channels video-channels-schedule ms-1">
                          <Row>
                            {videoStreams.map((videoStream, index) => (
                              <Col key={index} xl={3} lg={4} sm={6} className="mb-2 ps-2 px-0">
                                <Card className="card-bordered position-relative product-card border-0 h-100 shadow-sm">
                                  <div
                                    className="product-thumb"
                                    draggable
                                    onDragStart={(e) =>
                                      handleDragEnd(videoStream.duration, videoStream.id, videoStream.thumbnail_base64)
                                    }
                                  >
                                    <Link to={`/product-details/${videoStream.id}`}>
                                      <img src={`data:image/png;base64, ${videoStream.thumbnail_base64}`} alt="" />
                                    </Link>
                                    <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.15)" }}>
                                      <Link
                                        to=""
                                        className="plus-icon rounded-pill p-1 d-flex justify-content-center"
                                        onClick={() =>
                                          handleDurationClick(
                                            videoStream.duration,
                                            videoStream.id,
                                            videoStream.thumbnail_base64
                                          )
                                        }
                                      >
                                        <Icon name="icon ni ni-plus-circle"></Icon>
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between p-1">
                                    <p className="mb-0 fs-12px">{videoStream.title}</p>
                                    <p className="fs-12px mb-0">{formatDuration(videoStream.duration)}</p>
                                  </div>
                                  <div className="p-1">
                                    {videoStream.tags &&
                                      videoStream.tags.length > 0 &&
                                      videoStream.tags.map((tag, index) => (
                                        <Badge color="primary" className="me-1" key={index}>
                                          {tag}
                                        </Badge>
                                      ))}
                                  </div>
                                </Card>
                              </Col>
                            ))}
                            <Col xl={3} lg={4} sm={6} className="text-center">
                              <Dropdown
                                isOpen={dropDownIsOpen}
                                toggle={DropDowntoggle}
                                className="d-inline-block h-100px mx-auto w-150px"
                              >
                                <DropdownToggle className="border w-100 justify-center h-100" color="secondery">
                                  <Card className="">
                                    <div className="">
                                      <Icon name="icon ni ni-plus-circle" className="fs-1"></Icon>
                                    </div>
                                  </Card>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <ul className="link-list-opt">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#links"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          navigate("/uploads");
                                        }}
                                      >
                                        <span>Upload Video</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#links"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setIsOpen1(true);
                                        }}
                                      >
                                        <span>Use Camera</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        href="#links"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setIsOpen2(true);
                                        }}
                                      >
                                        <span>Import From SiloCloud</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </Dropdown>
                            </Col>
                          </Row>
                        </div>
                      </InfiniteScroll> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-dim col-lg-3 p-1">
              <SchedularChannel
                streamName={streamData ? streamData.playlistpathLink : ""}
                startTime={streamData ? streamData.earliest_since : ""}
                endTime={streamData ? streamData.latest_till : ""}
                linkduration={linkduration}
                isBroadcastEnabled={isBroadcastEnabled}
                setIsBroadcastEnabled={setIsBroadcastEnabled}
                savedEpg={savedEpg}
                isloading={isLoading}
              />
            </div>
          </div>
        </div>
      </section>
      {/* <M3U8Modal
        isOpen={isOpen}
        toggle={toggle}
        label={"M3U8 stream link"}
        value="https://app.streamdeck.tv/hls/channel/4354865db1ee52073199408acdb4a2b3.m3u8"
      /> */}
      {/* <RecordAndUploadModal isOpen={isOpen1} toggle={toggle1} fetchVideoStreams={fetchVideoStreams} />
      <UploadFromStorageModal isOpen={isOpen2} toggle={toggle2} /> */}
    </>
  );
};

export default FCalender;
function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;
  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDuration;
}
