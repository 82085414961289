import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, OutlinedInput, RSelect } from "../../components/Component";
import { Alert, Button, DropdownMenu, DropdownToggle, Spinner, Tooltip, UncontrolledDropdown } from "reactstrap";
import { dateType } from "../Video/DropDown";
import { fetchAPI, reArrangeVideoApi } from "../../utils/api_helper";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useChannelList } from "../../utils/useChannelList";
import TooltipItem from "./TooltipItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ShimmerCategoryItem, ShimmerSectionHeader, ShimmerText, ShimmerThumbnail } from "react-shimmer-effects";
import Tour from "reactour";
import Cookies from "js-cookie";
const Channel = () => {
  const [loading, setLoading] = useState(true);
  const [scheduleloading, setScheduleLoading] = useState(true);
  const NavigateChannel = useNavigate("");
  const [videolist, setVideolist] = useState([]);
  const [filterVideolist, setFilterVideolist] = useState([]);
  const [rearrangevideo, setRearrangevideo] = useState(false);
  const [totalDuration, settotalDuration] = useState([]);
  const parms = useParams();
  const isMounted = useRef(false);

  const [selectedchannel, setSelectedchannel] = useState(null);
  const [channelCount, setChannelCount] = useState(0);

  const [channelList, setChannelList] = useChannelList(setSelectedchannel, setLoading, setChannelCount);
  const deletChanneldata = async (deletechannelId, indexID) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleFormData = new FormData();
        deleFormData.append("channel_id", deletechannelId);
        const deleteData = await fetchAPI(deleFormData, "delete-channel");
        if (deleteData.status) {
          toast.success(deleteData.message);
          const updatedChannelList = channelList.filter((channel) => channel.id !== deletechannelId);
          setChannelList(updatedChannelList);
          if (updatedChannelList?.[0]) {
            setSelectedchannel(updatedChannelList[0]);
          }
        } else {
          toast.error(deleteData.message);
        }
      }
    });
  };

  const [deletechannel, setDelete] = useState(false);
  const [editchannel, setEdit] = useState(false);

  const toggledelete = () => setDelete(!deletechannel);
  const toggleEdit = () => setEdit(!editchannel);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const addScheduleList = (channel) => {
    setSelectedchannel(channel);
    setVideolist(videolist);
    setFilterVideolist(videolist);
  };

  const searchChannel = (value) => {
    if (value && value.trim() !== "") {
      const inputValue = value.toLowerCase().trim();
      const filteredList = videolist.filter((channel) => {
        const name = channel.title ? channel.title.toLowerCase() : "";
        return name.includes(inputValue);
      });
      setFilterVideolist(filteredList);
    } else {
      setFilterVideolist(videolist);
    }
  };

  const updateLocalStorage = (updatedList) => {
    localStorage.setItem("videolist", JSON.stringify(updatedList));
  };

  useEffect(() => {
    const storedList = JSON.parse(localStorage.getItem("videolist"));
    if (storedList) {
      setVideolist(storedList);
      setFilterVideolist(storedList);
    }
  }, []);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    } else {
      const items = Array.from(videolist);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      const updatedItems = items.map((item, index) => {
        return {
          ...item,
          sequenceId: (index + 1).toString(), // Update ID sequentially
        };
      });

      setVideolist(updatedItems);
      setFilterVideolist(updatedItems);
      setRearrangevideo(!rearrangevideo);
      updateLocalStorage(updatedItems); // Update local storage with the new order
    }
  };

  const rearrange = async (channelId) => {
    try {
      let videos = videolist.map((video) => {
        return {
          id: video.scheduler_id,
          sequence: video.sequenceId,
        };
      });
      const resvideo = await reArrangeVideoApi(videos, channelId);
      if (resvideo.status) {
        toast.success(resvideo.message);
      } else {
        toast.error(resvideo.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isMounted.current) {
      rearrange(selectedchannel.id);
    } else {
      isMounted.current = true;
    }
  }, [rearrangevideo]);

  // right-side
  const scheduleApiGet = async (channelId) => {
    setScheduleLoading(true);
    try {
      const GetScheduleVideo = new FormData();
      GetScheduleVideo.append("channel_id", channelId);

      const scheduleList = await fetchAPI(GetScheduleVideo, "get-schedule-channel");
      if (scheduleList.status) {
        const setData = scheduleList?.data?.videos_data;
        const totalDuration = scheduleList?.data?.total_duration;
        setVideolist(setData);
        settotalDuration(totalDuration);
        setFilterVideolist(setData);
      } else {
        setVideolist([]);
        setFilterVideolist([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setScheduleLoading(false);
    }
  };

  const deleteVideoList = async (listId) => {
    try {
      const dataVideoList = new FormData();
      dataVideoList.append("loop_id", listId);
      const videoList = await fetchAPI(dataVideoList, "delete-program");
      if (videoList.status) {
        toast.success(videoList.message);
        const videolistarr = videolist.filter((channel) => channel.scheduler_id !== listId);
        setVideolist(videolistarr);
        setFilterVideolist(videolistarr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedchannel !== null) {
      scheduleApiGet(selectedchannel.id);
    }
  }, [selectedchannel]);

  // if (loading) {
  //   return (
  //     <div className="d-flex justify-content-start align-items-center m-4">
  //       <Spinner color="primary" size="sm" />
  //       <h5 className="ms-1">Loading...</h5>
  //     </div>
  //   );
  // }
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [isTourOpen1, setIsTourOpen1] = useState(true);
  const tourSteps = [
    {
      selector: "",
      content: () => (
        <>
          <h3 className="m-0">Get Started with Your Channel</h3>
          <p style={{ fontSize: "19px" }} className="m-0">
            Welcome to our platform! Create your channel now to unlock live streaming and TV content. Start broadcasting
            and engage with your audience today!
          </p>
        </>
      ),
    },
    {
      selector: ".create-channel-step",
      content: () => (
        <>
          <p style={{ fontSize: "19px" }} className="m-0">
            Click on <strong className="text-primary">Create Channel</strong> to create your first channel.
          </p>
        </>
      ),
    },
  ];
  const tourSteps1 = [
    {
      selector: ".menuicon3",
      content: () => (
        <>
          <h3>👏 Congratulations!</h3>
          <p style={{ fontSize: "19px" }} className="m-0">
            You have just created your <strong className="text-primary">first channel</strong>
          </p>
          <Alert className="" color="warning">
            {/* <Icon name="alert-circle" /> */}
            These channels will used in to Live Stream your content or add programs
          </Alert>
        </>
      ),
    },
  ];
  const navigate = useNavigate();
  // useEffect(() => {
  //   const tutorialSeen = Cookies.get("channelTutorial");
  //   if (!tutorialSeen) {
  //     setIsTourOpen(true);
  //   } else {
  //     setIsTourOpen(false);
  //   }
  // }, []);
  const finishChannelTutorial = () => {
    // Cookies.set("channelTutorial", "true", { expires: 7 });
    navigate("/create-channel");
  };
  return (
    <>
      {!loading && channelList.length === 0 && (
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          showDots={false}
          accentColor="#e14954"
          prevButton={
            <button
              className="btn btn-secondary"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Prev
            </button>
          }
          nextButton={
            <button
              className="btn btn-primary"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Next
            </button>
          }
          lastStepNextButton={
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                finishChannelTutorial();
                return false;
              }}
            >
              Create Channel Now
            </button>
          }
        />
      )}
      {!loading && channelList.length === 1 && (
        <Tour
          steps={tourSteps1}
          isOpen={isTourOpen1}
          onRequestClose={() => setIsTourOpen1(false)}
          showDots={false}
          prevButton={
            <button
              className="btn btn-secondary"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              skip
            </button>
          }
          nextButton={
            <button
              className="btn btn-primary"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Next
            </button>
          }
          lastStepNextButton={
            <button
              className="btn btn-primary"
              // style={{ position: "relative", right: "25px" }}
              onClick={(e) => {
                e.preventDefault();
                navigate("/lives");
                return false;
              }}
            >
              Let's Move To LiveStream.
            </button>
          }
        />
      )}
      <section className="m-3">
        <div className="container-fluid">
          <div className="row" id="channel-list">
            <div className="col-lg-6">
              <div className="bg-white rounded p-3">
                <h5 className="">({channelCount}) Channels</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <UncontrolledDropdown>
                      <div className="btn-group create-channel-step">
                        <Button color="primary" onClick={() => NavigateChannel("/create-channel")}>
                          <Icon name="plus" />
                          <span>Create Channel</span>
                        </Button>
                        <DropdownToggle className="dropdown-toggle-split" color="primary">
                          <Icon name="chevron-down"></Icon>
                        </DropdownToggle>
                      </div>
                      <DropdownMenu>
                        <ul className="link-list-opt">
                          <li>
                            <Link to="/create-channel/scheduled?type=0">
                              <Icon name="calendar-booking" className="text-warning pe-1" />
                              <span>Scheduled (linear)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/create-channel/looped?type=1">
                              <Icon name="redo" className="text-success pe-1" />
                              <span>Looped (linear)</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/create-channel/vod">
                              <Icon name="view-list-wd" className="text-primart pe-1" />
                              <span>Playlist (on demand)</span>
                            </Link>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div>
                    <div>0 total views</div>
                  </div>
                </div>
                <>
                  {loading ? (
                    <div className="mt-3">
                      <ShimmerThumbnail height={100} rounded />
                      <ShimmerThumbnail height={100} rounded />
                      <ShimmerThumbnail height={100} rounded />
                    </div>
                  ) : channelList.length > 0 ? (
                    channelList.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={`d-flex bg-secondary-dim align-items-center shadow-sm mt-3 justify-content-between pointer setHover ${
                            index === selectedItemIndex ? "hover-theme" : ""
                          }`}
                          onClick={() => addScheduleList(item)}
                        >
                          <div className="d-flex p-1">
                            <div>
                              {item.channel_type === "0" && parms.type !== "looped" ? (
                                item.linear_channel_type === "0" ? (
                                  <Icon name="calendar-booking" className="fs-4 text-warning" />
                                ) : (
                                  <Icon name="redo" className="fs-4 text-success" />
                                )
                              ) : item.channel_type === "1" ? (
                                <Icon name="spark" className="fs-4 text-warning" />
                              ) : item.channel_type === "2" ? (
                                <Icon name="view-list-wd" className="fs-4 text-primary" />
                              ) : (
                                <Icon name="redo" className="fs-4 text-warning" />
                              )}
                            </div>
                            <div className="ms-1">
                              <TooltipItem key={index} placement="top" text={item.channel_name} id={index} />
                              <span className="fs-12px">
                                <Icon name="eye" /> 0
                              </span>
                            </div>
                          </div>
                          <ul className="list-unstyled d-flex mx-1 g-1 list-channel">
                            <li>
                              {item.channel_type === "1" ? (
                                <>
                                  <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                    <Icon name="play" />
                                    <span>Preview</span>
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Link to={`/embed-channel/${item.id}`}>
                                    <Button color="dark" size="sm" className="btn-dim fs-10px px-1">
                                      <Icon name="code" />
                                      <span>Preview & Embed</span>
                                    </Button>
                                  </Link>
                                </>
                              )}
                            </li>
                            <li>
                              {item.channel_type === "2" ? (
                                ""
                              ) : (
                                // <Link to="/stream-link">
                                //   <TooltipItem
                                //     key={"m3" + index}
                                //     placement="top"
                                //     text="Get.m3u8 Link"
                                //     toolTipList={true}
                                //     btntext={[{ name: "external" }, { text: ".M3U8" }]}
                                //     id={"m3" + index}
                                //   />
                                // </Link>
                                <></>
                              )}
                            </li>
                            <li>
                              {item.channel_type === "1" ? (
                                // <Link to="">
                                //   <TooltipItem
                                //     key={"epg" + index}
                                //     placement="top"
                                //     text="Get EPG guide link"
                                //     toolTipList={true}
                                //     btntext={[{ name: "view-list-wd" }, { text: "EPG" }]}
                                //     id={"epg" + index}
                                //   />
                                // </Link>
                                <></>
                              ) : item.channel_type === "2" ? (
                                ""
                              ) : (
                                // <Link to="/tv-channel">
                                //   <TooltipItem
                                //     key={"tv" + index}
                                //     placement="top"
                                //     text="Distribute the channel on TV Apps"
                                //     toolTipList={true}
                                //     btntext={[{ name: "plus" }, { text: "TV Apps" }]}
                                //     id={"tv" + index}
                                //   />
                                // </Link>
                                <></>
                              )}
                            </li>
                            <li>
                              <Link to={`/copy-channel/${item.id}`}>
                                <TooltipItem
                                  key={"copy" + index}
                                  placement="top"
                                  text="Duplicate"
                                  toolTipList={true}
                                  btntext={[{ name: "copy" }]}
                                  id={"copy" + index}
                                />
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`${
                                  item.channel_type === "0"
                                    ? item.linear_channel_type === "0"
                                      ? "/edit-channel/scheduled/"
                                      : "/edit-channel/looped/"
                                    : item.channel_type === "1"
                                    ? "/edit-channel/fast/"
                                    : item.channel_type === "2"
                                    ? "/edit-channel/vod/"
                                    : "/edit-channel/unknown/"
                                }${window.btoa(item.id)}`}
                              >
                                <TooltipItem
                                  key={"edit" + index}
                                  // onClick={() => deletChanneldata(item.id)}
                                  placement="top"
                                  text="Edit"
                                  toolTipList={true}
                                  btntext={[{ name: "edit" }]}
                                  id={"edit" + index}
                                />
                              </Link>
                            </li>
                            <li id="trash">
                              <Link to="">
                                <Button
                                  className="btn-icon btn-dim fs-10px px-1"
                                  onClick={() => deletChanneldata(item.id, index)}
                                  color="dark"
                                  size="sm"
                                >
                                  <Icon name="trash" />
                                </Button>
                                <Tooltip placement="top" isOpen={deletechannel} target="trash" toggle={toggledelete}>
                                  Delete
                                </Tooltip>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-2">No channels exist. Create a one to explore more.</div>
                  )}
                </>
              </div>
            </div>
            {loading || scheduleloading ? (
              <>
                <div className="col-lg-6">
                  <div className="bg-white rounded p-3 pb-1">
                    <ShimmerCategoryItem imageType="circular" imageWidth={100} imageHeight={100} text cta />
                    <ShimmerSectionHeader />
                  </div>
                </div>
              </>
            ) : channelList.length > 0 ? (
              <div className="col-lg-6">
                <div className="card p-3">
                  <div className="d-flex align-items-center">
                    {selectedchannel?.channel_type === "0" ? (
                      selectedchannel?.linear_channel_type === "0" ? (
                        <Icon name="calendar-booking" className="fs-4 text-warning" />
                      ) : (
                        <Icon name="redo" className="fs-4 text-success" />
                      )
                    ) : selectedchannel?.channel_type === "1" ? (
                      <Icon name="spark" className="fs-4 text-warning" />
                    ) : (
                      <Icon name="view-list-wd" className="fs-4 text-primary" />
                    )}
                    <h5 className="ps-1">{selectedchannel?.channel_name}</h5>
                  </div>
                  <div className="my-2">
                    {(selectedchannel?.channel_type === "0" &&
                      selectedchannel?.linear_channel_type !== "1" &&
                      selectedchannel?.schedule_duration === "0") ||
                    (selectedchannel?.channel_type === "1" && selectedchannel?.schedule_duration === "0") ? (
                      <Link to={`/schedule-channel/timeline/${selectedchannel.id}`} className="">
                        {/* <Button color="primary">
                          <Icon name="calendar-booking" />
                          <span>Schedule</span>
                        </Button> */}
                      </Link>
                    ) : (selectedchannel?.channel_type === "0" && selectedchannel?.linear_channel_type !== "0") ||
                      selectedchannel?.channel_type === "2" ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          {/* <Link to={`/add-video/${selectedchannel.id}`} className="">
                            <Button color="primary">
                              <Icon name="plus" />
                              <span>Video</span>
                            </Button>
                          </Link> */}
                          <div className="form-control-wrap outline-input ps-1 w-175px">
                            <OutlinedInput
                              id="outlined-icon"
                              onChange={(value) => searchChannel(value)}
                              label=""
                              size="sm"
                              icon="search"
                            />
                          </div>
                        </div>
                        <div>
                          <p className="mb-0 fs-13px text-body">
                            {videolist.length > 0 && videolist[0]
                              ? `${videolist.length} videos | Total channel duration: ${formatDuration(totalDuration)}`
                              : ""}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="d-flex">
                          <Link to={`/schedule-channel/timeline/${selectedchannel?.id}`} className="">
                            <Button color="primary">
                              <Icon name="calendar" />
                              <span>Schedule</span>
                            </Button>
                          </Link>
                          <div className="form-group w-25 ms-1">
                            <RSelect placeholder="Monday" options={dateType} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {filterVideolist.map((item, index) => (
                    <div key={index} className="d-flex justify-content-between mb-1 shadow-sm align-items-center p-1">
                      {/* <div className="d-flex"> */}
                      <div className="d-flex align-items-center">
                        <div className="w-80px h-20px border" style={{ minWidth: "80px" }}>
                          <img
                            src={item.thumbnail_base64}
                            alt=""
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                          />
                        </div>
                        <div className="ps-2">
                          <h6 className="">
                            {(selectedchannel?.channel_type === "0" && selectedchannel?.linear_channel_type !== "0") ||
                            selectedchannel?.channel_type === "2" ? (
                              <span className="me-1">{index + 1} - </span>
                            ) : null}

                            <span className="">{item.title}</span>
                          </h6>
                          <span className="opacity-75">{item.start_time}</span>
                          {(selectedchannel?.channel_type === "0" && selectedchannel?.linear_channel_type !== "0") ||
                          selectedchannel?.channel_type === "2" ? (
                            <>
                              <Icon name="eye" className="" />
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        {selectedchannel?.channel_type === "0" && selectedchannel?.linear_channel_type !== "0" ? (
                          <>
                            <Icon
                              name="trash"
                              className="p-1 bg-azure-dim ms-1"
                              onClick={() => deleteVideoList(item.scheduler_id)}
                            />
                          </>
                        ) : selectedchannel?.channel_type === "2" ? (
                          <>
                            <Icon name="code" className="p-1 bg-azure-dim pointer" />
                            <Icon
                              name="trash"
                              className="p-1 bg-azure-dim ms-1"
                              onClick={() => deleteVideoList(item.scheduler_id)}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                  {/* <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {filterVideolist.map((item, index) => (
                            <Draggable
                              key={String(item.id + "_" + index)}
                              draggableId={String(item.id + "_" + index)}
                              index={index}
                            >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> */}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* <Delete open={isOpen} toggleopen={toggleopen} /> */}
      </section>
    </>
  );
};

export default Channel;

function formatDuration(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedDuration;
}
