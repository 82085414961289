import React, { useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import Homepage from "../pages/Homepage";
import { validateChannelContext } from "../pages/app/StremContext";
import PlayoutGallery from "../pages/VideoGallery/PlayoutGallery";
import Layout from "../layout/Index";
import Video from "../pages/Video/Video";
import Upload from "../pages/Upload/Upload";
import Channel from "../pages/channel/Channel";
import EmbedChannel from "../pages/channel/EmbedChannel";
import StreamLink from "../pages/channel/StreamLink";
import TvChannel from "../pages/channel/TvChannel";
import EditChannel from "../pages/channel/EditChannel";
import CreateChannel from "../pages/channel/CreateChannel";
import LiveStream from "../pages/liveStreams/LiveStream";
import LiveFeedChannel from "../pages/liveStreams/LiveFeedChannel";
import Delete from "../pages/channel/Delete";
import CopyChannel from "../pages/channel/CopyChannel";
import Scheduled from "../pages/channel/Scheduled";
import Looped from "../pages/channel/Looped";
import Fast from "../pages/channel/Fast";
import PlayList from "../pages/channel/PlayList";
import SubscriptionPackage from "../pages/app/SubscriptionPackage";
import Website from "../pages/website/Website";
import New from "../pages/website/New";
import Cookies from "js-cookie";
import Welcome from "../pages/app/Welcome";
import Player from "../pages/analytics/Player";
import EncodeVideo from "../pages/liveStreams/EncodeVideo";
import LivesChannel from "../pages/liveStreams/LivesChannel";
import { fetchAPI } from "../utils/api_helper";
import CreateLiveStream from "../pages/liveStreams/CreateLiveStream";
import EditVideo from "../pages/Video/EditVideo";
import AddVideo from "../pages/channel/AddVideo";
import TimeLine from "../pages/channel/TimeLine";
import Test from "../pages/Test";
import Medialibrary from "../pages/channel/Medialibrary";
import LayoutNew from "../layout/LayoutNew";
import ChannelStatus from "../pages/channel/ChannelStatus";
import Schedulartab from "../pages/channel/Schedulartab";
import ChannelView from "../pages/channel/ChannelView";
import Analytics from "../pages/analytics/Analytics";
import { StreamProvider } from "../pages/StreamContext";
import "../css/streamdeck_css/custom_loader.css";
import Embed from "../pages/embed/Embed";
import AnalyticsNew from "../pages/analytics/AnalyticsNew";

const Pages = () => {
  const [validate, setvalidate] = useState(false);

  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // const chaekValidate = async () => {
  //   const formdata = new FormData();
  //   formdata.append("channel_id", "");
  //   const getChannel = await fetchAPI(formdata, "get-channel");
  //   console.log(getChannel);
  //   if (getChannel?.data?.channel?.length > 0) {
  //     setvalidate(true);
  //   }
  // };

  return (
    <validateChannelContext.Provider value={[validate, setvalidate]}>
      <StreamProvider>
        <Routes>
          <Route path="test" element={<Test />}></Route>
          <Route path="embed/:id" element={<Embed />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />}></Route>
            {/* <Route path="welcome" element={<Welcome />}></Route> */}
            <Route path="videos" element={<Video />}></Route>
            <Route path="edit-video/:id" element={<EditVideo />}></Route>
            <Route path="uploads" element={<Upload />}></Route>
            <Route path="channels" element={<Channel />}></Route>

            <Route path="create-channel/:type" element={<Scheduled />}></Route>
            <Route path="create-channel/:type" element={<Looped />}></Route>
            <Route path="create-channel/:type" element={<PlayList />}></Route>
            <Route path="create-channel/fast" element={<Fast />}></Route>
            <Route path="edit-channel/fast/:id" element={<Fast />}></Route>
            <Route path="embed-channel/:type" element={<EmbedChannel />}></Route>
            <Route path="stream-link" element={<StreamLink />}></Route>
            <Route path="tv-channel" element={<TvChannel />}></Route>
            <Route path="edit-channel/:type/:id" element={<EditChannel />}></Route>
            <Route path="create-channel" element={<CreateChannel />}></Route>
            <Route path="add-video/:channelid" element={<AddVideo />}></Route>
            <Route path="schedule-channel/timeline/:id" element={<TimeLine />}></Route>
            <Route path="schedule-channel/timeline" element={<TimeLine />}></Route>
            <Route path="Playout-gallery" element={<PlayoutGallery />}></Route>
            {/* Just for testing /encode-video */}
            {/* <Route path="encode-video" element={<EncodeVideo />}></Route> */}

            <Route path="live-streams" element={<LiveStream />}></Route>
            <Route path="live-streams/create/:stream_name" element={<CreateLiveStream />}></Route>
            <Route path="live-feed-channel/:stream_id" element={<LiveFeedChannel />}></Route>
            <Route path="delete" element={<Delete />}></Route>
            <Route path="copy-channel/:id" element={<CopyChannel />}></Route>
            <Route path="packages" element={<SubscriptionPackage />}></Route>
            <Route path="websites" element={<Website />}></Route>
            <Route path="websites/new" element={<New />}></Route>
            <Route path="websites/website-edit/:id" element={<New />}></Route>
            <Route path="analytics-old" element={<Analytics />}></Route>
            <Route path="analytics" element={<AnalyticsNew />}></Route>
            <Route path="lives" element={<LivesChannel />}></Route>
            {/*Pre-built Pages*/}
            <Route path="media-library" element={<Medialibrary />}></Route>
            <Route path="schedular" element={<Schedulartab />}></Route>
            <Route path="channel-status" element={<ChannelStatus />}></Route>
            <Route path="channel-view/:id" element={<ChannelView />}></Route>
          </Route>
          {/* <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNew />}></Route> */}
        </Routes>
      </StreamProvider>
    </validateChannelContext.Provider>
  );
};
export default Pages;
