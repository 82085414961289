import React, { useEffect } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import { Icon } from "../../components/Component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../../css/streamdeck_css/common.css";
import Notification from "./dropdown/notification/Notification.jsx";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import PackageModal from "./PackageModal";
import { useHeaderText } from "../../pages/HeaderTextContext";
import LogoSmall from "../../images/applogos/logowhite.png";
import LogoDark from "../../images/applogos/logodark.png";
import { fetchAPI } from "../../utils/Utils.js";
import Notifications from "./dropdown/notification/Notifications.js";
const Header = ({ fixed, className }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { skin } = useTheme();

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  const [fullScreenOn, setFullScreenOn] = useState(false);
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  // useEffect(() => {
  //   const fetchTheme = async () => {
  //     try {
  //       const data = new FormData();
  //       data.append("action", "fetch");
  //       const response = await fetchAPI(data, "api/v1/public/frontend-settings");
  //       if (response.status) {
  //         const fetchedTheme = response?.data?.frontend_settings?.theme;
  //         themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchTheme();
  // }, [fullScreenOn]);

  const location = useLocation();
  // const [text, setText] = useState(
  //   "Welcome to our SiloStreamDeck! Explore endless entertainment and discover new favorites. Enjoy your streaming journey with us!"
  // );
  // useEffect(() => {
  //   if (location.pathname === "/videos") {
  //     setText("This is media library you can find all uploaded files here");
  //   }
  //   if (location.pathname === "/lives") {
  //     setText("This is RTMP Streams that you have created all can see here!");
  //   }
  //   if (location.pathname === "/schedule-channel/timeline") {
  //     setText("Schedule your channels using drag and drop in EPG");
  //   }
  // },[location.pathname]);
  const text = useHeaderText();
  useEffect(() => {
    const element = document.querySelector(".sevensegmentdisplay");
    if (element) {
      element.style.animation = "none";
      void element.offsetHeight; // trigger reflow
      element.style.animation = null;
    }
  }, [text]);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${date}/${month}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    // <section className="section-header">
    <div className={`${headerClass} border-0 section-header`}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <div className="nk-apps-brand ">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              {skin === "dark" ? (
                <img
                  className=""
                  src={LogoDark}
                  alt="logo"
                  style={{ height: "45px", width: "230px", objectFit: "contain" }}
                />
              ) : (
                <img
                  className=""
                  src={LogoSmall}
                  alt="logo-dark"
                  style={{ height: "45px", width: "230px", objectFit: "contain" }}
                />
              )}
            </Link>
          </div>
          <div className="nk-header-app-name pointer">
            <div className="">
              <p className="mb-0 fw-bolder text-blue-color">{time}</p>
              <p className="fs-9px mb-0">{date}</p>
            </div>
            <div>
              <Link to="https://calendar.silocloud.io/">
                <Icon name="calender-date" className="head-icon" />
              </Link>
            </div>
            <div className="form-control-wrap ms-4 me-2 header-search">
              <div className="form-icon form-icon-left">
                <Icon name="search" />
              </div>
              <input
                className="form-control"
                type="text"
                id="appsearch"
                placeholder="Search"
                style={{ borderRadius: "24px" }}
              />
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="bg-light text-information col-lg-6 d-flex align-center">
              <h4 className="sevensegmentdisplay">{text}</h4>
            </div>
          </div>
          <div className="nk-header-tools align-items-center py-1">
            <ul className="nk-quick-nav">
              <li className="mt-1">
                {fullScreenOn ? (
                  <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                ) : (
                  <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                )}
              </li>
              <li className="notification-dropdown">
                <Notifications />
              </li>
              <li className="notification-dropdown">
                <AppDropdown />
              </li>
              <li className="user-dropdown d-flex">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PackageModal toggleIsOpenModal={toggleIsOpen} isOpen={isOpen} />
    </div>
    // </section>
  );
};
export default Header;
