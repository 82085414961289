import React from 'react'
import Scheduled from './Scheduled'

const Looped = () => {
    return (
        <>
            <Scheduled/>
        </>

    )
}

export default Looped