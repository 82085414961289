import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Icon, PreviewCard, ReactDataTable } from "../../components/Component";
import { useNavigate } from "react-router";
import { fetchAPI } from "../../utils/api_helper";
import { StreamAddContext } from "../app/StremContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import HlsPlayer from "./HlsPlayer";
import "../../css/streamdeck_css/streamdeck.css";
import Tour from "reactour";
import { PositionCache } from "@fullcalendar/core/internal";
const LivesChannel = () => {
  const navigate = useNavigate();
  const [streamList, setStreamList] = useState([]);
  const { setStreamKey, setChannelKey, setStreamId, setCreateChannel } = useContext(StreamAddContext);
  const [modal, setModal] = useState(false);
  const [streamUrl, setStreamUrl] = useState("");
  const [userId, setUserId] = useState("1234"); // Replace with actual userId
  const [channelKey, setChannelKeyState] = useState(""); // Add state for channelKey
  const [isPlaying, setIsPlaying] = useState(false); // State to handle play/pause
  const [titleName, setTitleName] = useState("");
  const toggleModal = () => setModal(!modal);

  const sendCreateUrl = (streamKeyID, streamKey, playbackUrlKey, id) => {
    setStreamKey(streamKey);
    setChannelKey(playbackUrlKey);
    setStreamId(id);
    navigate(`/live-streams/create/${streamKeyID}`);
  };

  const deleteCreateUrl = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleFormData = new FormData();
        deleFormData.append("stream_id", id);
        const getLiveData = await fetchAPI(deleFormData, "delete-live-stream");
        if (getLiveData.status) {
          toast.success(getLiveData.message);
          fetchData();
        } else {
          toast.error(getLiveData.message);
        }
      }
    });
  };

  const fetchData = async () => {
    try {
      const getLiveData = await fetchAPI("", "get-live-stream");
      if (getLiveData.status) {
        const getdata = getLiveData?.data?.livestreams;
        setStreamList(getdata);
      } else {
        setStreamList([]);
        console.log(`Error fetching data: ${getLiveData}`);
      }
    } catch (error) {
      console.log(`Error fetching data: ${error}`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dataTableColumns = [
    {
      name: "Stream Key",
      selector: (row) => row.stream_key,
      sortable: true,
      header: { className: "custom-header" },
    },
    {
      name: "Status",
      selector: (row) => row.stream_status,
      sortable: true,
      hide: 370,
      cell: (row) => (
        <div className="text-cent">
          {row.stream_status === "1" ? (
            <Icon name="check-circle" className="Stream-True"></Icon>
          ) : (
            <Icon name="cross-circle" className="Stream-False"></Icon>
          )}
        </div>
      ),
    },
    {
      name: "Established Date",
      selector: (row) => row.created_at,
      sortable: true,
      hide: 370,
      cell: (row) => (
        <div className="text-cent">
          <span>{new Date(row.created_at).toLocaleDateString({ day: "numeric", month: "long", year: "numeric" })}</span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      sortable: true,
      hide: 370,
      cell: (row) => (
        <div className="text-cent">
          <Icon
            name="edit"
            className="mx-1 pointer"
            onClick={() => sendCreateUrl(row.stream_key_id, row.stream_key, row.playback_url_key, row.id)}
          />
          <Icon name="trash" onClick={() => deleteCreateUrl(row.id)} className="mx-1 pointer"></Icon>
          {row.stream_status === "1" && (
            <Icon
              name="play-circle"
              className="mx-1 pointer"
              onClick={(e) => {
                setStreamUrl(row.stream_url_live);
                setChannelKeyState(row.playback_url_key);
                setUserId(row.user_id);
                setTitleName(row.stream_title);
                toggleModal();
                e.preventDefault();
              }}
            />
          )}
        </div>
      ),
    },
  ];
  // console.log(streamList.length);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const tourSteps = [
    {
      selector: ".create-live-stream-step",
      content: () => (
        <>
          <p style={{ fontSize: "19px", position: "relative", top: "10px" }} className="m-0">
            Create Live Stream Now!
          </p>
        </>
      ),
    },
  ];
  return (
    <>
      {streamList.length === 0 && (
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          showDots={false}
          accentColor="#e14954"
          prevButton={
            <button
              className="btn btn-secondary d-none"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Prev
            </button>
          }
          nextButton={
            <button
              className="btn btn-primary d-none"
              callbackFunc={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Next
            </button>
          }
          lastStepNextButton={
            <button
              className="btn btn-primary d-none"
              onClick={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              Create Channel Now
            </button>
          }
        />
      )}
      <section className="m-3">
        <div className="container-fluid">
          <div>
            <h5>Live Streams</h5>
            <Button
              color="primary"
              onClick={() => {
                setCreateChannel(true);
                navigate("/live-streams");
              }}
              className="my-2 create-live-stream-step"
            >
              <Icon name="plus" />
              <span>Create Live Stream</span>
            </Button>
            <Row className="mt-2 center-align-header ">
              <Col md="12">
                <PreviewCard key={streamList.length}>
                  <ReactDataTable data={streamList} columns={dataTableColumns} pagination />
                </PreviewCard>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Live Stream</ModalHeader>
        <ModalBody>
          <HlsPlayer
            src={streamUrl}
            userId={userId}
            channelKey={channelKey}
            titleName={titleName}
            setIsPlaying={setIsPlaying}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default LivesChannel;
