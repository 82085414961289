// WebcamSettingsContext.js
import React, { createContext, useState } from 'react';

export const WebcamSettingsContext = createContext();

export const WebcamSettingsProvider = ({ children }) => {
  const [volume, setVolume] = useState(1);
  const [echoCancellation, setEchoCancellation] = useState(true);
  const [noiseSuppression, setNoiseSuppression] = useState(true);
  const [autoGainControl, setAutoGainControl] = useState(true);
  const [videoWidth, setVideoWidth] = useState(485);
  const [videoHeight, setVideoHeight] = useState(360);
  const [facingMode, setFacingMode] = useState("user"); // New state for facingMode
  const [aspectRatio, setAspectRatio] = useState(1.33)

  return (
    <WebcamSettingsContext.Provider
      value={{
        volume,
        setVolume,
        echoCancellation,
        setEchoCancellation,
        noiseSuppression,
        setNoiseSuppression,
        autoGainControl,
        setAutoGainControl,
        videoWidth,
        setVideoWidth,
        videoHeight,
        setVideoHeight,
        facingMode,
        setFacingMode, 
        aspectRatio,
        setAspectRatio,
      }}
    >
      {children}
    </WebcamSettingsContext.Provider>
  );
};

export default WebcamSettingsContext;
