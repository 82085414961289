import { Button, Col, Row } from "reactstrap";
import coin from "../assets/coin.jpg";

const Test = () => {
  let stocks = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4baiSbB2n8BUGhxd9L5Ah5pnhpMh44f9ofw&usqp=CAU";

  const coins = [
    {
      id: 1,
      coinImg: coin,
      heading: "Minority Startups",
      value1: "57945.50",
      value2: "58390.00",
      value3: "57210.20",
    },
    {
      id: 2,
      coinImg: coin,
      heading: "Noitavonne Products",
      value1: "3542.30",
      value2: "3598.10",
      value3: "3510.20",
    },
    {
      id: 3,
      coinImg: coin,
      heading: "Penguin & Rook Fund",
      value1: "1.25",
      value2: "1.30",
      value3: "1.20",
    },
  ];

  const tableData = [
    {
      id: 1,
      icon: coin,
      name: "Bitcoin",
      symbol: "BTC",
      price: "$57,945.50",
      hourChange: "+0.54%",
      dayChange: "-1.20%",
      weekChange: "+3.76%",
      marketCap: "$1,080,235,000,000",
      volume24h: "$45,678,900,000",
      circulatingSupply: "18,674,562 BTC",
      graph: stocks,
    },
    {
      id: 2,
      icon: coin,
      name: "Ethereum",
      symbol: "ETH",
      price: "$3,542.30",
      hourChange: "-0.86%",
      dayChange: "+2.10%",
      weekChange: "+6.45%",
      marketCap: "$420,567,000,000",
      volume24h: "$20,345,200,000",
      circulatingSupply: "117,567,890 ETH",
      graph: stocks,
    },
    {
      id: 3,
      icon: coin,
      name: "Ripple",
      symbol: "XRP",
      price: "$1.25",
      hourChange: "+1.20%",
      dayChange: "+3.50%",
      weekChange: "+8.76%",
      marketCap: "$56,890,000,000",
      volume24h: "$5,678,900,000",
      circulatingSupply: "45,789,123,456 XRP",
      graph: stocks,
    },
    {
      id: 4,
      icon: coin,
      name: "Litecoin",
      symbol: "LTC",
      price: "$186.50",
      hourChange: "-0.32%",
      dayChange: "+1.60%",
      weekChange: "+4.25%",
      marketCap: "$12,345,678,000",
      volume24h: "$1,234,567,000",
      circulatingSupply: "66,789,012 LTC",
      graph: stocks,
    },
  ];

  return (
    <>
      <div className="m-auto my-4 bg-white p-4 pb-0" style={{ borderRadius: "25px", maxWidth: "1000px" }}>
        <Row className="justify-content-between align-items-center mb-4">
          <Col xs={12} sm={8}>
            <div>
              <h3>Today’s Prices by Market Cap</h3>
              <p>
                The Enumium Index market cap is 1.8M, a{" "}
                <em className="icon ni ni-upword-alt-fill" style={{ color: "green" }}></em> 1.82% increase over the last
                day.
                <a href="https://dashlite.net/demo3/components/elements/cards.html" target="_blank">
                  Read More
                </a>
              </p>
            </div>
          </Col>
          <Col xs="auto" sm="auto">
            <p style={{ fontSize: "20px", fontWeight: 900 }} className="text-blue pointer">
              More {">"}
            </p>
          </Col>
        </Row>
        <div style={{ overflowX: "auto" }}>
          <table>
            {coins.map((item, index) => (
              <tbody key={index}>
                <tr>
                  <td className="px-4">{index + 1}</td>
                  <td className="">
                    <img src={item.coinImg} alt="" width={45} style={{ borderRadius: "50%" }} />
                  </td>
                  <td className="px-4 ms-4" style={{fontWeight : 900}}>{item.heading}</td>
                  <td className="px-4" style={{ color: "green" }}>
                    <em className="icon ni ni-upword-alt-fill"></em>
                    {item.value1}%
                  </td>
                  <td className="px-4" style={{ color: "red" }}>
                    <em className="icon ni ni-downward-alt-fill"></em>
                    {item.value2}%
                  </td>
                  <td className="px-4" style={{ color: "red" }}>
                    <em className="icon ni ni-downward-alt-fill"></em>
                    {item.value3}%
                  </td>
                  <td className="pt-2 flex justify-content-between align-items-center">
                    <Button color="light" className="me-2">
                      Follow
                    </Button>
                    <Button color="success">Buy</Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
        <div className="flex justify-content-between w-10 m-auto mb-0 pointer" style={{ fontSize: "2rem" }}>
          <p>●</p>
          <p style={{ color: "#5D7AFF" }}>●</p>
          <p>●</p>
        </div>
      </div>

      <div className="p-4">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th> <th scope="col"></th> <th scope="col">Name</th> <th scope="col">Symbol</th>{" "}
              <th scope="col">Price</th> <th scope="col">1h%</th> <th scope="col">24h%</th> <th scope="col">7d%</th>{" "}
              <th scope="col">
                Market Cap <em class="icon ni ni-info-fill"></em>
              </th>
              <th scope="col">
                Volume(24h) <em class="icon ni ni-info-fill"></em>
              </th>
              <th scope="col">
                Circulating Supply <em class="icon ni ni-info-fill"></em>
              </th>
              <th scope="col">Graph</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <>
                  <tr>
                    <th scope="row">{index + 1}</th>{" "}
                    <td>
                      <img src={item.icon} width={25} />
                    </td>{" "}
                    <td>{item.name}</td> <td>{item.symbol}</td> <td>{item.price}</td>
                    <td style={{ color: "green" }}>{item.hourChange}</td>{" "}
                    <td style={{ color: "red" }}>{item.dayChange}</td>{" "}
                    <td style={{ color: "blue" }}>{item.weekChange}</td> <td>{item.marketCap}</td>{" "}
                    <td>{item.volume24h}</td> <td>{item.circulatingSupply}</td>
                    <td>
                      <img src={item.graph} alt="" width={100} />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Test;
