import React, { useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  Card,
  UncontrolledDropdown,
  DropdownItem,
  CardHeader,
  CardFooter,
  CardText,
  CardBody,
  CardTitle,
  Progress,
} from "reactstrap";

import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import BrowserUser from "./BrowserUser";
import UserMap from "./UserMap";
import "./analytics.css";
import { useNavigate } from "react-router";
import ViewsOverview from "./ViewsOverview";
import ChannelViews from "./ChannelViews";
import { apiRequest } from "../../utils/apiHelper";

function AnalyticsNew() {
  const [sm, updateSm] = useState(false);

  const navigate = useNavigate();

  const [analyticsOvData, setAnalyticsOvData] = useState({
    totalChannels: 0,
    totalStreams: 0,
    totalStreamTime: "0H:0M:0S",
    avgStreamTime: "0H:0M:0S",
  });

  const [viewData, setViewData] = useState({ avg_stream_views: 0, max_stream_views: 0 });

  function convertTimeFormat(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(":").map((part) => parseInt(part));

    // Total seconds
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    // Convert to the desired format
    const newHours = Math.floor(totalSeconds / 3600) % 24;
    const newMinutes = Math.floor((totalSeconds % 3600) / 60);
    const newSeconds = totalSeconds % 60;

    // Adjust for overflow
    const days = Math.floor(totalSeconds / (24 * 3600));
    const adjustedHours = days * 24 + newHours;

    return `${adjustedHours}h ${newMinutes}m ${newSeconds}s`;
  }

  const fetchAnalyticsOverview = async () => {
    try {
      const response = await apiRequest("analytics/overview");
      const data = response.data;
      setAnalyticsOvData(data);
    } catch (error) {
      console.error("Error fetching analytics overview:", error);
    }
  };

  const fetchViewData = async () => {
    try {
      const response = await apiRequest("analytics/overview-stream", "POST");
      setViewData(response.data);
    } catch (error) {
      setViewData({
        avg_stream_views: 0,
        max_stream_views: 0,
      });
      console.error("Error fetching overview data:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsOverview();
    fetchViewData();
  }, []);

  return (
    <div>
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Analytics
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Unlock Insights, Drive Growth.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        color="primary"
                        onClick={() => {
                          navigate("/analytics-old");
                        }}
                      >
                        {/* <Icon name="reload"></Icon> */}
                        <span>Old version</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {/* <Col md={2}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Broadcasted Channels</CardText>
                  <CardTitle tag="h2">{analyticsOvData.totalChannels}</CardTitle>
                  <Button
                    color="primary"
                    className="mt-3"
                    onClick={() => {
                      navigate("/channels");
                    }}
                  >
                    See All
                  </Button>
                </CardBody>
              </Card>
            </Col> */}
            <Col md={2}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Streams</CardText>
                  <CardTitle tag="h2">{analyticsOvData.totalStreams}</CardTitle>
                  {/* <Button
                    color="primary"
                    className="mt-3"
                    onClick={() => {
                      navigate("/lives");
                    }}
                  >
                    See All
                  </Button> */}
                </CardBody>
              </Card>
            </Col>

            <Col md={2}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Average Views / Stream</CardText>
                  <CardTitle tag="h2">{Math.floor(viewData.avg_stream_views)}</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={2}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Total Views</CardText>
                  <CardTitle tag="h2">{viewData.max_stream_views}</CardTitle>
                </CardBody>
              </Card>
            </Col>

            <Col md={3}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Total Stream Duration</CardText>
                  <CardTitle tag="h2">{convertTimeFormat(analyticsOvData.totalStreamTime)}</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="card-bordered">
                <CardBody className="card-inner overview-card text-center">
                  <CardText>Average Stream Duration</CardText>
                  <CardTitle tag="h2">{convertTimeFormat(analyticsOvData.avgStreamTime)}</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Block>

        <Block>
          <Row className="g-gs">
            <Col lg="8" xxl="8">
              <PreviewAltCard className="h-100 card-bordered">
                <ViewsOverview />
              </PreviewAltCard>
            </Col>
            <Col lg="4" xxl="4">
              <PreviewAltCard className="h-100 card-bordered">
                <ChannelViews />
              </PreviewAltCard>
            </Col>
          </Row>
        </Block>

        <Block>
          <Row className="g-gs">
            <Col md="6" xxl="6">
              <PreviewAltCard className="h-100 card-bordered">
                <UserMap />
              </PreviewAltCard>
            </Col>
            <Col md="6" xxl="6">
              <Card className="card-bordered h-100">
                <BrowserUser />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </div>
  );
}

export default AnalyticsNew;
