import React, { useEffect, useState } from "react";
import { BackTo, Icon } from "../../components/Component";
import { Button, Label } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import { fetchAPI } from "../../utils/api_helper";

const CopyChannel = () => {
  const [addchannelname, setaddchannelname] = useState("");
  const Navigate = useNavigate("");
  const paramschannel = useParams();

  const getChannelData = async () => {
    try {
      const channeldata = new FormData();
      channeldata.append("channel_id", paramschannel.id);
      const getChannelData = await fetchAPI(channeldata, "get-channel");
      if (getChannelData.status) {
        const dataname = getChannelData?.data?.channel?.[0]?.channel_name;
        setaddchannelname(dataname);
    } else {
      }
    } catch (error) {}
  };

  const copyChannelData = async () => {
    try {
      const channeldata = new FormData();
      channeldata.append("channel_id", paramschannel.id);
      const getChannelData = await fetchAPI(channeldata, "copy-channel");
      if (getChannelData.status) {
        Navigate("/channels");
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getChannelData();
  }, []);

  return (
    <>
      <section className=" m-3">
        <div className="container-fluid">
          <div className="bg-white p-3">
            <h5 className="pointer mb-3 d-inline-block" onClick={() => Navigate(-1)}>
              <Icon name="arrow-left" className="pe-1"></Icon>Duplicate channel
            </h5>
            <div className="form-group">
              <Label htmlFor="default-0" className="form-label">
                <h6 className="fw-semibold">Name</h6>
              </Label>
              <div className="form-control-wrap">
                <input className="form-control" type="text" id="" readOnly value={"Copy of " + addchannelname} />
              </div>
            </div>
            <div className="nk-modal-action justify-end">
              <ul className="btn-toolbar mt-4 g-4 align-center">
                <li>
                  <Button onClick={() => Navigate(-1)} color="primary" outline>
                    No
                  </Button>
                </li>
                <li>
                  <Button color="primary" onClick={copyChannelData}>
                    Yes
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CopyChannel;
