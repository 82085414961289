import React, { useState, useEffect } from "react";
import "./datepicker.css";
import { Icon } from "../../components/Component";

function DatePicker({ onDateSelect, onClose, selectedDate: initialSelectedDate }) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [displayedMonth, setDisplayedMonth] = useState(currentDate.getMonth());
  const [displayedYear, setDisplayedYear] = useState(currentDate.getFullYear());
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate ? new Date(initialSelectedDate) : null);

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  useEffect(() => {
    if (initialSelectedDate) {
      setSelectedDate(new Date(initialSelectedDate));
      setDisplayedMonth(new Date(initialSelectedDate).getMonth());
      setDisplayedYear(new Date(initialSelectedDate).getFullYear());
    }
  }, [initialSelectedDate]);

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePreviousMonth = () => {
    if (displayedMonth === 0) {
      setDisplayedMonth(11);
      setDisplayedYear(displayedYear - 1);
    } else {
      setDisplayedMonth(displayedMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (displayedMonth === 11) {
      setDisplayedMonth(0);
      setDisplayedYear(displayedYear + 1);
    } else {
      setDisplayedMonth(displayedMonth + 1);
    }
  };

  const handleDateClick = (day) => {
    const selectedDate = new Date(displayedYear, displayedMonth, day);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const date = String(selectedDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;
    setSelectedDate(selectedDate); // Update the selected date state
    onDateSelect(formattedDate);
  };

  const generateCalendar = () => {
    const daysInCurrentMonth = getDaysInMonth(displayedMonth, displayedYear);
    const firstDayOfMonth = getFirstDayOfMonth(displayedMonth, displayedYear);
    const daysInPreviousMonth = getDaysInMonth(displayedMonth - 1, displayedYear);

    const calendarDays = [];

    // Days from previous month
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      calendarDays.push({
        day: daysInPreviousMonth - i,
        currentMonth: false,
      });
    }

    // Days of current month
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      calendarDays.push({
        day: i,
        currentMonth: true,
        selectedDay:
          selectedDate &&
          i === selectedDate.getDate() &&
          displayedMonth === selectedDate.getMonth() &&
          displayedYear === selectedDate.getFullYear(),
      });
    }

    // Days from next month
    const totalDays = calendarDays.length;
    const remainingDays = 42 - totalDays; // A full 6-week calendar view
    for (let i = 1; i <= remainingDays; i++) {
      calendarDays.push({
        day: i,
        currentMonth: false,
      });
    }

    return calendarDays;
  };

  const calendarDays = generateCalendar();

  return (
    <div className="datepicker">
      <div className="d-flex justify-content-end mb-2 pointer" onClick={onClose}>
        <Icon name="cross" className="fs-3 text-danger" />
      </div>
      <div className="datepicker-top">
        <div className="month-selector">
          <button className="arrow" onClick={handlePreviousMonth}>
            <Icon name="chevron-left" />
          </button>
          <span className="month-name text-black fs-2 ff-mono">
            {new Date(displayedYear, displayedMonth).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </span>
          <button className="arrow" onClick={handleNextMonth}>
            <Icon name="chevron-right" />
          </button>
        </div>
      </div>
      <div className="datepicker-calendar">
        {daysOfWeek.map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {calendarDays.map((date, index) => (
          <button
            key={index}
            className={`date ${!date.currentMonth ? "faded" : ""} ${date.selectedDay ? "selected-day" : ""}`}
            onClick={() => date.currentMonth && handleDateClick(date.day)}
          >
            {date.day}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DatePicker;
